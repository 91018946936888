html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted; /* 2 */
}

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button,
input {
  /* 1 */
  overflow: visible;
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

button {
  background-color: transparent;
  background-image: none;
  padding: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

html {
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 1 */
  line-height: 1.5; /* 2 */
}

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: #d5d6d7; /* 2 */
}

hr {
  border-top-width: 1px;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #a0aec0;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #a0aec0;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #a0aec0;
}

input::placeholder,
textarea::placeholder {
  color: #a0aec0;
}

button,
[role="button"] {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

pre,
code,
kbd,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
    monospace;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (max-width: 768px) {
  .desktop-only {
    display: none !important;
  }

  .mobile-only {
    display: block !important;
  }
}

@media (min-width: 769px) {
  .mobile-only {
    display: none !important;
  }
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-local {
  background-attachment: local;
}

.bg-scroll {
  background-attachment: scroll;
}

.bg-transparent {
  background-color: transparent;
}

.bg-white {
  --bg-opacity: 1;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.bg-black {
  --bg-opacity: 1;
  background-color: #000000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.bg-gray-50 {
  --bg-opacity: 1;
  background-color: #f9fafb;
  background-color: rgba(249, 250, 251, var(--bg-opacity));
}

.bg-gray-100 {
  --bg-opacity: 1;
  background-color: #f4f5f7;
  background-color: rgba(244, 245, 247, var(--bg-opacity));
}

.bg-gray-200 {
  --bg-opacity: 1;
  background-color: #e5e7eb;
  background-color: rgba(229, 231, 235, var(--bg-opacity));
}

.bg-gray-300 {
  --bg-opacity: 1;
  background-color: #d5d6d7;
  background-color: rgba(213, 214, 215, var(--bg-opacity));
}

.bg-gray-400 {
  --bg-opacity: 1;
  background-color: #9e9e9e;
  background-color: rgba(158, 158, 158, var(--bg-opacity));
}

.bg-gray-500 {
  --bg-opacity: 1;
  background-color: #707275;
  background-color: rgba(112, 114, 117, var(--bg-opacity));
}

.bg-gray-600 {
  --bg-opacity: 1;
  background-color: #4c4f52;
  background-color: rgba(76, 79, 82, var(--bg-opacity));
}

.bg-gray-700 {
  --bg-opacity: 1;
  background-color: #24262d;
  background-color: rgba(36, 38, 45, var(--bg-opacity));
}

.bg-gray-800 {
  --bg-opacity: 1;
  background-color: #1a1c23;
  background-color: rgba(26, 28, 35, var(--bg-opacity));
}

.bg-gray-900 {
  --bg-opacity: 1;
  background-color: #121317;
  background-color: rgba(18, 19, 23, var(--bg-opacity));
}

.bg-cool-gray-50 {
  --bg-opacity: 1;
  background-color: #fbfdfe;
  background-color: rgba(251, 253, 254, var(--bg-opacity));
}

.bg-cool-gray-100 {
  --bg-opacity: 1;
  background-color: #f1f5f9;
  background-color: rgba(241, 245, 249, var(--bg-opacity));
}

.bg-cool-gray-200 {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity));
}

.bg-cool-gray-300 {
  --bg-opacity: 1;
  background-color: #cfd8e3;
  background-color: rgba(207, 216, 227, var(--bg-opacity));
}

.bg-cool-gray-400 {
  --bg-opacity: 1;
  background-color: #97a6ba;
  background-color: rgba(151, 166, 186, var(--bg-opacity));
}

.bg-cool-gray-500 {
  --bg-opacity: 1;
  background-color: #64748b;
  background-color: rgba(100, 116, 139, var(--bg-opacity));
}

.bg-cool-gray-600 {
  --bg-opacity: 1;
  background-color: #475569;
  background-color: rgba(71, 85, 105, var(--bg-opacity));
}

.bg-cool-gray-700 {
  --bg-opacity: 1;
  background-color: #364152;
  background-color: rgba(54, 65, 82, var(--bg-opacity));
}

.bg-cool-gray-800 {
  --bg-opacity: 1;
  background-color: #27303f;
  background-color: rgba(39, 48, 63, var(--bg-opacity));
}

.bg-cool-gray-900 {
  --bg-opacity: 1;
  background-color: #1a202e;
  background-color: rgba(26, 32, 46, var(--bg-opacity));
}

.bg-red-50 {
  --bg-opacity: 1;
  background-color: #fdf2f2;
  background-color: rgba(253, 242, 242, var(--bg-opacity));
}

.bg-red-100 {
  --bg-opacity: 1;
  background-color: #fde8e8;
  background-color: rgba(253, 232, 232, var(--bg-opacity));
}

.bg-red-200 {
  --bg-opacity: 1;
  background-color: #fbd5d5;
  background-color: rgba(251, 213, 213, var(--bg-opacity));
}

.bg-red-300 {
  --bg-opacity: 1;
  background-color: #f8b4b4;
  background-color: rgba(248, 180, 180, var(--bg-opacity));
}

.bg-red-400 {
  --bg-opacity: 1;
  background-color: #f98080;
  background-color: rgba(249, 128, 128, var(--bg-opacity));
}

.bg-red-500 {
  --bg-opacity: 1;
  background-color: #f05252;
  background-color: rgba(240, 82, 82, var(--bg-opacity));
}

.bg-red-600 {
  --bg-opacity: 1;
  background-color: #e02424;
  background-color: rgba(224, 36, 36, var(--bg-opacity));
}

.bg-red-700 {
  --bg-opacity: 1;
  background-color: #c81e1e;
  background-color: rgba(200, 30, 30, var(--bg-opacity));
}

.bg-red-800 {
  --bg-opacity: 1;
  background-color: #9b1c1c;
  background-color: rgba(155, 28, 28, var(--bg-opacity));
}

.bg-red-900 {
  --bg-opacity: 1;
  background-color: #771d1d;
  background-color: rgba(119, 29, 29, var(--bg-opacity));
}

.bg-orange-50 {
  --bg-opacity: 1;
  background-color: #fff8f1;
  background-color: rgba(255, 248, 241, var(--bg-opacity));
}

.bg-orange-100 {
  --bg-opacity: 1;
  background-color: #feecdc;
  background-color: rgba(254, 236, 220, var(--bg-opacity));
}

.bg-orange-200 {
  --bg-opacity: 1;
  background-color: #fcd9bd;
  background-color: rgba(252, 217, 189, var(--bg-opacity));
}

.bg-orange-300 {
  --bg-opacity: 1;
  background-color: #fdba8c;
  background-color: rgba(253, 186, 140, var(--bg-opacity));
}

.bg-orange-400 {
  --bg-opacity: 1;
  background-color: #ff8a4c;
  background-color: rgba(255, 138, 76, var(--bg-opacity));
}

.bg-orange-500 {
  --bg-opacity: 1;
  background-color: #ff5a1f;
  background-color: rgba(255, 90, 31, var(--bg-opacity));
}

.bg-orange-600 {
  --bg-opacity: 1;
  background-color: #d03801;
  background-color: rgba(208, 56, 1, var(--bg-opacity));
}

.bg-orange-700 {
  --bg-opacity: 1;
  background-color: #b43403;
  background-color: rgba(180, 52, 3, var(--bg-opacity));
}

.bg-orange-800 {
  --bg-opacity: 1;
  background-color: #8a2c0d;
  background-color: rgba(138, 44, 13, var(--bg-opacity));
}

.bg-orange-900 {
  --bg-opacity: 1;
  background-color: #771d1d;
  background-color: rgba(119, 29, 29, var(--bg-opacity));
}

.bg-yellow-50 {
  --bg-opacity: 1;
  background-color: #fdfdea;
  background-color: rgba(253, 253, 234, var(--bg-opacity));
}

.bg-yellow-100 {
  --bg-opacity: 1;
  background-color: #fdf6b2;
  background-color: rgba(253, 246, 178, var(--bg-opacity));
}

.bg-yellow-200 {
  --bg-opacity: 1;
  background-color: #fce96a;
  background-color: rgba(252, 233, 106, var(--bg-opacity));
}

.bg-yellow-300 {
  --bg-opacity: 1;
  background-color: #faca15;
  background-color: rgba(250, 202, 21, var(--bg-opacity));
}

.bg-yellow-400 {
  --bg-opacity: 1;
  background-color: #e3a008;
  background-color: rgba(227, 160, 8, var(--bg-opacity));
}

.bg-yellow-500 {
  --bg-opacity: 1;
  background-color: #c27803;
  background-color: rgba(194, 120, 3, var(--bg-opacity));
}

.bg-yellow-600 {
  --bg-opacity: 1;
  background-color: #9f580a;
  background-color: rgba(159, 88, 10, var(--bg-opacity));
}

.bg-yellow-700 {
  --bg-opacity: 1;
  background-color: #8e4b10;
  background-color: rgba(142, 75, 16, var(--bg-opacity));
}

.bg-yellow-800 {
  --bg-opacity: 1;
  background-color: #723b13;
  background-color: rgba(114, 59, 19, var(--bg-opacity));
}

.bg-yellow-900 {
  --bg-opacity: 1;
  background-color: #633112;
  background-color: rgba(99, 49, 18, var(--bg-opacity));
}

.bg-green-50 {
  --bg-opacity: 1;
  background-color: #f3faf7;
  background-color: rgba(243, 250, 247, var(--bg-opacity));
}

.bg-green-100 {
  --bg-opacity: 1;
  background-color: #def7ec;
  background-color: rgba(222, 247, 236, var(--bg-opacity));
}

.bg-green-200 {
  --bg-opacity: 1;
  background-color: #bcf0da;
  background-color: rgba(188, 240, 218, var(--bg-opacity));
}

.bg-green-300 {
  --bg-opacity: 1;
  background-color: #84e1bc;
  background-color: rgba(132, 225, 188, var(--bg-opacity));
}

.bg-green-400 {
  --bg-opacity: 1;
  background-color: #31c48d;
  background-color: rgba(49, 196, 141, var(--bg-opacity));
}

.bg-green-500 {
  --bg-opacity: 1;
  background-color: #0e9f6e;
  background-color: rgba(14, 159, 110, var(--bg-opacity));
}

.bg-green-600 {
  --bg-opacity: 1;
  background-color: #057a55;
  background-color: rgba(5, 122, 85, var(--bg-opacity));
}

.bg-green-700 {
  --bg-opacity: 1;
  background-color: #046c4e;
  background-color: rgba(4, 108, 78, var(--bg-opacity));
}

.bg-green-800 {
  --bg-opacity: 1;
  background-color: #03543f;
  background-color: rgba(3, 84, 63, var(--bg-opacity));
}

.bg-green-900 {
  --bg-opacity: 1;
  background-color: #014737;
  background-color: rgba(1, 71, 55, var(--bg-opacity));
}

.bg-teal-50 {
  --bg-opacity: 1;
  background-color: #edfafa;
  background-color: rgba(237, 250, 250, var(--bg-opacity));
}

.bg-teal-100 {
  --bg-opacity: 1;
  background-color: #d5f5f6;
  background-color: rgba(213, 245, 246, var(--bg-opacity));
}

.bg-teal-200 {
  --bg-opacity: 1;
  background-color: #afecef;
  background-color: rgba(175, 236, 239, var(--bg-opacity));
}

.bg-teal-300 {
  --bg-opacity: 1;
  background-color: #7edce2;
  background-color: rgba(126, 220, 226, var(--bg-opacity));
}

.bg-teal-400 {
  --bg-opacity: 1;
  background-color: #16bdca;
  background-color: rgba(22, 189, 202, var(--bg-opacity));
}

.bg-teal-500 {
  --bg-opacity: 1;
  background-color: #0694a2;
  background-color: rgba(6, 148, 162, var(--bg-opacity));
}

.bg-teal-600 {
  --bg-opacity: 1;
  background-color: #047481;
  background-color: rgba(4, 116, 129, var(--bg-opacity));
}

.bg-teal-700 {
  --bg-opacity: 1;
  background-color: #036672;
  background-color: rgba(3, 102, 114, var(--bg-opacity));
}

.bg-teal-800 {
  --bg-opacity: 1;
  background-color: #05505c;
  background-color: rgba(5, 80, 92, var(--bg-opacity));
}

.bg-teal-900 {
  --bg-opacity: 1;
  background-color: #014451;
  background-color: rgba(1, 68, 81, var(--bg-opacity));
}

.bg-blue-50 {
  --bg-opacity: 1;
  background-color: #ebf5ff;
  background-color: rgba(235, 245, 255, var(--bg-opacity));
}

.bg-blue-100 {
  --bg-opacity: 1;
  background-color: #e1effe;
  background-color: rgba(225, 239, 254, var(--bg-opacity));
}

.bg-blue-200 {
  --bg-opacity: 1;
  background-color: #c3ddfd;
  background-color: rgba(195, 221, 253, var(--bg-opacity));
}

.bg-blue-300 {
  --bg-opacity: 1;
  background-color: #a4cafe;
  background-color: rgba(164, 202, 254, var(--bg-opacity));
}

.bg-blue-400 {
  --bg-opacity: 1;
  background-color: #76a9fa;
  background-color: rgba(118, 169, 250, var(--bg-opacity));
}

.bg-blue-500 {
  --bg-opacity: 1;
  background-color: #3f83f8;
  background-color: rgba(63, 131, 248, var(--bg-opacity));
}

.bg-blue-600 {
  --bg-opacity: 1;
  background-color: #1c64f2;
  background-color: rgba(28, 100, 242, var(--bg-opacity));
}

.bg-blue-700 {
  --bg-opacity: 1;
  background-color: #1a56db;
  background-color: rgba(26, 86, 219, var(--bg-opacity));
}

.bg-blue-800 {
  --bg-opacity: 1;
  background-color: #1e429f;
  background-color: rgba(30, 66, 159, var(--bg-opacity));
}

.bg-blue-900 {
  --bg-opacity: 1;
  background-color: #233876;
  background-color: rgba(35, 56, 118, var(--bg-opacity));
}

.bg-indigo-50 {
  --bg-opacity: 1;
  background-color: #f0f5ff;
  background-color: rgba(240, 245, 255, var(--bg-opacity));
}

.bg-indigo-100 {
  --bg-opacity: 1;
  background-color: #e5edff;
  background-color: rgba(229, 237, 255, var(--bg-opacity));
}

.bg-indigo-200 {
  --bg-opacity: 1;
  background-color: #cddbfe;
  background-color: rgba(205, 219, 254, var(--bg-opacity));
}

.bg-indigo-300 {
  --bg-opacity: 1;
  background-color: #b4c6fc;
  background-color: rgba(180, 198, 252, var(--bg-opacity));
}

.bg-indigo-400 {
  --bg-opacity: 1;
  background-color: #8da2fb;
  background-color: rgba(141, 162, 251, var(--bg-opacity));
}

.bg-indigo-500 {
  --bg-opacity: 1;
  background-color: #6875f5;
  background-color: rgba(104, 117, 245, var(--bg-opacity));
}

.bg-indigo-600 {
  --bg-opacity: 1;
  background-color: #5850ec;
  background-color: rgba(88, 80, 236, var(--bg-opacity));
}

.bg-indigo-700 {
  --bg-opacity: 1;
  background-color: #5145cd;
  background-color: rgba(81, 69, 205, var(--bg-opacity));
}

.bg-indigo-800 {
  --bg-opacity: 1;
  background-color: #42389d;
  background-color: rgba(66, 56, 157, var(--bg-opacity));
}

.bg-indigo-900 {
  --bg-opacity: 1;
  background-color: #362f78;
  background-color: rgba(54, 47, 120, var(--bg-opacity));
}

.bg-purple-50 {
  --bg-opacity: 1;
  background-color: #f6f5ff;
  background-color: rgba(246, 245, 255, var(--bg-opacity));
}

.bg-purple-100 {
  --bg-opacity: 1;
  background-color: #edebfe;
  background-color: rgba(237, 235, 254, var(--bg-opacity));
}

.bg-purple-200 {
  --bg-opacity: 1;
  background-color: #dcd7fe;
  background-color: rgba(220, 215, 254, var(--bg-opacity));
}

.bg-purple-300 {
  --bg-opacity: 1;
  background-color: #cabffd;
  background-color: rgba(202, 191, 253, var(--bg-opacity));
}

.bg-purple-400 {
  --bg-opacity: 1;
  background-color: #ac94fa;
  background-color: rgba(172, 148, 250, var(--bg-opacity));
}

.bg-purple-500 {
  --bg-opacity: 1;
  background-color: #9061f9;
  background-color: rgba(144, 97, 249, var(--bg-opacity));
}

.bg-purple-600 {
  --bg-opacity: 1;
  background-color: #7e3af2;
  background-color: rgba(126, 58, 242, var(--bg-opacity));
}

.bg-purple-700 {
  --bg-opacity: 1;
  background-color: #6c2bd9;
  background-color: rgba(108, 43, 217, var(--bg-opacity));
}

.bg-purple-800 {
  --bg-opacity: 1;
  background-color: #5521b5;
  background-color: rgba(85, 33, 181, var(--bg-opacity));
}

.bg-purple-900 {
  --bg-opacity: 1;
  background-color: #4a1d96;
  background-color: rgba(74, 29, 150, var(--bg-opacity));
}

.bg-pink-50 {
  --bg-opacity: 1;
  background-color: #fdf2f8;
  background-color: rgba(253, 242, 248, var(--bg-opacity));
}

.bg-pink-100 {
  --bg-opacity: 1;
  background-color: #fce8f3;
  background-color: rgba(252, 232, 243, var(--bg-opacity));
}

.bg-pink-200 {
  --bg-opacity: 1;
  background-color: #fad1e8;
  background-color: rgba(250, 209, 232, var(--bg-opacity));
}

.bg-pink-300 {
  --bg-opacity: 1;
  background-color: #f8b4d9;
  background-color: rgba(248, 180, 217, var(--bg-opacity));
}

.bg-pink-400 {
  --bg-opacity: 1;
  background-color: #f17eb8;
  background-color: rgba(241, 126, 184, var(--bg-opacity));
}

.bg-pink-500 {
  --bg-opacity: 1;
  background-color: #e74694;
  background-color: rgba(231, 70, 148, var(--bg-opacity));
}

.bg-pink-600 {
  --bg-opacity: 1;
  background-color: #d61f69;
  background-color: rgba(214, 31, 105, var(--bg-opacity));
}

.bg-pink-700 {
  --bg-opacity: 1;
  background-color: #bf125d;
  background-color: rgba(191, 18, 93, var(--bg-opacity));
}

.bg-pink-800 {
  --bg-opacity: 1;
  background-color: #99154b;
  background-color: rgba(153, 21, 75, var(--bg-opacity));
}

.bg-pink-900 {
  --bg-opacity: 1;
  background-color: #751a3d;
  background-color: rgba(117, 26, 61, var(--bg-opacity));
}

.hover\:bg-transparent:hover {
  background-color: transparent;
}

.hover\:bg-white:hover {
  --bg-opacity: 1;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.hover\:bg-black:hover {
  --bg-opacity: 1;
  background-color: #000000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.hover\:bg-gray-50:hover {
  --bg-opacity: 1;
  background-color: #f9fafb;
  background-color: rgba(249, 250, 251, var(--bg-opacity));
}

.hover\:bg-gray-100:hover {
  --bg-opacity: 1;
  background-color: #f4f5f7;
  background-color: rgba(244, 245, 247, var(--bg-opacity));
}

.hover\:bg-gray-200:hover {
  --bg-opacity: 1;
  background-color: #e5e7eb;
  background-color: rgba(229, 231, 235, var(--bg-opacity));
}

.hover\:bg-gray-300:hover {
  --bg-opacity: 1;
  background-color: #d5d6d7;
  background-color: rgba(213, 214, 215, var(--bg-opacity));
}

.hover\:bg-gray-400:hover {
  --bg-opacity: 1;
  background-color: #9e9e9e;
  background-color: rgba(158, 158, 158, var(--bg-opacity));
}

.hover\:bg-gray-500:hover {
  --bg-opacity: 1;
  background-color: #707275;
  background-color: rgba(112, 114, 117, var(--bg-opacity));
}

.hover\:bg-gray-600:hover {
  --bg-opacity: 1;
  background-color: #4c4f52;
  background-color: rgba(76, 79, 82, var(--bg-opacity));
}

.hover\:bg-gray-700:hover {
  --bg-opacity: 1;
  background-color: #24262d;
  background-color: rgba(36, 38, 45, var(--bg-opacity));
}

.hover\:bg-gray-800:hover {
  --bg-opacity: 1;
  background-color: #1a1c23;
  background-color: rgba(26, 28, 35, var(--bg-opacity));
}

.hover\:bg-gray-900:hover {
  --bg-opacity: 1;
  background-color: #121317;
  background-color: rgba(18, 19, 23, var(--bg-opacity));
}

.hover\:bg-cool-gray-50:hover {
  --bg-opacity: 1;
  background-color: #fbfdfe;
  background-color: rgba(251, 253, 254, var(--bg-opacity));
}

.hover\:bg-cool-gray-100:hover {
  --bg-opacity: 1;
  background-color: #f1f5f9;
  background-color: rgba(241, 245, 249, var(--bg-opacity));
}

.hover\:bg-cool-gray-200:hover {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity));
}

.hover\:bg-cool-gray-300:hover {
  --bg-opacity: 1;
  background-color: #cfd8e3;
  background-color: rgba(207, 216, 227, var(--bg-opacity));
}

.hover\:bg-cool-gray-400:hover {
  --bg-opacity: 1;
  background-color: #97a6ba;
  background-color: rgba(151, 166, 186, var(--bg-opacity));
}

.hover\:bg-cool-gray-500:hover {
  --bg-opacity: 1;
  background-color: #64748b;
  background-color: rgba(100, 116, 139, var(--bg-opacity));
}

.hover\:bg-cool-gray-600:hover {
  --bg-opacity: 1;
  background-color: #475569;
  background-color: rgba(71, 85, 105, var(--bg-opacity));
}

.hover\:bg-cool-gray-700:hover {
  --bg-opacity: 1;
  background-color: #364152;
  background-color: rgba(54, 65, 82, var(--bg-opacity));
}

.hover\:bg-cool-gray-800:hover {
  --bg-opacity: 1;
  background-color: #27303f;
  background-color: rgba(39, 48, 63, var(--bg-opacity));
}

.hover\:bg-cool-gray-900:hover {
  --bg-opacity: 1;
  background-color: #1a202e;
  background-color: rgba(26, 32, 46, var(--bg-opacity));
}

.hover\:bg-red-50:hover {
  --bg-opacity: 1;
  background-color: #fdf2f2;
  background-color: rgba(253, 242, 242, var(--bg-opacity));
}

.hover\:bg-red-100:hover {
  --bg-opacity: 1;
  background-color: #fde8e8;
  background-color: rgba(253, 232, 232, var(--bg-opacity));
}

.hover\:bg-red-200:hover {
  --bg-opacity: 1;
  background-color: #fbd5d5;
  background-color: rgba(251, 213, 213, var(--bg-opacity));
}

.hover\:bg-red-300:hover {
  --bg-opacity: 1;
  background-color: #f8b4b4;
  background-color: rgba(248, 180, 180, var(--bg-opacity));
}

.hover\:bg-red-400:hover {
  --bg-opacity: 1;
  background-color: #f98080;
  background-color: rgba(249, 128, 128, var(--bg-opacity));
}

.hover\:bg-red-500:hover {
  --bg-opacity: 1;
  background-color: #f05252;
  background-color: rgba(240, 82, 82, var(--bg-opacity));
}

.hover\:bg-red-600:hover {
  --bg-opacity: 1;
  background-color: #e02424;
  background-color: rgba(224, 36, 36, var(--bg-opacity));
}

.hover\:bg-red-700:hover {
  --bg-opacity: 1;
  background-color: #c81e1e;
  background-color: rgba(200, 30, 30, var(--bg-opacity));
}

.hover\:bg-red-800:hover {
  --bg-opacity: 1;
  background-color: #9b1c1c;
  background-color: rgba(155, 28, 28, var(--bg-opacity));
}

.hover\:bg-red-900:hover {
  --bg-opacity: 1;
  background-color: #771d1d;
  background-color: rgba(119, 29, 29, var(--bg-opacity));
}

.hover\:bg-orange-50:hover {
  --bg-opacity: 1;
  background-color: #fff8f1;
  background-color: rgba(255, 248, 241, var(--bg-opacity));
}

.hover\:bg-orange-100:hover {
  --bg-opacity: 1;
  background-color: #feecdc;
  background-color: rgba(254, 236, 220, var(--bg-opacity));
}

.hover\:bg-orange-200:hover {
  --bg-opacity: 1;
  background-color: #fcd9bd;
  background-color: rgba(252, 217, 189, var(--bg-opacity));
}

.hover\:bg-orange-300:hover {
  --bg-opacity: 1;
  background-color: #fdba8c;
  background-color: rgba(253, 186, 140, var(--bg-opacity));
}

.hover\:bg-orange-400:hover {
  --bg-opacity: 1;
  background-color: #ff8a4c;
  background-color: rgba(255, 138, 76, var(--bg-opacity));
}

.hover\:bg-orange-500:hover {
  --bg-opacity: 1;
  background-color: #ff5a1f;
  background-color: rgba(255, 90, 31, var(--bg-opacity));
}

.hover\:bg-orange-600:hover {
  --bg-opacity: 1;
  background-color: #d03801;
  background-color: rgba(208, 56, 1, var(--bg-opacity));
}

.hover\:bg-orange-700:hover {
  --bg-opacity: 1;
  background-color: #b43403;
  background-color: rgba(180, 52, 3, var(--bg-opacity));
}

.hover\:bg-orange-800:hover {
  --bg-opacity: 1;
  background-color: #8a2c0d;
  background-color: rgba(138, 44, 13, var(--bg-opacity));
}

.hover\:bg-orange-900:hover {
  --bg-opacity: 1;
  background-color: #771d1d;
  background-color: rgba(119, 29, 29, var(--bg-opacity));
}

.hover\:bg-yellow-50:hover {
  --bg-opacity: 1;
  background-color: #fdfdea;
  background-color: rgba(253, 253, 234, var(--bg-opacity));
}

.hover\:bg-yellow-100:hover {
  --bg-opacity: 1;
  background-color: #fdf6b2;
  background-color: rgba(253, 246, 178, var(--bg-opacity));
}

.hover\:bg-yellow-200:hover {
  --bg-opacity: 1;
  background-color: #fce96a;
  background-color: rgba(252, 233, 106, var(--bg-opacity));
}

.hover\:bg-yellow-300:hover {
  --bg-opacity: 1;
  background-color: #faca15;
  background-color: rgba(250, 202, 21, var(--bg-opacity));
}

.hover\:bg-yellow-400:hover {
  --bg-opacity: 1;
  background-color: #e3a008;
  background-color: rgba(227, 160, 8, var(--bg-opacity));
}

.hover\:bg-yellow-500:hover {
  --bg-opacity: 1;
  background-color: #c27803;
  background-color: rgba(194, 120, 3, var(--bg-opacity));
}

.hover\:bg-yellow-600:hover {
  --bg-opacity: 1;
  background-color: #9f580a;
  background-color: rgba(159, 88, 10, var(--bg-opacity));
}

.hover\:bg-yellow-700:hover {
  --bg-opacity: 1;
  background-color: #8e4b10;
  background-color: rgba(142, 75, 16, var(--bg-opacity));
}

.hover\:bg-yellow-800:hover {
  --bg-opacity: 1;
  background-color: #723b13;
  background-color: rgba(114, 59, 19, var(--bg-opacity));
}

.hover\:bg-yellow-900:hover {
  --bg-opacity: 1;
  background-color: #633112;
  background-color: rgba(99, 49, 18, var(--bg-opacity));
}

.hover\:bg-green-50:hover {
  --bg-opacity: 1;
  background-color: #f3faf7;
  background-color: rgba(243, 250, 247, var(--bg-opacity));
}

.hover\:bg-green-100:hover {
  --bg-opacity: 1;
  background-color: #def7ec;
  background-color: rgba(222, 247, 236, var(--bg-opacity));
}

.hover\:bg-green-200:hover {
  --bg-opacity: 1;
  background-color: #bcf0da;
  background-color: rgba(188, 240, 218, var(--bg-opacity));
}

.hover\:bg-green-300:hover {
  --bg-opacity: 1;
  background-color: #84e1bc;
  background-color: rgba(132, 225, 188, var(--bg-opacity));
}

.hover\:bg-green-400:hover {
  --bg-opacity: 1;
  background-color: #31c48d;
  background-color: rgba(49, 196, 141, var(--bg-opacity));
}

.hover\:bg-green-500:hover {
  --bg-opacity: 1;
  background-color: #0e9f6e;
  background-color: rgba(14, 159, 110, var(--bg-opacity));
}

.hover\:bg-green-600:hover {
  --bg-opacity: 1;
  background-color: #057a55;
  background-color: rgba(5, 122, 85, var(--bg-opacity));
}

.hover\:bg-green-700:hover {
  --bg-opacity: 1;
  background-color: #046c4e;
  background-color: rgba(4, 108, 78, var(--bg-opacity));
}

.hover\:bg-green-800:hover {
  --bg-opacity: 1;
  background-color: #03543f;
  background-color: rgba(3, 84, 63, var(--bg-opacity));
}

.hover\:bg-green-900:hover {
  --bg-opacity: 1;
  background-color: #014737;
  background-color: rgba(1, 71, 55, var(--bg-opacity));
}

.hover\:bg-teal-50:hover {
  --bg-opacity: 1;
  background-color: #edfafa;
  background-color: rgba(237, 250, 250, var(--bg-opacity));
}

.hover\:bg-teal-100:hover {
  --bg-opacity: 1;
  background-color: #d5f5f6;
  background-color: rgba(213, 245, 246, var(--bg-opacity));
}

.hover\:bg-teal-200:hover {
  --bg-opacity: 1;
  background-color: #afecef;
  background-color: rgba(175, 236, 239, var(--bg-opacity));
}

.hover\:bg-teal-300:hover {
  --bg-opacity: 1;
  background-color: #7edce2;
  background-color: rgba(126, 220, 226, var(--bg-opacity));
}

.hover\:bg-teal-400:hover {
  --bg-opacity: 1;
  background-color: #16bdca;
  background-color: rgba(22, 189, 202, var(--bg-opacity));
}

.hover\:bg-teal-500:hover {
  --bg-opacity: 1;
  background-color: #0694a2;
  background-color: rgba(6, 148, 162, var(--bg-opacity));
}

.hover\:bg-teal-600:hover {
  --bg-opacity: 1;
  background-color: #047481;
  background-color: rgba(4, 116, 129, var(--bg-opacity));
}

.hover\:bg-teal-700:hover {
  --bg-opacity: 1;
  background-color: #036672;
  background-color: rgba(3, 102, 114, var(--bg-opacity));
}

.hover\:bg-teal-800:hover {
  --bg-opacity: 1;
  background-color: #05505c;
  background-color: rgba(5, 80, 92, var(--bg-opacity));
}

.hover\:bg-teal-900:hover {
  --bg-opacity: 1;
  background-color: #014451;
  background-color: rgba(1, 68, 81, var(--bg-opacity));
}

.hover\:bg-blue-50:hover {
  --bg-opacity: 1;
  background-color: #ebf5ff;
  background-color: rgba(235, 245, 255, var(--bg-opacity));
}

.hover\:bg-blue-100:hover {
  --bg-opacity: 1;
  background-color: #e1effe;
  background-color: rgba(225, 239, 254, var(--bg-opacity));
}

.hover\:bg-blue-200:hover {
  --bg-opacity: 1;
  background-color: #c3ddfd;
  background-color: rgba(195, 221, 253, var(--bg-opacity));
}

.hover\:bg-blue-300:hover {
  --bg-opacity: 1;
  background-color: #a4cafe;
  background-color: rgba(164, 202, 254, var(--bg-opacity));
}

.hover\:bg-blue-400:hover {
  --bg-opacity: 1;
  background-color: #76a9fa;
  background-color: rgba(118, 169, 250, var(--bg-opacity));
}

.hover\:bg-blue-500:hover {
  --bg-opacity: 1;
  background-color: #3f83f8;
  background-color: rgba(63, 131, 248, var(--bg-opacity));
}

.hover\:bg-blue-600:hover {
  --bg-opacity: 1;
  background-color: #1c64f2;
  background-color: rgba(28, 100, 242, var(--bg-opacity));
}

.hover\:bg-blue-700:hover {
  --bg-opacity: 1;
  background-color: #1a56db;
  background-color: rgba(26, 86, 219, var(--bg-opacity));
}

.hover\:bg-blue-800:hover {
  --bg-opacity: 1;
  background-color: #1e429f;
  background-color: rgba(30, 66, 159, var(--bg-opacity));
}

.hover\:bg-blue-900:hover {
  --bg-opacity: 1;
  background-color: #233876;
  background-color: rgba(35, 56, 118, var(--bg-opacity));
}

.hover\:bg-indigo-50:hover {
  --bg-opacity: 1;
  background-color: #f0f5ff;
  background-color: rgba(240, 245, 255, var(--bg-opacity));
}

.hover\:bg-indigo-100:hover {
  --bg-opacity: 1;
  background-color: #e5edff;
  background-color: rgba(229, 237, 255, var(--bg-opacity));
}

.hover\:bg-indigo-200:hover {
  --bg-opacity: 1;
  background-color: #cddbfe;
  background-color: rgba(205, 219, 254, var(--bg-opacity));
}

.hover\:bg-indigo-300:hover {
  --bg-opacity: 1;
  background-color: #b4c6fc;
  background-color: rgba(180, 198, 252, var(--bg-opacity));
}

.hover\:bg-indigo-400:hover {
  --bg-opacity: 1;
  background-color: #8da2fb;
  background-color: rgba(141, 162, 251, var(--bg-opacity));
}

.hover\:bg-indigo-500:hover {
  --bg-opacity: 1;
  background-color: #6875f5;
  background-color: rgba(104, 117, 245, var(--bg-opacity));
}

.hover\:bg-indigo-600:hover {
  --bg-opacity: 1;
  background-color: #5850ec;
  background-color: rgba(88, 80, 236, var(--bg-opacity));
}

.hover\:bg-indigo-700:hover {
  --bg-opacity: 1;
  background-color: #5145cd;
  background-color: rgba(81, 69, 205, var(--bg-opacity));
}

.hover\:bg-indigo-800:hover {
  --bg-opacity: 1;
  background-color: #42389d;
  background-color: rgba(66, 56, 157, var(--bg-opacity));
}

.hover\:bg-indigo-900:hover {
  --bg-opacity: 1;
  background-color: #362f78;
  background-color: rgba(54, 47, 120, var(--bg-opacity));
}

.hover\:bg-purple-50:hover {
  --bg-opacity: 1;
  background-color: #f6f5ff;
  background-color: rgba(246, 245, 255, var(--bg-opacity));
}

.hover\:bg-purple-100:hover {
  --bg-opacity: 1;
  background-color: #edebfe;
  background-color: rgba(237, 235, 254, var(--bg-opacity));
}

.hover\:bg-purple-200:hover {
  --bg-opacity: 1;
  background-color: #dcd7fe;
  background-color: rgba(220, 215, 254, var(--bg-opacity));
}

.hover\:bg-purple-300:hover {
  --bg-opacity: 1;
  background-color: #cabffd;
  background-color: rgba(202, 191, 253, var(--bg-opacity));
}

.hover\:bg-purple-400:hover {
  --bg-opacity: 1;
  background-color: #ac94fa;
  background-color: rgba(172, 148, 250, var(--bg-opacity));
}

.hover\:bg-purple-500:hover {
  --bg-opacity: 1;
  background-color: #9061f9;
  background-color: rgba(144, 97, 249, var(--bg-opacity));
}

.hover\:bg-purple-600:hover {
  --bg-opacity: 1;
  background-color: #7e3af2;
  background-color: rgba(126, 58, 242, var(--bg-opacity));
}

.hover\:bg-purple-700:hover {
  --bg-opacity: 1;
  background-color: #6c2bd9;
  background-color: rgba(108, 43, 217, var(--bg-opacity));
}

.hover\:bg-purple-800:hover {
  --bg-opacity: 1;
  background-color: #5521b5;
  background-color: rgba(85, 33, 181, var(--bg-opacity));
}

.hover\:bg-purple-900:hover {
  --bg-opacity: 1;
  background-color: #4a1d96;
  background-color: rgba(74, 29, 150, var(--bg-opacity));
}

.hover\:bg-pink-50:hover {
  --bg-opacity: 1;
  background-color: #fdf2f8;
  background-color: rgba(253, 242, 248, var(--bg-opacity));
}

.hover\:bg-pink-100:hover {
  --bg-opacity: 1;
  background-color: #fce8f3;
  background-color: rgba(252, 232, 243, var(--bg-opacity));
}

.hover\:bg-pink-200:hover {
  --bg-opacity: 1;
  background-color: #fad1e8;
  background-color: rgba(250, 209, 232, var(--bg-opacity));
}

.hover\:bg-pink-300:hover {
  --bg-opacity: 1;
  background-color: #f8b4d9;
  background-color: rgba(248, 180, 217, var(--bg-opacity));
}

.hover\:bg-pink-400:hover {
  --bg-opacity: 1;
  background-color: #f17eb8;
  background-color: rgba(241, 126, 184, var(--bg-opacity));
}

.hover\:bg-pink-500:hover {
  --bg-opacity: 1;
  background-color: #e74694;
  background-color: rgba(231, 70, 148, var(--bg-opacity));
}

.hover\:bg-pink-600:hover {
  --bg-opacity: 1;
  background-color: #d61f69;
  background-color: rgba(214, 31, 105, var(--bg-opacity));
}

.hover\:bg-pink-700:hover {
  --bg-opacity: 1;
  background-color: #bf125d;
  background-color: rgba(191, 18, 93, var(--bg-opacity));
}

.hover\:bg-pink-800:hover {
  --bg-opacity: 1;
  background-color: #99154b;
  background-color: rgba(153, 21, 75, var(--bg-opacity));
}

.hover\:bg-pink-900:hover {
  --bg-opacity: 1;
  background-color: #751a3d;
  background-color: rgba(117, 26, 61, var(--bg-opacity));
}

.focus\:bg-transparent:focus {
  background-color: transparent;
}

.focus\:bg-white:focus {
  --bg-opacity: 1;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.focus\:bg-black:focus {
  --bg-opacity: 1;
  background-color: #000000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.focus\:bg-gray-50:focus {
  --bg-opacity: 1;
  background-color: #f9fafb;
  background-color: rgba(249, 250, 251, var(--bg-opacity));
}

.focus\:bg-gray-100:focus {
  --bg-opacity: 1;
  background-color: #f4f5f7;
  background-color: rgba(244, 245, 247, var(--bg-opacity));
}

.focus\:bg-gray-200:focus {
  --bg-opacity: 1;
  background-color: #e5e7eb;
  background-color: rgba(229, 231, 235, var(--bg-opacity));
}

.focus\:bg-gray-300:focus {
  --bg-opacity: 1;
  background-color: #d5d6d7;
  background-color: rgba(213, 214, 215, var(--bg-opacity));
}

.focus\:bg-gray-400:focus {
  --bg-opacity: 1;
  background-color: #9e9e9e;
  background-color: rgba(158, 158, 158, var(--bg-opacity));
}

.focus\:bg-gray-500:focus {
  --bg-opacity: 1;
  background-color: #707275;
  background-color: rgba(112, 114, 117, var(--bg-opacity));
}

.focus\:bg-gray-600:focus {
  --bg-opacity: 1;
  background-color: #4c4f52;
  background-color: rgba(76, 79, 82, var(--bg-opacity));
}

.focus\:bg-gray-700:focus {
  --bg-opacity: 1;
  background-color: #24262d;
  background-color: rgba(36, 38, 45, var(--bg-opacity));
}

.focus\:bg-gray-800:focus {
  --bg-opacity: 1;
  background-color: #1a1c23;
  background-color: rgba(26, 28, 35, var(--bg-opacity));
}

.focus\:bg-gray-900:focus {
  --bg-opacity: 1;
  background-color: #121317;
  background-color: rgba(18, 19, 23, var(--bg-opacity));
}

.focus\:bg-cool-gray-50:focus {
  --bg-opacity: 1;
  background-color: #fbfdfe;
  background-color: rgba(251, 253, 254, var(--bg-opacity));
}

.focus\:bg-cool-gray-100:focus {
  --bg-opacity: 1;
  background-color: #f1f5f9;
  background-color: rgba(241, 245, 249, var(--bg-opacity));
}

.focus\:bg-cool-gray-200:focus {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity));
}

.focus\:bg-cool-gray-300:focus {
  --bg-opacity: 1;
  background-color: #cfd8e3;
  background-color: rgba(207, 216, 227, var(--bg-opacity));
}

.focus\:bg-cool-gray-400:focus {
  --bg-opacity: 1;
  background-color: #97a6ba;
  background-color: rgba(151, 166, 186, var(--bg-opacity));
}

.focus\:bg-cool-gray-500:focus {
  --bg-opacity: 1;
  background-color: #64748b;
  background-color: rgba(100, 116, 139, var(--bg-opacity));
}

.focus\:bg-cool-gray-600:focus {
  --bg-opacity: 1;
  background-color: #475569;
  background-color: rgba(71, 85, 105, var(--bg-opacity));
}

.focus\:bg-cool-gray-700:focus {
  --bg-opacity: 1;
  background-color: #364152;
  background-color: rgba(54, 65, 82, var(--bg-opacity));
}

.focus\:bg-cool-gray-800:focus {
  --bg-opacity: 1;
  background-color: #27303f;
  background-color: rgba(39, 48, 63, var(--bg-opacity));
}

.focus\:bg-cool-gray-900:focus {
  --bg-opacity: 1;
  background-color: #1a202e;
  background-color: rgba(26, 32, 46, var(--bg-opacity));
}

.focus\:bg-red-50:focus {
  --bg-opacity: 1;
  background-color: #fdf2f2;
  background-color: rgba(253, 242, 242, var(--bg-opacity));
}

.focus\:bg-red-100:focus {
  --bg-opacity: 1;
  background-color: #fde8e8;
  background-color: rgba(253, 232, 232, var(--bg-opacity));
}

.focus\:bg-red-200:focus {
  --bg-opacity: 1;
  background-color: #fbd5d5;
  background-color: rgba(251, 213, 213, var(--bg-opacity));
}

.focus\:bg-red-300:focus {
  --bg-opacity: 1;
  background-color: #f8b4b4;
  background-color: rgba(248, 180, 180, var(--bg-opacity));
}

.focus\:bg-red-400:focus {
  --bg-opacity: 1;
  background-color: #f98080;
  background-color: rgba(249, 128, 128, var(--bg-opacity));
}

.focus\:bg-red-500:focus {
  --bg-opacity: 1;
  background-color: #f05252;
  background-color: rgba(240, 82, 82, var(--bg-opacity));
}

.focus\:bg-red-600:focus {
  --bg-opacity: 1;
  background-color: #e02424;
  background-color: rgba(224, 36, 36, var(--bg-opacity));
}

.focus\:bg-red-700:focus {
  --bg-opacity: 1;
  background-color: #c81e1e;
  background-color: rgba(200, 30, 30, var(--bg-opacity));
}

.focus\:bg-red-800:focus {
  --bg-opacity: 1;
  background-color: #9b1c1c;
  background-color: rgba(155, 28, 28, var(--bg-opacity));
}

.focus\:bg-red-900:focus {
  --bg-opacity: 1;
  background-color: #771d1d;
  background-color: rgba(119, 29, 29, var(--bg-opacity));
}

.focus\:bg-orange-50:focus {
  --bg-opacity: 1;
  background-color: #fff8f1;
  background-color: rgba(255, 248, 241, var(--bg-opacity));
}

.focus\:bg-orange-100:focus {
  --bg-opacity: 1;
  background-color: #feecdc;
  background-color: rgba(254, 236, 220, var(--bg-opacity));
}

.focus\:bg-orange-200:focus {
  --bg-opacity: 1;
  background-color: #fcd9bd;
  background-color: rgba(252, 217, 189, var(--bg-opacity));
}

.focus\:bg-orange-300:focus {
  --bg-opacity: 1;
  background-color: #fdba8c;
  background-color: rgba(253, 186, 140, var(--bg-opacity));
}

.focus\:bg-orange-400:focus {
  --bg-opacity: 1;
  background-color: #ff8a4c;
  background-color: rgba(255, 138, 76, var(--bg-opacity));
}

.focus\:bg-orange-500:focus {
  --bg-opacity: 1;
  background-color: #ff5a1f;
  background-color: rgba(255, 90, 31, var(--bg-opacity));
}

.focus\:bg-orange-600:focus {
  --bg-opacity: 1;
  background-color: #d03801;
  background-color: rgba(208, 56, 1, var(--bg-opacity));
}

.focus\:bg-orange-700:focus {
  --bg-opacity: 1;
  background-color: #b43403;
  background-color: rgba(180, 52, 3, var(--bg-opacity));
}

.focus\:bg-orange-800:focus {
  --bg-opacity: 1;
  background-color: #8a2c0d;
  background-color: rgba(138, 44, 13, var(--bg-opacity));
}

.focus\:bg-orange-900:focus {
  --bg-opacity: 1;
  background-color: #771d1d;
  background-color: rgba(119, 29, 29, var(--bg-opacity));
}

.focus\:bg-yellow-50:focus {
  --bg-opacity: 1;
  background-color: #fdfdea;
  background-color: rgba(253, 253, 234, var(--bg-opacity));
}

.focus\:bg-yellow-100:focus {
  --bg-opacity: 1;
  background-color: #fdf6b2;
  background-color: rgba(253, 246, 178, var(--bg-opacity));
}

.focus\:bg-yellow-200:focus {
  --bg-opacity: 1;
  background-color: #fce96a;
  background-color: rgba(252, 233, 106, var(--bg-opacity));
}

.focus\:bg-yellow-300:focus {
  --bg-opacity: 1;
  background-color: #faca15;
  background-color: rgba(250, 202, 21, var(--bg-opacity));
}

.focus\:bg-yellow-400:focus {
  --bg-opacity: 1;
  background-color: #e3a008;
  background-color: rgba(227, 160, 8, var(--bg-opacity));
}

.focus\:bg-yellow-500:focus {
  --bg-opacity: 1;
  background-color: #c27803;
  background-color: rgba(194, 120, 3, var(--bg-opacity));
}

.focus\:bg-yellow-600:focus {
  --bg-opacity: 1;
  background-color: #9f580a;
  background-color: rgba(159, 88, 10, var(--bg-opacity));
}

.focus\:bg-yellow-700:focus {
  --bg-opacity: 1;
  background-color: #8e4b10;
  background-color: rgba(142, 75, 16, var(--bg-opacity));
}

.focus\:bg-yellow-800:focus {
  --bg-opacity: 1;
  background-color: #723b13;
  background-color: rgba(114, 59, 19, var(--bg-opacity));
}

.focus\:bg-yellow-900:focus {
  --bg-opacity: 1;
  background-color: #633112;
  background-color: rgba(99, 49, 18, var(--bg-opacity));
}

.focus\:bg-green-50:focus {
  --bg-opacity: 1;
  background-color: #f3faf7;
  background-color: rgba(243, 250, 247, var(--bg-opacity));
}

.focus\:bg-green-100:focus {
  --bg-opacity: 1;
  background-color: #def7ec;
  background-color: rgba(222, 247, 236, var(--bg-opacity));
}

.focus\:bg-green-200:focus {
  --bg-opacity: 1;
  background-color: #bcf0da;
  background-color: rgba(188, 240, 218, var(--bg-opacity));
}

.focus\:bg-green-300:focus {
  --bg-opacity: 1;
  background-color: #84e1bc;
  background-color: rgba(132, 225, 188, var(--bg-opacity));
}

.focus\:bg-green-400:focus {
  --bg-opacity: 1;
  background-color: #31c48d;
  background-color: rgba(49, 196, 141, var(--bg-opacity));
}

.focus\:bg-green-500:focus {
  --bg-opacity: 1;
  background-color: #0e9f6e;
  background-color: rgba(14, 159, 110, var(--bg-opacity));
}

.focus\:bg-green-600:focus {
  --bg-opacity: 1;
  background-color: #057a55;
  background-color: rgba(5, 122, 85, var(--bg-opacity));
}

.focus\:bg-green-700:focus {
  --bg-opacity: 1;
  background-color: #046c4e;
  background-color: rgba(4, 108, 78, var(--bg-opacity));
}

.focus\:bg-green-800:focus {
  --bg-opacity: 1;
  background-color: #03543f;
  background-color: rgba(3, 84, 63, var(--bg-opacity));
}

.focus\:bg-green-900:focus {
  --bg-opacity: 1;
  background-color: #014737;
  background-color: rgba(1, 71, 55, var(--bg-opacity));
}

.focus\:bg-teal-50:focus {
  --bg-opacity: 1;
  background-color: #edfafa;
  background-color: rgba(237, 250, 250, var(--bg-opacity));
}

.focus\:bg-teal-100:focus {
  --bg-opacity: 1;
  background-color: #d5f5f6;
  background-color: rgba(213, 245, 246, var(--bg-opacity));
}

.focus\:bg-teal-200:focus {
  --bg-opacity: 1;
  background-color: #afecef;
  background-color: rgba(175, 236, 239, var(--bg-opacity));
}

.focus\:bg-teal-300:focus {
  --bg-opacity: 1;
  background-color: #7edce2;
  background-color: rgba(126, 220, 226, var(--bg-opacity));
}

.focus\:bg-teal-400:focus {
  --bg-opacity: 1;
  background-color: #16bdca;
  background-color: rgba(22, 189, 202, var(--bg-opacity));
}

.focus\:bg-teal-500:focus {
  --bg-opacity: 1;
  background-color: #0694a2;
  background-color: rgba(6, 148, 162, var(--bg-opacity));
}

.focus\:bg-teal-600:focus {
  --bg-opacity: 1;
  background-color: #047481;
  background-color: rgba(4, 116, 129, var(--bg-opacity));
}

.focus\:bg-teal-700:focus {
  --bg-opacity: 1;
  background-color: #036672;
  background-color: rgba(3, 102, 114, var(--bg-opacity));
}

.focus\:bg-teal-800:focus {
  --bg-opacity: 1;
  background-color: #05505c;
  background-color: rgba(5, 80, 92, var(--bg-opacity));
}

.focus\:bg-teal-900:focus {
  --bg-opacity: 1;
  background-color: #014451;
  background-color: rgba(1, 68, 81, var(--bg-opacity));
}

.focus\:bg-blue-50:focus {
  --bg-opacity: 1;
  background-color: #ebf5ff;
  background-color: rgba(235, 245, 255, var(--bg-opacity));
}

.focus\:bg-blue-100:focus {
  --bg-opacity: 1;
  background-color: #e1effe;
  background-color: rgba(225, 239, 254, var(--bg-opacity));
}

.focus\:bg-blue-200:focus {
  --bg-opacity: 1;
  background-color: #c3ddfd;
  background-color: rgba(195, 221, 253, var(--bg-opacity));
}

.focus\:bg-blue-300:focus {
  --bg-opacity: 1;
  background-color: #a4cafe;
  background-color: rgba(164, 202, 254, var(--bg-opacity));
}

.focus\:bg-blue-400:focus {
  --bg-opacity: 1;
  background-color: #76a9fa;
  background-color: rgba(118, 169, 250, var(--bg-opacity));
}

.focus\:bg-blue-500:focus {
  --bg-opacity: 1;
  background-color: #3f83f8;
  background-color: rgba(63, 131, 248, var(--bg-opacity));
}

.focus\:bg-blue-600:focus {
  --bg-opacity: 1;
  background-color: #1c64f2;
  background-color: rgba(28, 100, 242, var(--bg-opacity));
}

.focus\:bg-blue-700:focus {
  --bg-opacity: 1;
  background-color: #1a56db;
  background-color: rgba(26, 86, 219, var(--bg-opacity));
}

.focus\:bg-blue-800:focus {
  --bg-opacity: 1;
  background-color: #1e429f;
  background-color: rgba(30, 66, 159, var(--bg-opacity));
}

.focus\:bg-blue-900:focus {
  --bg-opacity: 1;
  background-color: #233876;
  background-color: rgba(35, 56, 118, var(--bg-opacity));
}

.focus\:bg-indigo-50:focus {
  --bg-opacity: 1;
  background-color: #f0f5ff;
  background-color: rgba(240, 245, 255, var(--bg-opacity));
}

.focus\:bg-indigo-100:focus {
  --bg-opacity: 1;
  background-color: #e5edff;
  background-color: rgba(229, 237, 255, var(--bg-opacity));
}

.focus\:bg-indigo-200:focus {
  --bg-opacity: 1;
  background-color: #cddbfe;
  background-color: rgba(205, 219, 254, var(--bg-opacity));
}

.focus\:bg-indigo-300:focus {
  --bg-opacity: 1;
  background-color: #b4c6fc;
  background-color: rgba(180, 198, 252, var(--bg-opacity));
}

.focus\:bg-indigo-400:focus {
  --bg-opacity: 1;
  background-color: #8da2fb;
  background-color: rgba(141, 162, 251, var(--bg-opacity));
}

.focus\:bg-indigo-500:focus {
  --bg-opacity: 1;
  background-color: #6875f5;
  background-color: rgba(104, 117, 245, var(--bg-opacity));
}

.focus\:bg-indigo-600:focus {
  --bg-opacity: 1;
  background-color: #5850ec;
  background-color: rgba(88, 80, 236, var(--bg-opacity));
}

.focus\:bg-indigo-700:focus {
  --bg-opacity: 1;
  background-color: #5145cd;
  background-color: rgba(81, 69, 205, var(--bg-opacity));
}

.focus\:bg-indigo-800:focus {
  --bg-opacity: 1;
  background-color: #42389d;
  background-color: rgba(66, 56, 157, var(--bg-opacity));
}

.focus\:bg-indigo-900:focus {
  --bg-opacity: 1;
  background-color: #362f78;
  background-color: rgba(54, 47, 120, var(--bg-opacity));
}

.focus\:bg-purple-50:focus {
  --bg-opacity: 1;
  background-color: #f6f5ff;
  background-color: rgba(246, 245, 255, var(--bg-opacity));
}

.focus\:bg-purple-100:focus {
  --bg-opacity: 1;
  background-color: #edebfe;
  background-color: rgba(237, 235, 254, var(--bg-opacity));
}

.focus\:bg-purple-200:focus {
  --bg-opacity: 1;
  background-color: #dcd7fe;
  background-color: rgba(220, 215, 254, var(--bg-opacity));
}

.focus\:bg-purple-300:focus {
  --bg-opacity: 1;
  background-color: #cabffd;
  background-color: rgba(202, 191, 253, var(--bg-opacity));
}

.focus\:bg-purple-400:focus {
  --bg-opacity: 1;
  background-color: #ac94fa;
  background-color: rgba(172, 148, 250, var(--bg-opacity));
}

.focus\:bg-purple-500:focus {
  --bg-opacity: 1;
  background-color: #9061f9;
  background-color: rgba(144, 97, 249, var(--bg-opacity));
}

.focus\:bg-purple-600:focus {
  --bg-opacity: 1;
  background-color: #7e3af2;
  background-color: rgba(126, 58, 242, var(--bg-opacity));
}

.focus\:bg-purple-700:focus {
  --bg-opacity: 1;
  background-color: #6c2bd9;
  background-color: rgba(108, 43, 217, var(--bg-opacity));
}

.focus\:bg-purple-800:focus {
  --bg-opacity: 1;
  background-color: #5521b5;
  background-color: rgba(85, 33, 181, var(--bg-opacity));
}

.focus\:bg-purple-900:focus {
  --bg-opacity: 1;
  background-color: #4a1d96;
  background-color: rgba(74, 29, 150, var(--bg-opacity));
}

.focus\:bg-pink-50:focus {
  --bg-opacity: 1;
  background-color: #fdf2f8;
  background-color: rgba(253, 242, 248, var(--bg-opacity));
}

.focus\:bg-pink-100:focus {
  --bg-opacity: 1;
  background-color: #fce8f3;
  background-color: rgba(252, 232, 243, var(--bg-opacity));
}

.focus\:bg-pink-200:focus {
  --bg-opacity: 1;
  background-color: #fad1e8;
  background-color: rgba(250, 209, 232, var(--bg-opacity));
}

.focus\:bg-pink-300:focus {
  --bg-opacity: 1;
  background-color: #f8b4d9;
  background-color: rgba(248, 180, 217, var(--bg-opacity));
}

.focus\:bg-pink-400:focus {
  --bg-opacity: 1;
  background-color: #f17eb8;
  background-color: rgba(241, 126, 184, var(--bg-opacity));
}

.focus\:bg-pink-500:focus {
  --bg-opacity: 1;
  background-color: #e74694;
  background-color: rgba(231, 70, 148, var(--bg-opacity));
}

.focus\:bg-pink-600:focus {
  --bg-opacity: 1;
  background-color: #d61f69;
  background-color: rgba(214, 31, 105, var(--bg-opacity));
}

.focus\:bg-pink-700:focus {
  --bg-opacity: 1;
  background-color: #bf125d;
  background-color: rgba(191, 18, 93, var(--bg-opacity));
}

.focus\:bg-pink-800:focus {
  --bg-opacity: 1;
  background-color: #99154b;
  background-color: rgba(153, 21, 75, var(--bg-opacity));
}

.focus\:bg-pink-900:focus {
  --bg-opacity: 1;
  background-color: #751a3d;
  background-color: rgba(117, 26, 61, var(--bg-opacity));
}

.active\:bg-transparent:active {
  background-color: transparent;
}

.active\:bg-white:active {
  --bg-opacity: 1;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.active\:bg-black:active {
  --bg-opacity: 1;
  background-color: #000000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.active\:bg-gray-50:active {
  --bg-opacity: 1;
  background-color: #f9fafb;
  background-color: rgba(249, 250, 251, var(--bg-opacity));
}

.active\:bg-gray-100:active {
  --bg-opacity: 1;
  background-color: #f4f5f7;
  background-color: rgba(244, 245, 247, var(--bg-opacity));
}

.active\:bg-gray-200:active {
  --bg-opacity: 1;
  background-color: #e5e7eb;
  background-color: rgba(229, 231, 235, var(--bg-opacity));
}

.active\:bg-gray-300:active {
  --bg-opacity: 1;
  background-color: #d5d6d7;
  background-color: rgba(213, 214, 215, var(--bg-opacity));
}

.active\:bg-gray-400:active {
  --bg-opacity: 1;
  background-color: #9e9e9e;
  background-color: rgba(158, 158, 158, var(--bg-opacity));
}

.active\:bg-gray-500:active {
  --bg-opacity: 1;
  background-color: #707275;
  background-color: rgba(112, 114, 117, var(--bg-opacity));
}

.active\:bg-gray-600:active {
  --bg-opacity: 1;
  background-color: #4c4f52;
  background-color: rgba(76, 79, 82, var(--bg-opacity));
}

.active\:bg-gray-700:active {
  --bg-opacity: 1;
  background-color: #24262d;
  background-color: rgba(36, 38, 45, var(--bg-opacity));
}

.active\:bg-gray-800:active {
  --bg-opacity: 1;
  background-color: #1a1c23;
  background-color: rgba(26, 28, 35, var(--bg-opacity));
}

.active\:bg-gray-900:active {
  --bg-opacity: 1;
  background-color: #121317;
  background-color: rgba(18, 19, 23, var(--bg-opacity));
}

.active\:bg-cool-gray-50:active {
  --bg-opacity: 1;
  background-color: #fbfdfe;
  background-color: rgba(251, 253, 254, var(--bg-opacity));
}

.active\:bg-cool-gray-100:active {
  --bg-opacity: 1;
  background-color: #f1f5f9;
  background-color: rgba(241, 245, 249, var(--bg-opacity));
}

.active\:bg-cool-gray-200:active {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity));
}

.active\:bg-cool-gray-300:active {
  --bg-opacity: 1;
  background-color: #cfd8e3;
  background-color: rgba(207, 216, 227, var(--bg-opacity));
}

.active\:bg-cool-gray-400:active {
  --bg-opacity: 1;
  background-color: #97a6ba;
  background-color: rgba(151, 166, 186, var(--bg-opacity));
}

.active\:bg-cool-gray-500:active {
  --bg-opacity: 1;
  background-color: #64748b;
  background-color: rgba(100, 116, 139, var(--bg-opacity));
}

.active\:bg-cool-gray-600:active {
  --bg-opacity: 1;
  background-color: #475569;
  background-color: rgba(71, 85, 105, var(--bg-opacity));
}

.active\:bg-cool-gray-700:active {
  --bg-opacity: 1;
  background-color: #364152;
  background-color: rgba(54, 65, 82, var(--bg-opacity));
}

.active\:bg-cool-gray-800:active {
  --bg-opacity: 1;
  background-color: #27303f;
  background-color: rgba(39, 48, 63, var(--bg-opacity));
}

.active\:bg-cool-gray-900:active {
  --bg-opacity: 1;
  background-color: #1a202e;
  background-color: rgba(26, 32, 46, var(--bg-opacity));
}

.active\:bg-red-50:active {
  --bg-opacity: 1;
  background-color: #fdf2f2;
  background-color: rgba(253, 242, 242, var(--bg-opacity));
}

.active\:bg-red-100:active {
  --bg-opacity: 1;
  background-color: #fde8e8;
  background-color: rgba(253, 232, 232, var(--bg-opacity));
}

.active\:bg-red-200:active {
  --bg-opacity: 1;
  background-color: #fbd5d5;
  background-color: rgba(251, 213, 213, var(--bg-opacity));
}

.active\:bg-red-300:active {
  --bg-opacity: 1;
  background-color: #f8b4b4;
  background-color: rgba(248, 180, 180, var(--bg-opacity));
}

.active\:bg-red-400:active {
  --bg-opacity: 1;
  background-color: #f98080;
  background-color: rgba(249, 128, 128, var(--bg-opacity));
}

.active\:bg-red-500:active {
  --bg-opacity: 1;
  background-color: #f05252;
  background-color: rgba(240, 82, 82, var(--bg-opacity));
}

.active\:bg-red-600:active {
  --bg-opacity: 1;
  background-color: #e02424;
  background-color: rgba(224, 36, 36, var(--bg-opacity));
}

.active\:bg-red-700:active {
  --bg-opacity: 1;
  background-color: #c81e1e;
  background-color: rgba(200, 30, 30, var(--bg-opacity));
}

.active\:bg-red-800:active {
  --bg-opacity: 1;
  background-color: #9b1c1c;
  background-color: rgba(155, 28, 28, var(--bg-opacity));
}

.active\:bg-red-900:active {
  --bg-opacity: 1;
  background-color: #771d1d;
  background-color: rgba(119, 29, 29, var(--bg-opacity));
}

.active\:bg-orange-50:active {
  --bg-opacity: 1;
  background-color: #fff8f1;
  background-color: rgba(255, 248, 241, var(--bg-opacity));
}

.active\:bg-orange-100:active {
  --bg-opacity: 1;
  background-color: #feecdc;
  background-color: rgba(254, 236, 220, var(--bg-opacity));
}

.active\:bg-orange-200:active {
  --bg-opacity: 1;
  background-color: #fcd9bd;
  background-color: rgba(252, 217, 189, var(--bg-opacity));
}

.active\:bg-orange-300:active {
  --bg-opacity: 1;
  background-color: #fdba8c;
  background-color: rgba(253, 186, 140, var(--bg-opacity));
}

.active\:bg-orange-400:active {
  --bg-opacity: 1;
  background-color: #ff8a4c;
  background-color: rgba(255, 138, 76, var(--bg-opacity));
}

.active\:bg-orange-500:active {
  --bg-opacity: 1;
  background-color: #ff5a1f;
  background-color: rgba(255, 90, 31, var(--bg-opacity));
}

.active\:bg-orange-600:active {
  --bg-opacity: 1;
  background-color: #d03801;
  background-color: rgba(208, 56, 1, var(--bg-opacity));
}

.active\:bg-orange-700:active {
  --bg-opacity: 1;
  background-color: #b43403;
  background-color: rgba(180, 52, 3, var(--bg-opacity));
}

.active\:bg-orange-800:active {
  --bg-opacity: 1;
  background-color: #8a2c0d;
  background-color: rgba(138, 44, 13, var(--bg-opacity));
}

.active\:bg-orange-900:active {
  --bg-opacity: 1;
  background-color: #771d1d;
  background-color: rgba(119, 29, 29, var(--bg-opacity));
}

.active\:bg-yellow-50:active {
  --bg-opacity: 1;
  background-color: #fdfdea;
  background-color: rgba(253, 253, 234, var(--bg-opacity));
}

.active\:bg-yellow-100:active {
  --bg-opacity: 1;
  background-color: #fdf6b2;
  background-color: rgba(253, 246, 178, var(--bg-opacity));
}

.active\:bg-yellow-200:active {
  --bg-opacity: 1;
  background-color: #fce96a;
  background-color: rgba(252, 233, 106, var(--bg-opacity));
}

.active\:bg-yellow-300:active {
  --bg-opacity: 1;
  background-color: #faca15;
  background-color: rgba(250, 202, 21, var(--bg-opacity));
}

.active\:bg-yellow-400:active {
  --bg-opacity: 1;
  background-color: #e3a008;
  background-color: rgba(227, 160, 8, var(--bg-opacity));
}

.active\:bg-yellow-500:active {
  --bg-opacity: 1;
  background-color: #c27803;
  background-color: rgba(194, 120, 3, var(--bg-opacity));
}

.active\:bg-yellow-600:active {
  --bg-opacity: 1;
  background-color: #9f580a;
  background-color: rgba(159, 88, 10, var(--bg-opacity));
}

.active\:bg-yellow-700:active {
  --bg-opacity: 1;
  background-color: #8e4b10;
  background-color: rgba(142, 75, 16, var(--bg-opacity));
}

.active\:bg-yellow-800:active {
  --bg-opacity: 1;
  background-color: #723b13;
  background-color: rgba(114, 59, 19, var(--bg-opacity));
}

.active\:bg-yellow-900:active {
  --bg-opacity: 1;
  background-color: #633112;
  background-color: rgba(99, 49, 18, var(--bg-opacity));
}

.active\:bg-green-50:active {
  --bg-opacity: 1;
  background-color: #f3faf7;
  background-color: rgba(243, 250, 247, var(--bg-opacity));
}

.active\:bg-green-100:active {
  --bg-opacity: 1;
  background-color: #def7ec;
  background-color: rgba(222, 247, 236, var(--bg-opacity));
}

.active\:bg-green-200:active {
  --bg-opacity: 1;
  background-color: #bcf0da;
  background-color: rgba(188, 240, 218, var(--bg-opacity));
}

.active\:bg-green-300:active {
  --bg-opacity: 1;
  background-color: #84e1bc;
  background-color: rgba(132, 225, 188, var(--bg-opacity));
}

.active\:bg-green-400:active {
  --bg-opacity: 1;
  background-color: #31c48d;
  background-color: rgba(49, 196, 141, var(--bg-opacity));
}

.active\:bg-green-500:active {
  --bg-opacity: 1;
  background-color: #0e9f6e;
  background-color: rgba(14, 159, 110, var(--bg-opacity));
}

.active\:bg-green-600:active {
  --bg-opacity: 1;
  background-color: #057a55;
  background-color: rgba(5, 122, 85, var(--bg-opacity));
}

.active\:bg-green-700:active {
  --bg-opacity: 1;
  background-color: #046c4e;
  background-color: rgba(4, 108, 78, var(--bg-opacity));
}

.active\:bg-green-800:active {
  --bg-opacity: 1;
  background-color: #03543f;
  background-color: rgba(3, 84, 63, var(--bg-opacity));
}

.active\:bg-green-900:active {
  --bg-opacity: 1;
  background-color: #014737;
  background-color: rgba(1, 71, 55, var(--bg-opacity));
}

.active\:bg-teal-50:active {
  --bg-opacity: 1;
  background-color: #edfafa;
  background-color: rgba(237, 250, 250, var(--bg-opacity));
}

.active\:bg-teal-100:active {
  --bg-opacity: 1;
  background-color: #d5f5f6;
  background-color: rgba(213, 245, 246, var(--bg-opacity));
}

.active\:bg-teal-200:active {
  --bg-opacity: 1;
  background-color: #afecef;
  background-color: rgba(175, 236, 239, var(--bg-opacity));
}

.active\:bg-teal-300:active {
  --bg-opacity: 1;
  background-color: #7edce2;
  background-color: rgba(126, 220, 226, var(--bg-opacity));
}

.active\:bg-teal-400:active {
  --bg-opacity: 1;
  background-color: #16bdca;
  background-color: rgba(22, 189, 202, var(--bg-opacity));
}

.active\:bg-teal-500:active {
  --bg-opacity: 1;
  background-color: #0694a2;
  background-color: rgba(6, 148, 162, var(--bg-opacity));
}

.active\:bg-teal-600:active {
  --bg-opacity: 1;
  background-color: #047481;
  background-color: rgba(4, 116, 129, var(--bg-opacity));
}

.active\:bg-teal-700:active {
  --bg-opacity: 1;
  background-color: #036672;
  background-color: rgba(3, 102, 114, var(--bg-opacity));
}

.active\:bg-teal-800:active {
  --bg-opacity: 1;
  background-color: #05505c;
  background-color: rgba(5, 80, 92, var(--bg-opacity));
}

.active\:bg-teal-900:active {
  --bg-opacity: 1;
  background-color: #014451;
  background-color: rgba(1, 68, 81, var(--bg-opacity));
}

.active\:bg-blue-50:active {
  --bg-opacity: 1;
  background-color: #ebf5ff;
  background-color: rgba(235, 245, 255, var(--bg-opacity));
}

.active\:bg-blue-100:active {
  --bg-opacity: 1;
  background-color: #e1effe;
  background-color: rgba(225, 239, 254, var(--bg-opacity));
}

.active\:bg-blue-200:active {
  --bg-opacity: 1;
  background-color: #c3ddfd;
  background-color: rgba(195, 221, 253, var(--bg-opacity));
}

.active\:bg-blue-300:active {
  --bg-opacity: 1;
  background-color: #a4cafe;
  background-color: rgba(164, 202, 254, var(--bg-opacity));
}

.active\:bg-blue-400:active {
  --bg-opacity: 1;
  background-color: #76a9fa;
  background-color: rgba(118, 169, 250, var(--bg-opacity));
}

.active\:bg-blue-500:active {
  --bg-opacity: 1;
  background-color: #3f83f8;
  background-color: rgba(63, 131, 248, var(--bg-opacity));
}

.active\:bg-blue-600:active {
  --bg-opacity: 1;
  background-color: #1c64f2;
  background-color: rgba(28, 100, 242, var(--bg-opacity));
}

.active\:bg-blue-700:active {
  --bg-opacity: 1;
  background-color: #1a56db;
  background-color: rgba(26, 86, 219, var(--bg-opacity));
}

.active\:bg-blue-800:active {
  --bg-opacity: 1;
  background-color: #1e429f;
  background-color: rgba(30, 66, 159, var(--bg-opacity));
}

.active\:bg-blue-900:active {
  --bg-opacity: 1;
  background-color: #233876;
  background-color: rgba(35, 56, 118, var(--bg-opacity));
}

.active\:bg-indigo-50:active {
  --bg-opacity: 1;
  background-color: #f0f5ff;
  background-color: rgba(240, 245, 255, var(--bg-opacity));
}

.active\:bg-indigo-100:active {
  --bg-opacity: 1;
  background-color: #e5edff;
  background-color: rgba(229, 237, 255, var(--bg-opacity));
}

.active\:bg-indigo-200:active {
  --bg-opacity: 1;
  background-color: #cddbfe;
  background-color: rgba(205, 219, 254, var(--bg-opacity));
}

.active\:bg-indigo-300:active {
  --bg-opacity: 1;
  background-color: #b4c6fc;
  background-color: rgba(180, 198, 252, var(--bg-opacity));
}

.active\:bg-indigo-400:active {
  --bg-opacity: 1;
  background-color: #8da2fb;
  background-color: rgba(141, 162, 251, var(--bg-opacity));
}

.active\:bg-indigo-500:active {
  --bg-opacity: 1;
  background-color: #6875f5;
  background-color: rgba(104, 117, 245, var(--bg-opacity));
}

.active\:bg-indigo-600:active {
  --bg-opacity: 1;
  background-color: #5850ec;
  background-color: rgba(88, 80, 236, var(--bg-opacity));
}

.active\:bg-indigo-700:active {
  --bg-opacity: 1;
  background-color: #5145cd;
  background-color: rgba(81, 69, 205, var(--bg-opacity));
}

.active\:bg-indigo-800:active {
  --bg-opacity: 1;
  background-color: #42389d;
  background-color: rgba(66, 56, 157, var(--bg-opacity));
}

.active\:bg-indigo-900:active {
  --bg-opacity: 1;
  background-color: #362f78;
  background-color: rgba(54, 47, 120, var(--bg-opacity));
}

.active\:bg-purple-50:active {
  --bg-opacity: 1;
  background-color: #f6f5ff;
  background-color: rgba(246, 245, 255, var(--bg-opacity));
}

.active\:bg-purple-100:active {
  --bg-opacity: 1;
  background-color: #edebfe;
  background-color: rgba(237, 235, 254, var(--bg-opacity));
}

.active\:bg-purple-200:active {
  --bg-opacity: 1;
  background-color: #dcd7fe;
  background-color: rgba(220, 215, 254, var(--bg-opacity));
}

.active\:bg-purple-300:active {
  --bg-opacity: 1;
  background-color: #cabffd;
  background-color: rgba(202, 191, 253, var(--bg-opacity));
}

.active\:bg-purple-400:active {
  --bg-opacity: 1;
  background-color: #ac94fa;
  background-color: rgba(172, 148, 250, var(--bg-opacity));
}

.active\:bg-purple-500:active {
  --bg-opacity: 1;
  background-color: #9061f9;
  background-color: rgba(144, 97, 249, var(--bg-opacity));
}

.active\:bg-purple-600:active {
  --bg-opacity: 1;
  background-color: #7e3af2;
  background-color: rgba(126, 58, 242, var(--bg-opacity));
}

.active\:bg-purple-700:active {
  --bg-opacity: 1;
  background-color: #6c2bd9;
  background-color: rgba(108, 43, 217, var(--bg-opacity));
}

.active\:bg-purple-800:active {
  --bg-opacity: 1;
  background-color: #5521b5;
  background-color: rgba(85, 33, 181, var(--bg-opacity));
}

.active\:bg-purple-900:active {
  --bg-opacity: 1;
  background-color: #4a1d96;
  background-color: rgba(74, 29, 150, var(--bg-opacity));
}

.active\:bg-pink-50:active {
  --bg-opacity: 1;
  background-color: #fdf2f8;
  background-color: rgba(253, 242, 248, var(--bg-opacity));
}

.active\:bg-pink-100:active {
  --bg-opacity: 1;
  background-color: #fce8f3;
  background-color: rgba(252, 232, 243, var(--bg-opacity));
}

.active\:bg-pink-200:active {
  --bg-opacity: 1;
  background-color: #fad1e8;
  background-color: rgba(250, 209, 232, var(--bg-opacity));
}

.active\:bg-pink-300:active {
  --bg-opacity: 1;
  background-color: #f8b4d9;
  background-color: rgba(248, 180, 217, var(--bg-opacity));
}

.active\:bg-pink-400:active {
  --bg-opacity: 1;
  background-color: #f17eb8;
  background-color: rgba(241, 126, 184, var(--bg-opacity));
}

.active\:bg-pink-500:active {
  --bg-opacity: 1;
  background-color: #e74694;
  background-color: rgba(231, 70, 148, var(--bg-opacity));
}

.active\:bg-pink-600:active {
  --bg-opacity: 1;
  background-color: #d61f69;
  background-color: rgba(214, 31, 105, var(--bg-opacity));
}

.active\:bg-pink-700:active {
  --bg-opacity: 1;
  background-color: #bf125d;
  background-color: rgba(191, 18, 93, var(--bg-opacity));
}

.active\:bg-pink-800:active {
  --bg-opacity: 1;
  background-color: #99154b;
  background-color: rgba(153, 21, 75, var(--bg-opacity));
}

.active\:bg-pink-900:active {
  --bg-opacity: 1;
  background-color: #751a3d;
  background-color: rgba(117, 26, 61, var(--bg-opacity));
}

.odd\:bg-transparent:nth-child(odd) {
  background-color: transparent;
}

.odd\:bg-white:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.odd\:bg-black:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #000000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.odd\:bg-gray-50:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #f9fafb;
  background-color: rgba(249, 250, 251, var(--bg-opacity));
}

.odd\:bg-gray-100:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #f4f5f7;
  background-color: rgba(244, 245, 247, var(--bg-opacity));
}

.odd\:bg-gray-200:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #e5e7eb;
  background-color: rgba(229, 231, 235, var(--bg-opacity));
}

.odd\:bg-gray-300:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #d5d6d7;
  background-color: rgba(213, 214, 215, var(--bg-opacity));
}

.odd\:bg-gray-400:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #9e9e9e;
  background-color: rgba(158, 158, 158, var(--bg-opacity));
}

.odd\:bg-gray-500:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #707275;
  background-color: rgba(112, 114, 117, var(--bg-opacity));
}

.odd\:bg-gray-600:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #4c4f52;
  background-color: rgba(76, 79, 82, var(--bg-opacity));
}

.odd\:bg-gray-700:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #24262d;
  background-color: rgba(36, 38, 45, var(--bg-opacity));
}

.odd\:bg-gray-800:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #1a1c23;
  background-color: rgba(26, 28, 35, var(--bg-opacity));
}

.odd\:bg-gray-900:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #121317;
  background-color: rgba(18, 19, 23, var(--bg-opacity));
}

.odd\:bg-cool-gray-50:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #fbfdfe;
  background-color: rgba(251, 253, 254, var(--bg-opacity));
}

.odd\:bg-cool-gray-100:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #f1f5f9;
  background-color: rgba(241, 245, 249, var(--bg-opacity));
}

.odd\:bg-cool-gray-200:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity));
}

.odd\:bg-cool-gray-300:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #cfd8e3;
  background-color: rgba(207, 216, 227, var(--bg-opacity));
}

.odd\:bg-cool-gray-400:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #97a6ba;
  background-color: rgba(151, 166, 186, var(--bg-opacity));
}

.odd\:bg-cool-gray-500:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #64748b;
  background-color: rgba(100, 116, 139, var(--bg-opacity));
}

.odd\:bg-cool-gray-600:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #475569;
  background-color: rgba(71, 85, 105, var(--bg-opacity));
}

.odd\:bg-cool-gray-700:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #364152;
  background-color: rgba(54, 65, 82, var(--bg-opacity));
}

.odd\:bg-cool-gray-800:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #27303f;
  background-color: rgba(39, 48, 63, var(--bg-opacity));
}

.odd\:bg-cool-gray-900:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #1a202e;
  background-color: rgba(26, 32, 46, var(--bg-opacity));
}

.odd\:bg-red-50:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #fdf2f2;
  background-color: rgba(253, 242, 242, var(--bg-opacity));
}

.odd\:bg-red-100:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #fde8e8;
  background-color: rgba(253, 232, 232, var(--bg-opacity));
}

.odd\:bg-red-200:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #fbd5d5;
  background-color: rgba(251, 213, 213, var(--bg-opacity));
}

.odd\:bg-red-300:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #f8b4b4;
  background-color: rgba(248, 180, 180, var(--bg-opacity));
}

.odd\:bg-red-400:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #f98080;
  background-color: rgba(249, 128, 128, var(--bg-opacity));
}

.odd\:bg-red-500:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #f05252;
  background-color: rgba(240, 82, 82, var(--bg-opacity));
}

.odd\:bg-red-600:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #e02424;
  background-color: rgba(224, 36, 36, var(--bg-opacity));
}

.odd\:bg-red-700:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #c81e1e;
  background-color: rgba(200, 30, 30, var(--bg-opacity));
}

.odd\:bg-red-800:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #9b1c1c;
  background-color: rgba(155, 28, 28, var(--bg-opacity));
}

.odd\:bg-red-900:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #771d1d;
  background-color: rgba(119, 29, 29, var(--bg-opacity));
}

.odd\:bg-orange-50:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #fff8f1;
  background-color: rgba(255, 248, 241, var(--bg-opacity));
}

.odd\:bg-orange-100:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #feecdc;
  background-color: rgba(254, 236, 220, var(--bg-opacity));
}

.odd\:bg-orange-200:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #fcd9bd;
  background-color: rgba(252, 217, 189, var(--bg-opacity));
}

.odd\:bg-orange-300:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #fdba8c;
  background-color: rgba(253, 186, 140, var(--bg-opacity));
}

.odd\:bg-orange-400:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #ff8a4c;
  background-color: rgba(255, 138, 76, var(--bg-opacity));
}

.odd\:bg-orange-500:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #ff5a1f;
  background-color: rgba(255, 90, 31, var(--bg-opacity));
}

.odd\:bg-orange-600:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #d03801;
  background-color: rgba(208, 56, 1, var(--bg-opacity));
}

.odd\:bg-orange-700:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #b43403;
  background-color: rgba(180, 52, 3, var(--bg-opacity));
}

.odd\:bg-orange-800:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #8a2c0d;
  background-color: rgba(138, 44, 13, var(--bg-opacity));
}

.odd\:bg-orange-900:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #771d1d;
  background-color: rgba(119, 29, 29, var(--bg-opacity));
}

.odd\:bg-yellow-50:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #fdfdea;
  background-color: rgba(253, 253, 234, var(--bg-opacity));
}

.odd\:bg-yellow-100:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #fdf6b2;
  background-color: rgba(253, 246, 178, var(--bg-opacity));
}

.odd\:bg-yellow-200:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #fce96a;
  background-color: rgba(252, 233, 106, var(--bg-opacity));
}

.odd\:bg-yellow-300:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #faca15;
  background-color: rgba(250, 202, 21, var(--bg-opacity));
}

.odd\:bg-yellow-400:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #e3a008;
  background-color: rgba(227, 160, 8, var(--bg-opacity));
}

.odd\:bg-yellow-500:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #c27803;
  background-color: rgba(194, 120, 3, var(--bg-opacity));
}

.odd\:bg-yellow-600:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #9f580a;
  background-color: rgba(159, 88, 10, var(--bg-opacity));
}

.odd\:bg-yellow-700:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #8e4b10;
  background-color: rgba(142, 75, 16, var(--bg-opacity));
}

.odd\:bg-yellow-800:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #723b13;
  background-color: rgba(114, 59, 19, var(--bg-opacity));
}

.odd\:bg-yellow-900:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #633112;
  background-color: rgba(99, 49, 18, var(--bg-opacity));
}

.odd\:bg-green-50:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #f3faf7;
  background-color: rgba(243, 250, 247, var(--bg-opacity));
}

.odd\:bg-green-100:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #def7ec;
  background-color: rgba(222, 247, 236, var(--bg-opacity));
}

.odd\:bg-green-200:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #bcf0da;
  background-color: rgba(188, 240, 218, var(--bg-opacity));
}

.odd\:bg-green-300:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #84e1bc;
  background-color: rgba(132, 225, 188, var(--bg-opacity));
}

.odd\:bg-green-400:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #31c48d;
  background-color: rgba(49, 196, 141, var(--bg-opacity));
}

.odd\:bg-green-500:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #0e9f6e;
  background-color: rgba(14, 159, 110, var(--bg-opacity));
}

.odd\:bg-green-600:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #057a55;
  background-color: rgba(5, 122, 85, var(--bg-opacity));
}

.odd\:bg-green-700:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #046c4e;
  background-color: rgba(4, 108, 78, var(--bg-opacity));
}

.odd\:bg-green-800:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #03543f;
  background-color: rgba(3, 84, 63, var(--bg-opacity));
}

.odd\:bg-green-900:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #014737;
  background-color: rgba(1, 71, 55, var(--bg-opacity));
}

.odd\:bg-teal-50:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #edfafa;
  background-color: rgba(237, 250, 250, var(--bg-opacity));
}

.odd\:bg-teal-100:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #d5f5f6;
  background-color: rgba(213, 245, 246, var(--bg-opacity));
}

.odd\:bg-teal-200:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #afecef;
  background-color: rgba(175, 236, 239, var(--bg-opacity));
}

.odd\:bg-teal-300:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #7edce2;
  background-color: rgba(126, 220, 226, var(--bg-opacity));
}

.odd\:bg-teal-400:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #16bdca;
  background-color: rgba(22, 189, 202, var(--bg-opacity));
}

.odd\:bg-teal-500:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #0694a2;
  background-color: rgba(6, 148, 162, var(--bg-opacity));
}

.odd\:bg-teal-600:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #047481;
  background-color: rgba(4, 116, 129, var(--bg-opacity));
}

.odd\:bg-teal-700:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #036672;
  background-color: rgba(3, 102, 114, var(--bg-opacity));
}

.odd\:bg-teal-800:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #05505c;
  background-color: rgba(5, 80, 92, var(--bg-opacity));
}

.odd\:bg-teal-900:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #014451;
  background-color: rgba(1, 68, 81, var(--bg-opacity));
}

.odd\:bg-blue-50:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #ebf5ff;
  background-color: rgba(235, 245, 255, var(--bg-opacity));
}

.odd\:bg-blue-100:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #e1effe;
  background-color: rgba(225, 239, 254, var(--bg-opacity));
}

.odd\:bg-blue-200:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #c3ddfd;
  background-color: rgba(195, 221, 253, var(--bg-opacity));
}

.odd\:bg-blue-300:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #a4cafe;
  background-color: rgba(164, 202, 254, var(--bg-opacity));
}

.odd\:bg-blue-400:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #76a9fa;
  background-color: rgba(118, 169, 250, var(--bg-opacity));
}

.odd\:bg-blue-500:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #3f83f8;
  background-color: rgba(63, 131, 248, var(--bg-opacity));
}

.odd\:bg-blue-600:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #1c64f2;
  background-color: rgba(28, 100, 242, var(--bg-opacity));
}

.odd\:bg-blue-700:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #1a56db;
  background-color: rgba(26, 86, 219, var(--bg-opacity));
}

.odd\:bg-blue-800:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #1e429f;
  background-color: rgba(30, 66, 159, var(--bg-opacity));
}

.odd\:bg-blue-900:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #233876;
  background-color: rgba(35, 56, 118, var(--bg-opacity));
}

.odd\:bg-indigo-50:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #f0f5ff;
  background-color: rgba(240, 245, 255, var(--bg-opacity));
}

.odd\:bg-indigo-100:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #e5edff;
  background-color: rgba(229, 237, 255, var(--bg-opacity));
}

.odd\:bg-indigo-200:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #cddbfe;
  background-color: rgba(205, 219, 254, var(--bg-opacity));
}

.odd\:bg-indigo-300:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #b4c6fc;
  background-color: rgba(180, 198, 252, var(--bg-opacity));
}

.odd\:bg-indigo-400:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #8da2fb;
  background-color: rgba(141, 162, 251, var(--bg-opacity));
}

.odd\:bg-indigo-500:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #6875f5;
  background-color: rgba(104, 117, 245, var(--bg-opacity));
}

.odd\:bg-indigo-600:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #5850ec;
  background-color: rgba(88, 80, 236, var(--bg-opacity));
}

.odd\:bg-indigo-700:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #5145cd;
  background-color: rgba(81, 69, 205, var(--bg-opacity));
}

.odd\:bg-indigo-800:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #42389d;
  background-color: rgba(66, 56, 157, var(--bg-opacity));
}

.odd\:bg-indigo-900:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #362f78;
  background-color: rgba(54, 47, 120, var(--bg-opacity));
}

.odd\:bg-purple-50:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #f6f5ff;
  background-color: rgba(246, 245, 255, var(--bg-opacity));
}

.odd\:bg-purple-100:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #edebfe;
  background-color: rgba(237, 235, 254, var(--bg-opacity));
}

.odd\:bg-purple-200:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #dcd7fe;
  background-color: rgba(220, 215, 254, var(--bg-opacity));
}

.odd\:bg-purple-300:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #cabffd;
  background-color: rgba(202, 191, 253, var(--bg-opacity));
}

.odd\:bg-purple-400:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #ac94fa;
  background-color: rgba(172, 148, 250, var(--bg-opacity));
}

.odd\:bg-purple-500:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #9061f9;
  background-color: rgba(144, 97, 249, var(--bg-opacity));
}

.odd\:bg-purple-600:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #7e3af2;
  background-color: rgba(126, 58, 242, var(--bg-opacity));
}

.odd\:bg-purple-700:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #6c2bd9;
  background-color: rgba(108, 43, 217, var(--bg-opacity));
}

.odd\:bg-purple-800:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #5521b5;
  background-color: rgba(85, 33, 181, var(--bg-opacity));
}

.odd\:bg-purple-900:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #4a1d96;
  background-color: rgba(74, 29, 150, var(--bg-opacity));
}

.odd\:bg-pink-50:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #fdf2f8;
  background-color: rgba(253, 242, 248, var(--bg-opacity));
}

.odd\:bg-pink-100:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #fce8f3;
  background-color: rgba(252, 232, 243, var(--bg-opacity));
}

.odd\:bg-pink-200:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #fad1e8;
  background-color: rgba(250, 209, 232, var(--bg-opacity));
}

.odd\:bg-pink-300:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #f8b4d9;
  background-color: rgba(248, 180, 217, var(--bg-opacity));
}

.odd\:bg-pink-400:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #f17eb8;
  background-color: rgba(241, 126, 184, var(--bg-opacity));
}

.odd\:bg-pink-500:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #e74694;
  background-color: rgba(231, 70, 148, var(--bg-opacity));
}

.odd\:bg-pink-600:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #d61f69;
  background-color: rgba(214, 31, 105, var(--bg-opacity));
}

.odd\:bg-pink-700:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #bf125d;
  background-color: rgba(191, 18, 93, var(--bg-opacity));
}

.odd\:bg-pink-800:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #99154b;
  background-color: rgba(153, 21, 75, var(--bg-opacity));
}

.odd\:bg-pink-900:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #751a3d;
  background-color: rgba(117, 26, 61, var(--bg-opacity));
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-left {
  background-position: left;
}

.bg-left-bottom {
  background-position: left bottom;
}

.bg-left-top {
  background-position: left top;
}

.bg-right {
  background-position: right;
}

.bg-right-bottom {
  background-position: right bottom;
}

.bg-right-top {
  background-position: right top;
}

.bg-top {
  background-position: top;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.bg-repeat-y {
  background-repeat: repeat-y;
}

.bg-repeat-round {
  background-repeat: round;
}

.bg-repeat-space {
  background-repeat: space;
}

.bg-auto {
  background-size: auto;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.border-collapse {
  border-collapse: collapse;
}

.border-separate {
  border-collapse: separate;
}

.border-transparent {
  border-color: transparent;
}

.border-white {
  --border-opacity: 1;
  border-color: #ffffff;
  border-color: rgba(255, 255, 255, var(--border-opacity));
}

.border-black {
  --border-opacity: 1;
  border-color: #000000;
  border-color: rgba(0, 0, 0, var(--border-opacity));
}

.border-gray-50 {
  --border-opacity: 1;
  border-color: #f9fafb;
  border-color: rgba(249, 250, 251, var(--border-opacity));
}

.border-gray-100 {
  --border-opacity: 1;
  border-color: #f4f5f7;
  border-color: rgba(244, 245, 247, var(--border-opacity));
}

.border-gray-200 {
  --border-opacity: 1;
  border-color: #e5e7eb;
  border-color: rgba(229, 231, 235, var(--border-opacity));
}

.border-gray-300 {
  --border-opacity: 1;
  border-color: #d5d6d7;
  border-color: rgba(213, 214, 215, var(--border-opacity));
}

.border-gray-400 {
  --border-opacity: 1;
  border-color: #9e9e9e;
  border-color: rgba(158, 158, 158, var(--border-opacity));
}

.border-gray-500 {
  --border-opacity: 1;
  border-color: #707275;
  border-color: rgba(112, 114, 117, var(--border-opacity));
}

.border-gray-600 {
  --border-opacity: 1;
  border-color: #4c4f52;
  border-color: rgba(76, 79, 82, var(--border-opacity));
}

.border-gray-700 {
  --border-opacity: 1;
  border-color: #24262d;
  border-color: rgba(36, 38, 45, var(--border-opacity));
}

.border-gray-800 {
  --border-opacity: 1;
  border-color: #1a1c23;
  border-color: rgba(26, 28, 35, var(--border-opacity));
}

.border-gray-900 {
  --border-opacity: 1;
  border-color: #121317;
  border-color: rgba(18, 19, 23, var(--border-opacity));
}

.border-cool-gray-50 {
  --border-opacity: 1;
  border-color: #fbfdfe;
  border-color: rgba(251, 253, 254, var(--border-opacity));
}

.border-cool-gray-100 {
  --border-opacity: 1;
  border-color: #f1f5f9;
  border-color: rgba(241, 245, 249, var(--border-opacity));
}

.border-cool-gray-200 {
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity));
}

.border-cool-gray-300 {
  --border-opacity: 1;
  border-color: #cfd8e3;
  border-color: rgba(207, 216, 227, var(--border-opacity));
}

.border-cool-gray-400 {
  --border-opacity: 1;
  border-color: #97a6ba;
  border-color: rgba(151, 166, 186, var(--border-opacity));
}

.border-cool-gray-500 {
  --border-opacity: 1;
  border-color: #64748b;
  border-color: rgba(100, 116, 139, var(--border-opacity));
}

.border-cool-gray-600 {
  --border-opacity: 1;
  border-color: #475569;
  border-color: rgba(71, 85, 105, var(--border-opacity));
}

.border-cool-gray-700 {
  --border-opacity: 1;
  border-color: #364152;
  border-color: rgba(54, 65, 82, var(--border-opacity));
}

.border-cool-gray-800 {
  --border-opacity: 1;
  border-color: #27303f;
  border-color: rgba(39, 48, 63, var(--border-opacity));
}

.border-cool-gray-900 {
  --border-opacity: 1;
  border-color: #1a202e;
  border-color: rgba(26, 32, 46, var(--border-opacity));
}

.border-red-50 {
  --border-opacity: 1;
  border-color: #fdf2f2;
  border-color: rgba(253, 242, 242, var(--border-opacity));
}

.border-red-100 {
  --border-opacity: 1;
  border-color: #fde8e8;
  border-color: rgba(253, 232, 232, var(--border-opacity));
}

.border-red-200 {
  --border-opacity: 1;
  border-color: #fbd5d5;
  border-color: rgba(251, 213, 213, var(--border-opacity));
}

.border-red-300 {
  --border-opacity: 1;
  border-color: #f8b4b4;
  border-color: rgba(248, 180, 180, var(--border-opacity));
}

.border-red-400 {
  --border-opacity: 1;
  border-color: #f98080;
  border-color: rgba(249, 128, 128, var(--border-opacity));
}

.border-red-500 {
  --border-opacity: 1;
  border-color: #f05252;
  border-color: rgba(240, 82, 82, var(--border-opacity));
}

.border-red-600 {
  --border-opacity: 1;
  border-color: #e02424;
  border-color: rgba(224, 36, 36, var(--border-opacity));
}

.border-red-700 {
  --border-opacity: 1;
  border-color: #c81e1e;
  border-color: rgba(200, 30, 30, var(--border-opacity));
}

.border-red-800 {
  --border-opacity: 1;
  border-color: #9b1c1c;
  border-color: rgba(155, 28, 28, var(--border-opacity));
}

.border-red-900 {
  --border-opacity: 1;
  border-color: #771d1d;
  border-color: rgba(119, 29, 29, var(--border-opacity));
}

.border-orange-50 {
  --border-opacity: 1;
  border-color: #fff8f1;
  border-color: rgba(255, 248, 241, var(--border-opacity));
}

.border-orange-100 {
  --border-opacity: 1;
  border-color: #feecdc;
  border-color: rgba(254, 236, 220, var(--border-opacity));
}

.border-orange-200 {
  --border-opacity: 1;
  border-color: #fcd9bd;
  border-color: rgba(252, 217, 189, var(--border-opacity));
}

.border-orange-300 {
  --border-opacity: 1;
  border-color: #fdba8c;
  border-color: rgba(253, 186, 140, var(--border-opacity));
}

.border-orange-400 {
  --border-opacity: 1;
  border-color: #ff8a4c;
  border-color: rgba(255, 138, 76, var(--border-opacity));
}

.border-orange-500 {
  --border-opacity: 1;
  border-color: #ff5a1f;
  border-color: rgba(255, 90, 31, var(--border-opacity));
}

.border-orange-600 {
  --border-opacity: 1;
  border-color: #d03801;
  border-color: rgba(208, 56, 1, var(--border-opacity));
}

.border-orange-700 {
  --border-opacity: 1;
  border-color: #b43403;
  border-color: rgba(180, 52, 3, var(--border-opacity));
}

.border-orange-800 {
  --border-opacity: 1;
  border-color: #8a2c0d;
  border-color: rgba(138, 44, 13, var(--border-opacity));
}

.border-orange-900 {
  --border-opacity: 1;
  border-color: #771d1d;
  border-color: rgba(119, 29, 29, var(--border-opacity));
}

.border-yellow-50 {
  --border-opacity: 1;
  border-color: #fdfdea;
  border-color: rgba(253, 253, 234, var(--border-opacity));
}

.border-yellow-100 {
  --border-opacity: 1;
  border-color: #fdf6b2;
  border-color: rgba(253, 246, 178, var(--border-opacity));
}

.border-yellow-200 {
  --border-opacity: 1;
  border-color: #fce96a;
  border-color: rgba(252, 233, 106, var(--border-opacity));
}

.border-yellow-300 {
  --border-opacity: 1;
  border-color: #faca15;
  border-color: rgba(250, 202, 21, var(--border-opacity));
}

.border-yellow-400 {
  --border-opacity: 1;
  border-color: #e3a008;
  border-color: rgba(227, 160, 8, var(--border-opacity));
}

.border-yellow-500 {
  --border-opacity: 1;
  border-color: #c27803;
  border-color: rgba(194, 120, 3, var(--border-opacity));
}

.border-yellow-600 {
  --border-opacity: 1;
  border-color: #9f580a;
  border-color: rgba(159, 88, 10, var(--border-opacity));
}

.border-yellow-700 {
  --border-opacity: 1;
  border-color: #8e4b10;
  border-color: rgba(142, 75, 16, var(--border-opacity));
}

.border-yellow-800 {
  --border-opacity: 1;
  border-color: #723b13;
  border-color: rgba(114, 59, 19, var(--border-opacity));
}

.border-yellow-900 {
  --border-opacity: 1;
  border-color: #633112;
  border-color: rgba(99, 49, 18, var(--border-opacity));
}

.border-green-50 {
  --border-opacity: 1;
  border-color: #f3faf7;
  border-color: rgba(243, 250, 247, var(--border-opacity));
}

.border-green-100 {
  --border-opacity: 1;
  border-color: #def7ec;
  border-color: rgba(222, 247, 236, var(--border-opacity));
}

.border-green-200 {
  --border-opacity: 1;
  border-color: #bcf0da;
  border-color: rgba(188, 240, 218, var(--border-opacity));
}

.border-green-300 {
  --border-opacity: 1;
  border-color: #84e1bc;
  border-color: rgba(132, 225, 188, var(--border-opacity));
}

.border-green-400 {
  --border-opacity: 1;
  border-color: #31c48d;
  border-color: rgba(49, 196, 141, var(--border-opacity));
}

.border-green-500 {
  --border-opacity: 1;
  border-color: #0e9f6e;
  border-color: rgba(14, 159, 110, var(--border-opacity));
}

.border-green-600 {
  --border-opacity: 1;
  border-color: #057a55;
  border-color: rgba(5, 122, 85, var(--border-opacity));
}

.border-green-700 {
  --border-opacity: 1;
  border-color: #046c4e;
  border-color: rgba(4, 108, 78, var(--border-opacity));
}

.border-green-800 {
  --border-opacity: 1;
  border-color: #03543f;
  border-color: rgba(3, 84, 63, var(--border-opacity));
}

.border-green-900 {
  --border-opacity: 1;
  border-color: #014737;
  border-color: rgba(1, 71, 55, var(--border-opacity));
}

.border-teal-50 {
  --border-opacity: 1;
  border-color: #edfafa;
  border-color: rgba(237, 250, 250, var(--border-opacity));
}

.border-teal-100 {
  --border-opacity: 1;
  border-color: #d5f5f6;
  border-color: rgba(213, 245, 246, var(--border-opacity));
}

.border-teal-200 {
  --border-opacity: 1;
  border-color: #afecef;
  border-color: rgba(175, 236, 239, var(--border-opacity));
}

.border-teal-300 {
  --border-opacity: 1;
  border-color: #7edce2;
  border-color: rgba(126, 220, 226, var(--border-opacity));
}

.border-teal-400 {
  --border-opacity: 1;
  border-color: #16bdca;
  border-color: rgba(22, 189, 202, var(--border-opacity));
}

.border-teal-500 {
  --border-opacity: 1;
  border-color: #0694a2;
  border-color: rgba(6, 148, 162, var(--border-opacity));
}

.border-teal-600 {
  --border-opacity: 1;
  border-color: #047481;
  border-color: rgba(4, 116, 129, var(--border-opacity));
}

.border-teal-700 {
  --border-opacity: 1;
  border-color: #036672;
  border-color: rgba(3, 102, 114, var(--border-opacity));
}

.border-teal-800 {
  --border-opacity: 1;
  border-color: #05505c;
  border-color: rgba(5, 80, 92, var(--border-opacity));
}

.border-teal-900 {
  --border-opacity: 1;
  border-color: #014451;
  border-color: rgba(1, 68, 81, var(--border-opacity));
}

.border-blue-50 {
  --border-opacity: 1;
  border-color: #ebf5ff;
  border-color: rgba(235, 245, 255, var(--border-opacity));
}

.border-blue-100 {
  --border-opacity: 1;
  border-color: #e1effe;
  border-color: rgba(225, 239, 254, var(--border-opacity));
}

.border-blue-200 {
  --border-opacity: 1;
  border-color: #c3ddfd;
  border-color: rgba(195, 221, 253, var(--border-opacity));
}

.border-blue-300 {
  --border-opacity: 1;
  border-color: #a4cafe;
  border-color: rgba(164, 202, 254, var(--border-opacity));
}

.border-blue-400 {
  --border-opacity: 1;
  border-color: #76a9fa;
  border-color: rgba(118, 169, 250, var(--border-opacity));
}

.border-blue-500 {
  --border-opacity: 1;
  border-color: #3f83f8;
  border-color: rgba(63, 131, 248, var(--border-opacity));
}

.border-blue-600 {
  --border-opacity: 1;
  border-color: #1c64f2;
  border-color: rgba(28, 100, 242, var(--border-opacity));
}

.border-blue-700 {
  --border-opacity: 1;
  border-color: #1a56db;
  border-color: rgba(26, 86, 219, var(--border-opacity));
}

.border-blue-800 {
  --border-opacity: 1;
  border-color: #1e429f;
  border-color: rgba(30, 66, 159, var(--border-opacity));
}

.border-blue-900 {
  --border-opacity: 1;
  border-color: #233876;
  border-color: rgba(35, 56, 118, var(--border-opacity));
}

.border-indigo-50 {
  --border-opacity: 1;
  border-color: #f0f5ff;
  border-color: rgba(240, 245, 255, var(--border-opacity));
}

.border-indigo-100 {
  --border-opacity: 1;
  border-color: #e5edff;
  border-color: rgba(229, 237, 255, var(--border-opacity));
}

.border-indigo-200 {
  --border-opacity: 1;
  border-color: #cddbfe;
  border-color: rgba(205, 219, 254, var(--border-opacity));
}

.border-indigo-300 {
  --border-opacity: 1;
  border-color: #b4c6fc;
  border-color: rgba(180, 198, 252, var(--border-opacity));
}

.border-indigo-400 {
  --border-opacity: 1;
  border-color: #8da2fb;
  border-color: rgba(141, 162, 251, var(--border-opacity));
}

.border-indigo-500 {
  --border-opacity: 1;
  border-color: #6875f5;
  border-color: rgba(104, 117, 245, var(--border-opacity));
}

.border-indigo-600 {
  --border-opacity: 1;
  border-color: #5850ec;
  border-color: rgba(88, 80, 236, var(--border-opacity));
}

.border-indigo-700 {
  --border-opacity: 1;
  border-color: #5145cd;
  border-color: rgba(81, 69, 205, var(--border-opacity));
}

.border-indigo-800 {
  --border-opacity: 1;
  border-color: #42389d;
  border-color: rgba(66, 56, 157, var(--border-opacity));
}

.border-indigo-900 {
  --border-opacity: 1;
  border-color: #362f78;
  border-color: rgba(54, 47, 120, var(--border-opacity));
}

.border-purple-50 {
  --border-opacity: 1;
  border-color: #f6f5ff;
  border-color: rgba(246, 245, 255, var(--border-opacity));
}

.border-purple-100 {
  --border-opacity: 1;
  border-color: #edebfe;
  border-color: rgba(237, 235, 254, var(--border-opacity));
}

.border-purple-200 {
  --border-opacity: 1;
  border-color: #dcd7fe;
  border-color: rgba(220, 215, 254, var(--border-opacity));
}

.border-purple-300 {
  --border-opacity: 1;
  border-color: #cabffd;
  border-color: rgba(202, 191, 253, var(--border-opacity));
}

.border-purple-400 {
  --border-opacity: 1;
  border-color: #ac94fa;
  border-color: rgba(172, 148, 250, var(--border-opacity));
}

.border-purple-500 {
  --border-opacity: 1;
  border-color: #9061f9;
  border-color: rgba(144, 97, 249, var(--border-opacity));
}

.border-purple-600 {
  --border-opacity: 1;
  border-color: #7e3af2;
  border-color: rgba(126, 58, 242, var(--border-opacity));
}

.border-purple-700 {
  --border-opacity: 1;
  border-color: #6c2bd9;
  border-color: rgba(108, 43, 217, var(--border-opacity));
}

.border-purple-800 {
  --border-opacity: 1;
  border-color: #5521b5;
  border-color: rgba(85, 33, 181, var(--border-opacity));
}

.border-purple-900 {
  --border-opacity: 1;
  border-color: #4a1d96;
  border-color: rgba(74, 29, 150, var(--border-opacity));
}

.border-pink-50 {
  --border-opacity: 1;
  border-color: #fdf2f8;
  border-color: rgba(253, 242, 248, var(--border-opacity));
}

.border-pink-100 {
  --border-opacity: 1;
  border-color: #fce8f3;
  border-color: rgba(252, 232, 243, var(--border-opacity));
}

.border-pink-200 {
  --border-opacity: 1;
  border-color: #fad1e8;
  border-color: rgba(250, 209, 232, var(--border-opacity));
}

.border-pink-300 {
  --border-opacity: 1;
  border-color: #f8b4d9;
  border-color: rgba(248, 180, 217, var(--border-opacity));
}

.border-pink-400 {
  --border-opacity: 1;
  border-color: #f17eb8;
  border-color: rgba(241, 126, 184, var(--border-opacity));
}

.border-pink-500 {
  --border-opacity: 1;
  border-color: #e74694;
  border-color: rgba(231, 70, 148, var(--border-opacity));
}

.border-pink-600 {
  --border-opacity: 1;
  border-color: #d61f69;
  border-color: rgba(214, 31, 105, var(--border-opacity));
}

.border-pink-700 {
  --border-opacity: 1;
  border-color: #bf125d;
  border-color: rgba(191, 18, 93, var(--border-opacity));
}

.border-pink-800 {
  --border-opacity: 1;
  border-color: #99154b;
  border-color: rgba(153, 21, 75, var(--border-opacity));
}

.border-pink-900 {
  --border-opacity: 1;
  border-color: #751a3d;
  border-color: rgba(117, 26, 61, var(--border-opacity));
}

.hover\:border-transparent:hover {
  border-color: transparent;
}

.hover\:border-white:hover {
  --border-opacity: 1;
  border-color: #ffffff;
  border-color: rgba(255, 255, 255, var(--border-opacity));
}

.hover\:border-black:hover {
  --border-opacity: 1;
  border-color: #000000;
  border-color: rgba(0, 0, 0, var(--border-opacity));
}

.hover\:border-gray-50:hover {
  --border-opacity: 1;
  border-color: #f9fafb;
  border-color: rgba(249, 250, 251, var(--border-opacity));
}

.hover\:border-gray-100:hover {
  --border-opacity: 1;
  border-color: #f4f5f7;
  border-color: rgba(244, 245, 247, var(--border-opacity));
}

.hover\:border-gray-200:hover {
  --border-opacity: 1;
  border-color: #e5e7eb;
  border-color: rgba(229, 231, 235, var(--border-opacity));
}

.hover\:border-gray-300:hover {
  --border-opacity: 1;
  border-color: #d5d6d7;
  border-color: rgba(213, 214, 215, var(--border-opacity));
}

.hover\:border-gray-400:hover {
  --border-opacity: 1;
  border-color: #9e9e9e;
  border-color: rgba(158, 158, 158, var(--border-opacity));
}

.hover\:border-gray-500:hover {
  --border-opacity: 1;
  border-color: #707275;
  border-color: rgba(112, 114, 117, var(--border-opacity));
}

.hover\:border-gray-600:hover {
  --border-opacity: 1;
  border-color: #4c4f52;
  border-color: rgba(76, 79, 82, var(--border-opacity));
}

.hover\:border-gray-700:hover {
  --border-opacity: 1;
  border-color: #24262d;
  border-color: rgba(36, 38, 45, var(--border-opacity));
}

.hover\:border-gray-800:hover {
  --border-opacity: 1;
  border-color: #1a1c23;
  border-color: rgba(26, 28, 35, var(--border-opacity));
}

.hover\:border-gray-900:hover {
  --border-opacity: 1;
  border-color: #121317;
  border-color: rgba(18, 19, 23, var(--border-opacity));
}

.hover\:border-cool-gray-50:hover {
  --border-opacity: 1;
  border-color: #fbfdfe;
  border-color: rgba(251, 253, 254, var(--border-opacity));
}

.hover\:border-cool-gray-100:hover {
  --border-opacity: 1;
  border-color: #f1f5f9;
  border-color: rgba(241, 245, 249, var(--border-opacity));
}

.hover\:border-cool-gray-200:hover {
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity));
}

.hover\:border-cool-gray-300:hover {
  --border-opacity: 1;
  border-color: #cfd8e3;
  border-color: rgba(207, 216, 227, var(--border-opacity));
}

.hover\:border-cool-gray-400:hover {
  --border-opacity: 1;
  border-color: #97a6ba;
  border-color: rgba(151, 166, 186, var(--border-opacity));
}

.hover\:border-cool-gray-500:hover {
  --border-opacity: 1;
  border-color: #64748b;
  border-color: rgba(100, 116, 139, var(--border-opacity));
}

.hover\:border-cool-gray-600:hover {
  --border-opacity: 1;
  border-color: #475569;
  border-color: rgba(71, 85, 105, var(--border-opacity));
}

.hover\:border-cool-gray-700:hover {
  --border-opacity: 1;
  border-color: #364152;
  border-color: rgba(54, 65, 82, var(--border-opacity));
}

.hover\:border-cool-gray-800:hover {
  --border-opacity: 1;
  border-color: #27303f;
  border-color: rgba(39, 48, 63, var(--border-opacity));
}

.hover\:border-cool-gray-900:hover {
  --border-opacity: 1;
  border-color: #1a202e;
  border-color: rgba(26, 32, 46, var(--border-opacity));
}

.hover\:border-red-50:hover {
  --border-opacity: 1;
  border-color: #fdf2f2;
  border-color: rgba(253, 242, 242, var(--border-opacity));
}

.hover\:border-red-100:hover {
  --border-opacity: 1;
  border-color: #fde8e8;
  border-color: rgba(253, 232, 232, var(--border-opacity));
}

.hover\:border-red-200:hover {
  --border-opacity: 1;
  border-color: #fbd5d5;
  border-color: rgba(251, 213, 213, var(--border-opacity));
}

.hover\:border-red-300:hover {
  --border-opacity: 1;
  border-color: #f8b4b4;
  border-color: rgba(248, 180, 180, var(--border-opacity));
}

.hover\:border-red-400:hover {
  --border-opacity: 1;
  border-color: #f98080;
  border-color: rgba(249, 128, 128, var(--border-opacity));
}

.hover\:border-red-500:hover {
  --border-opacity: 1;
  border-color: #f05252;
  border-color: rgba(240, 82, 82, var(--border-opacity));
}

.hover\:border-red-600:hover {
  --border-opacity: 1;
  border-color: #e02424;
  border-color: rgba(224, 36, 36, var(--border-opacity));
}

.hover\:border-red-700:hover {
  --border-opacity: 1;
  border-color: #c81e1e;
  border-color: rgba(200, 30, 30, var(--border-opacity));
}

.hover\:border-red-800:hover {
  --border-opacity: 1;
  border-color: #9b1c1c;
  border-color: rgba(155, 28, 28, var(--border-opacity));
}

.hover\:border-red-900:hover {
  --border-opacity: 1;
  border-color: #771d1d;
  border-color: rgba(119, 29, 29, var(--border-opacity));
}

.hover\:border-orange-50:hover {
  --border-opacity: 1;
  border-color: #fff8f1;
  border-color: rgba(255, 248, 241, var(--border-opacity));
}

.hover\:border-orange-100:hover {
  --border-opacity: 1;
  border-color: #feecdc;
  border-color: rgba(254, 236, 220, var(--border-opacity));
}

.hover\:border-orange-200:hover {
  --border-opacity: 1;
  border-color: #fcd9bd;
  border-color: rgba(252, 217, 189, var(--border-opacity));
}

.hover\:border-orange-300:hover {
  --border-opacity: 1;
  border-color: #fdba8c;
  border-color: rgba(253, 186, 140, var(--border-opacity));
}

.hover\:border-orange-400:hover {
  --border-opacity: 1;
  border-color: #ff8a4c;
  border-color: rgba(255, 138, 76, var(--border-opacity));
}

.hover\:border-orange-500:hover {
  --border-opacity: 1;
  border-color: #ff5a1f;
  border-color: rgba(255, 90, 31, var(--border-opacity));
}

.hover\:border-orange-600:hover {
  --border-opacity: 1;
  border-color: #d03801;
  border-color: rgba(208, 56, 1, var(--border-opacity));
}

.hover\:border-orange-700:hover {
  --border-opacity: 1;
  border-color: #b43403;
  border-color: rgba(180, 52, 3, var(--border-opacity));
}

.hover\:border-orange-800:hover {
  --border-opacity: 1;
  border-color: #8a2c0d;
  border-color: rgba(138, 44, 13, var(--border-opacity));
}

.hover\:border-orange-900:hover {
  --border-opacity: 1;
  border-color: #771d1d;
  border-color: rgba(119, 29, 29, var(--border-opacity));
}

.hover\:border-yellow-50:hover {
  --border-opacity: 1;
  border-color: #fdfdea;
  border-color: rgba(253, 253, 234, var(--border-opacity));
}

.hover\:border-yellow-100:hover {
  --border-opacity: 1;
  border-color: #fdf6b2;
  border-color: rgba(253, 246, 178, var(--border-opacity));
}

.hover\:border-yellow-200:hover {
  --border-opacity: 1;
  border-color: #fce96a;
  border-color: rgba(252, 233, 106, var(--border-opacity));
}

.hover\:border-yellow-300:hover {
  --border-opacity: 1;
  border-color: #faca15;
  border-color: rgba(250, 202, 21, var(--border-opacity));
}

.hover\:border-yellow-400:hover {
  --border-opacity: 1;
  border-color: #e3a008;
  border-color: rgba(227, 160, 8, var(--border-opacity));
}

.hover\:border-yellow-500:hover {
  --border-opacity: 1;
  border-color: #c27803;
  border-color: rgba(194, 120, 3, var(--border-opacity));
}

.hover\:border-yellow-600:hover {
  --border-opacity: 1;
  border-color: #9f580a;
  border-color: rgba(159, 88, 10, var(--border-opacity));
}

.hover\:border-yellow-700:hover {
  --border-opacity: 1;
  border-color: #8e4b10;
  border-color: rgba(142, 75, 16, var(--border-opacity));
}

.hover\:border-yellow-800:hover {
  --border-opacity: 1;
  border-color: #723b13;
  border-color: rgba(114, 59, 19, var(--border-opacity));
}

.hover\:border-yellow-900:hover {
  --border-opacity: 1;
  border-color: #633112;
  border-color: rgba(99, 49, 18, var(--border-opacity));
}

.hover\:border-green-50:hover {
  --border-opacity: 1;
  border-color: #f3faf7;
  border-color: rgba(243, 250, 247, var(--border-opacity));
}

.hover\:border-green-100:hover {
  --border-opacity: 1;
  border-color: #def7ec;
  border-color: rgba(222, 247, 236, var(--border-opacity));
}

.hover\:border-green-200:hover {
  --border-opacity: 1;
  border-color: #bcf0da;
  border-color: rgba(188, 240, 218, var(--border-opacity));
}

.hover\:border-green-300:hover {
  --border-opacity: 1;
  border-color: #84e1bc;
  border-color: rgba(132, 225, 188, var(--border-opacity));
}

.hover\:border-green-400:hover {
  --border-opacity: 1;
  border-color: #31c48d;
  border-color: rgba(49, 196, 141, var(--border-opacity));
}

.hover\:border-green-500:hover {
  --border-opacity: 1;
  border-color: #0e9f6e;
  border-color: rgba(14, 159, 110, var(--border-opacity));
}

.hover\:border-green-600:hover {
  --border-opacity: 1;
  border-color: #057a55;
  border-color: rgba(5, 122, 85, var(--border-opacity));
}

.hover\:border-green-700:hover {
  --border-opacity: 1;
  border-color: #046c4e;
  border-color: rgba(4, 108, 78, var(--border-opacity));
}

.hover\:border-green-800:hover {
  --border-opacity: 1;
  border-color: #03543f;
  border-color: rgba(3, 84, 63, var(--border-opacity));
}

.hover\:border-green-900:hover {
  --border-opacity: 1;
  border-color: #014737;
  border-color: rgba(1, 71, 55, var(--border-opacity));
}

.hover\:border-teal-50:hover {
  --border-opacity: 1;
  border-color: #edfafa;
  border-color: rgba(237, 250, 250, var(--border-opacity));
}

.hover\:border-teal-100:hover {
  --border-opacity: 1;
  border-color: #d5f5f6;
  border-color: rgba(213, 245, 246, var(--border-opacity));
}

.hover\:border-teal-200:hover {
  --border-opacity: 1;
  border-color: #afecef;
  border-color: rgba(175, 236, 239, var(--border-opacity));
}

.hover\:border-teal-300:hover {
  --border-opacity: 1;
  border-color: #7edce2;
  border-color: rgba(126, 220, 226, var(--border-opacity));
}

.hover\:border-teal-400:hover {
  --border-opacity: 1;
  border-color: #16bdca;
  border-color: rgba(22, 189, 202, var(--border-opacity));
}

.hover\:border-teal-500:hover {
  --border-opacity: 1;
  border-color: #0694a2;
  border-color: rgba(6, 148, 162, var(--border-opacity));
}

.hover\:border-teal-600:hover {
  --border-opacity: 1;
  border-color: #047481;
  border-color: rgba(4, 116, 129, var(--border-opacity));
}

.hover\:border-teal-700:hover {
  --border-opacity: 1;
  border-color: #036672;
  border-color: rgba(3, 102, 114, var(--border-opacity));
}

.hover\:border-teal-800:hover {
  --border-opacity: 1;
  border-color: #05505c;
  border-color: rgba(5, 80, 92, var(--border-opacity));
}

.hover\:border-teal-900:hover {
  --border-opacity: 1;
  border-color: #014451;
  border-color: rgba(1, 68, 81, var(--border-opacity));
}

.hover\:border-blue-50:hover {
  --border-opacity: 1;
  border-color: #ebf5ff;
  border-color: rgba(235, 245, 255, var(--border-opacity));
}

.hover\:border-blue-100:hover {
  --border-opacity: 1;
  border-color: #e1effe;
  border-color: rgba(225, 239, 254, var(--border-opacity));
}

.hover\:border-blue-200:hover {
  --border-opacity: 1;
  border-color: #c3ddfd;
  border-color: rgba(195, 221, 253, var(--border-opacity));
}

.hover\:border-blue-300:hover {
  --border-opacity: 1;
  border-color: #a4cafe;
  border-color: rgba(164, 202, 254, var(--border-opacity));
}

.hover\:border-blue-400:hover {
  --border-opacity: 1;
  border-color: #76a9fa;
  border-color: rgba(118, 169, 250, var(--border-opacity));
}

.hover\:border-blue-500:hover {
  --border-opacity: 1;
  border-color: #3f83f8;
  border-color: rgba(63, 131, 248, var(--border-opacity));
}

.hover\:border-blue-600:hover {
  --border-opacity: 1;
  border-color: #1c64f2;
  border-color: rgba(28, 100, 242, var(--border-opacity));
}

.hover\:border-blue-700:hover {
  --border-opacity: 1;
  border-color: #1a56db;
  border-color: rgba(26, 86, 219, var(--border-opacity));
}

.hover\:border-blue-800:hover {
  --border-opacity: 1;
  border-color: #1e429f;
  border-color: rgba(30, 66, 159, var(--border-opacity));
}

.hover\:border-blue-900:hover {
  --border-opacity: 1;
  border-color: #233876;
  border-color: rgba(35, 56, 118, var(--border-opacity));
}

.hover\:border-indigo-50:hover {
  --border-opacity: 1;
  border-color: #f0f5ff;
  border-color: rgba(240, 245, 255, var(--border-opacity));
}

.hover\:border-indigo-100:hover {
  --border-opacity: 1;
  border-color: #e5edff;
  border-color: rgba(229, 237, 255, var(--border-opacity));
}

.hover\:border-indigo-200:hover {
  --border-opacity: 1;
  border-color: #cddbfe;
  border-color: rgba(205, 219, 254, var(--border-opacity));
}

.hover\:border-indigo-300:hover {
  --border-opacity: 1;
  border-color: #b4c6fc;
  border-color: rgba(180, 198, 252, var(--border-opacity));
}

.hover\:border-indigo-400:hover {
  --border-opacity: 1;
  border-color: #8da2fb;
  border-color: rgba(141, 162, 251, var(--border-opacity));
}

.hover\:border-indigo-500:hover {
  --border-opacity: 1;
  border-color: #6875f5;
  border-color: rgba(104, 117, 245, var(--border-opacity));
}

.hover\:border-indigo-600:hover {
  --border-opacity: 1;
  border-color: #5850ec;
  border-color: rgba(88, 80, 236, var(--border-opacity));
}

.hover\:border-indigo-700:hover {
  --border-opacity: 1;
  border-color: #5145cd;
  border-color: rgba(81, 69, 205, var(--border-opacity));
}

.hover\:border-indigo-800:hover {
  --border-opacity: 1;
  border-color: #42389d;
  border-color: rgba(66, 56, 157, var(--border-opacity));
}

.hover\:border-indigo-900:hover {
  --border-opacity: 1;
  border-color: #362f78;
  border-color: rgba(54, 47, 120, var(--border-opacity));
}

.hover\:border-purple-50:hover {
  --border-opacity: 1;
  border-color: #f6f5ff;
  border-color: rgba(246, 245, 255, var(--border-opacity));
}

.hover\:border-purple-100:hover {
  --border-opacity: 1;
  border-color: #edebfe;
  border-color: rgba(237, 235, 254, var(--border-opacity));
}

.hover\:border-purple-200:hover {
  --border-opacity: 1;
  border-color: #dcd7fe;
  border-color: rgba(220, 215, 254, var(--border-opacity));
}

.hover\:border-purple-300:hover {
  --border-opacity: 1;
  border-color: #cabffd;
  border-color: rgba(202, 191, 253, var(--border-opacity));
}

.hover\:border-purple-400:hover {
  --border-opacity: 1;
  border-color: #ac94fa;
  border-color: rgba(172, 148, 250, var(--border-opacity));
}

.hover\:border-purple-500:hover {
  --border-opacity: 1;
  border-color: #9061f9;
  border-color: rgba(144, 97, 249, var(--border-opacity));
}

.hover\:border-purple-600:hover {
  --border-opacity: 1;
  border-color: #7e3af2;
  border-color: rgba(126, 58, 242, var(--border-opacity));
}

.hover\:border-purple-700:hover {
  --border-opacity: 1;
  border-color: #6c2bd9;
  border-color: rgba(108, 43, 217, var(--border-opacity));
}

.hover\:border-purple-800:hover {
  --border-opacity: 1;
  border-color: #5521b5;
  border-color: rgba(85, 33, 181, var(--border-opacity));
}

.hover\:border-purple-900:hover {
  --border-opacity: 1;
  border-color: #4a1d96;
  border-color: rgba(74, 29, 150, var(--border-opacity));
}

.hover\:border-pink-50:hover {
  --border-opacity: 1;
  border-color: #fdf2f8;
  border-color: rgba(253, 242, 248, var(--border-opacity));
}

.hover\:border-pink-100:hover {
  --border-opacity: 1;
  border-color: #fce8f3;
  border-color: rgba(252, 232, 243, var(--border-opacity));
}

.hover\:border-pink-200:hover {
  --border-opacity: 1;
  border-color: #fad1e8;
  border-color: rgba(250, 209, 232, var(--border-opacity));
}

.hover\:border-pink-300:hover {
  --border-opacity: 1;
  border-color: #f8b4d9;
  border-color: rgba(248, 180, 217, var(--border-opacity));
}

.hover\:border-pink-400:hover {
  --border-opacity: 1;
  border-color: #f17eb8;
  border-color: rgba(241, 126, 184, var(--border-opacity));
}

.hover\:border-pink-500:hover {
  --border-opacity: 1;
  border-color: #e74694;
  border-color: rgba(231, 70, 148, var(--border-opacity));
}

.hover\:border-pink-600:hover {
  --border-opacity: 1;
  border-color: #d61f69;
  border-color: rgba(214, 31, 105, var(--border-opacity));
}

.hover\:border-pink-700:hover {
  --border-opacity: 1;
  border-color: #bf125d;
  border-color: rgba(191, 18, 93, var(--border-opacity));
}

.hover\:border-pink-800:hover {
  --border-opacity: 1;
  border-color: #99154b;
  border-color: rgba(153, 21, 75, var(--border-opacity));
}

.hover\:border-pink-900:hover {
  --border-opacity: 1;
  border-color: #751a3d;
  border-color: rgba(117, 26, 61, var(--border-opacity));
}

.focus\:border-transparent:focus {
  border-color: transparent;
}

.focus\:border-white:focus {
  --border-opacity: 1;
  border-color: #ffffff;
  border-color: rgba(255, 255, 255, var(--border-opacity));
}

.focus\:border-black:focus {
  --border-opacity: 1;
  border-color: #000000;
  border-color: rgba(0, 0, 0, var(--border-opacity));
}

.focus\:border-gray-50:focus {
  --border-opacity: 1;
  border-color: #f9fafb;
  border-color: rgba(249, 250, 251, var(--border-opacity));
}

.focus\:border-gray-100:focus {
  --border-opacity: 1;
  border-color: #f4f5f7;
  border-color: rgba(244, 245, 247, var(--border-opacity));
}

.focus\:border-gray-200:focus {
  --border-opacity: 1;
  border-color: #e5e7eb;
  border-color: rgba(229, 231, 235, var(--border-opacity));
}

.focus\:border-gray-300:focus {
  --border-opacity: 1;
  border-color: #d5d6d7;
  border-color: rgba(213, 214, 215, var(--border-opacity));
}

.focus\:border-gray-400:focus {
  --border-opacity: 1;
  border-color: #9e9e9e;
  border-color: rgba(158, 158, 158, var(--border-opacity));
}

.focus\:border-gray-500:focus {
  --border-opacity: 1;
  border-color: #707275;
  border-color: rgba(112, 114, 117, var(--border-opacity));
}

.focus\:border-gray-600:focus {
  --border-opacity: 1;
  border-color: #4c4f52;
  border-color: rgba(76, 79, 82, var(--border-opacity));
}

.focus\:border-gray-700:focus {
  --border-opacity: 1;
  border-color: #24262d;
  border-color: rgba(36, 38, 45, var(--border-opacity));
}

.focus\:border-gray-800:focus {
  --border-opacity: 1;
  border-color: #1a1c23;
  border-color: rgba(26, 28, 35, var(--border-opacity));
}

.focus\:border-gray-900:focus {
  --border-opacity: 1;
  border-color: #121317;
  border-color: rgba(18, 19, 23, var(--border-opacity));
}

.focus\:border-cool-gray-50:focus {
  --border-opacity: 1;
  border-color: #fbfdfe;
  border-color: rgba(251, 253, 254, var(--border-opacity));
}

.focus\:border-cool-gray-100:focus {
  --border-opacity: 1;
  border-color: #f1f5f9;
  border-color: rgba(241, 245, 249, var(--border-opacity));
}

.focus\:border-cool-gray-200:focus {
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity));
}

.focus\:border-cool-gray-300:focus {
  --border-opacity: 1;
  border-color: #cfd8e3;
  border-color: rgba(207, 216, 227, var(--border-opacity));
}

.focus\:border-cool-gray-400:focus {
  --border-opacity: 1;
  border-color: #97a6ba;
  border-color: rgba(151, 166, 186, var(--border-opacity));
}

.focus\:border-cool-gray-500:focus {
  --border-opacity: 1;
  border-color: #64748b;
  border-color: rgba(100, 116, 139, var(--border-opacity));
}

.focus\:border-cool-gray-600:focus {
  --border-opacity: 1;
  border-color: #475569;
  border-color: rgba(71, 85, 105, var(--border-opacity));
}

.focus\:border-cool-gray-700:focus {
  --border-opacity: 1;
  border-color: #364152;
  border-color: rgba(54, 65, 82, var(--border-opacity));
}

.focus\:border-cool-gray-800:focus {
  --border-opacity: 1;
  border-color: #27303f;
  border-color: rgba(39, 48, 63, var(--border-opacity));
}

.focus\:border-cool-gray-900:focus {
  --border-opacity: 1;
  border-color: #1a202e;
  border-color: rgba(26, 32, 46, var(--border-opacity));
}

.focus\:border-red-50:focus {
  --border-opacity: 1;
  border-color: #fdf2f2;
  border-color: rgba(253, 242, 242, var(--border-opacity));
}

.focus\:border-red-100:focus {
  --border-opacity: 1;
  border-color: #fde8e8;
  border-color: rgba(253, 232, 232, var(--border-opacity));
}

.focus\:border-red-200:focus {
  --border-opacity: 1;
  border-color: #fbd5d5;
  border-color: rgba(251, 213, 213, var(--border-opacity));
}

.focus\:border-red-300:focus {
  --border-opacity: 1;
  border-color: #f8b4b4;
  border-color: rgba(248, 180, 180, var(--border-opacity));
}

.focus\:border-red-400:focus {
  --border-opacity: 1;
  border-color: #f98080;
  border-color: rgba(249, 128, 128, var(--border-opacity));
}

.focus\:border-red-500:focus {
  --border-opacity: 1;
  border-color: #f05252;
  border-color: rgba(240, 82, 82, var(--border-opacity));
}

.focus\:border-red-600:focus {
  --border-opacity: 1;
  border-color: #e02424;
  border-color: rgba(224, 36, 36, var(--border-opacity));
}

.focus\:border-red-700:focus {
  --border-opacity: 1;
  border-color: #c81e1e;
  border-color: rgba(200, 30, 30, var(--border-opacity));
}

.focus\:border-red-800:focus {
  --border-opacity: 1;
  border-color: #9b1c1c;
  border-color: rgba(155, 28, 28, var(--border-opacity));
}

.focus\:border-red-900:focus {
  --border-opacity: 1;
  border-color: #771d1d;
  border-color: rgba(119, 29, 29, var(--border-opacity));
}

.focus\:border-orange-50:focus {
  --border-opacity: 1;
  border-color: #fff8f1;
  border-color: rgba(255, 248, 241, var(--border-opacity));
}

.focus\:border-orange-100:focus {
  --border-opacity: 1;
  border-color: #feecdc;
  border-color: rgba(254, 236, 220, var(--border-opacity));
}

.focus\:border-orange-200:focus {
  --border-opacity: 1;
  border-color: #fcd9bd;
  border-color: rgba(252, 217, 189, var(--border-opacity));
}

.focus\:border-orange-300:focus {
  --border-opacity: 1;
  border-color: #fdba8c;
  border-color: rgba(253, 186, 140, var(--border-opacity));
}

.focus\:border-orange-400:focus {
  --border-opacity: 1;
  border-color: #ff8a4c;
  border-color: rgba(255, 138, 76, var(--border-opacity));
}

.focus\:border-orange-500:focus {
  --border-opacity: 1;
  border-color: #ff5a1f;
  border-color: rgba(255, 90, 31, var(--border-opacity));
}

.focus\:border-orange-600:focus {
  --border-opacity: 1;
  border-color: #d03801;
  border-color: rgba(208, 56, 1, var(--border-opacity));
}

.focus\:border-orange-700:focus {
  --border-opacity: 1;
  border-color: #b43403;
  border-color: rgba(180, 52, 3, var(--border-opacity));
}

.focus\:border-orange-800:focus {
  --border-opacity: 1;
  border-color: #8a2c0d;
  border-color: rgba(138, 44, 13, var(--border-opacity));
}

.focus\:border-orange-900:focus {
  --border-opacity: 1;
  border-color: #771d1d;
  border-color: rgba(119, 29, 29, var(--border-opacity));
}

.focus\:border-yellow-50:focus {
  --border-opacity: 1;
  border-color: #fdfdea;
  border-color: rgba(253, 253, 234, var(--border-opacity));
}

.focus\:border-yellow-100:focus {
  --border-opacity: 1;
  border-color: #fdf6b2;
  border-color: rgba(253, 246, 178, var(--border-opacity));
}

.focus\:border-yellow-200:focus {
  --border-opacity: 1;
  border-color: #fce96a;
  border-color: rgba(252, 233, 106, var(--border-opacity));
}

.focus\:border-yellow-300:focus {
  --border-opacity: 1;
  border-color: #faca15;
  border-color: rgba(250, 202, 21, var(--border-opacity));
}

.focus\:border-yellow-400:focus {
  --border-opacity: 1;
  border-color: #e3a008;
  border-color: rgba(227, 160, 8, var(--border-opacity));
}

.focus\:border-yellow-500:focus {
  --border-opacity: 1;
  border-color: #c27803;
  border-color: rgba(194, 120, 3, var(--border-opacity));
}

.focus\:border-yellow-600:focus {
  --border-opacity: 1;
  border-color: #9f580a;
  border-color: rgba(159, 88, 10, var(--border-opacity));
}

.focus\:border-yellow-700:focus {
  --border-opacity: 1;
  border-color: #8e4b10;
  border-color: rgba(142, 75, 16, var(--border-opacity));
}

.focus\:border-yellow-800:focus {
  --border-opacity: 1;
  border-color: #723b13;
  border-color: rgba(114, 59, 19, var(--border-opacity));
}

.focus\:border-yellow-900:focus {
  --border-opacity: 1;
  border-color: #633112;
  border-color: rgba(99, 49, 18, var(--border-opacity));
}

.focus\:border-green-50:focus {
  --border-opacity: 1;
  border-color: #f3faf7;
  border-color: rgba(243, 250, 247, var(--border-opacity));
}

.focus\:border-green-100:focus {
  --border-opacity: 1;
  border-color: #def7ec;
  border-color: rgba(222, 247, 236, var(--border-opacity));
}

.focus\:border-green-200:focus {
  --border-opacity: 1;
  border-color: #bcf0da;
  border-color: rgba(188, 240, 218, var(--border-opacity));
}

.focus\:border-green-300:focus {
  --border-opacity: 1;
  border-color: #84e1bc;
  border-color: rgba(132, 225, 188, var(--border-opacity));
}

.focus\:border-green-400:focus {
  --border-opacity: 1;
  border-color: #31c48d;
  border-color: rgba(49, 196, 141, var(--border-opacity));
}

.focus\:border-green-500:focus {
  --border-opacity: 1;
  border-color: #0e9f6e;
  border-color: rgba(14, 159, 110, var(--border-opacity));
}

.focus\:border-green-600:focus {
  --border-opacity: 1;
  border-color: #057a55;
  border-color: rgba(5, 122, 85, var(--border-opacity));
}

.focus\:border-green-700:focus {
  --border-opacity: 1;
  border-color: #046c4e;
  border-color: rgba(4, 108, 78, var(--border-opacity));
}

.focus\:border-green-800:focus {
  --border-opacity: 1;
  border-color: #03543f;
  border-color: rgba(3, 84, 63, var(--border-opacity));
}

.focus\:border-green-900:focus {
  --border-opacity: 1;
  border-color: #014737;
  border-color: rgba(1, 71, 55, var(--border-opacity));
}

.focus\:border-teal-50:focus {
  --border-opacity: 1;
  border-color: #edfafa;
  border-color: rgba(237, 250, 250, var(--border-opacity));
}

.focus\:border-teal-100:focus {
  --border-opacity: 1;
  border-color: #d5f5f6;
  border-color: rgba(213, 245, 246, var(--border-opacity));
}

.focus\:border-teal-200:focus {
  --border-opacity: 1;
  border-color: #afecef;
  border-color: rgba(175, 236, 239, var(--border-opacity));
}

.focus\:border-teal-300:focus {
  --border-opacity: 1;
  border-color: #7edce2;
  border-color: rgba(126, 220, 226, var(--border-opacity));
}

.focus\:border-teal-400:focus {
  --border-opacity: 1;
  border-color: #16bdca;
  border-color: rgba(22, 189, 202, var(--border-opacity));
}

.focus\:border-teal-500:focus {
  --border-opacity: 1;
  border-color: #0694a2;
  border-color: rgba(6, 148, 162, var(--border-opacity));
}

.focus\:border-teal-600:focus {
  --border-opacity: 1;
  border-color: #047481;
  border-color: rgba(4, 116, 129, var(--border-opacity));
}

.focus\:border-teal-700:focus {
  --border-opacity: 1;
  border-color: #036672;
  border-color: rgba(3, 102, 114, var(--border-opacity));
}

.focus\:border-teal-800:focus {
  --border-opacity: 1;
  border-color: #05505c;
  border-color: rgba(5, 80, 92, var(--border-opacity));
}

.focus\:border-teal-900:focus {
  --border-opacity: 1;
  border-color: #014451;
  border-color: rgba(1, 68, 81, var(--border-opacity));
}

.focus\:border-blue-50:focus {
  --border-opacity: 1;
  border-color: #ebf5ff;
  border-color: rgba(235, 245, 255, var(--border-opacity));
}

.focus\:border-blue-100:focus {
  --border-opacity: 1;
  border-color: #e1effe;
  border-color: rgba(225, 239, 254, var(--border-opacity));
}

.focus\:border-blue-200:focus {
  --border-opacity: 1;
  border-color: #c3ddfd;
  border-color: rgba(195, 221, 253, var(--border-opacity));
}

.focus\:border-blue-300:focus {
  --border-opacity: 1;
  border-color: #a4cafe;
  border-color: rgba(164, 202, 254, var(--border-opacity));
}

.focus\:border-blue-400:focus {
  --border-opacity: 1;
  border-color: #76a9fa;
  border-color: rgba(118, 169, 250, var(--border-opacity));
}

.focus\:border-blue-500:focus {
  --border-opacity: 1;
  border-color: #3f83f8;
  border-color: rgba(63, 131, 248, var(--border-opacity));
}

.focus\:border-blue-600:focus {
  --border-opacity: 1;
  border-color: #1c64f2;
  border-color: rgba(28, 100, 242, var(--border-opacity));
}

.focus\:border-blue-700:focus {
  --border-opacity: 1;
  border-color: #1a56db;
  border-color: rgba(26, 86, 219, var(--border-opacity));
}

.focus\:border-blue-800:focus {
  --border-opacity: 1;
  border-color: #1e429f;
  border-color: rgba(30, 66, 159, var(--border-opacity));
}

.focus\:border-blue-900:focus {
  --border-opacity: 1;
  border-color: #233876;
  border-color: rgba(35, 56, 118, var(--border-opacity));
}

.focus\:border-indigo-50:focus {
  --border-opacity: 1;
  border-color: #f0f5ff;
  border-color: rgba(240, 245, 255, var(--border-opacity));
}

.focus\:border-indigo-100:focus {
  --border-opacity: 1;
  border-color: #e5edff;
  border-color: rgba(229, 237, 255, var(--border-opacity));
}

.focus\:border-indigo-200:focus {
  --border-opacity: 1;
  border-color: #cddbfe;
  border-color: rgba(205, 219, 254, var(--border-opacity));
}

.focus\:border-indigo-300:focus {
  --border-opacity: 1;
  border-color: #b4c6fc;
  border-color: rgba(180, 198, 252, var(--border-opacity));
}

.focus\:border-indigo-400:focus {
  --border-opacity: 1;
  border-color: #8da2fb;
  border-color: rgba(141, 162, 251, var(--border-opacity));
}

.focus\:border-indigo-500:focus {
  --border-opacity: 1;
  border-color: #6875f5;
  border-color: rgba(104, 117, 245, var(--border-opacity));
}

.focus\:border-indigo-600:focus {
  --border-opacity: 1;
  border-color: #5850ec;
  border-color: rgba(88, 80, 236, var(--border-opacity));
}

.focus\:border-indigo-700:focus {
  --border-opacity: 1;
  border-color: #5145cd;
  border-color: rgba(81, 69, 205, var(--border-opacity));
}

.focus\:border-indigo-800:focus {
  --border-opacity: 1;
  border-color: #42389d;
  border-color: rgba(66, 56, 157, var(--border-opacity));
}

.focus\:border-indigo-900:focus {
  --border-opacity: 1;
  border-color: #362f78;
  border-color: rgba(54, 47, 120, var(--border-opacity));
}

.focus\:border-purple-50:focus {
  --border-opacity: 1;
  border-color: #f6f5ff;
  border-color: rgba(246, 245, 255, var(--border-opacity));
}

.focus\:border-purple-100:focus {
  --border-opacity: 1;
  border-color: #edebfe;
  border-color: rgba(237, 235, 254, var(--border-opacity));
}

.focus\:border-purple-200:focus {
  --border-opacity: 1;
  border-color: #dcd7fe;
  border-color: rgba(220, 215, 254, var(--border-opacity));
}

.focus\:border-purple-300:focus {
  --border-opacity: 1;
  border-color: #cabffd;
  border-color: rgba(202, 191, 253, var(--border-opacity));
}

.focus\:border-purple-400:focus {
  --border-opacity: 1;
  border-color: #ac94fa;
  border-color: rgba(172, 148, 250, var(--border-opacity));
}

.focus\:border-purple-500:focus {
  --border-opacity: 1;
  border-color: #9061f9;
  border-color: rgba(144, 97, 249, var(--border-opacity));
}

.focus\:border-purple-600:focus {
  --border-opacity: 1;
  border-color: #7e3af2;
  border-color: rgba(126, 58, 242, var(--border-opacity));
}

.focus\:border-purple-700:focus {
  --border-opacity: 1;
  border-color: #6c2bd9;
  border-color: rgba(108, 43, 217, var(--border-opacity));
}

.focus\:border-purple-800:focus {
  --border-opacity: 1;
  border-color: #5521b5;
  border-color: rgba(85, 33, 181, var(--border-opacity));
}

.focus\:border-purple-900:focus {
  --border-opacity: 1;
  border-color: #4a1d96;
  border-color: rgba(74, 29, 150, var(--border-opacity));
}

.focus\:border-pink-50:focus {
  --border-opacity: 1;
  border-color: #fdf2f8;
  border-color: rgba(253, 242, 248, var(--border-opacity));
}

.focus\:border-pink-100:focus {
  --border-opacity: 1;
  border-color: #fce8f3;
  border-color: rgba(252, 232, 243, var(--border-opacity));
}

.focus\:border-pink-200:focus {
  --border-opacity: 1;
  border-color: #fad1e8;
  border-color: rgba(250, 209, 232, var(--border-opacity));
}

.focus\:border-pink-300:focus {
  --border-opacity: 1;
  border-color: #f8b4d9;
  border-color: rgba(248, 180, 217, var(--border-opacity));
}

.focus\:border-pink-400:focus {
  --border-opacity: 1;
  border-color: #f17eb8;
  border-color: rgba(241, 126, 184, var(--border-opacity));
}

.focus\:border-pink-500:focus {
  --border-opacity: 1;
  border-color: #e74694;
  border-color: rgba(231, 70, 148, var(--border-opacity));
}

.focus\:border-pink-600:focus {
  --border-opacity: 1;
  border-color: #d61f69;
  border-color: rgba(214, 31, 105, var(--border-opacity));
}

.focus\:border-pink-700:focus {
  --border-opacity: 1;
  border-color: #bf125d;
  border-color: rgba(191, 18, 93, var(--border-opacity));
}

.focus\:border-pink-800:focus {
  --border-opacity: 1;
  border-color: #99154b;
  border-color: rgba(153, 21, 75, var(--border-opacity));
}

.focus\:border-pink-900:focus {
  --border-opacity: 1;
  border-color: #751a3d;
  border-color: rgba(117, 26, 61, var(--border-opacity));
}

.border-opacity-0 {
  --border-opacity: 0;
}

.border-opacity-25 {
  --border-opacity: 0.25;
}

.border-opacity-50 {
  --border-opacity: 0.5;
}

.border-opacity-75 {
  --border-opacity: 0.75;
}

.border-opacity-100 {
  --border-opacity: 1;
}

.hover\:border-opacity-0:hover {
  --border-opacity: 0;
}

.hover\:border-opacity-25:hover {
  --border-opacity: 0.25;
}

.hover\:border-opacity-50:hover {
  --border-opacity: 0.5;
}

.hover\:border-opacity-75:hover {
  --border-opacity: 0.75;
}

.hover\:border-opacity-100:hover {
  --border-opacity: 1;
}

.focus\:border-opacity-0:focus {
  --border-opacity: 0;
}

.focus\:border-opacity-25:focus {
  --border-opacity: 0.25;
}

.focus\:border-opacity-50:focus {
  --border-opacity: 0.5;
}

.focus\:border-opacity-75:focus {
  --border-opacity: 0.75;
}

.focus\:border-opacity-100:focus {
  --border-opacity: 1;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-t-sm {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}

.rounded-r-sm {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

.rounded-b-sm {
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.rounded-l-sm {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.rounded-r-md {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.rounded-b-md {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.rounded-l-md {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-tl-none {
  border-top-left-radius: 0;
}

.rounded-tr-none {
  border-top-right-radius: 0;
}

.rounded-br-none {
  border-bottom-right-radius: 0;
}

.rounded-bl-none {
  border-bottom-left-radius: 0;
}

.rounded-tl-sm {
  border-top-left-radius: 0.125rem;
}

.rounded-tr-sm {
  border-top-right-radius: 0.125rem;
}

.rounded-br-sm {
  border-bottom-right-radius: 0.125rem;
}

.rounded-bl-sm {
  border-bottom-left-radius: 0.125rem;
}

.rounded-tl {
  border-top-left-radius: 0.25rem;
}

.rounded-tr {
  border-top-right-radius: 0.25rem;
}

.rounded-br {
  border-bottom-right-radius: 0.25rem;
}

.rounded-bl {
  border-bottom-left-radius: 0.25rem;
}

.rounded-tl-md {
  border-top-left-radius: 0.375rem;
}

.rounded-tr-md {
  border-top-right-radius: 0.375rem;
}

.rounded-br-md {
  border-bottom-right-radius: 0.375rem;
}

.rounded-bl-md {
  border-bottom-left-radius: 0.375rem;
}

.rounded-tl-lg {
  border-top-left-radius: 0.5rem;
}

.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}

.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}

.rounded-tl-full {
  border-top-left-radius: 9999px;
}

.rounded-tr-full {
  border-top-right-radius: 9999px;
}

.rounded-br-full {
  border-bottom-right-radius: 9999px;
}

.rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border-double {
  border-style: double;
}

.border-none {
  border-style: none;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-8 {
  border-width: 8px;
}

.border {
  border-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-r-0 {
  border-right-width: 0;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-l-0 {
  border-left-width: 0;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-t-8 {
  border-top-width: 8px;
}

.border-r-8 {
  border-right-width: 8px;
}

.border-b-8 {
  border-bottom-width: 8px;
}

.border-l-8 {
  border-left-width: 8px;
}

.border-t {
  border-top-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}

.cursor-text {
  cursor: text;
}

.cursor-move {
  cursor: move;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.table-caption {
  display: table-caption;
}

.table-cell {
  display: table-cell;
}

.table-column {
  display: table-column;
}

.table-column-group {
  display: table-column-group;
}

.table-footer-group {
  display: table-footer-group;
}

.table-header-group {
  display: table-header-group;
}

.table-row-group {
  display: table-row-group;
}

.table-row {
  display: table-row;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.hidden {
  display: none;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-initial {
  flex: 0 1 auto;
}

.flex-none {
  flex: none;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.order-first {
  order: -9999;
}

.order-last {
  order: 9999;
}

.order-none {
  order: 0;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.clear-both {
  clear: both;
}

.clear-none {
  clear: none;
}

.font-sans {
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.font-serif {
  font-family: Georgia, Cambria, "Times New Roman", Times, serif;
}

.font-mono {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
    monospace;
}

.font-hairline {
  font-weight: 100;
}

.font-thin {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.hover\:font-hairline:hover {
  font-weight: 100;
}

.hover\:font-thin:hover {
  font-weight: 200;
}

.hover\:font-light:hover {
  font-weight: 300;
}

.hover\:font-normal:hover {
  font-weight: 400;
}

.hover\:font-medium:hover {
  font-weight: 500;
}

.hover\:font-semibold:hover {
  font-weight: 600;
}

.hover\:font-bold:hover {
  font-weight: 700;
}

.hover\:font-extrabold:hover {
  font-weight: 800;
}

.hover\:font-black:hover {
  font-weight: 900;
}

.focus\:font-hairline:focus {
  font-weight: 100;
}

.focus\:font-thin:focus {
  font-weight: 200;
}

.focus\:font-light:focus {
  font-weight: 300;
}

.focus\:font-normal:focus {
  font-weight: 400;
}

.focus\:font-medium:focus {
  font-weight: 500;
}

.focus\:font-semibold:focus {
  font-weight: 600;
}

.focus\:font-bold:focus {
  font-weight: 700;
}

.focus\:font-extrabold:focus {
  font-weight: 800;
}

.focus\:font-black:focus {
  font-weight: 900;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: 0.25rem;
}

.h-2 {
  height: 0.5rem;
}

.h-3 {
  height: 0.75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-32 {
  height: 8rem;
}

.h-40 {
  height: 10rem;
}

.h-48 {
  height: 12rem;
}

.h-56 {
  height: 14rem;
}

.h-64 {
  height: 16rem;
}

.h-auto {
  height: auto;
}

.h-px {
  height: 1px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.text-xs {
  font-size: 0.75rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-4xl {
  font-size: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
}

.text-6xl {
  font-size: 4rem;
}

.leading-3 {
  line-height: 0.75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-snug {
  line-height: 1.375;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-loose {
  line-height: 2;
}

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

.list-none {
  list-style-type: none;
}

.list-disc {
  list-style-type: disc;
}

.list-decimal {
  list-style-type: decimal;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 0.75rem;
}

.m-4 {
  margin: 1rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-6 {
  margin: 1.5rem;
}

.m-8 {
  margin: 2rem;
}

.m-10 {
  margin: 2.5rem;
}

.m-12 {
  margin: 3rem;
}

.m-16 {
  margin: 4rem;
}

.m-20 {
  margin: 5rem;
}

.m-24 {
  margin: 6rem;
}

.m-32 {
  margin: 8rem;
}

.m-40 {
  margin: 10rem;
}

.m-48 {
  margin: 12rem;
}

.m-56 {
  margin: 14rem;
}

.m-64 {
  margin: 16rem;
}

.m-auto {
  margin: auto;
}

.m-px {
  margin: 1px;
}

.-m-1 {
  margin: -0.25rem;
}

.-m-2 {
  margin: -0.5rem;
}

.-m-3 {
  margin: -0.75rem;
}

.-m-4 {
  margin: -1rem;
}

.-m-5 {
  margin: -1.25rem;
}

.-m-6 {
  margin: -1.5rem;
}

.-m-8 {
  margin: -2rem;
}

.-m-10 {
  margin: -2.5rem;
}

.-m-12 {
  margin: -3rem;
}

.-m-16 {
  margin: -4rem;
}

.-m-20 {
  margin: -5rem;
}

.-m-24 {
  margin: -6rem;
}

.-m-32 {
  margin: -8rem;
}

.-m-40 {
  margin: -10rem;
}

.-m-48 {
  margin: -12rem;
}

.-m-56 {
  margin: -14rem;
}

.-m-64 {
  margin: -16rem;
}

.-m-px {
  margin: -1px;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.mx-32 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.my-40 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.mx-40 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.my-48 {
  margin-top: 12rem;
  margin-bottom: 12rem;
}

.mx-48 {
  margin-left: 12rem;
  margin-right: 12rem;
}

.my-56 {
  margin-top: 14rem;
  margin-bottom: 14rem;
}

.mx-56 {
  margin-left: 14rem;
  margin-right: 14rem;
}

.my-64 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.mx-64 {
  margin-left: 16rem;
  margin-right: 16rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.-my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.-my-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.-my-3 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.-my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.-my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.-my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.-mx-12 {
  margin-left: -3rem;
  margin-right: -3rem;
}

.-my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.-mx-16 {
  margin-left: -4rem;
  margin-right: -4rem;
}

.-my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.-mx-20 {
  margin-left: -5rem;
  margin-right: -5rem;
}

.-my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.-mx-24 {
  margin-left: -6rem;
  margin-right: -6rem;
}

.-my-32 {
  margin-top: -8rem;
  margin-bottom: -8rem;
}

.-mx-32 {
  margin-left: -8rem;
  margin-right: -8rem;
}

.-my-40 {
  margin-top: -10rem;
  margin-bottom: -10rem;
}

.-mx-40 {
  margin-left: -10rem;
  margin-right: -10rem;
}

.-my-48 {
  margin-top: -12rem;
  margin-bottom: -12rem;
}

.-mx-48 {
  margin-left: -12rem;
  margin-right: -12rem;
}

.-my-56 {
  margin-top: -14rem;
  margin-bottom: -14rem;
}

.-mx-56 {
  margin-left: -14rem;
  margin-right: -14rem;
}

.-my-64 {
  margin-top: -16rem;
  margin-bottom: -16rem;
}

.-mx-64 {
  margin-left: -16rem;
  margin-right: -16rem;
}

.-my-px {
  margin-top: -1px;
  margin-bottom: -1px;
}

.-mx-px {
  margin-left: -1px;
  margin-right: -1px;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.ml-12 {
  margin-left: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.ml-16 {
  margin-left: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mr-20 {
  margin-right: 5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.ml-20 {
  margin-left: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mr-24 {
  margin-right: 6rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.ml-24 {
  margin-left: 6rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mr-32 {
  margin-right: 8rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.ml-32 {
  margin-left: 8rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mr-40 {
  margin-right: 10rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.ml-40 {
  margin-left: 10rem;
}

.mt-48 {
  margin-top: 12rem;
}

.mr-48 {
  margin-right: 12rem;
}

.mb-48 {
  margin-bottom: 12rem;
}

.ml-48 {
  margin-left: 12rem;
}

.mt-56 {
  margin-top: 14rem;
}

.mr-56 {
  margin-right: 14rem;
}

.mb-56 {
  margin-bottom: 14rem;
}

.ml-56 {
  margin-left: 14rem;
}

.mt-64 {
  margin-top: 16rem;
}

.mr-64 {
  margin-right: 16rem;
}

.mb-64 {
  margin-bottom: 16rem;
}

.ml-64 {
  margin-left: 16rem;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mt-px {
  margin-top: 1px;
}

.mr-px {
  margin-right: 1px;
}

.mb-px {
  margin-bottom: 1px;
}

.ml-px {
  margin-left: 1px;
}

.-mt-1 {
  margin-top: -0.25rem;
}

.-mr-1 {
  margin-right: -0.25rem;
}

.-mb-1 {
  margin-bottom: -0.25rem;
}

.-ml-1 {
  margin-left: -0.25rem;
}

.-mt-2 {
  margin-top: -0.5rem;
}

.-mr-2 {
  margin-right: -0.5rem;
}

.-mb-2 {
  margin-bottom: -0.5rem;
}

.-ml-2 {
  margin-left: -0.5rem;
}

.-mt-3 {
  margin-top: -0.75rem;
}

.-mr-3 {
  margin-right: -0.75rem;
}

.-mb-3 {
  margin-bottom: -0.75rem;
}

.-ml-3 {
  margin-left: -0.75rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.-mr-5 {
  margin-right: -1.25rem;
}

.-mb-5 {
  margin-bottom: -1.25rem;
}

.-ml-5 {
  margin-left: -1.25rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.-mr-6 {
  margin-right: -1.5rem;
}

.-mb-6 {
  margin-bottom: -1.5rem;
}

.-ml-6 {
  margin-left: -1.5rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.-mr-8 {
  margin-right: -2rem;
}

.-mb-8 {
  margin-bottom: -2rem;
}

.-ml-8 {
  margin-left: -2rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.-mr-10 {
  margin-right: -2.5rem;
}

.-mb-10 {
  margin-bottom: -2.5rem;
}

.-ml-10 {
  margin-left: -2.5rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mr-12 {
  margin-right: -3rem;
}

.-mb-12 {
  margin-bottom: -3rem;
}

.-ml-12 {
  margin-left: -3rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.-mr-16 {
  margin-right: -4rem;
}

.-mb-16 {
  margin-bottom: -4rem;
}

.-ml-16 {
  margin-left: -4rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.-mr-20 {
  margin-right: -5rem;
}

.-mb-20 {
  margin-bottom: -5rem;
}

.-ml-20 {
  margin-left: -5rem;
}

.-mt-24 {
  margin-top: -6rem;
}

.-mr-24 {
  margin-right: -6rem;
}

.-mb-24 {
  margin-bottom: -6rem;
}

.-ml-24 {
  margin-left: -6rem;
}

.-mt-32 {
  margin-top: -8rem;
}

.-mr-32 {
  margin-right: -8rem;
}

.-mb-32 {
  margin-bottom: -8rem;
}

.-ml-32 {
  margin-left: -8rem;
}

.-mt-40 {
  margin-top: -10rem;
}

.-mr-40 {
  margin-right: -10rem;
}

.-mb-40 {
  margin-bottom: -10rem;
}

.-ml-40 {
  margin-left: -10rem;
}

.-mt-48 {
  margin-top: -12rem;
}

.-mr-48 {
  margin-right: -12rem;
}

.-mb-48 {
  margin-bottom: -12rem;
}

.-ml-48 {
  margin-left: -12rem;
}

.-mt-56 {
  margin-top: -14rem;
}

.-mr-56 {
  margin-right: -14rem;
}

.-mb-56 {
  margin-bottom: -14rem;
}

.-ml-56 {
  margin-left: -14rem;
}

.-mt-64 {
  margin-top: -16rem;
}

.-mr-64 {
  margin-right: -16rem;
}

.-mb-64 {
  margin-bottom: -16rem;
}

.-ml-64 {
  margin-left: -16rem;
}

.-mt-px {
  margin-top: -1px;
}

.-mr-px {
  margin-right: -1px;
}

.-mb-px {
  margin-bottom: -1px;
}

.-ml-px {
  margin-left: -1px;
}

.last\:m-0:last-child {
  margin: 0;
}

.last\:m-1:last-child {
  margin: 0.25rem;
}

.last\:m-2:last-child {
  margin: 0.5rem;
}

.last\:m-3:last-child {
  margin: 0.75rem;
}

.last\:m-4:last-child {
  margin: 1rem;
}

.last\:m-5:last-child {
  margin: 1.25rem;
}

.last\:m-6:last-child {
  margin: 1.5rem;
}

.last\:m-8:last-child {
  margin: 2rem;
}

.last\:m-10:last-child {
  margin: 2.5rem;
}

.last\:m-12:last-child {
  margin: 3rem;
}

.last\:m-16:last-child {
  margin: 4rem;
}

.last\:m-20:last-child {
  margin: 5rem;
}

.last\:m-24:last-child {
  margin: 6rem;
}

.last\:m-32:last-child {
  margin: 8rem;
}

.last\:m-40:last-child {
  margin: 10rem;
}

.last\:m-48:last-child {
  margin: 12rem;
}

.last\:m-56:last-child {
  margin: 14rem;
}

.last\:m-64:last-child {
  margin: 16rem;
}

.last\:m-auto:last-child {
  margin: auto;
}

.last\:m-px:last-child {
  margin: 1px;
}

.last\:-m-1:last-child {
  margin: -0.25rem;
}

.last\:-m-2:last-child {
  margin: -0.5rem;
}

.last\:-m-3:last-child {
  margin: -0.75rem;
}

.last\:-m-4:last-child {
  margin: -1rem;
}

.last\:-m-5:last-child {
  margin: -1.25rem;
}

.last\:-m-6:last-child {
  margin: -1.5rem;
}

.last\:-m-8:last-child {
  margin: -2rem;
}

.last\:-m-10:last-child {
  margin: -2.5rem;
}

.last\:-m-12:last-child {
  margin: -3rem;
}

.last\:-m-16:last-child {
  margin: -4rem;
}

.last\:-m-20:last-child {
  margin: -5rem;
}

.last\:-m-24:last-child {
  margin: -6rem;
}

.last\:-m-32:last-child {
  margin: -8rem;
}

.last\:-m-40:last-child {
  margin: -10rem;
}

.last\:-m-48:last-child {
  margin: -12rem;
}

.last\:-m-56:last-child {
  margin: -14rem;
}

.last\:-m-64:last-child {
  margin: -16rem;
}

.last\:-m-px:last-child {
  margin: -1px;
}

.last\:my-0:last-child {
  margin-top: 0;
  margin-bottom: 0;
}

.last\:mx-0:last-child {
  margin-left: 0;
  margin-right: 0;
}

.last\:my-1:last-child {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.last\:mx-1:last-child {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.last\:my-2:last-child {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.last\:mx-2:last-child {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.last\:my-3:last-child {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.last\:mx-3:last-child {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.last\:my-4:last-child {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.last\:mx-4:last-child {
  margin-left: 1rem;
  margin-right: 1rem;
}

.last\:my-5:last-child {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.last\:mx-5:last-child {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.last\:my-6:last-child {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.last\:mx-6:last-child {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.last\:my-8:last-child {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.last\:mx-8:last-child {
  margin-left: 2rem;
  margin-right: 2rem;
}

.last\:my-10:last-child {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.last\:mx-10:last-child {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.last\:my-12:last-child {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.last\:mx-12:last-child {
  margin-left: 3rem;
  margin-right: 3rem;
}

.last\:my-16:last-child {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.last\:mx-16:last-child {
  margin-left: 4rem;
  margin-right: 4rem;
}

.last\:my-20:last-child {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.last\:mx-20:last-child {
  margin-left: 5rem;
  margin-right: 5rem;
}

.last\:my-24:last-child {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.last\:mx-24:last-child {
  margin-left: 6rem;
  margin-right: 6rem;
}

.last\:my-32:last-child {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.last\:mx-32:last-child {
  margin-left: 8rem;
  margin-right: 8rem;
}

.last\:my-40:last-child {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.last\:mx-40:last-child {
  margin-left: 10rem;
  margin-right: 10rem;
}

.last\:my-48:last-child {
  margin-top: 12rem;
  margin-bottom: 12rem;
}

.last\:mx-48:last-child {
  margin-left: 12rem;
  margin-right: 12rem;
}

.last\:my-56:last-child {
  margin-top: 14rem;
  margin-bottom: 14rem;
}

.last\:mx-56:last-child {
  margin-left: 14rem;
  margin-right: 14rem;
}

.last\:my-64:last-child {
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.last\:mx-64:last-child {
  margin-left: 16rem;
  margin-right: 16rem;
}

.last\:my-auto:last-child {
  margin-top: auto;
  margin-bottom: auto;
}

.last\:mx-auto:last-child {
  margin-left: auto;
  margin-right: auto;
}

.last\:my-px:last-child {
  margin-top: 1px;
  margin-bottom: 1px;
}

.last\:mx-px:last-child {
  margin-left: 1px;
  margin-right: 1px;
}

.last\:-my-1:last-child {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.last\:-mx-1:last-child {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.last\:-my-2:last-child {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.last\:-mx-2:last-child {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.last\:-my-3:last-child {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.last\:-mx-3:last-child {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.last\:-my-4:last-child {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.last\:-mx-4:last-child {
  margin-left: -1rem;
  margin-right: -1rem;
}

.last\:-my-5:last-child {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.last\:-mx-5:last-child {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.last\:-my-6:last-child {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.last\:-mx-6:last-child {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.last\:-my-8:last-child {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.last\:-mx-8:last-child {
  margin-left: -2rem;
  margin-right: -2rem;
}

.last\:-my-10:last-child {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.last\:-mx-10:last-child {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.last\:-my-12:last-child {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.last\:-mx-12:last-child {
  margin-left: -3rem;
  margin-right: -3rem;
}

.last\:-my-16:last-child {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.last\:-mx-16:last-child {
  margin-left: -4rem;
  margin-right: -4rem;
}

.last\:-my-20:last-child {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.last\:-mx-20:last-child {
  margin-left: -5rem;
  margin-right: -5rem;
}

.last\:-my-24:last-child {
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.last\:-mx-24:last-child {
  margin-left: -6rem;
  margin-right: -6rem;
}

.last\:-my-32:last-child {
  margin-top: -8rem;
  margin-bottom: -8rem;
}

.last\:-mx-32:last-child {
  margin-left: -8rem;
  margin-right: -8rem;
}

.last\:-my-40:last-child {
  margin-top: -10rem;
  margin-bottom: -10rem;
}

.last\:-mx-40:last-child {
  margin-left: -10rem;
  margin-right: -10rem;
}

.last\:-my-48:last-child {
  margin-top: -12rem;
  margin-bottom: -12rem;
}

.last\:-mx-48:last-child {
  margin-left: -12rem;
  margin-right: -12rem;
}

.last\:-my-56:last-child {
  margin-top: -14rem;
  margin-bottom: -14rem;
}

.last\:-mx-56:last-child {
  margin-left: -14rem;
  margin-right: -14rem;
}

.last\:-my-64:last-child {
  margin-top: -16rem;
  margin-bottom: -16rem;
}

.last\:-mx-64:last-child {
  margin-left: -16rem;
  margin-right: -16rem;
}

.last\:-my-px:last-child {
  margin-top: -1px;
  margin-bottom: -1px;
}

.last\:-mx-px:last-child {
  margin-left: -1px;
  margin-right: -1px;
}

.last\:mt-0:last-child {
  margin-top: 0;
}

.last\:mr-0:last-child {
  margin-right: 0;
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.last\:ml-0:last-child {
  margin-left: 0;
}

.last\:mt-1:last-child {
  margin-top: 0.25rem;
}

.last\:mr-1:last-child {
  margin-right: 0.25rem;
}

.last\:mb-1:last-child {
  margin-bottom: 0.25rem;
}

.last\:ml-1:last-child {
  margin-left: 0.25rem;
}

.last\:mt-2:last-child {
  margin-top: 0.5rem;
}

.last\:mr-2:last-child {
  margin-right: 0.5rem;
}

.last\:mb-2:last-child {
  margin-bottom: 0.5rem;
}

.last\:ml-2:last-child {
  margin-left: 0.5rem;
}

.last\:mt-3:last-child {
  margin-top: 0.75rem;
}

.last\:mr-3:last-child {
  margin-right: 0.75rem;
}

.last\:mb-3:last-child {
  margin-bottom: 0.75rem;
}

.last\:ml-3:last-child {
  margin-left: 0.75rem;
}

.last\:mt-4:last-child {
  margin-top: 1rem;
}

.last\:mr-4:last-child {
  margin-right: 1rem;
}

.last\:mb-4:last-child {
  margin-bottom: 1rem;
}

.last\:ml-4:last-child {
  margin-left: 1rem;
}

.last\:mt-5:last-child {
  margin-top: 1.25rem;
}

.last\:mr-5:last-child {
  margin-right: 1.25rem;
}

.last\:mb-5:last-child {
  margin-bottom: 1.25rem;
}

.last\:ml-5:last-child {
  margin-left: 1.25rem;
}

.last\:mt-6:last-child {
  margin-top: 1.5rem;
}

.last\:mr-6:last-child {
  margin-right: 1.5rem;
}

.last\:mb-6:last-child {
  margin-bottom: 1.5rem;
}

.last\:ml-6:last-child {
  margin-left: 1.5rem;
}

.last\:mt-8:last-child {
  margin-top: 2rem;
}

.last\:mr-8:last-child {
  margin-right: 2rem;
}

.last\:mb-8:last-child {
  margin-bottom: 2rem;
}

.last\:ml-8:last-child {
  margin-left: 2rem;
}

.last\:mt-10:last-child {
  margin-top: 2.5rem;
}

.last\:mr-10:last-child {
  margin-right: 2.5rem;
}

.last\:mb-10:last-child {
  margin-bottom: 2.5rem;
}

.last\:ml-10:last-child {
  margin-left: 2.5rem;
}

.last\:mt-12:last-child {
  margin-top: 3rem;
}

.last\:mr-12:last-child {
  margin-right: 3rem;
}

.last\:mb-12:last-child {
  margin-bottom: 3rem;
}

.last\:ml-12:last-child {
  margin-left: 3rem;
}

.last\:mt-16:last-child {
  margin-top: 4rem;
}

.last\:mr-16:last-child {
  margin-right: 4rem;
}

.last\:mb-16:last-child {
  margin-bottom: 4rem;
}

.last\:ml-16:last-child {
  margin-left: 4rem;
}

.last\:mt-20:last-child {
  margin-top: 5rem;
}

.last\:mr-20:last-child {
  margin-right: 5rem;
}

.last\:mb-20:last-child {
  margin-bottom: 5rem;
}

.last\:ml-20:last-child {
  margin-left: 5rem;
}

.last\:mt-24:last-child {
  margin-top: 6rem;
}

.last\:mr-24:last-child {
  margin-right: 6rem;
}

.last\:mb-24:last-child {
  margin-bottom: 6rem;
}

.last\:ml-24:last-child {
  margin-left: 6rem;
}

.last\:mt-32:last-child {
  margin-top: 8rem;
}

.last\:mr-32:last-child {
  margin-right: 8rem;
}

.last\:mb-32:last-child {
  margin-bottom: 8rem;
}

.last\:ml-32:last-child {
  margin-left: 8rem;
}

.last\:mt-40:last-child {
  margin-top: 10rem;
}

.last\:mr-40:last-child {
  margin-right: 10rem;
}

.last\:mb-40:last-child {
  margin-bottom: 10rem;
}

.last\:ml-40:last-child {
  margin-left: 10rem;
}

.last\:mt-48:last-child {
  margin-top: 12rem;
}

.last\:mr-48:last-child {
  margin-right: 12rem;
}

.last\:mb-48:last-child {
  margin-bottom: 12rem;
}

.last\:ml-48:last-child {
  margin-left: 12rem;
}

.last\:mt-56:last-child {
  margin-top: 14rem;
}

.last\:mr-56:last-child {
  margin-right: 14rem;
}

.last\:mb-56:last-child {
  margin-bottom: 14rem;
}

.last\:ml-56:last-child {
  margin-left: 14rem;
}

.last\:mt-64:last-child {
  margin-top: 16rem;
}

.last\:mr-64:last-child {
  margin-right: 16rem;
}

.last\:mb-64:last-child {
  margin-bottom: 16rem;
}

.last\:ml-64:last-child {
  margin-left: 16rem;
}

.last\:mt-auto:last-child {
  margin-top: auto;
}

.last\:mr-auto:last-child {
  margin-right: auto;
}

.last\:mb-auto:last-child {
  margin-bottom: auto;
}

.last\:ml-auto:last-child {
  margin-left: auto;
}

.last\:mt-px:last-child {
  margin-top: 1px;
}

.last\:mr-px:last-child {
  margin-right: 1px;
}

.last\:mb-px:last-child {
  margin-bottom: 1px;
}

.last\:ml-px:last-child {
  margin-left: 1px;
}

.last\:-mt-1:last-child {
  margin-top: -0.25rem;
}

.last\:-mr-1:last-child {
  margin-right: -0.25rem;
}

.last\:-mb-1:last-child {
  margin-bottom: -0.25rem;
}

.last\:-ml-1:last-child {
  margin-left: -0.25rem;
}

.last\:-mt-2:last-child {
  margin-top: -0.5rem;
}

.last\:-mr-2:last-child {
  margin-right: -0.5rem;
}

.last\:-mb-2:last-child {
  margin-bottom: -0.5rem;
}

.last\:-ml-2:last-child {
  margin-left: -0.5rem;
}

.last\:-mt-3:last-child {
  margin-top: -0.75rem;
}

.last\:-mr-3:last-child {
  margin-right: -0.75rem;
}

.last\:-mb-3:last-child {
  margin-bottom: -0.75rem;
}

.last\:-ml-3:last-child {
  margin-left: -0.75rem;
}

.last\:-mt-4:last-child {
  margin-top: -1rem;
}

.last\:-mr-4:last-child {
  margin-right: -1rem;
}

.last\:-mb-4:last-child {
  margin-bottom: -1rem;
}

.last\:-ml-4:last-child {
  margin-left: -1rem;
}

.last\:-mt-5:last-child {
  margin-top: -1.25rem;
}

.last\:-mr-5:last-child {
  margin-right: -1.25rem;
}

.last\:-mb-5:last-child {
  margin-bottom: -1.25rem;
}

.last\:-ml-5:last-child {
  margin-left: -1.25rem;
}

.last\:-mt-6:last-child {
  margin-top: -1.5rem;
}

.last\:-mr-6:last-child {
  margin-right: -1.5rem;
}

.last\:-mb-6:last-child {
  margin-bottom: -1.5rem;
}

.last\:-ml-6:last-child {
  margin-left: -1.5rem;
}

.last\:-mt-8:last-child {
  margin-top: -2rem;
}

.last\:-mr-8:last-child {
  margin-right: -2rem;
}

.last\:-mb-8:last-child {
  margin-bottom: -2rem;
}

.last\:-ml-8:last-child {
  margin-left: -2rem;
}

.last\:-mt-10:last-child {
  margin-top: -2.5rem;
}

.last\:-mr-10:last-child {
  margin-right: -2.5rem;
}

.last\:-mb-10:last-child {
  margin-bottom: -2.5rem;
}

.last\:-ml-10:last-child {
  margin-left: -2.5rem;
}

.last\:-mt-12:last-child {
  margin-top: -3rem;
}

.last\:-mr-12:last-child {
  margin-right: -3rem;
}

.last\:-mb-12:last-child {
  margin-bottom: -3rem;
}

.last\:-ml-12:last-child {
  margin-left: -3rem;
}

.last\:-mt-16:last-child {
  margin-top: -4rem;
}

.last\:-mr-16:last-child {
  margin-right: -4rem;
}

.last\:-mb-16:last-child {
  margin-bottom: -4rem;
}

.last\:-ml-16:last-child {
  margin-left: -4rem;
}

.last\:-mt-20:last-child {
  margin-top: -5rem;
}

.last\:-mr-20:last-child {
  margin-right: -5rem;
}

.last\:-mb-20:last-child {
  margin-bottom: -5rem;
}

.last\:-ml-20:last-child {
  margin-left: -5rem;
}

.last\:-mt-24:last-child {
  margin-top: -6rem;
}

.last\:-mr-24:last-child {
  margin-right: -6rem;
}

.last\:-mb-24:last-child {
  margin-bottom: -6rem;
}

.last\:-ml-24:last-child {
  margin-left: -6rem;
}

.last\:-mt-32:last-child {
  margin-top: -8rem;
}

.last\:-mr-32:last-child {
  margin-right: -8rem;
}

.last\:-mb-32:last-child {
  margin-bottom: -8rem;
}

.last\:-ml-32:last-child {
  margin-left: -8rem;
}

.last\:-mt-40:last-child {
  margin-top: -10rem;
}

.last\:-mr-40:last-child {
  margin-right: -10rem;
}

.last\:-mb-40:last-child {
  margin-bottom: -10rem;
}

.last\:-ml-40:last-child {
  margin-left: -10rem;
}

.last\:-mt-48:last-child {
  margin-top: -12rem;
}

.last\:-mr-48:last-child {
  margin-right: -12rem;
}

.last\:-mb-48:last-child {
  margin-bottom: -12rem;
}

.last\:-ml-48:last-child {
  margin-left: -12rem;
}

.last\:-mt-56:last-child {
  margin-top: -14rem;
}

.last\:-mr-56:last-child {
  margin-right: -14rem;
}

.last\:-mb-56:last-child {
  margin-bottom: -14rem;
}

.last\:-ml-56:last-child {
  margin-left: -14rem;
}

.last\:-mt-64:last-child {
  margin-top: -16rem;
}

.last\:-mr-64:last-child {
  margin-right: -16rem;
}

.last\:-mb-64:last-child {
  margin-bottom: -16rem;
}

.last\:-ml-64:last-child {
  margin-left: -16rem;
}

.last\:-mt-px:last-child {
  margin-top: -1px;
}

.last\:-mr-px:last-child {
  margin-right: -1px;
}

.last\:-mb-px:last-child {
  margin-bottom: -1px;
}

.last\:-ml-px:last-child {
  margin-left: -1px;
}

.max-h-0 {
  max-height: 0;
}

.max-h-1 {
  max-height: 0.25rem;
}

.max-h-2 {
  max-height: 0.5rem;
}

.max-h-3 {
  max-height: 0.75rem;
}

.max-h-4 {
  max-height: 1rem;
}

.max-h-5 {
  max-height: 1.25rem;
}

.max-h-6 {
  max-height: 1.5rem;
}

.max-h-8 {
  max-height: 2rem;
}

.max-h-10 {
  max-height: 2.5rem;
}

.max-h-12 {
  max-height: 3rem;
}

.max-h-16 {
  max-height: 4rem;
}

.max-h-20 {
  max-height: 5rem;
}

.max-h-24 {
  max-height: 6rem;
}

.max-h-32 {
  max-height: 8rem;
}

.max-h-40 {
  max-height: 10rem;
}

.max-h-48 {
  max-height: 12rem;
}

.max-h-56 {
  max-height: 14rem;
}

.max-h-64 {
  max-height: 16rem;
}

.max-h-xl {
  max-height: 36rem;
}

.max-h-px {
  max-height: 1px;
}

.max-w-none {
  max-width: none;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.min-h-0 {
  min-height: 0;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.min-w-0 {
  min-width: 0;
}

.min-w-1 {
  min-width: 0.25rem;
}

.min-w-2 {
  min-width: 0.5rem;
}

.min-w-3 {
  min-width: 0.75rem;
}

.min-w-4 {
  min-width: 1rem;
}

.min-w-5 {
  min-width: 1.25rem;
}

.min-w-6 {
  min-width: 1.5rem;
}

.min-w-8 {
  min-width: 2rem;
}

.min-w-10 {
  min-width: 2.5rem;
}

.min-w-12 {
  min-width: 3rem;
}

.min-w-16 {
  min-width: 4rem;
}

.min-w-20 {
  min-width: 5rem;
}

.min-w-24 {
  min-width: 6rem;
}

.min-w-32 {
  min-width: 8rem;
}

.min-w-40 {
  min-width: 10rem;
}

.min-w-48 {
  min-width: 12rem;
}

.min-w-56 {
  min-width: 14rem;
}

.min-w-64 {
  min-width: 16rem;
}

.min-w-max-content {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.min-w-px {
  min-width: 1px;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-fill {
  object-fit: fill;
}

.object-none {
  object-fit: none;
}

.object-scale-down {
  object-fit: scale-down;
}

.object-bottom {
  object-position: bottom;
}

.object-center {
  object-position: center;
}

.object-left {
  object-position: left;
}

.object-left-bottom {
  object-position: left bottom;
}

.object-left-top {
  object-position: left top;
}

.object-right {
  object-position: right;
}

.object-right-bottom {
  object-position: right bottom;
}

.object-right-top {
  object-position: right top;
}

.object-top {
  object-position: top;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

.hover\:opacity-0:hover {
  opacity: 0;
}

.hover\:opacity-25:hover {
  opacity: 0.25;
}

.hover\:opacity-50:hover {
  opacity: 0.5;
}

.hover\:opacity-75:hover {
  opacity: 0.75;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.focus\:opacity-0:focus {
  opacity: 0;
}

.focus\:opacity-25:focus {
  opacity: 0.25;
}

.focus\:opacity-50:focus {
  opacity: 0.5;
}

.focus\:opacity-75:focus {
  opacity: 0.75;
}

.focus\:opacity-100:focus {
  opacity: 1;
}

.outline-none {
  outline: 0;
}

.focus\:outline-none:focus {
  outline: 0;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.scrolling-touch {
  -webkit-overflow-scrolling: touch;
}

.scrolling-auto {
  -webkit-overflow-scrolling: auto;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-12 {
  padding: 3rem;
}

.p-16 {
  padding: 4rem;
}

.p-20 {
  padding: 5rem;
}

.p-24 {
  padding: 6rem;
}

.p-32 {
  padding: 8rem;
}

.p-40 {
  padding: 10rem;
}

.p-48 {
  padding: 12rem;
}

.p-56 {
  padding: 14rem;
}

.p-64 {
  padding: 16rem;
}

.p-px {
  padding: 1px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}

.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.pt-0 {
  padding-top: 0;
}

.pr-0 {
  padding-right: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pr-24 {
  padding-right: 6rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pl-24 {
  padding-left: 6rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pr-32 {
  padding-right: 8rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pl-32 {
  padding-left: 8rem;
}

.pt-40 {
  padding-top: 10rem;
}

.pr-40 {
  padding-right: 10rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pl-40 {
  padding-left: 10rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pr-48 {
  padding-right: 12rem;
}

.pb-48 {
  padding-bottom: 12rem;
}

.pl-48 {
  padding-left: 12rem;
}

.pt-56 {
  padding-top: 14rem;
}

.pr-56 {
  padding-right: 14rem;
}

.pb-56 {
  padding-bottom: 14rem;
}

.pl-56 {
  padding-left: 14rem;
}

.pt-64 {
  padding-top: 16rem;
}

.pr-64 {
  padding-right: 16rem;
}

.pb-64 {
  padding-bottom: 16rem;
}

.pl-64 {
  padding-left: 16rem;
}

.pt-px {
  padding-top: 1px;
}

.pr-px {
  padding-right: 1px;
}

.pb-px {
  padding-bottom: 1px;
}

.pl-px {
  padding-left: 1px;
}

.placeholder-transparent::-webkit-input-placeholder {
  color: transparent;
}

.placeholder-transparent:-ms-input-placeholder {
  color: transparent;
}

.placeholder-transparent::-ms-input-placeholder {
  color: transparent;
}

.placeholder-transparent::placeholder {
  color: transparent;
}

.placeholder-white::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.placeholder-white:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.placeholder-white::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.placeholder-white::placeholder {
  --placeholder-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.placeholder-black::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #000000;
  color: rgba(0, 0, 0, var(--placeholder-opacity));
}

.placeholder-black:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #000000;
  color: rgba(0, 0, 0, var(--placeholder-opacity));
}

.placeholder-black::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #000000;
  color: rgba(0, 0, 0, var(--placeholder-opacity));
}

.placeholder-black::placeholder {
  --placeholder-opacity: 1;
  color: #000000;
  color: rgba(0, 0, 0, var(--placeholder-opacity));
}

.placeholder-gray-50::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--placeholder-opacity));
}

.placeholder-gray-50:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--placeholder-opacity));
}

.placeholder-gray-50::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--placeholder-opacity));
}

.placeholder-gray-50::placeholder {
  --placeholder-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--placeholder-opacity));
}

.placeholder-gray-100::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--placeholder-opacity));
}

.placeholder-gray-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--placeholder-opacity));
}

.placeholder-gray-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--placeholder-opacity));
}

.placeholder-gray-100::placeholder {
  --placeholder-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--placeholder-opacity));
}

.placeholder-gray-200::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--placeholder-opacity));
}

.placeholder-gray-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--placeholder-opacity));
}

.placeholder-gray-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--placeholder-opacity));
}

.placeholder-gray-200::placeholder {
  --placeholder-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--placeholder-opacity));
}

.placeholder-gray-300::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #d5d6d7;
  color: rgba(213, 214, 215, var(--placeholder-opacity));
}

.placeholder-gray-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d5d6d7;
  color: rgba(213, 214, 215, var(--placeholder-opacity));
}

.placeholder-gray-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d5d6d7;
  color: rgba(213, 214, 215, var(--placeholder-opacity));
}

.placeholder-gray-300::placeholder {
  --placeholder-opacity: 1;
  color: #d5d6d7;
  color: rgba(213, 214, 215, var(--placeholder-opacity));
}

.placeholder-gray-400::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #9e9e9e;
  color: rgba(158, 158, 158, var(--placeholder-opacity));
}

.placeholder-gray-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9e9e9e;
  color: rgba(158, 158, 158, var(--placeholder-opacity));
}

.placeholder-gray-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9e9e9e;
  color: rgba(158, 158, 158, var(--placeholder-opacity));
}

.placeholder-gray-400::placeholder {
  --placeholder-opacity: 1;
  color: #9e9e9e;
  color: rgba(158, 158, 158, var(--placeholder-opacity));
}

.placeholder-gray-500::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #707275;
  color: rgba(112, 114, 117, var(--placeholder-opacity));
}

.placeholder-gray-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #707275;
  color: rgba(112, 114, 117, var(--placeholder-opacity));
}

.placeholder-gray-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #707275;
  color: rgba(112, 114, 117, var(--placeholder-opacity));
}

.placeholder-gray-500::placeholder {
  --placeholder-opacity: 1;
  color: #707275;
  color: rgba(112, 114, 117, var(--placeholder-opacity));
}

.placeholder-gray-600::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #4c4f52;
  color: rgba(76, 79, 82, var(--placeholder-opacity));
}

.placeholder-gray-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4c4f52;
  color: rgba(76, 79, 82, var(--placeholder-opacity));
}

.placeholder-gray-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4c4f52;
  color: rgba(76, 79, 82, var(--placeholder-opacity));
}

.placeholder-gray-600::placeholder {
  --placeholder-opacity: 1;
  color: #4c4f52;
  color: rgba(76, 79, 82, var(--placeholder-opacity));
}

.placeholder-gray-700::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #24262d;
  color: rgba(36, 38, 45, var(--placeholder-opacity));
}

.placeholder-gray-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #24262d;
  color: rgba(36, 38, 45, var(--placeholder-opacity));
}

.placeholder-gray-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #24262d;
  color: rgba(36, 38, 45, var(--placeholder-opacity));
}

.placeholder-gray-700::placeholder {
  --placeholder-opacity: 1;
  color: #24262d;
  color: rgba(36, 38, 45, var(--placeholder-opacity));
}

.placeholder-gray-800::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a1c23;
  color: rgba(26, 28, 35, var(--placeholder-opacity));
}

.placeholder-gray-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a1c23;
  color: rgba(26, 28, 35, var(--placeholder-opacity));
}

.placeholder-gray-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a1c23;
  color: rgba(26, 28, 35, var(--placeholder-opacity));
}

.placeholder-gray-800::placeholder {
  --placeholder-opacity: 1;
  color: #1a1c23;
  color: rgba(26, 28, 35, var(--placeholder-opacity));
}

.placeholder-gray-900::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #121317;
  color: rgba(18, 19, 23, var(--placeholder-opacity));
}

.placeholder-gray-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #121317;
  color: rgba(18, 19, 23, var(--placeholder-opacity));
}

.placeholder-gray-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #121317;
  color: rgba(18, 19, 23, var(--placeholder-opacity));
}

.placeholder-gray-900::placeholder {
  --placeholder-opacity: 1;
  color: #121317;
  color: rgba(18, 19, 23, var(--placeholder-opacity));
}

.placeholder-cool-gray-50::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--placeholder-opacity));
}

.placeholder-cool-gray-50:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--placeholder-opacity));
}

.placeholder-cool-gray-50::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--placeholder-opacity));
}

.placeholder-cool-gray-50::placeholder {
  --placeholder-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--placeholder-opacity));
}

.placeholder-cool-gray-100::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--placeholder-opacity));
}

.placeholder-cool-gray-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--placeholder-opacity));
}

.placeholder-cool-gray-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--placeholder-opacity));
}

.placeholder-cool-gray-100::placeholder {
  --placeholder-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--placeholder-opacity));
}

.placeholder-cool-gray-200::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity));
}

.placeholder-cool-gray-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity));
}

.placeholder-cool-gray-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity));
}

.placeholder-cool-gray-200::placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity));
}

.placeholder-cool-gray-300::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--placeholder-opacity));
}

.placeholder-cool-gray-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--placeholder-opacity));
}

.placeholder-cool-gray-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--placeholder-opacity));
}

.placeholder-cool-gray-300::placeholder {
  --placeholder-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--placeholder-opacity));
}

.placeholder-cool-gray-400::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--placeholder-opacity));
}

.placeholder-cool-gray-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--placeholder-opacity));
}

.placeholder-cool-gray-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--placeholder-opacity));
}

.placeholder-cool-gray-400::placeholder {
  --placeholder-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--placeholder-opacity));
}

.placeholder-cool-gray-500::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--placeholder-opacity));
}

.placeholder-cool-gray-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--placeholder-opacity));
}

.placeholder-cool-gray-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--placeholder-opacity));
}

.placeholder-cool-gray-500::placeholder {
  --placeholder-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--placeholder-opacity));
}

.placeholder-cool-gray-600::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--placeholder-opacity));
}

.placeholder-cool-gray-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--placeholder-opacity));
}

.placeholder-cool-gray-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--placeholder-opacity));
}

.placeholder-cool-gray-600::placeholder {
  --placeholder-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--placeholder-opacity));
}

.placeholder-cool-gray-700::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--placeholder-opacity));
}

.placeholder-cool-gray-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--placeholder-opacity));
}

.placeholder-cool-gray-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--placeholder-opacity));
}

.placeholder-cool-gray-700::placeholder {
  --placeholder-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--placeholder-opacity));
}

.placeholder-cool-gray-800::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--placeholder-opacity));
}

.placeholder-cool-gray-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--placeholder-opacity));
}

.placeholder-cool-gray-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--placeholder-opacity));
}

.placeholder-cool-gray-800::placeholder {
  --placeholder-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--placeholder-opacity));
}

.placeholder-cool-gray-900::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--placeholder-opacity));
}

.placeholder-cool-gray-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--placeholder-opacity));
}

.placeholder-cool-gray-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--placeholder-opacity));
}

.placeholder-cool-gray-900::placeholder {
  --placeholder-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--placeholder-opacity));
}

.placeholder-red-50::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--placeholder-opacity));
}

.placeholder-red-50:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--placeholder-opacity));
}

.placeholder-red-50::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--placeholder-opacity));
}

.placeholder-red-50::placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--placeholder-opacity));
}

.placeholder-red-100::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--placeholder-opacity));
}

.placeholder-red-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--placeholder-opacity));
}

.placeholder-red-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--placeholder-opacity));
}

.placeholder-red-100::placeholder {
  --placeholder-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--placeholder-opacity));
}

.placeholder-red-200::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--placeholder-opacity));
}

.placeholder-red-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--placeholder-opacity));
}

.placeholder-red-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--placeholder-opacity));
}

.placeholder-red-200::placeholder {
  --placeholder-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--placeholder-opacity));
}

.placeholder-red-300::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--placeholder-opacity));
}

.placeholder-red-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--placeholder-opacity));
}

.placeholder-red-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--placeholder-opacity));
}

.placeholder-red-300::placeholder {
  --placeholder-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--placeholder-opacity));
}

.placeholder-red-400::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--placeholder-opacity));
}

.placeholder-red-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--placeholder-opacity));
}

.placeholder-red-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--placeholder-opacity));
}

.placeholder-red-400::placeholder {
  --placeholder-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--placeholder-opacity));
}

.placeholder-red-500::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--placeholder-opacity));
}

.placeholder-red-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--placeholder-opacity));
}

.placeholder-red-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--placeholder-opacity));
}

.placeholder-red-500::placeholder {
  --placeholder-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--placeholder-opacity));
}

.placeholder-red-600::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--placeholder-opacity));
}

.placeholder-red-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--placeholder-opacity));
}

.placeholder-red-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--placeholder-opacity));
}

.placeholder-red-600::placeholder {
  --placeholder-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--placeholder-opacity));
}

.placeholder-red-700::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--placeholder-opacity));
}

.placeholder-red-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--placeholder-opacity));
}

.placeholder-red-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--placeholder-opacity));
}

.placeholder-red-700::placeholder {
  --placeholder-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--placeholder-opacity));
}

.placeholder-red-800::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--placeholder-opacity));
}

.placeholder-red-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--placeholder-opacity));
}

.placeholder-red-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--placeholder-opacity));
}

.placeholder-red-800::placeholder {
  --placeholder-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--placeholder-opacity));
}

.placeholder-red-900::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity));
}

.placeholder-red-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity));
}

.placeholder-red-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity));
}

.placeholder-red-900::placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity));
}

.placeholder-orange-50::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--placeholder-opacity));
}

.placeholder-orange-50:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--placeholder-opacity));
}

.placeholder-orange-50::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--placeholder-opacity));
}

.placeholder-orange-50::placeholder {
  --placeholder-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--placeholder-opacity));
}

.placeholder-orange-100::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--placeholder-opacity));
}

.placeholder-orange-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--placeholder-opacity));
}

.placeholder-orange-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--placeholder-opacity));
}

.placeholder-orange-100::placeholder {
  --placeholder-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--placeholder-opacity));
}

.placeholder-orange-200::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--placeholder-opacity));
}

.placeholder-orange-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--placeholder-opacity));
}

.placeholder-orange-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--placeholder-opacity));
}

.placeholder-orange-200::placeholder {
  --placeholder-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--placeholder-opacity));
}

.placeholder-orange-300::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--placeholder-opacity));
}

.placeholder-orange-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--placeholder-opacity));
}

.placeholder-orange-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--placeholder-opacity));
}

.placeholder-orange-300::placeholder {
  --placeholder-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--placeholder-opacity));
}

.placeholder-orange-400::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--placeholder-opacity));
}

.placeholder-orange-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--placeholder-opacity));
}

.placeholder-orange-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--placeholder-opacity));
}

.placeholder-orange-400::placeholder {
  --placeholder-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--placeholder-opacity));
}

.placeholder-orange-500::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--placeholder-opacity));
}

.placeholder-orange-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--placeholder-opacity));
}

.placeholder-orange-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--placeholder-opacity));
}

.placeholder-orange-500::placeholder {
  --placeholder-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--placeholder-opacity));
}

.placeholder-orange-600::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--placeholder-opacity));
}

.placeholder-orange-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--placeholder-opacity));
}

.placeholder-orange-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--placeholder-opacity));
}

.placeholder-orange-600::placeholder {
  --placeholder-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--placeholder-opacity));
}

.placeholder-orange-700::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--placeholder-opacity));
}

.placeholder-orange-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--placeholder-opacity));
}

.placeholder-orange-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--placeholder-opacity));
}

.placeholder-orange-700::placeholder {
  --placeholder-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--placeholder-opacity));
}

.placeholder-orange-800::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--placeholder-opacity));
}

.placeholder-orange-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--placeholder-opacity));
}

.placeholder-orange-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--placeholder-opacity));
}

.placeholder-orange-800::placeholder {
  --placeholder-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--placeholder-opacity));
}

.placeholder-orange-900::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity));
}

.placeholder-orange-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity));
}

.placeholder-orange-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity));
}

.placeholder-orange-900::placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity));
}

.placeholder-yellow-50::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--placeholder-opacity));
}

.placeholder-yellow-50:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--placeholder-opacity));
}

.placeholder-yellow-50::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--placeholder-opacity));
}

.placeholder-yellow-50::placeholder {
  --placeholder-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--placeholder-opacity));
}

.placeholder-yellow-100::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--placeholder-opacity));
}

.placeholder-yellow-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--placeholder-opacity));
}

.placeholder-yellow-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--placeholder-opacity));
}

.placeholder-yellow-100::placeholder {
  --placeholder-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--placeholder-opacity));
}

.placeholder-yellow-200::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--placeholder-opacity));
}

.placeholder-yellow-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--placeholder-opacity));
}

.placeholder-yellow-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--placeholder-opacity));
}

.placeholder-yellow-200::placeholder {
  --placeholder-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--placeholder-opacity));
}

.placeholder-yellow-300::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--placeholder-opacity));
}

.placeholder-yellow-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--placeholder-opacity));
}

.placeholder-yellow-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--placeholder-opacity));
}

.placeholder-yellow-300::placeholder {
  --placeholder-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--placeholder-opacity));
}

.placeholder-yellow-400::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--placeholder-opacity));
}

.placeholder-yellow-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--placeholder-opacity));
}

.placeholder-yellow-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--placeholder-opacity));
}

.placeholder-yellow-400::placeholder {
  --placeholder-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--placeholder-opacity));
}

.placeholder-yellow-500::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--placeholder-opacity));
}

.placeholder-yellow-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--placeholder-opacity));
}

.placeholder-yellow-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--placeholder-opacity));
}

.placeholder-yellow-500::placeholder {
  --placeholder-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--placeholder-opacity));
}

.placeholder-yellow-600::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--placeholder-opacity));
}

.placeholder-yellow-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--placeholder-opacity));
}

.placeholder-yellow-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--placeholder-opacity));
}

.placeholder-yellow-600::placeholder {
  --placeholder-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--placeholder-opacity));
}

.placeholder-yellow-700::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--placeholder-opacity));
}

.placeholder-yellow-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--placeholder-opacity));
}

.placeholder-yellow-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--placeholder-opacity));
}

.placeholder-yellow-700::placeholder {
  --placeholder-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--placeholder-opacity));
}

.placeholder-yellow-800::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--placeholder-opacity));
}

.placeholder-yellow-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--placeholder-opacity));
}

.placeholder-yellow-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--placeholder-opacity));
}

.placeholder-yellow-800::placeholder {
  --placeholder-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--placeholder-opacity));
}

.placeholder-yellow-900::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--placeholder-opacity));
}

.placeholder-yellow-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--placeholder-opacity));
}

.placeholder-yellow-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--placeholder-opacity));
}

.placeholder-yellow-900::placeholder {
  --placeholder-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--placeholder-opacity));
}

.placeholder-green-50::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--placeholder-opacity));
}

.placeholder-green-50:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--placeholder-opacity));
}

.placeholder-green-50::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--placeholder-opacity));
}

.placeholder-green-50::placeholder {
  --placeholder-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--placeholder-opacity));
}

.placeholder-green-100::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--placeholder-opacity));
}

.placeholder-green-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--placeholder-opacity));
}

.placeholder-green-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--placeholder-opacity));
}

.placeholder-green-100::placeholder {
  --placeholder-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--placeholder-opacity));
}

.placeholder-green-200::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--placeholder-opacity));
}

.placeholder-green-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--placeholder-opacity));
}

.placeholder-green-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--placeholder-opacity));
}

.placeholder-green-200::placeholder {
  --placeholder-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--placeholder-opacity));
}

.placeholder-green-300::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--placeholder-opacity));
}

.placeholder-green-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--placeholder-opacity));
}

.placeholder-green-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--placeholder-opacity));
}

.placeholder-green-300::placeholder {
  --placeholder-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--placeholder-opacity));
}

.placeholder-green-400::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--placeholder-opacity));
}

.placeholder-green-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--placeholder-opacity));
}

.placeholder-green-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--placeholder-opacity));
}

.placeholder-green-400::placeholder {
  --placeholder-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--placeholder-opacity));
}

.placeholder-green-500::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--placeholder-opacity));
}

.placeholder-green-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--placeholder-opacity));
}

.placeholder-green-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--placeholder-opacity));
}

.placeholder-green-500::placeholder {
  --placeholder-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--placeholder-opacity));
}

.placeholder-green-600::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--placeholder-opacity));
}

.placeholder-green-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--placeholder-opacity));
}

.placeholder-green-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--placeholder-opacity));
}

.placeholder-green-600::placeholder {
  --placeholder-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--placeholder-opacity));
}

.placeholder-green-700::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--placeholder-opacity));
}

.placeholder-green-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--placeholder-opacity));
}

.placeholder-green-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--placeholder-opacity));
}

.placeholder-green-700::placeholder {
  --placeholder-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--placeholder-opacity));
}

.placeholder-green-800::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--placeholder-opacity));
}

.placeholder-green-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--placeholder-opacity));
}

.placeholder-green-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--placeholder-opacity));
}

.placeholder-green-800::placeholder {
  --placeholder-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--placeholder-opacity));
}

.placeholder-green-900::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--placeholder-opacity));
}

.placeholder-green-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--placeholder-opacity));
}

.placeholder-green-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--placeholder-opacity));
}

.placeholder-green-900::placeholder {
  --placeholder-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--placeholder-opacity));
}

.placeholder-teal-50::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--placeholder-opacity));
}

.placeholder-teal-50:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--placeholder-opacity));
}

.placeholder-teal-50::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--placeholder-opacity));
}

.placeholder-teal-50::placeholder {
  --placeholder-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--placeholder-opacity));
}

.placeholder-teal-100::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--placeholder-opacity));
}

.placeholder-teal-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--placeholder-opacity));
}

.placeholder-teal-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--placeholder-opacity));
}

.placeholder-teal-100::placeholder {
  --placeholder-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--placeholder-opacity));
}

.placeholder-teal-200::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--placeholder-opacity));
}

.placeholder-teal-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--placeholder-opacity));
}

.placeholder-teal-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--placeholder-opacity));
}

.placeholder-teal-200::placeholder {
  --placeholder-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--placeholder-opacity));
}

.placeholder-teal-300::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--placeholder-opacity));
}

.placeholder-teal-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--placeholder-opacity));
}

.placeholder-teal-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--placeholder-opacity));
}

.placeholder-teal-300::placeholder {
  --placeholder-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--placeholder-opacity));
}

.placeholder-teal-400::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--placeholder-opacity));
}

.placeholder-teal-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--placeholder-opacity));
}

.placeholder-teal-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--placeholder-opacity));
}

.placeholder-teal-400::placeholder {
  --placeholder-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--placeholder-opacity));
}

.placeholder-teal-500::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--placeholder-opacity));
}

.placeholder-teal-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--placeholder-opacity));
}

.placeholder-teal-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--placeholder-opacity));
}

.placeholder-teal-500::placeholder {
  --placeholder-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--placeholder-opacity));
}

.placeholder-teal-600::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--placeholder-opacity));
}

.placeholder-teal-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--placeholder-opacity));
}

.placeholder-teal-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--placeholder-opacity));
}

.placeholder-teal-600::placeholder {
  --placeholder-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--placeholder-opacity));
}

.placeholder-teal-700::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--placeholder-opacity));
}

.placeholder-teal-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--placeholder-opacity));
}

.placeholder-teal-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--placeholder-opacity));
}

.placeholder-teal-700::placeholder {
  --placeholder-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--placeholder-opacity));
}

.placeholder-teal-800::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--placeholder-opacity));
}

.placeholder-teal-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--placeholder-opacity));
}

.placeholder-teal-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--placeholder-opacity));
}

.placeholder-teal-800::placeholder {
  --placeholder-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--placeholder-opacity));
}

.placeholder-teal-900::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--placeholder-opacity));
}

.placeholder-teal-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--placeholder-opacity));
}

.placeholder-teal-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--placeholder-opacity));
}

.placeholder-teal-900::placeholder {
  --placeholder-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--placeholder-opacity));
}

.placeholder-blue-50::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--placeholder-opacity));
}

.placeholder-blue-50:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--placeholder-opacity));
}

.placeholder-blue-50::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--placeholder-opacity));
}

.placeholder-blue-50::placeholder {
  --placeholder-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--placeholder-opacity));
}

.placeholder-blue-100::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--placeholder-opacity));
}

.placeholder-blue-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--placeholder-opacity));
}

.placeholder-blue-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--placeholder-opacity));
}

.placeholder-blue-100::placeholder {
  --placeholder-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--placeholder-opacity));
}

.placeholder-blue-200::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--placeholder-opacity));
}

.placeholder-blue-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--placeholder-opacity));
}

.placeholder-blue-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--placeholder-opacity));
}

.placeholder-blue-200::placeholder {
  --placeholder-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--placeholder-opacity));
}

.placeholder-blue-300::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--placeholder-opacity));
}

.placeholder-blue-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--placeholder-opacity));
}

.placeholder-blue-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--placeholder-opacity));
}

.placeholder-blue-300::placeholder {
  --placeholder-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--placeholder-opacity));
}

.placeholder-blue-400::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--placeholder-opacity));
}

.placeholder-blue-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--placeholder-opacity));
}

.placeholder-blue-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--placeholder-opacity));
}

.placeholder-blue-400::placeholder {
  --placeholder-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--placeholder-opacity));
}

.placeholder-blue-500::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--placeholder-opacity));
}

.placeholder-blue-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--placeholder-opacity));
}

.placeholder-blue-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--placeholder-opacity));
}

.placeholder-blue-500::placeholder {
  --placeholder-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--placeholder-opacity));
}

.placeholder-blue-600::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--placeholder-opacity));
}

.placeholder-blue-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--placeholder-opacity));
}

.placeholder-blue-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--placeholder-opacity));
}

.placeholder-blue-600::placeholder {
  --placeholder-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--placeholder-opacity));
}

.placeholder-blue-700::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--placeholder-opacity));
}

.placeholder-blue-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--placeholder-opacity));
}

.placeholder-blue-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--placeholder-opacity));
}

.placeholder-blue-700::placeholder {
  --placeholder-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--placeholder-opacity));
}

.placeholder-blue-800::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--placeholder-opacity));
}

.placeholder-blue-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--placeholder-opacity));
}

.placeholder-blue-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--placeholder-opacity));
}

.placeholder-blue-800::placeholder {
  --placeholder-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--placeholder-opacity));
}

.placeholder-blue-900::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--placeholder-opacity));
}

.placeholder-blue-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--placeholder-opacity));
}

.placeholder-blue-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--placeholder-opacity));
}

.placeholder-blue-900::placeholder {
  --placeholder-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--placeholder-opacity));
}

.placeholder-indigo-50::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--placeholder-opacity));
}

.placeholder-indigo-50:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--placeholder-opacity));
}

.placeholder-indigo-50::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--placeholder-opacity));
}

.placeholder-indigo-50::placeholder {
  --placeholder-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--placeholder-opacity));
}

.placeholder-indigo-100::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--placeholder-opacity));
}

.placeholder-indigo-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--placeholder-opacity));
}

.placeholder-indigo-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--placeholder-opacity));
}

.placeholder-indigo-100::placeholder {
  --placeholder-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--placeholder-opacity));
}

.placeholder-indigo-200::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--placeholder-opacity));
}

.placeholder-indigo-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--placeholder-opacity));
}

.placeholder-indigo-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--placeholder-opacity));
}

.placeholder-indigo-200::placeholder {
  --placeholder-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--placeholder-opacity));
}

.placeholder-indigo-300::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--placeholder-opacity));
}

.placeholder-indigo-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--placeholder-opacity));
}

.placeholder-indigo-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--placeholder-opacity));
}

.placeholder-indigo-300::placeholder {
  --placeholder-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--placeholder-opacity));
}

.placeholder-indigo-400::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--placeholder-opacity));
}

.placeholder-indigo-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--placeholder-opacity));
}

.placeholder-indigo-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--placeholder-opacity));
}

.placeholder-indigo-400::placeholder {
  --placeholder-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--placeholder-opacity));
}

.placeholder-indigo-500::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--placeholder-opacity));
}

.placeholder-indigo-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--placeholder-opacity));
}

.placeholder-indigo-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--placeholder-opacity));
}

.placeholder-indigo-500::placeholder {
  --placeholder-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--placeholder-opacity));
}

.placeholder-indigo-600::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--placeholder-opacity));
}

.placeholder-indigo-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--placeholder-opacity));
}

.placeholder-indigo-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--placeholder-opacity));
}

.placeholder-indigo-600::placeholder {
  --placeholder-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--placeholder-opacity));
}

.placeholder-indigo-700::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--placeholder-opacity));
}

.placeholder-indigo-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--placeholder-opacity));
}

.placeholder-indigo-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--placeholder-opacity));
}

.placeholder-indigo-700::placeholder {
  --placeholder-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--placeholder-opacity));
}

.placeholder-indigo-800::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--placeholder-opacity));
}

.placeholder-indigo-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--placeholder-opacity));
}

.placeholder-indigo-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--placeholder-opacity));
}

.placeholder-indigo-800::placeholder {
  --placeholder-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--placeholder-opacity));
}

.placeholder-indigo-900::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--placeholder-opacity));
}

.placeholder-indigo-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--placeholder-opacity));
}

.placeholder-indigo-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--placeholder-opacity));
}

.placeholder-indigo-900::placeholder {
  --placeholder-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--placeholder-opacity));
}

.placeholder-purple-50::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--placeholder-opacity));
}

.placeholder-purple-50:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--placeholder-opacity));
}

.placeholder-purple-50::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--placeholder-opacity));
}

.placeholder-purple-50::placeholder {
  --placeholder-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--placeholder-opacity));
}

.placeholder-purple-100::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--placeholder-opacity));
}

.placeholder-purple-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--placeholder-opacity));
}

.placeholder-purple-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--placeholder-opacity));
}

.placeholder-purple-100::placeholder {
  --placeholder-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--placeholder-opacity));
}

.placeholder-purple-200::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--placeholder-opacity));
}

.placeholder-purple-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--placeholder-opacity));
}

.placeholder-purple-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--placeholder-opacity));
}

.placeholder-purple-200::placeholder {
  --placeholder-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--placeholder-opacity));
}

.placeholder-purple-300::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--placeholder-opacity));
}

.placeholder-purple-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--placeholder-opacity));
}

.placeholder-purple-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--placeholder-opacity));
}

.placeholder-purple-300::placeholder {
  --placeholder-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--placeholder-opacity));
}

.placeholder-purple-400::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--placeholder-opacity));
}

.placeholder-purple-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--placeholder-opacity));
}

.placeholder-purple-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--placeholder-opacity));
}

.placeholder-purple-400::placeholder {
  --placeholder-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--placeholder-opacity));
}

.placeholder-purple-500::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--placeholder-opacity));
}

.placeholder-purple-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--placeholder-opacity));
}

.placeholder-purple-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--placeholder-opacity));
}

.placeholder-purple-500::placeholder {
  --placeholder-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--placeholder-opacity));
}

.placeholder-purple-600::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--placeholder-opacity));
}

.placeholder-purple-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--placeholder-opacity));
}

.placeholder-purple-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--placeholder-opacity));
}

.placeholder-purple-600::placeholder {
  --placeholder-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--placeholder-opacity));
}

.placeholder-purple-700::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--placeholder-opacity));
}

.placeholder-purple-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--placeholder-opacity));
}

.placeholder-purple-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--placeholder-opacity));
}

.placeholder-purple-700::placeholder {
  --placeholder-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--placeholder-opacity));
}

.placeholder-purple-800::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--placeholder-opacity));
}

.placeholder-purple-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--placeholder-opacity));
}

.placeholder-purple-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--placeholder-opacity));
}

.placeholder-purple-800::placeholder {
  --placeholder-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--placeholder-opacity));
}

.placeholder-purple-900::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--placeholder-opacity));
}

.placeholder-purple-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--placeholder-opacity));
}

.placeholder-purple-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--placeholder-opacity));
}

.placeholder-purple-900::placeholder {
  --placeholder-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--placeholder-opacity));
}

.placeholder-pink-50::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--placeholder-opacity));
}

.placeholder-pink-50:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--placeholder-opacity));
}

.placeholder-pink-50::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--placeholder-opacity));
}

.placeholder-pink-50::placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--placeholder-opacity));
}

.placeholder-pink-100::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--placeholder-opacity));
}

.placeholder-pink-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--placeholder-opacity));
}

.placeholder-pink-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--placeholder-opacity));
}

.placeholder-pink-100::placeholder {
  --placeholder-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--placeholder-opacity));
}

.placeholder-pink-200::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--placeholder-opacity));
}

.placeholder-pink-200:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--placeholder-opacity));
}

.placeholder-pink-200::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--placeholder-opacity));
}

.placeholder-pink-200::placeholder {
  --placeholder-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--placeholder-opacity));
}

.placeholder-pink-300::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--placeholder-opacity));
}

.placeholder-pink-300:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--placeholder-opacity));
}

.placeholder-pink-300::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--placeholder-opacity));
}

.placeholder-pink-300::placeholder {
  --placeholder-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--placeholder-opacity));
}

.placeholder-pink-400::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--placeholder-opacity));
}

.placeholder-pink-400:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--placeholder-opacity));
}

.placeholder-pink-400::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--placeholder-opacity));
}

.placeholder-pink-400::placeholder {
  --placeholder-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--placeholder-opacity));
}

.placeholder-pink-500::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--placeholder-opacity));
}

.placeholder-pink-500:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--placeholder-opacity));
}

.placeholder-pink-500::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--placeholder-opacity));
}

.placeholder-pink-500::placeholder {
  --placeholder-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--placeholder-opacity));
}

.placeholder-pink-600::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--placeholder-opacity));
}

.placeholder-pink-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--placeholder-opacity));
}

.placeholder-pink-600::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--placeholder-opacity));
}

.placeholder-pink-600::placeholder {
  --placeholder-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--placeholder-opacity));
}

.placeholder-pink-700::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--placeholder-opacity));
}

.placeholder-pink-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--placeholder-opacity));
}

.placeholder-pink-700::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--placeholder-opacity));
}

.placeholder-pink-700::placeholder {
  --placeholder-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--placeholder-opacity));
}

.placeholder-pink-800::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--placeholder-opacity));
}

.placeholder-pink-800:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--placeholder-opacity));
}

.placeholder-pink-800::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--placeholder-opacity));
}

.placeholder-pink-800::placeholder {
  --placeholder-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--placeholder-opacity));
}

.placeholder-pink-900::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--placeholder-opacity));
}

.placeholder-pink-900:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--placeholder-opacity));
}

.placeholder-pink-900::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--placeholder-opacity));
}

.placeholder-pink-900::placeholder {
  --placeholder-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--placeholder-opacity));
}

.focus\:placeholder-transparent:focus::-webkit-input-placeholder {
  color: transparent;
}

.focus\:placeholder-transparent:focus:-ms-input-placeholder {
  color: transparent;
}

.focus\:placeholder-transparent:focus::-ms-input-placeholder {
  color: transparent;
}

.focus\:placeholder-transparent:focus::placeholder {
  color: transparent;
}

.focus\:placeholder-white:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.focus\:placeholder-white:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.focus\:placeholder-white:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.focus\:placeholder-white:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.focus\:placeholder-black:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #000000;
  color: rgba(0, 0, 0, var(--placeholder-opacity));
}

.focus\:placeholder-black:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #000000;
  color: rgba(0, 0, 0, var(--placeholder-opacity));
}

.focus\:placeholder-black:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #000000;
  color: rgba(0, 0, 0, var(--placeholder-opacity));
}

.focus\:placeholder-black:focus::placeholder {
  --placeholder-opacity: 1;
  color: #000000;
  color: rgba(0, 0, 0, var(--placeholder-opacity));
}

.focus\:placeholder-gray-50:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--placeholder-opacity));
}

.focus\:placeholder-gray-50:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--placeholder-opacity));
}

.focus\:placeholder-gray-50:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--placeholder-opacity));
}

.focus\:placeholder-gray-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--placeholder-opacity));
}

.focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--placeholder-opacity));
}

.focus\:placeholder-gray-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--placeholder-opacity));
}

.focus\:placeholder-gray-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--placeholder-opacity));
}

.focus\:placeholder-gray-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--placeholder-opacity));
}

.focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--placeholder-opacity));
}

.focus\:placeholder-gray-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--placeholder-opacity));
}

.focus\:placeholder-gray-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--placeholder-opacity));
}

.focus\:placeholder-gray-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--placeholder-opacity));
}

.focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #d5d6d7;
  color: rgba(213, 214, 215, var(--placeholder-opacity));
}

.focus\:placeholder-gray-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d5d6d7;
  color: rgba(213, 214, 215, var(--placeholder-opacity));
}

.focus\:placeholder-gray-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d5d6d7;
  color: rgba(213, 214, 215, var(--placeholder-opacity));
}

.focus\:placeholder-gray-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #d5d6d7;
  color: rgba(213, 214, 215, var(--placeholder-opacity));
}

.focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #9e9e9e;
  color: rgba(158, 158, 158, var(--placeholder-opacity));
}

.focus\:placeholder-gray-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9e9e9e;
  color: rgba(158, 158, 158, var(--placeholder-opacity));
}

.focus\:placeholder-gray-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9e9e9e;
  color: rgba(158, 158, 158, var(--placeholder-opacity));
}

.focus\:placeholder-gray-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #9e9e9e;
  color: rgba(158, 158, 158, var(--placeholder-opacity));
}

.focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #707275;
  color: rgba(112, 114, 117, var(--placeholder-opacity));
}

.focus\:placeholder-gray-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #707275;
  color: rgba(112, 114, 117, var(--placeholder-opacity));
}

.focus\:placeholder-gray-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #707275;
  color: rgba(112, 114, 117, var(--placeholder-opacity));
}

.focus\:placeholder-gray-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #707275;
  color: rgba(112, 114, 117, var(--placeholder-opacity));
}

.focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #4c4f52;
  color: rgba(76, 79, 82, var(--placeholder-opacity));
}

.focus\:placeholder-gray-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4c4f52;
  color: rgba(76, 79, 82, var(--placeholder-opacity));
}

.focus\:placeholder-gray-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4c4f52;
  color: rgba(76, 79, 82, var(--placeholder-opacity));
}

.focus\:placeholder-gray-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #4c4f52;
  color: rgba(76, 79, 82, var(--placeholder-opacity));
}

.focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #24262d;
  color: rgba(36, 38, 45, var(--placeholder-opacity));
}

.focus\:placeholder-gray-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #24262d;
  color: rgba(36, 38, 45, var(--placeholder-opacity));
}

.focus\:placeholder-gray-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #24262d;
  color: rgba(36, 38, 45, var(--placeholder-opacity));
}

.focus\:placeholder-gray-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #24262d;
  color: rgba(36, 38, 45, var(--placeholder-opacity));
}

.focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a1c23;
  color: rgba(26, 28, 35, var(--placeholder-opacity));
}

.focus\:placeholder-gray-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a1c23;
  color: rgba(26, 28, 35, var(--placeholder-opacity));
}

.focus\:placeholder-gray-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a1c23;
  color: rgba(26, 28, 35, var(--placeholder-opacity));
}

.focus\:placeholder-gray-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #1a1c23;
  color: rgba(26, 28, 35, var(--placeholder-opacity));
}

.focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #121317;
  color: rgba(18, 19, 23, var(--placeholder-opacity));
}

.focus\:placeholder-gray-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #121317;
  color: rgba(18, 19, 23, var(--placeholder-opacity));
}

.focus\:placeholder-gray-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #121317;
  color: rgba(18, 19, 23, var(--placeholder-opacity));
}

.focus\:placeholder-gray-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #121317;
  color: rgba(18, 19, 23, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-50:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-50:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-50:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--placeholder-opacity));
}

.focus\:placeholder-cool-gray-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--placeholder-opacity));
}

.focus\:placeholder-red-50:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--placeholder-opacity));
}

.focus\:placeholder-red-50:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--placeholder-opacity));
}

.focus\:placeholder-red-50:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--placeholder-opacity));
}

.focus\:placeholder-red-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--placeholder-opacity));
}

.focus\:placeholder-red-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--placeholder-opacity));
}

.focus\:placeholder-red-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--placeholder-opacity));
}

.focus\:placeholder-red-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--placeholder-opacity));
}

.focus\:placeholder-red-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--placeholder-opacity));
}

.focus\:placeholder-red-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--placeholder-opacity));
}

.focus\:placeholder-red-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--placeholder-opacity));
}

.focus\:placeholder-red-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--placeholder-opacity));
}

.focus\:placeholder-red-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--placeholder-opacity));
}

.focus\:placeholder-red-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--placeholder-opacity));
}

.focus\:placeholder-red-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--placeholder-opacity));
}

.focus\:placeholder-red-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--placeholder-opacity));
}

.focus\:placeholder-red-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--placeholder-opacity));
}

.focus\:placeholder-red-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--placeholder-opacity));
}

.focus\:placeholder-red-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--placeholder-opacity));
}

.focus\:placeholder-red-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--placeholder-opacity));
}

.focus\:placeholder-red-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--placeholder-opacity));
}

.focus\:placeholder-red-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--placeholder-opacity));
}

.focus\:placeholder-red-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--placeholder-opacity));
}

.focus\:placeholder-red-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--placeholder-opacity));
}

.focus\:placeholder-red-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--placeholder-opacity));
}

.focus\:placeholder-red-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--placeholder-opacity));
}

.focus\:placeholder-red-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--placeholder-opacity));
}

.focus\:placeholder-red-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--placeholder-opacity));
}

.focus\:placeholder-red-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--placeholder-opacity));
}

.focus\:placeholder-red-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--placeholder-opacity));
}

.focus\:placeholder-red-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--placeholder-opacity));
}

.focus\:placeholder-red-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--placeholder-opacity));
}

.focus\:placeholder-red-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--placeholder-opacity));
}

.focus\:placeholder-red-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--placeholder-opacity));
}

.focus\:placeholder-red-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--placeholder-opacity));
}

.focus\:placeholder-red-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--placeholder-opacity));
}

.focus\:placeholder-red-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--placeholder-opacity));
}

.focus\:placeholder-red-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity));
}

.focus\:placeholder-red-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity));
}

.focus\:placeholder-red-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity));
}

.focus\:placeholder-red-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity));
}

.focus\:placeholder-orange-50:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--placeholder-opacity));
}

.focus\:placeholder-orange-50:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--placeholder-opacity));
}

.focus\:placeholder-orange-50:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--placeholder-opacity));
}

.focus\:placeholder-orange-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--placeholder-opacity));
}

.focus\:placeholder-orange-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--placeholder-opacity));
}

.focus\:placeholder-orange-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--placeholder-opacity));
}

.focus\:placeholder-orange-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--placeholder-opacity));
}

.focus\:placeholder-orange-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--placeholder-opacity));
}

.focus\:placeholder-orange-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--placeholder-opacity));
}

.focus\:placeholder-orange-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--placeholder-opacity));
}

.focus\:placeholder-orange-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--placeholder-opacity));
}

.focus\:placeholder-orange-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--placeholder-opacity));
}

.focus\:placeholder-orange-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--placeholder-opacity));
}

.focus\:placeholder-orange-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--placeholder-opacity));
}

.focus\:placeholder-orange-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--placeholder-opacity));
}

.focus\:placeholder-orange-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--placeholder-opacity));
}

.focus\:placeholder-orange-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--placeholder-opacity));
}

.focus\:placeholder-orange-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--placeholder-opacity));
}

.focus\:placeholder-orange-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--placeholder-opacity));
}

.focus\:placeholder-orange-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--placeholder-opacity));
}

.focus\:placeholder-orange-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--placeholder-opacity));
}

.focus\:placeholder-orange-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--placeholder-opacity));
}

.focus\:placeholder-orange-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--placeholder-opacity));
}

.focus\:placeholder-orange-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--placeholder-opacity));
}

.focus\:placeholder-orange-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--placeholder-opacity));
}

.focus\:placeholder-orange-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--placeholder-opacity));
}

.focus\:placeholder-orange-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--placeholder-opacity));
}

.focus\:placeholder-orange-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--placeholder-opacity));
}

.focus\:placeholder-orange-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--placeholder-opacity));
}

.focus\:placeholder-orange-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--placeholder-opacity));
}

.focus\:placeholder-orange-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--placeholder-opacity));
}

.focus\:placeholder-orange-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--placeholder-opacity));
}

.focus\:placeholder-orange-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--placeholder-opacity));
}

.focus\:placeholder-orange-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--placeholder-opacity));
}

.focus\:placeholder-orange-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--placeholder-opacity));
}

.focus\:placeholder-orange-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--placeholder-opacity));
}

.focus\:placeholder-orange-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity));
}

.focus\:placeholder-orange-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity));
}

.focus\:placeholder-orange-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity));
}

.focus\:placeholder-orange-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-50:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-50:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-50:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--placeholder-opacity));
}

.focus\:placeholder-yellow-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--placeholder-opacity));
}

.focus\:placeholder-green-50:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--placeholder-opacity));
}

.focus\:placeholder-green-50:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--placeholder-opacity));
}

.focus\:placeholder-green-50:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--placeholder-opacity));
}

.focus\:placeholder-green-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--placeholder-opacity));
}

.focus\:placeholder-green-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--placeholder-opacity));
}

.focus\:placeholder-green-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--placeholder-opacity));
}

.focus\:placeholder-green-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--placeholder-opacity));
}

.focus\:placeholder-green-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--placeholder-opacity));
}

.focus\:placeholder-green-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--placeholder-opacity));
}

.focus\:placeholder-green-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--placeholder-opacity));
}

.focus\:placeholder-green-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--placeholder-opacity));
}

.focus\:placeholder-green-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--placeholder-opacity));
}

.focus\:placeholder-green-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--placeholder-opacity));
}

.focus\:placeholder-green-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--placeholder-opacity));
}

.focus\:placeholder-green-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--placeholder-opacity));
}

.focus\:placeholder-green-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--placeholder-opacity));
}

.focus\:placeholder-green-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--placeholder-opacity));
}

.focus\:placeholder-green-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--placeholder-opacity));
}

.focus\:placeholder-green-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--placeholder-opacity));
}

.focus\:placeholder-green-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--placeholder-opacity));
}

.focus\:placeholder-green-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--placeholder-opacity));
}

.focus\:placeholder-green-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--placeholder-opacity));
}

.focus\:placeholder-green-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--placeholder-opacity));
}

.focus\:placeholder-green-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--placeholder-opacity));
}

.focus\:placeholder-green-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--placeholder-opacity));
}

.focus\:placeholder-green-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--placeholder-opacity));
}

.focus\:placeholder-green-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--placeholder-opacity));
}

.focus\:placeholder-green-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--placeholder-opacity));
}

.focus\:placeholder-green-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--placeholder-opacity));
}

.focus\:placeholder-green-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--placeholder-opacity));
}

.focus\:placeholder-green-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--placeholder-opacity));
}

.focus\:placeholder-green-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--placeholder-opacity));
}

.focus\:placeholder-green-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--placeholder-opacity));
}

.focus\:placeholder-green-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--placeholder-opacity));
}

.focus\:placeholder-green-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--placeholder-opacity));
}

.focus\:placeholder-green-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--placeholder-opacity));
}

.focus\:placeholder-green-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--placeholder-opacity));
}

.focus\:placeholder-green-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--placeholder-opacity));
}

.focus\:placeholder-green-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--placeholder-opacity));
}

.focus\:placeholder-green-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--placeholder-opacity));
}

.focus\:placeholder-teal-50:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--placeholder-opacity));
}

.focus\:placeholder-teal-50:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--placeholder-opacity));
}

.focus\:placeholder-teal-50:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--placeholder-opacity));
}

.focus\:placeholder-teal-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--placeholder-opacity));
}

.focus\:placeholder-teal-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--placeholder-opacity));
}

.focus\:placeholder-teal-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--placeholder-opacity));
}

.focus\:placeholder-teal-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--placeholder-opacity));
}

.focus\:placeholder-teal-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--placeholder-opacity));
}

.focus\:placeholder-teal-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--placeholder-opacity));
}

.focus\:placeholder-teal-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--placeholder-opacity));
}

.focus\:placeholder-teal-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--placeholder-opacity));
}

.focus\:placeholder-teal-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--placeholder-opacity));
}

.focus\:placeholder-teal-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--placeholder-opacity));
}

.focus\:placeholder-teal-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--placeholder-opacity));
}

.focus\:placeholder-teal-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--placeholder-opacity));
}

.focus\:placeholder-teal-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--placeholder-opacity));
}

.focus\:placeholder-teal-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--placeholder-opacity));
}

.focus\:placeholder-teal-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--placeholder-opacity));
}

.focus\:placeholder-teal-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--placeholder-opacity));
}

.focus\:placeholder-teal-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--placeholder-opacity));
}

.focus\:placeholder-teal-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--placeholder-opacity));
}

.focus\:placeholder-teal-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--placeholder-opacity));
}

.focus\:placeholder-teal-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--placeholder-opacity));
}

.focus\:placeholder-teal-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--placeholder-opacity));
}

.focus\:placeholder-teal-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--placeholder-opacity));
}

.focus\:placeholder-teal-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--placeholder-opacity));
}

.focus\:placeholder-teal-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--placeholder-opacity));
}

.focus\:placeholder-teal-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--placeholder-opacity));
}

.focus\:placeholder-teal-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--placeholder-opacity));
}

.focus\:placeholder-teal-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--placeholder-opacity));
}

.focus\:placeholder-teal-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--placeholder-opacity));
}

.focus\:placeholder-teal-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--placeholder-opacity));
}

.focus\:placeholder-teal-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--placeholder-opacity));
}

.focus\:placeholder-teal-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--placeholder-opacity));
}

.focus\:placeholder-teal-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--placeholder-opacity));
}

.focus\:placeholder-teal-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--placeholder-opacity));
}

.focus\:placeholder-teal-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--placeholder-opacity));
}

.focus\:placeholder-teal-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--placeholder-opacity));
}

.focus\:placeholder-teal-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--placeholder-opacity));
}

.focus\:placeholder-teal-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--placeholder-opacity));
}

.focus\:placeholder-blue-50:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--placeholder-opacity));
}

.focus\:placeholder-blue-50:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--placeholder-opacity));
}

.focus\:placeholder-blue-50:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--placeholder-opacity));
}

.focus\:placeholder-blue-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--placeholder-opacity));
}

.focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--placeholder-opacity));
}

.focus\:placeholder-blue-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--placeholder-opacity));
}

.focus\:placeholder-blue-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--placeholder-opacity));
}

.focus\:placeholder-blue-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--placeholder-opacity));
}

.focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--placeholder-opacity));
}

.focus\:placeholder-blue-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--placeholder-opacity));
}

.focus\:placeholder-blue-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--placeholder-opacity));
}

.focus\:placeholder-blue-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--placeholder-opacity));
}

.focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--placeholder-opacity));
}

.focus\:placeholder-blue-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--placeholder-opacity));
}

.focus\:placeholder-blue-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--placeholder-opacity));
}

.focus\:placeholder-blue-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--placeholder-opacity));
}

.focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--placeholder-opacity));
}

.focus\:placeholder-blue-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--placeholder-opacity));
}

.focus\:placeholder-blue-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--placeholder-opacity));
}

.focus\:placeholder-blue-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--placeholder-opacity));
}

.focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--placeholder-opacity));
}

.focus\:placeholder-blue-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--placeholder-opacity));
}

.focus\:placeholder-blue-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--placeholder-opacity));
}

.focus\:placeholder-blue-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--placeholder-opacity));
}

.focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--placeholder-opacity));
}

.focus\:placeholder-blue-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--placeholder-opacity));
}

.focus\:placeholder-blue-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--placeholder-opacity));
}

.focus\:placeholder-blue-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--placeholder-opacity));
}

.focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--placeholder-opacity));
}

.focus\:placeholder-blue-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--placeholder-opacity));
}

.focus\:placeholder-blue-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--placeholder-opacity));
}

.focus\:placeholder-blue-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--placeholder-opacity));
}

.focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--placeholder-opacity));
}

.focus\:placeholder-blue-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--placeholder-opacity));
}

.focus\:placeholder-blue-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--placeholder-opacity));
}

.focus\:placeholder-blue-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--placeholder-opacity));
}

.focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--placeholder-opacity));
}

.focus\:placeholder-blue-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--placeholder-opacity));
}

.focus\:placeholder-blue-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--placeholder-opacity));
}

.focus\:placeholder-blue-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-50:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-50:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-50:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--placeholder-opacity));
}

.focus\:placeholder-indigo-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--placeholder-opacity));
}

.focus\:placeholder-purple-50:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--placeholder-opacity));
}

.focus\:placeholder-purple-50:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--placeholder-opacity));
}

.focus\:placeholder-purple-50:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--placeholder-opacity));
}

.focus\:placeholder-purple-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--placeholder-opacity));
}

.focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--placeholder-opacity));
}

.focus\:placeholder-purple-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--placeholder-opacity));
}

.focus\:placeholder-purple-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--placeholder-opacity));
}

.focus\:placeholder-purple-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--placeholder-opacity));
}

.focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--placeholder-opacity));
}

.focus\:placeholder-purple-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--placeholder-opacity));
}

.focus\:placeholder-purple-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--placeholder-opacity));
}

.focus\:placeholder-purple-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--placeholder-opacity));
}

.focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--placeholder-opacity));
}

.focus\:placeholder-purple-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--placeholder-opacity));
}

.focus\:placeholder-purple-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--placeholder-opacity));
}

.focus\:placeholder-purple-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--placeholder-opacity));
}

.focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--placeholder-opacity));
}

.focus\:placeholder-purple-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--placeholder-opacity));
}

.focus\:placeholder-purple-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--placeholder-opacity));
}

.focus\:placeholder-purple-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--placeholder-opacity));
}

.focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--placeholder-opacity));
}

.focus\:placeholder-purple-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--placeholder-opacity));
}

.focus\:placeholder-purple-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--placeholder-opacity));
}

.focus\:placeholder-purple-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--placeholder-opacity));
}

.focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--placeholder-opacity));
}

.focus\:placeholder-purple-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--placeholder-opacity));
}

.focus\:placeholder-purple-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--placeholder-opacity));
}

.focus\:placeholder-purple-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--placeholder-opacity));
}

.focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--placeholder-opacity));
}

.focus\:placeholder-purple-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--placeholder-opacity));
}

.focus\:placeholder-purple-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--placeholder-opacity));
}

.focus\:placeholder-purple-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--placeholder-opacity));
}

.focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--placeholder-opacity));
}

.focus\:placeholder-purple-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--placeholder-opacity));
}

.focus\:placeholder-purple-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--placeholder-opacity));
}

.focus\:placeholder-purple-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--placeholder-opacity));
}

.focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--placeholder-opacity));
}

.focus\:placeholder-purple-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--placeholder-opacity));
}

.focus\:placeholder-purple-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--placeholder-opacity));
}

.focus\:placeholder-purple-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--placeholder-opacity));
}

.focus\:placeholder-pink-50:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--placeholder-opacity));
}

.focus\:placeholder-pink-50:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--placeholder-opacity));
}

.focus\:placeholder-pink-50:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--placeholder-opacity));
}

.focus\:placeholder-pink-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--placeholder-opacity));
}

.focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--placeholder-opacity));
}

.focus\:placeholder-pink-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--placeholder-opacity));
}

.focus\:placeholder-pink-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--placeholder-opacity));
}

.focus\:placeholder-pink-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--placeholder-opacity));
}

.focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--placeholder-opacity));
}

.focus\:placeholder-pink-200:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--placeholder-opacity));
}

.focus\:placeholder-pink-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--placeholder-opacity));
}

.focus\:placeholder-pink-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--placeholder-opacity));
}

.focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--placeholder-opacity));
}

.focus\:placeholder-pink-300:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--placeholder-opacity));
}

.focus\:placeholder-pink-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--placeholder-opacity));
}

.focus\:placeholder-pink-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--placeholder-opacity));
}

.focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--placeholder-opacity));
}

.focus\:placeholder-pink-400:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--placeholder-opacity));
}

.focus\:placeholder-pink-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--placeholder-opacity));
}

.focus\:placeholder-pink-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--placeholder-opacity));
}

.focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--placeholder-opacity));
}

.focus\:placeholder-pink-500:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--placeholder-opacity));
}

.focus\:placeholder-pink-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--placeholder-opacity));
}

.focus\:placeholder-pink-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--placeholder-opacity));
}

.focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--placeholder-opacity));
}

.focus\:placeholder-pink-600:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--placeholder-opacity));
}

.focus\:placeholder-pink-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--placeholder-opacity));
}

.focus\:placeholder-pink-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--placeholder-opacity));
}

.focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--placeholder-opacity));
}

.focus\:placeholder-pink-700:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--placeholder-opacity));
}

.focus\:placeholder-pink-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--placeholder-opacity));
}

.focus\:placeholder-pink-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--placeholder-opacity));
}

.focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--placeholder-opacity));
}

.focus\:placeholder-pink-800:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--placeholder-opacity));
}

.focus\:placeholder-pink-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--placeholder-opacity));
}

.focus\:placeholder-pink-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--placeholder-opacity));
}

.focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--placeholder-opacity));
}

.focus\:placeholder-pink-900:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--placeholder-opacity));
}

.focus\:placeholder-pink-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--placeholder-opacity));
}

.focus\:placeholder-pink-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--placeholder-opacity));
}

.placeholder-opacity-0::-webkit-input-placeholder {
  --placeholder-opacity: 0;
}

.placeholder-opacity-0:-ms-input-placeholder {
  --placeholder-opacity: 0;
}

.placeholder-opacity-0::-ms-input-placeholder {
  --placeholder-opacity: 0;
}

.placeholder-opacity-0::placeholder {
  --placeholder-opacity: 0;
}

.placeholder-opacity-25::-webkit-input-placeholder {
  --placeholder-opacity: 0.25;
}

.placeholder-opacity-25:-ms-input-placeholder {
  --placeholder-opacity: 0.25;
}

.placeholder-opacity-25::-ms-input-placeholder {
  --placeholder-opacity: 0.25;
}

.placeholder-opacity-25::placeholder {
  --placeholder-opacity: 0.25;
}

.placeholder-opacity-50::-webkit-input-placeholder {
  --placeholder-opacity: 0.5;
}

.placeholder-opacity-50:-ms-input-placeholder {
  --placeholder-opacity: 0.5;
}

.placeholder-opacity-50::-ms-input-placeholder {
  --placeholder-opacity: 0.5;
}

.placeholder-opacity-50::placeholder {
  --placeholder-opacity: 0.5;
}

.placeholder-opacity-75::-webkit-input-placeholder {
  --placeholder-opacity: 0.75;
}

.placeholder-opacity-75:-ms-input-placeholder {
  --placeholder-opacity: 0.75;
}

.placeholder-opacity-75::-ms-input-placeholder {
  --placeholder-opacity: 0.75;
}

.placeholder-opacity-75::placeholder {
  --placeholder-opacity: 0.75;
}

.placeholder-opacity-100::-webkit-input-placeholder {
  --placeholder-opacity: 1;
}

.placeholder-opacity-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
}

.placeholder-opacity-100::-ms-input-placeholder {
  --placeholder-opacity: 1;
}

.placeholder-opacity-100::placeholder {
  --placeholder-opacity: 1;
}

.focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0;
}

.focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
  --placeholder-opacity: 0;
}

.focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
  --placeholder-opacity: 0;
}

.focus\:placeholder-opacity-0:focus::placeholder {
  --placeholder-opacity: 0;
}

.focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0.25;
}

.focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
  --placeholder-opacity: 0.25;
}

.focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
  --placeholder-opacity: 0.25;
}

.focus\:placeholder-opacity-25:focus::placeholder {
  --placeholder-opacity: 0.25;
}

.focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0.5;
}

.focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
  --placeholder-opacity: 0.5;
}

.focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
  --placeholder-opacity: 0.5;
}

.focus\:placeholder-opacity-50:focus::placeholder {
  --placeholder-opacity: 0.5;
}

.focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0.75;
}

.focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
  --placeholder-opacity: 0.75;
}

.focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
  --placeholder-opacity: 0.75;
}

.focus\:placeholder-opacity-75:focus::placeholder {
  --placeholder-opacity: 0.75;
}

.focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
}

.focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
}

.focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1;
}

.focus\:placeholder-opacity-100:focus::placeholder {
  --placeholder-opacity: 1;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.inset-auto {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.inset-x-0 {
  right: 0;
  left: 0;
}

.inset-y-auto {
  top: auto;
  bottom: auto;
}

.inset-x-auto {
  right: auto;
  left: auto;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-auto {
  top: auto;
}

.right-auto {
  right: auto;
}

.bottom-auto {
  bottom: auto;
}

.left-auto {
  left: auto;
}

.resize-none {
  resize: none;
}

.resize-y {
  resize: vertical;
}

.resize-x {
  resize: horizontal;
}

.resize {
  resize: both;
}

.shadow-xs {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.shadow-2xl {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.shadow-outline {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.shadow-none {
  box-shadow: none;
}

.shadow-bottom {
  box-shadow: 0 5px 6px -7px rgba(0, 0, 0, 0.6),
    0 2px 4px -5px rgba(0, 0, 0, 0.06);
}

.hover\:shadow-xs:hover {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.hover\:shadow-sm:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.hover\:shadow:hover {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.hover\:shadow-md:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.hover\:shadow-lg:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.hover\:shadow-xl:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.hover\:shadow-2xl:hover {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.hover\:shadow-inner:hover {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.hover\:shadow-outline:hover {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.hover\:shadow-none:hover {
  box-shadow: none;
}

.hover\:shadow-bottom:hover {
  box-shadow: 0 5px 6px -7px rgba(0, 0, 0, 0.6),
    0 2px 4px -5px rgba(0, 0, 0, 0.06);
}

.focus\:shadow-xs:focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.focus\:shadow-sm:focus {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.focus\:shadow:focus {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.focus\:shadow-md:focus {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.focus\:shadow-lg:focus {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.focus\:shadow-xl:focus {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.focus\:shadow-2xl:focus {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.focus\:shadow-inner:focus {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.focus\:shadow-outline:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.focus\:shadow-none:focus {
  box-shadow: none;
}

.focus\:shadow-bottom:focus {
  box-shadow: 0 5px 6px -7px rgba(0, 0, 0, 0.6),
    0 2px 4px -5px rgba(0, 0, 0, 0.06);
}

.fill-current {
  fill: currentColor;
}

.stroke-current {
  stroke: currentColor;
}

.stroke-0 {
  stroke-width: 0;
}

.stroke-1 {
  stroke-width: 1;
}

.stroke-2 {
  stroke-width: 2;
}

.table-auto {
  table-layout: auto;
}

.table-fixed {
  table-layout: fixed;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-transparent {
  color: transparent;
}

.text-white {
  --text-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.text-black {
  --text-opacity: 1;
  color: #000000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

.text-gray-50 {
  --text-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--text-opacity));
}

.text-gray-100 {
  --text-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--text-opacity));
}

.text-gray-200 {
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity));
}

.text-gray-300 {
  --text-opacity: 1;
  color: #d5d6d7;
  color: rgba(213, 214, 215, var(--text-opacity));
}

.text-gray-400 {
  --text-opacity: 1;
  color: #9e9e9e;
  color: rgba(158, 158, 158, var(--text-opacity));
}

.text-gray-500 {
  --text-opacity: 1;
  color: #707275;
  color: rgba(112, 114, 117, var(--text-opacity));
}

.text-gray-600 {
  --text-opacity: 1;
  color: #4c4f52;
  color: rgba(76, 79, 82, var(--text-opacity));
}

.text-gray-700 {
  --text-opacity: 1;
  color: #24262d;
  color: rgba(36, 38, 45, var(--text-opacity));
}

.text-gray-800 {
  --text-opacity: 1;
  color: #1a1c23;
  color: rgba(26, 28, 35, var(--text-opacity));
}

.text-gray-900 {
  --text-opacity: 1;
  color: #121317;
  color: rgba(18, 19, 23, var(--text-opacity));
}

.text-cool-gray-50 {
  --text-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--text-opacity));
}

.text-cool-gray-100 {
  --text-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--text-opacity));
}

.text-cool-gray-200 {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity));
}

.text-cool-gray-300 {
  --text-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--text-opacity));
}

.text-cool-gray-400 {
  --text-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--text-opacity));
}

.text-cool-gray-500 {
  --text-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--text-opacity));
}

.text-cool-gray-600 {
  --text-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--text-opacity));
}

.text-cool-gray-700 {
  --text-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--text-opacity));
}

.text-cool-gray-800 {
  --text-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--text-opacity));
}

.text-cool-gray-900 {
  --text-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--text-opacity));
}

.text-red-50 {
  --text-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--text-opacity));
}

.text-red-100 {
  --text-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--text-opacity));
}

.text-red-200 {
  --text-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--text-opacity));
}

.text-red-300 {
  --text-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--text-opacity));
}

.text-red-400 {
  --text-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--text-opacity));
}

.text-red-500 {
  --text-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--text-opacity));
}

.text-red-600 {
  --text-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--text-opacity));
}

.text-red-700 {
  --text-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--text-opacity));
}

.text-red-800 {
  --text-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--text-opacity));
}

.text-red-900 {
  --text-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--text-opacity));
}

.text-orange-50 {
  --text-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--text-opacity));
}

.text-orange-100 {
  --text-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--text-opacity));
}

.text-orange-200 {
  --text-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--text-opacity));
}

.text-orange-300 {
  --text-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--text-opacity));
}

.text-orange-400 {
  --text-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--text-opacity));
}

.text-orange-500 {
  --text-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--text-opacity));
}

.text-orange-600 {
  --text-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--text-opacity));
}

.text-orange-700 {
  --text-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--text-opacity));
}

.text-orange-800 {
  --text-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--text-opacity));
}

.text-orange-900 {
  --text-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--text-opacity));
}

.text-yellow-50 {
  --text-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--text-opacity));
}

.text-yellow-100 {
  --text-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--text-opacity));
}

.text-yellow-200 {
  --text-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--text-opacity));
}

.text-yellow-300 {
  --text-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--text-opacity));
}

.text-yellow-400 {
  --text-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--text-opacity));
}

.text-yellow-500 {
  --text-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--text-opacity));
}

.text-yellow-600 {
  --text-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--text-opacity));
}

.text-yellow-700 {
  --text-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--text-opacity));
}

.text-yellow-800 {
  --text-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--text-opacity));
}

.text-yellow-900 {
  --text-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--text-opacity));
}

.text-green-50 {
  --text-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--text-opacity));
}

.text-green-100 {
  --text-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--text-opacity));
}

.text-green-200 {
  --text-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--text-opacity));
}

.text-green-300 {
  --text-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--text-opacity));
}

.text-green-400 {
  --text-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--text-opacity));
}

.text-green-500 {
  --text-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--text-opacity));
}

.text-green-600 {
  --text-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--text-opacity));
}

.text-green-700 {
  --text-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--text-opacity));
}

.text-green-800 {
  --text-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--text-opacity));
}

.text-green-900 {
  --text-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--text-opacity));
}

.text-teal-50 {
  --text-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--text-opacity));
}

.text-teal-100 {
  --text-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--text-opacity));
}

.text-teal-200 {
  --text-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--text-opacity));
}

.text-teal-300 {
  --text-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--text-opacity));
}

.text-teal-400 {
  --text-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--text-opacity));
}

.text-teal-500 {
  --text-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--text-opacity));
}

.text-teal-600 {
  --text-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--text-opacity));
}

.text-teal-700 {
  --text-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--text-opacity));
}

.text-teal-800 {
  --text-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--text-opacity));
}

.text-teal-900 {
  --text-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--text-opacity));
}

.text-blue-50 {
  --text-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--text-opacity));
}

.text-blue-100 {
  --text-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--text-opacity));
}

.text-blue-200 {
  --text-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--text-opacity));
}

.text-blue-300 {
  --text-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--text-opacity));
}

.text-blue-400 {
  --text-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--text-opacity));
}

.text-blue-500 {
  --text-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--text-opacity));
}

.text-blue-600 {
  --text-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--text-opacity));
}

.text-blue-700 {
  --text-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--text-opacity));
}

.text-blue-800 {
  --text-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--text-opacity));
}

.text-blue-900 {
  --text-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--text-opacity));
}

.text-indigo-50 {
  --text-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--text-opacity));
}

.text-indigo-100 {
  --text-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--text-opacity));
}

.text-indigo-200 {
  --text-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--text-opacity));
}

.text-indigo-300 {
  --text-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--text-opacity));
}

.text-indigo-400 {
  --text-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--text-opacity));
}

.text-indigo-500 {
  --text-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--text-opacity));
}

.text-indigo-600 {
  --text-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--text-opacity));
}

.text-indigo-700 {
  --text-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--text-opacity));
}

.text-indigo-800 {
  --text-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--text-opacity));
}

.text-indigo-900 {
  --text-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--text-opacity));
}

.text-purple-50 {
  --text-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--text-opacity));
}

.text-purple-100 {
  --text-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--text-opacity));
}

.text-purple-200 {
  --text-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--text-opacity));
}

.text-purple-300 {
  --text-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--text-opacity));
}

.text-purple-400 {
  --text-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--text-opacity));
}

.text-purple-500 {
  --text-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--text-opacity));
}

.text-purple-600 {
  --text-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--text-opacity));
}

.text-purple-700 {
  --text-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--text-opacity));
}

.text-purple-800 {
  --text-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--text-opacity));
}

.text-purple-900 {
  --text-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--text-opacity));
}

.text-pink-50 {
  --text-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--text-opacity));
}

.text-pink-100 {
  --text-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--text-opacity));
}

.text-pink-200 {
  --text-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--text-opacity));
}

.text-pink-300 {
  --text-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--text-opacity));
}

.text-pink-400 {
  --text-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--text-opacity));
}

.text-pink-500 {
  --text-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--text-opacity));
}

.text-pink-600 {
  --text-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--text-opacity));
}

.text-pink-700 {
  --text-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--text-opacity));
}

.text-pink-800 {
  --text-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--text-opacity));
}

.text-pink-900 {
  --text-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--text-opacity));
}

.focus\:text-transparent:focus {
  color: transparent;
}

.focus\:text-white:focus {
  --text-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.focus\:text-black:focus {
  --text-opacity: 1;
  color: #000000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

.focus\:text-gray-50:focus {
  --text-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--text-opacity));
}

.focus\:text-gray-100:focus {
  --text-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--text-opacity));
}

.focus\:text-gray-200:focus {
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity));
}

.focus\:text-gray-300:focus {
  --text-opacity: 1;
  color: #d5d6d7;
  color: rgba(213, 214, 215, var(--text-opacity));
}

.focus\:text-gray-400:focus {
  --text-opacity: 1;
  color: #9e9e9e;
  color: rgba(158, 158, 158, var(--text-opacity));
}

.focus\:text-gray-500:focus {
  --text-opacity: 1;
  color: #707275;
  color: rgba(112, 114, 117, var(--text-opacity));
}

.focus\:text-gray-600:focus {
  --text-opacity: 1;
  color: #4c4f52;
  color: rgba(76, 79, 82, var(--text-opacity));
}

.focus\:text-gray-700:focus {
  --text-opacity: 1;
  color: #24262d;
  color: rgba(36, 38, 45, var(--text-opacity));
}

.focus\:text-gray-800:focus {
  --text-opacity: 1;
  color: #1a1c23;
  color: rgba(26, 28, 35, var(--text-opacity));
}

.focus\:text-gray-900:focus {
  --text-opacity: 1;
  color: #121317;
  color: rgba(18, 19, 23, var(--text-opacity));
}

.focus\:text-cool-gray-50:focus {
  --text-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--text-opacity));
}

.focus\:text-cool-gray-100:focus {
  --text-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--text-opacity));
}

.focus\:text-cool-gray-200:focus {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity));
}

.focus\:text-cool-gray-300:focus {
  --text-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--text-opacity));
}

.focus\:text-cool-gray-400:focus {
  --text-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--text-opacity));
}

.focus\:text-cool-gray-500:focus {
  --text-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--text-opacity));
}

.focus\:text-cool-gray-600:focus {
  --text-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--text-opacity));
}

.focus\:text-cool-gray-700:focus {
  --text-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--text-opacity));
}

.focus\:text-cool-gray-800:focus {
  --text-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--text-opacity));
}

.focus\:text-cool-gray-900:focus {
  --text-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--text-opacity));
}

.focus\:text-red-50:focus {
  --text-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--text-opacity));
}

.focus\:text-red-100:focus {
  --text-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--text-opacity));
}

.focus\:text-red-200:focus {
  --text-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--text-opacity));
}

.focus\:text-red-300:focus {
  --text-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--text-opacity));
}

.focus\:text-red-400:focus {
  --text-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--text-opacity));
}

.focus\:text-red-500:focus {
  --text-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--text-opacity));
}

.focus\:text-red-600:focus {
  --text-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--text-opacity));
}

.focus\:text-red-700:focus {
  --text-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--text-opacity));
}

.focus\:text-red-800:focus {
  --text-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--text-opacity));
}

.focus\:text-red-900:focus {
  --text-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--text-opacity));
}

.focus\:text-orange-50:focus {
  --text-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--text-opacity));
}

.focus\:text-orange-100:focus {
  --text-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--text-opacity));
}

.focus\:text-orange-200:focus {
  --text-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--text-opacity));
}

.focus\:text-orange-300:focus {
  --text-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--text-opacity));
}

.focus\:text-orange-400:focus {
  --text-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--text-opacity));
}

.focus\:text-orange-500:focus {
  --text-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--text-opacity));
}

.focus\:text-orange-600:focus {
  --text-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--text-opacity));
}

.focus\:text-orange-700:focus {
  --text-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--text-opacity));
}

.focus\:text-orange-800:focus {
  --text-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--text-opacity));
}

.focus\:text-orange-900:focus {
  --text-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--text-opacity));
}

.focus\:text-yellow-50:focus {
  --text-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--text-opacity));
}

.focus\:text-yellow-100:focus {
  --text-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--text-opacity));
}

.focus\:text-yellow-200:focus {
  --text-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--text-opacity));
}

.focus\:text-yellow-300:focus {
  --text-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--text-opacity));
}

.focus\:text-yellow-400:focus {
  --text-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--text-opacity));
}

.focus\:text-yellow-500:focus {
  --text-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--text-opacity));
}

.focus\:text-yellow-600:focus {
  --text-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--text-opacity));
}

.focus\:text-yellow-700:focus {
  --text-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--text-opacity));
}

.focus\:text-yellow-800:focus {
  --text-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--text-opacity));
}

.focus\:text-yellow-900:focus {
  --text-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--text-opacity));
}

.focus\:text-green-50:focus {
  --text-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--text-opacity));
}

.focus\:text-green-100:focus {
  --text-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--text-opacity));
}

.focus\:text-green-200:focus {
  --text-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--text-opacity));
}

.focus\:text-green-300:focus {
  --text-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--text-opacity));
}

.focus\:text-green-400:focus {
  --text-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--text-opacity));
}

.focus\:text-green-500:focus {
  --text-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--text-opacity));
}

.focus\:text-green-600:focus {
  --text-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--text-opacity));
}

.focus\:text-green-700:focus {
  --text-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--text-opacity));
}

.focus\:text-green-800:focus {
  --text-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--text-opacity));
}

.focus\:text-green-900:focus {
  --text-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--text-opacity));
}

.focus\:text-teal-50:focus {
  --text-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--text-opacity));
}

.focus\:text-teal-100:focus {
  --text-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--text-opacity));
}

.focus\:text-teal-200:focus {
  --text-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--text-opacity));
}

.focus\:text-teal-300:focus {
  --text-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--text-opacity));
}

.focus\:text-teal-400:focus {
  --text-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--text-opacity));
}

.focus\:text-teal-500:focus {
  --text-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--text-opacity));
}

.focus\:text-teal-600:focus {
  --text-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--text-opacity));
}

.focus\:text-teal-700:focus {
  --text-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--text-opacity));
}

.focus\:text-teal-800:focus {
  --text-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--text-opacity));
}

.focus\:text-teal-900:focus {
  --text-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--text-opacity));
}

.focus\:text-blue-50:focus {
  --text-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--text-opacity));
}

.focus\:text-blue-100:focus {
  --text-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--text-opacity));
}

.focus\:text-blue-200:focus {
  --text-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--text-opacity));
}

.focus\:text-blue-300:focus {
  --text-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--text-opacity));
}

.focus\:text-blue-400:focus {
  --text-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--text-opacity));
}

.focus\:text-blue-500:focus {
  --text-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--text-opacity));
}

.focus\:text-blue-600:focus {
  --text-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--text-opacity));
}

.focus\:text-blue-700:focus {
  --text-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--text-opacity));
}

.focus\:text-blue-800:focus {
  --text-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--text-opacity));
}

.focus\:text-blue-900:focus {
  --text-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--text-opacity));
}

.focus\:text-indigo-50:focus {
  --text-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--text-opacity));
}

.focus\:text-indigo-100:focus {
  --text-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--text-opacity));
}

.focus\:text-indigo-200:focus {
  --text-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--text-opacity));
}

.focus\:text-indigo-300:focus {
  --text-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--text-opacity));
}

.focus\:text-indigo-400:focus {
  --text-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--text-opacity));
}

.focus\:text-indigo-500:focus {
  --text-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--text-opacity));
}

.focus\:text-indigo-600:focus {
  --text-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--text-opacity));
}

.focus\:text-indigo-700:focus {
  --text-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--text-opacity));
}

.focus\:text-indigo-800:focus {
  --text-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--text-opacity));
}

.focus\:text-indigo-900:focus {
  --text-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--text-opacity));
}

.focus\:text-purple-50:focus {
  --text-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--text-opacity));
}

.focus\:text-purple-100:focus {
  --text-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--text-opacity));
}

.focus\:text-purple-200:focus {
  --text-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--text-opacity));
}

.focus\:text-purple-300:focus {
  --text-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--text-opacity));
}

.focus\:text-purple-400:focus {
  --text-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--text-opacity));
}

.focus\:text-purple-500:focus {
  --text-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--text-opacity));
}

.focus\:text-purple-600:focus {
  --text-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--text-opacity));
}

.focus\:text-purple-700:focus {
  --text-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--text-opacity));
}

.focus\:text-purple-800:focus {
  --text-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--text-opacity));
}

.focus\:text-purple-900:focus {
  --text-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--text-opacity));
}

.focus\:text-pink-50:focus {
  --text-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--text-opacity));
}

.focus\:text-pink-100:focus {
  --text-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--text-opacity));
}

.focus\:text-pink-200:focus {
  --text-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--text-opacity));
}

.focus\:text-pink-300:focus {
  --text-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--text-opacity));
}

.focus\:text-pink-400:focus {
  --text-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--text-opacity));
}

.focus\:text-pink-500:focus {
  --text-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--text-opacity));
}

.focus\:text-pink-600:focus {
  --text-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--text-opacity));
}

.focus\:text-pink-700:focus {
  --text-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--text-opacity));
}

.focus\:text-pink-800:focus {
  --text-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--text-opacity));
}

.focus\:text-pink-900:focus {
  --text-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--text-opacity));
}

.focus-within\:text-transparent:focus-within {
  color: transparent;
}

.focus-within\:text-white:focus-within {
  --text-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.focus-within\:text-black:focus-within {
  --text-opacity: 1;
  color: #000000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

.focus-within\:text-gray-50:focus-within {
  --text-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--text-opacity));
}

.focus-within\:text-gray-100:focus-within {
  --text-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--text-opacity));
}

.focus-within\:text-gray-200:focus-within {
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity));
}

.focus-within\:text-gray-300:focus-within {
  --text-opacity: 1;
  color: #d5d6d7;
  color: rgba(213, 214, 215, var(--text-opacity));
}

.focus-within\:text-gray-400:focus-within {
  --text-opacity: 1;
  color: #9e9e9e;
  color: rgba(158, 158, 158, var(--text-opacity));
}

.focus-within\:text-gray-500:focus-within {
  --text-opacity: 1;
  color: #707275;
  color: rgba(112, 114, 117, var(--text-opacity));
}

.focus-within\:text-gray-600:focus-within {
  --text-opacity: 1;
  color: #4c4f52;
  color: rgba(76, 79, 82, var(--text-opacity));
}

.focus-within\:text-gray-700:focus-within {
  --text-opacity: 1;
  color: #24262d;
  color: rgba(36, 38, 45, var(--text-opacity));
}

.focus-within\:text-gray-800:focus-within {
  --text-opacity: 1;
  color: #1a1c23;
  color: rgba(26, 28, 35, var(--text-opacity));
}

.focus-within\:text-gray-900:focus-within {
  --text-opacity: 1;
  color: #121317;
  color: rgba(18, 19, 23, var(--text-opacity));
}

.focus-within\:text-cool-gray-50:focus-within {
  --text-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--text-opacity));
}

.focus-within\:text-cool-gray-100:focus-within {
  --text-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--text-opacity));
}

.focus-within\:text-cool-gray-200:focus-within {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity));
}

.focus-within\:text-cool-gray-300:focus-within {
  --text-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--text-opacity));
}

.focus-within\:text-cool-gray-400:focus-within {
  --text-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--text-opacity));
}

.focus-within\:text-cool-gray-500:focus-within {
  --text-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--text-opacity));
}

.focus-within\:text-cool-gray-600:focus-within {
  --text-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--text-opacity));
}

.focus-within\:text-cool-gray-700:focus-within {
  --text-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--text-opacity));
}

.focus-within\:text-cool-gray-800:focus-within {
  --text-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--text-opacity));
}

.focus-within\:text-cool-gray-900:focus-within {
  --text-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--text-opacity));
}

.focus-within\:text-red-50:focus-within {
  --text-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--text-opacity));
}

.focus-within\:text-red-100:focus-within {
  --text-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--text-opacity));
}

.focus-within\:text-red-200:focus-within {
  --text-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--text-opacity));
}

.focus-within\:text-red-300:focus-within {
  --text-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--text-opacity));
}

.focus-within\:text-red-400:focus-within {
  --text-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--text-opacity));
}

.focus-within\:text-red-500:focus-within {
  --text-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--text-opacity));
}

.focus-within\:text-red-600:focus-within {
  --text-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--text-opacity));
}

.focus-within\:text-red-700:focus-within {
  --text-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--text-opacity));
}

.focus-within\:text-red-800:focus-within {
  --text-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--text-opacity));
}

.focus-within\:text-red-900:focus-within {
  --text-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--text-opacity));
}

.focus-within\:text-orange-50:focus-within {
  --text-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--text-opacity));
}

.focus-within\:text-orange-100:focus-within {
  --text-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--text-opacity));
}

.focus-within\:text-orange-200:focus-within {
  --text-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--text-opacity));
}

.focus-within\:text-orange-300:focus-within {
  --text-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--text-opacity));
}

.focus-within\:text-orange-400:focus-within {
  --text-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--text-opacity));
}

.focus-within\:text-orange-500:focus-within {
  --text-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--text-opacity));
}

.focus-within\:text-orange-600:focus-within {
  --text-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--text-opacity));
}

.focus-within\:text-orange-700:focus-within {
  --text-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--text-opacity));
}

.focus-within\:text-orange-800:focus-within {
  --text-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--text-opacity));
}

.focus-within\:text-orange-900:focus-within {
  --text-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--text-opacity));
}

.focus-within\:text-yellow-50:focus-within {
  --text-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--text-opacity));
}

.focus-within\:text-yellow-100:focus-within {
  --text-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--text-opacity));
}

.focus-within\:text-yellow-200:focus-within {
  --text-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--text-opacity));
}

.focus-within\:text-yellow-300:focus-within {
  --text-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--text-opacity));
}

.focus-within\:text-yellow-400:focus-within {
  --text-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--text-opacity));
}

.focus-within\:text-yellow-500:focus-within {
  --text-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--text-opacity));
}

.focus-within\:text-yellow-600:focus-within {
  --text-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--text-opacity));
}

.focus-within\:text-yellow-700:focus-within {
  --text-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--text-opacity));
}

.focus-within\:text-yellow-800:focus-within {
  --text-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--text-opacity));
}

.focus-within\:text-yellow-900:focus-within {
  --text-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--text-opacity));
}

.focus-within\:text-green-50:focus-within {
  --text-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--text-opacity));
}

.focus-within\:text-green-100:focus-within {
  --text-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--text-opacity));
}

.focus-within\:text-green-200:focus-within {
  --text-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--text-opacity));
}

.focus-within\:text-green-300:focus-within {
  --text-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--text-opacity));
}

.focus-within\:text-green-400:focus-within {
  --text-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--text-opacity));
}

.focus-within\:text-green-500:focus-within {
  --text-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--text-opacity));
}

.focus-within\:text-green-600:focus-within {
  --text-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--text-opacity));
}

.focus-within\:text-green-700:focus-within {
  --text-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--text-opacity));
}

.focus-within\:text-green-800:focus-within {
  --text-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--text-opacity));
}

.focus-within\:text-green-900:focus-within {
  --text-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--text-opacity));
}

.focus-within\:text-teal-50:focus-within {
  --text-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--text-opacity));
}

.focus-within\:text-teal-100:focus-within {
  --text-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--text-opacity));
}

.focus-within\:text-teal-200:focus-within {
  --text-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--text-opacity));
}

.focus-within\:text-teal-300:focus-within {
  --text-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--text-opacity));
}

.focus-within\:text-teal-400:focus-within {
  --text-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--text-opacity));
}

.focus-within\:text-teal-500:focus-within {
  --text-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--text-opacity));
}

.focus-within\:text-teal-600:focus-within {
  --text-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--text-opacity));
}

.focus-within\:text-teal-700:focus-within {
  --text-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--text-opacity));
}

.focus-within\:text-teal-800:focus-within {
  --text-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--text-opacity));
}

.focus-within\:text-teal-900:focus-within {
  --text-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--text-opacity));
}

.focus-within\:text-blue-50:focus-within {
  --text-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--text-opacity));
}

.focus-within\:text-blue-100:focus-within {
  --text-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--text-opacity));
}

.focus-within\:text-blue-200:focus-within {
  --text-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--text-opacity));
}

.focus-within\:text-blue-300:focus-within {
  --text-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--text-opacity));
}

.focus-within\:text-blue-400:focus-within {
  --text-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--text-opacity));
}

.focus-within\:text-blue-500:focus-within {
  --text-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--text-opacity));
}

.focus-within\:text-blue-600:focus-within {
  --text-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--text-opacity));
}

.focus-within\:text-blue-700:focus-within {
  --text-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--text-opacity));
}

.focus-within\:text-blue-800:focus-within {
  --text-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--text-opacity));
}

.focus-within\:text-blue-900:focus-within {
  --text-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--text-opacity));
}

.focus-within\:text-indigo-50:focus-within {
  --text-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--text-opacity));
}

.focus-within\:text-indigo-100:focus-within {
  --text-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--text-opacity));
}

.focus-within\:text-indigo-200:focus-within {
  --text-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--text-opacity));
}

.focus-within\:text-indigo-300:focus-within {
  --text-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--text-opacity));
}

.focus-within\:text-indigo-400:focus-within {
  --text-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--text-opacity));
}

.focus-within\:text-indigo-500:focus-within {
  --text-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--text-opacity));
}

.focus-within\:text-indigo-600:focus-within {
  --text-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--text-opacity));
}

.focus-within\:text-indigo-700:focus-within {
  --text-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--text-opacity));
}

.focus-within\:text-indigo-800:focus-within {
  --text-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--text-opacity));
}

.focus-within\:text-indigo-900:focus-within {
  --text-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--text-opacity));
}

.focus-within\:text-purple-50:focus-within {
  --text-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--text-opacity));
}

.focus-within\:text-purple-100:focus-within {
  --text-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--text-opacity));
}

.focus-within\:text-purple-200:focus-within {
  --text-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--text-opacity));
}

.focus-within\:text-purple-300:focus-within {
  --text-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--text-opacity));
}

.focus-within\:text-purple-400:focus-within {
  --text-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--text-opacity));
}

.focus-within\:text-purple-500:focus-within {
  --text-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--text-opacity));
}

.focus-within\:text-purple-600:focus-within {
  --text-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--text-opacity));
}

.focus-within\:text-purple-700:focus-within {
  --text-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--text-opacity));
}

.focus-within\:text-purple-800:focus-within {
  --text-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--text-opacity));
}

.focus-within\:text-purple-900:focus-within {
  --text-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--text-opacity));
}

.focus-within\:text-pink-50:focus-within {
  --text-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--text-opacity));
}

.focus-within\:text-pink-100:focus-within {
  --text-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--text-opacity));
}

.focus-within\:text-pink-200:focus-within {
  --text-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--text-opacity));
}

.focus-within\:text-pink-300:focus-within {
  --text-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--text-opacity));
}

.focus-within\:text-pink-400:focus-within {
  --text-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--text-opacity));
}

.focus-within\:text-pink-500:focus-within {
  --text-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--text-opacity));
}

.focus-within\:text-pink-600:focus-within {
  --text-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--text-opacity));
}

.focus-within\:text-pink-700:focus-within {
  --text-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--text-opacity));
}

.focus-within\:text-pink-800:focus-within {
  --text-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--text-opacity));
}

.focus-within\:text-pink-900:focus-within {
  --text-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--text-opacity));
}

.hover\:text-transparent:hover {
  color: transparent;
}

.hover\:text-white:hover {
  --text-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.hover\:text-black:hover {
  --text-opacity: 1;
  color: #000000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

.hover\:text-gray-50:hover {
  --text-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--text-opacity));
}

.hover\:text-gray-100:hover {
  --text-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--text-opacity));
}

.hover\:text-gray-200:hover {
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity));
}

.hover\:text-gray-300:hover {
  --text-opacity: 1;
  color: #d5d6d7;
  color: rgba(213, 214, 215, var(--text-opacity));
}

.hover\:text-gray-400:hover {
  --text-opacity: 1;
  color: #9e9e9e;
  color: rgba(158, 158, 158, var(--text-opacity));
}

.hover\:text-gray-500:hover {
  --text-opacity: 1;
  color: #707275;
  color: rgba(112, 114, 117, var(--text-opacity));
}

.hover\:text-gray-600:hover {
  --text-opacity: 1;
  color: #4c4f52;
  color: rgba(76, 79, 82, var(--text-opacity));
}

.hover\:text-gray-700:hover {
  --text-opacity: 1;
  color: #24262d;
  color: rgba(36, 38, 45, var(--text-opacity));
}

.hover\:text-gray-800:hover {
  --text-opacity: 1;
  color: #1a1c23;
  color: rgba(26, 28, 35, var(--text-opacity));
}

.hover\:text-gray-900:hover {
  --text-opacity: 1;
  color: #121317;
  color: rgba(18, 19, 23, var(--text-opacity));
}

.hover\:text-cool-gray-50:hover {
  --text-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--text-opacity));
}

.hover\:text-cool-gray-100:hover {
  --text-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--text-opacity));
}

.hover\:text-cool-gray-200:hover {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity));
}

.hover\:text-cool-gray-300:hover {
  --text-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--text-opacity));
}

.hover\:text-cool-gray-400:hover {
  --text-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--text-opacity));
}

.hover\:text-cool-gray-500:hover {
  --text-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--text-opacity));
}

.hover\:text-cool-gray-600:hover {
  --text-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--text-opacity));
}

.hover\:text-cool-gray-700:hover {
  --text-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--text-opacity));
}

.hover\:text-cool-gray-800:hover {
  --text-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--text-opacity));
}

.hover\:text-cool-gray-900:hover {
  --text-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--text-opacity));
}

.hover\:text-red-50:hover {
  --text-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--text-opacity));
}

.hover\:text-red-100:hover {
  --text-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--text-opacity));
}

.hover\:text-red-200:hover {
  --text-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--text-opacity));
}

.hover\:text-red-300:hover {
  --text-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--text-opacity));
}

.hover\:text-red-400:hover {
  --text-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--text-opacity));
}

.hover\:text-red-500:hover {
  --text-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--text-opacity));
}

.hover\:text-red-600:hover {
  --text-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--text-opacity));
}

.hover\:text-red-700:hover {
  --text-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--text-opacity));
}

.hover\:text-red-800:hover {
  --text-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--text-opacity));
}

.hover\:text-red-900:hover {
  --text-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--text-opacity));
}

.hover\:text-orange-50:hover {
  --text-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--text-opacity));
}

.hover\:text-orange-100:hover {
  --text-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--text-opacity));
}

.hover\:text-orange-200:hover {
  --text-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--text-opacity));
}

.hover\:text-orange-300:hover {
  --text-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--text-opacity));
}

.hover\:text-orange-400:hover {
  --text-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--text-opacity));
}

.hover\:text-orange-500:hover {
  --text-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--text-opacity));
}

.hover\:text-orange-600:hover {
  --text-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--text-opacity));
}

.hover\:text-orange-700:hover {
  --text-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--text-opacity));
}

.hover\:text-orange-800:hover {
  --text-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--text-opacity));
}

.hover\:text-orange-900:hover {
  --text-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--text-opacity));
}

.hover\:text-yellow-50:hover {
  --text-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--text-opacity));
}

.hover\:text-yellow-100:hover {
  --text-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--text-opacity));
}

.hover\:text-yellow-200:hover {
  --text-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--text-opacity));
}

.hover\:text-yellow-300:hover {
  --text-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--text-opacity));
}

.hover\:text-yellow-400:hover {
  --text-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--text-opacity));
}

.hover\:text-yellow-500:hover {
  --text-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--text-opacity));
}

.hover\:text-yellow-600:hover {
  --text-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--text-opacity));
}

.hover\:text-yellow-700:hover {
  --text-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--text-opacity));
}

.hover\:text-yellow-800:hover {
  --text-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--text-opacity));
}

.hover\:text-yellow-900:hover {
  --text-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--text-opacity));
}

.hover\:text-green-50:hover {
  --text-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--text-opacity));
}

.hover\:text-green-100:hover {
  --text-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--text-opacity));
}

.hover\:text-green-200:hover {
  --text-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--text-opacity));
}

.hover\:text-green-300:hover {
  --text-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--text-opacity));
}

.hover\:text-green-400:hover {
  --text-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--text-opacity));
}

.hover\:text-green-500:hover {
  --text-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--text-opacity));
}

.hover\:text-green-600:hover {
  --text-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--text-opacity));
}

.hover\:text-green-700:hover {
  --text-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--text-opacity));
}

.hover\:text-green-800:hover {
  --text-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--text-opacity));
}

.hover\:text-green-900:hover {
  --text-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--text-opacity));
}

.hover\:text-teal-50:hover {
  --text-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--text-opacity));
}

.hover\:text-teal-100:hover {
  --text-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--text-opacity));
}

.hover\:text-teal-200:hover {
  --text-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--text-opacity));
}

.hover\:text-teal-300:hover {
  --text-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--text-opacity));
}

.hover\:text-teal-400:hover {
  --text-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--text-opacity));
}

.hover\:text-teal-500:hover {
  --text-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--text-opacity));
}

.hover\:text-teal-600:hover {
  --text-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--text-opacity));
}

.hover\:text-teal-700:hover {
  --text-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--text-opacity));
}

.hover\:text-teal-800:hover {
  --text-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--text-opacity));
}

.hover\:text-teal-900:hover {
  --text-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--text-opacity));
}

.hover\:text-blue-50:hover {
  --text-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--text-opacity));
}

.hover\:text-blue-100:hover {
  --text-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--text-opacity));
}

.hover\:text-blue-200:hover {
  --text-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--text-opacity));
}

.hover\:text-blue-300:hover {
  --text-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--text-opacity));
}

.hover\:text-blue-400:hover {
  --text-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--text-opacity));
}

.hover\:text-blue-500:hover {
  --text-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--text-opacity));
}

.hover\:text-blue-600:hover {
  --text-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--text-opacity));
}

.hover\:text-blue-700:hover {
  --text-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--text-opacity));
}

.hover\:text-blue-800:hover {
  --text-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--text-opacity));
}

.hover\:text-blue-900:hover {
  --text-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--text-opacity));
}

.hover\:text-indigo-50:hover {
  --text-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--text-opacity));
}

.hover\:text-indigo-100:hover {
  --text-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--text-opacity));
}

.hover\:text-indigo-200:hover {
  --text-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--text-opacity));
}

.hover\:text-indigo-300:hover {
  --text-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--text-opacity));
}

.hover\:text-indigo-400:hover {
  --text-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--text-opacity));
}

.hover\:text-indigo-500:hover {
  --text-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--text-opacity));
}

.hover\:text-indigo-600:hover {
  --text-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--text-opacity));
}

.hover\:text-indigo-700:hover {
  --text-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--text-opacity));
}

.hover\:text-indigo-800:hover {
  --text-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--text-opacity));
}

.hover\:text-indigo-900:hover {
  --text-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--text-opacity));
}

.hover\:text-purple-50:hover {
  --text-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--text-opacity));
}

.hover\:text-purple-100:hover {
  --text-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--text-opacity));
}

.hover\:text-purple-200:hover {
  --text-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--text-opacity));
}

.hover\:text-purple-300:hover {
  --text-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--text-opacity));
}

.hover\:text-purple-400:hover {
  --text-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--text-opacity));
}

.hover\:text-purple-500:hover {
  --text-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--text-opacity));
}

.hover\:text-purple-600:hover {
  --text-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--text-opacity));
}

.hover\:text-purple-700:hover {
  --text-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--text-opacity));
}

.hover\:text-purple-800:hover {
  --text-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--text-opacity));
}

.hover\:text-purple-900:hover {
  --text-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--text-opacity));
}

.hover\:text-pink-50:hover {
  --text-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--text-opacity));
}

.hover\:text-pink-100:hover {
  --text-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--text-opacity));
}

.hover\:text-pink-200:hover {
  --text-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--text-opacity));
}

.hover\:text-pink-300:hover {
  --text-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--text-opacity));
}

.hover\:text-pink-400:hover {
  --text-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--text-opacity));
}

.hover\:text-pink-500:hover {
  --text-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--text-opacity));
}

.hover\:text-pink-600:hover {
  --text-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--text-opacity));
}

.hover\:text-pink-700:hover {
  --text-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--text-opacity));
}

.hover\:text-pink-800:hover {
  --text-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--text-opacity));
}

.hover\:text-pink-900:hover {
  --text-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--text-opacity));
}

.active\:text-transparent:active {
  color: transparent;
}

.active\:text-white:active {
  --text-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.active\:text-black:active {
  --text-opacity: 1;
  color: #000000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

.active\:text-gray-50:active {
  --text-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--text-opacity));
}

.active\:text-gray-100:active {
  --text-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--text-opacity));
}

.active\:text-gray-200:active {
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity));
}

.active\:text-gray-300:active {
  --text-opacity: 1;
  color: #d5d6d7;
  color: rgba(213, 214, 215, var(--text-opacity));
}

.active\:text-gray-400:active {
  --text-opacity: 1;
  color: #9e9e9e;
  color: rgba(158, 158, 158, var(--text-opacity));
}

.active\:text-gray-500:active {
  --text-opacity: 1;
  color: #707275;
  color: rgba(112, 114, 117, var(--text-opacity));
}

.active\:text-gray-600:active {
  --text-opacity: 1;
  color: #4c4f52;
  color: rgba(76, 79, 82, var(--text-opacity));
}

.active\:text-gray-700:active {
  --text-opacity: 1;
  color: #24262d;
  color: rgba(36, 38, 45, var(--text-opacity));
}

.active\:text-gray-800:active {
  --text-opacity: 1;
  color: #1a1c23;
  color: rgba(26, 28, 35, var(--text-opacity));
}

.active\:text-gray-900:active {
  --text-opacity: 1;
  color: #121317;
  color: rgba(18, 19, 23, var(--text-opacity));
}

.active\:text-cool-gray-50:active {
  --text-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--text-opacity));
}

.active\:text-cool-gray-100:active {
  --text-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--text-opacity));
}

.active\:text-cool-gray-200:active {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity));
}

.active\:text-cool-gray-300:active {
  --text-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--text-opacity));
}

.active\:text-cool-gray-400:active {
  --text-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--text-opacity));
}

.active\:text-cool-gray-500:active {
  --text-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--text-opacity));
}

.active\:text-cool-gray-600:active {
  --text-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--text-opacity));
}

.active\:text-cool-gray-700:active {
  --text-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--text-opacity));
}

.active\:text-cool-gray-800:active {
  --text-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--text-opacity));
}

.active\:text-cool-gray-900:active {
  --text-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--text-opacity));
}

.active\:text-red-50:active {
  --text-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--text-opacity));
}

.active\:text-red-100:active {
  --text-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--text-opacity));
}

.active\:text-red-200:active {
  --text-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--text-opacity));
}

.active\:text-red-300:active {
  --text-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--text-opacity));
}

.active\:text-red-400:active {
  --text-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--text-opacity));
}

.active\:text-red-500:active {
  --text-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--text-opacity));
}

.active\:text-red-600:active {
  --text-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--text-opacity));
}

.active\:text-red-700:active {
  --text-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--text-opacity));
}

.active\:text-red-800:active {
  --text-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--text-opacity));
}

.active\:text-red-900:active {
  --text-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--text-opacity));
}

.active\:text-orange-50:active {
  --text-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--text-opacity));
}

.active\:text-orange-100:active {
  --text-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--text-opacity));
}

.active\:text-orange-200:active {
  --text-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--text-opacity));
}

.active\:text-orange-300:active {
  --text-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--text-opacity));
}

.active\:text-orange-400:active {
  --text-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--text-opacity));
}

.active\:text-orange-500:active {
  --text-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--text-opacity));
}

.active\:text-orange-600:active {
  --text-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--text-opacity));
}

.active\:text-orange-700:active {
  --text-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--text-opacity));
}

.active\:text-orange-800:active {
  --text-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--text-opacity));
}

.active\:text-orange-900:active {
  --text-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--text-opacity));
}

.active\:text-yellow-50:active {
  --text-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--text-opacity));
}

.active\:text-yellow-100:active {
  --text-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--text-opacity));
}

.active\:text-yellow-200:active {
  --text-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--text-opacity));
}

.active\:text-yellow-300:active {
  --text-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--text-opacity));
}

.active\:text-yellow-400:active {
  --text-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--text-opacity));
}

.active\:text-yellow-500:active {
  --text-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--text-opacity));
}

.active\:text-yellow-600:active {
  --text-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--text-opacity));
}

.active\:text-yellow-700:active {
  --text-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--text-opacity));
}

.active\:text-yellow-800:active {
  --text-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--text-opacity));
}

.active\:text-yellow-900:active {
  --text-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--text-opacity));
}

.active\:text-green-50:active {
  --text-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--text-opacity));
}

.active\:text-green-100:active {
  --text-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--text-opacity));
}

.active\:text-green-200:active {
  --text-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--text-opacity));
}

.active\:text-green-300:active {
  --text-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--text-opacity));
}

.active\:text-green-400:active {
  --text-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--text-opacity));
}

.active\:text-green-500:active {
  --text-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--text-opacity));
}

.active\:text-green-600:active {
  --text-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--text-opacity));
}

.active\:text-green-700:active {
  --text-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--text-opacity));
}

.active\:text-green-800:active {
  --text-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--text-opacity));
}

.active\:text-green-900:active {
  --text-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--text-opacity));
}

.active\:text-teal-50:active {
  --text-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--text-opacity));
}

.active\:text-teal-100:active {
  --text-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--text-opacity));
}

.active\:text-teal-200:active {
  --text-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--text-opacity));
}

.active\:text-teal-300:active {
  --text-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--text-opacity));
}

.active\:text-teal-400:active {
  --text-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--text-opacity));
}

.active\:text-teal-500:active {
  --text-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--text-opacity));
}

.active\:text-teal-600:active {
  --text-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--text-opacity));
}

.active\:text-teal-700:active {
  --text-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--text-opacity));
}

.active\:text-teal-800:active {
  --text-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--text-opacity));
}

.active\:text-teal-900:active {
  --text-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--text-opacity));
}

.active\:text-blue-50:active {
  --text-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--text-opacity));
}

.active\:text-blue-100:active {
  --text-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--text-opacity));
}

.active\:text-blue-200:active {
  --text-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--text-opacity));
}

.active\:text-blue-300:active {
  --text-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--text-opacity));
}

.active\:text-blue-400:active {
  --text-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--text-opacity));
}

.active\:text-blue-500:active {
  --text-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--text-opacity));
}

.active\:text-blue-600:active {
  --text-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--text-opacity));
}

.active\:text-blue-700:active {
  --text-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--text-opacity));
}

.active\:text-blue-800:active {
  --text-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--text-opacity));
}

.active\:text-blue-900:active {
  --text-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--text-opacity));
}

.active\:text-indigo-50:active {
  --text-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--text-opacity));
}

.active\:text-indigo-100:active {
  --text-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--text-opacity));
}

.active\:text-indigo-200:active {
  --text-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--text-opacity));
}

.active\:text-indigo-300:active {
  --text-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--text-opacity));
}

.active\:text-indigo-400:active {
  --text-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--text-opacity));
}

.active\:text-indigo-500:active {
  --text-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--text-opacity));
}

.active\:text-indigo-600:active {
  --text-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--text-opacity));
}

.active\:text-indigo-700:active {
  --text-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--text-opacity));
}

.active\:text-indigo-800:active {
  --text-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--text-opacity));
}

.active\:text-indigo-900:active {
  --text-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--text-opacity));
}

.active\:text-purple-50:active {
  --text-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--text-opacity));
}

.active\:text-purple-100:active {
  --text-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--text-opacity));
}

.active\:text-purple-200:active {
  --text-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--text-opacity));
}

.active\:text-purple-300:active {
  --text-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--text-opacity));
}

.active\:text-purple-400:active {
  --text-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--text-opacity));
}

.active\:text-purple-500:active {
  --text-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--text-opacity));
}

.active\:text-purple-600:active {
  --text-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--text-opacity));
}

.active\:text-purple-700:active {
  --text-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--text-opacity));
}

.active\:text-purple-800:active {
  --text-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--text-opacity));
}

.active\:text-purple-900:active {
  --text-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--text-opacity));
}

.active\:text-pink-50:active {
  --text-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--text-opacity));
}

.active\:text-pink-100:active {
  --text-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--text-opacity));
}

.active\:text-pink-200:active {
  --text-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--text-opacity));
}

.active\:text-pink-300:active {
  --text-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--text-opacity));
}

.active\:text-pink-400:active {
  --text-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--text-opacity));
}

.active\:text-pink-500:active {
  --text-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--text-opacity));
}

.active\:text-pink-600:active {
  --text-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--text-opacity));
}

.active\:text-pink-700:active {
  --text-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--text-opacity));
}

.active\:text-pink-800:active {
  --text-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--text-opacity));
}

.active\:text-pink-900:active {
  --text-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--text-opacity));
}

.text-opacity-0 {
  --text-opacity: 0;
}

.text-opacity-25 {
  --text-opacity: 0.25;
}

.text-opacity-50 {
  --text-opacity: 0.5;
}

.text-opacity-75 {
  --text-opacity: 0.75;
}

.text-opacity-100 {
  --text-opacity: 1;
}

.hover\:text-opacity-0:hover {
  --text-opacity: 0;
}

.hover\:text-opacity-25:hover {
  --text-opacity: 0.25;
}

.hover\:text-opacity-50:hover {
  --text-opacity: 0.5;
}

.hover\:text-opacity-75:hover {
  --text-opacity: 0.75;
}

.hover\:text-opacity-100:hover {
  --text-opacity: 1;
}

.focus\:text-opacity-0:focus {
  --text-opacity: 0;
}

.focus\:text-opacity-25:focus {
  --text-opacity: 0.25;
}

.focus\:text-opacity-50:focus {
  --text-opacity: 0.5;
}

.focus\:text-opacity-75:focus {
  --text-opacity: 0.75;
}

.focus\:text-opacity-100:focus {
  --text-opacity: 1;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.no-underline {
  text-decoration: none;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.hover\:line-through:hover {
  text-decoration: line-through;
}

.hover\:no-underline:hover {
  text-decoration: none;
}

.focus\:underline:focus {
  text-decoration: underline;
}

.focus\:line-through:focus {
  text-decoration: line-through;
}

.focus\:no-underline:focus {
  text-decoration: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.tracking-tighter {
  letter-spacing: -0.05em;
}

.tracking-tight {
  letter-spacing: -0.025em;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.tracking-wider {
  letter-spacing: 0.05em;
}

.tracking-widest {
  letter-spacing: 0.1em;
}

.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select-text {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.select-all {
  -webkit-user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  user-select: all;
}

.select-auto {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-no-wrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.w-0 {
  width: 0;
}

.w-1 {
  width: 0.25rem;
}

.w-2 {
  width: 0.5rem;
}

.w-3 {
  width: 0.75rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-32 {
  width: 8rem;
}

.w-40 {
  width: 10rem;
}

.w-48 {
  width: 12rem;
}

.w-56 {
  width: 14rem;
}

.w-64 {
  width: 16rem;
}

.w-auto {
  width: auto;
}

.w-px {
  width: 1px;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-2\/3 {
  width: 66.666667%;
}

.w-1\/4 {
  width: 25%;
}

.w-2\/4 {
  width: 50%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-1\/6 {
  width: 16.666667%;
}

.w-2\/6 {
  width: 33.333333%;
}

.w-3\/6 {
  width: 50%;
}

.w-4\/6 {
  width: 66.666667%;
}

.w-5\/6 {
  width: 83.333333%;
}

.w-1\/12 {
  width: 8.333333%;
}

.w-2\/12 {
  width: 16.666667%;
}

.w-3\/12 {
  width: 25%;
}

.w-4\/12 {
  width: 33.333333%;
}

.w-5\/12 {
  width: 41.666667%;
}

.w-6\/12 {
  width: 50%;
}

.w-7\/12 {
  width: 58.333333%;
}

.w-8\/12 {
  width: 66.666667%;
}

.w-9\/12 {
  width: 75%;
}

.w-10\/12 {
  width: 83.333333%;
}

.w-11\/12 {
  width: 91.666667%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-auto {
  z-index: auto;
}

.gap-0 {
  grid-gap: 0;
  gap: 0;
}

.gap-1 {
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.gap-3 {
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.gap-4 {
  grid-gap: 1rem;
  gap: 1rem;
}

.gap-5 {
  grid-gap: 1.25rem;
  gap: 1.25rem;
}

.gap-6 {
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.gap-8 {
  grid-gap: 2rem;
  gap: 2rem;
}

.gap-10 {
  grid-gap: 2.5rem;
  gap: 2.5rem;
}

.gap-12 {
  grid-gap: 3rem;
  gap: 3rem;
}

.gap-16 {
  grid-gap: 4rem;
  gap: 4rem;
}

.gap-20 {
  grid-gap: 5rem;
  gap: 5rem;
}

.gap-24 {
  grid-gap: 6rem;
  gap: 6rem;
}

.gap-32 {
  grid-gap: 8rem;
  gap: 8rem;
}

.gap-40 {
  grid-gap: 10rem;
  gap: 10rem;
}

.gap-48 {
  grid-gap: 12rem;
  gap: 12rem;
}

.gap-56 {
  grid-gap: 14rem;
  gap: 14rem;
}

.gap-64 {
  grid-gap: 16rem;
  gap: 16rem;
}

.gap-px {
  grid-gap: 1px;
  gap: 1px;
}

.col-gap-0 {
  grid-column-gap: 0;
  -webkit-column-gap: 0;
  column-gap: 0;
}

.col-gap-1 {
  grid-column-gap: 0.25rem;
  -webkit-column-gap: 0.25rem;
  column-gap: 0.25rem;
}

.col-gap-2 {
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
  column-gap: 0.5rem;
}

.col-gap-3 {
  grid-column-gap: 0.75rem;
  -webkit-column-gap: 0.75rem;
  column-gap: 0.75rem;
}

.col-gap-4 {
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
  column-gap: 1rem;
}

.col-gap-5 {
  grid-column-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
  column-gap: 1.25rem;
}

.col-gap-6 {
  grid-column-gap: 1.5rem;
  -webkit-column-gap: 1.5rem;
  column-gap: 1.5rem;
}

.col-gap-8 {
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
  column-gap: 2rem;
}

.col-gap-10 {
  grid-column-gap: 2.5rem;
  -webkit-column-gap: 2.5rem;
  column-gap: 2.5rem;
}

.col-gap-12 {
  grid-column-gap: 3rem;
  -webkit-column-gap: 3rem;
  column-gap: 3rem;
}

.col-gap-16 {
  grid-column-gap: 4rem;
  -webkit-column-gap: 4rem;
  column-gap: 4rem;
}

.col-gap-20 {
  grid-column-gap: 5rem;
  -webkit-column-gap: 5rem;
  column-gap: 5rem;
}

.col-gap-24 {
  grid-column-gap: 6rem;
  -webkit-column-gap: 6rem;
  column-gap: 6rem;
}

.col-gap-32 {
  grid-column-gap: 8rem;
  -webkit-column-gap: 8rem;
  column-gap: 8rem;
}

.col-gap-40 {
  grid-column-gap: 10rem;
  -webkit-column-gap: 10rem;
  column-gap: 10rem;
}

.col-gap-48 {
  grid-column-gap: 12rem;
  -webkit-column-gap: 12rem;
  column-gap: 12rem;
}

.col-gap-56 {
  grid-column-gap: 14rem;
  -webkit-column-gap: 14rem;
  column-gap: 14rem;
}

.col-gap-64 {
  grid-column-gap: 16rem;
  -webkit-column-gap: 16rem;
  column-gap: 16rem;
}

.col-gap-px {
  grid-column-gap: 1px;
  -webkit-column-gap: 1px;
  column-gap: 1px;
}

.row-gap-0 {
  grid-row-gap: 0;
  row-gap: 0;
}

.row-gap-1 {
  grid-row-gap: 0.25rem;
  row-gap: 0.25rem;
}

.row-gap-2 {
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}

.row-gap-3 {
  grid-row-gap: 0.75rem;
  row-gap: 0.75rem;
}

.row-gap-4 {
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.row-gap-5 {
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem;
}

.row-gap-6 {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
}

.row-gap-8 {
  grid-row-gap: 2rem;
  row-gap: 2rem;
}

.row-gap-10 {
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem;
}

.row-gap-12 {
  grid-row-gap: 3rem;
  row-gap: 3rem;
}

.row-gap-16 {
  grid-row-gap: 4rem;
  row-gap: 4rem;
}

.row-gap-20 {
  grid-row-gap: 5rem;
  row-gap: 5rem;
}

.row-gap-24 {
  grid-row-gap: 6rem;
  row-gap: 6rem;
}

.row-gap-32 {
  grid-row-gap: 8rem;
  row-gap: 8rem;
}

.row-gap-40 {
  grid-row-gap: 10rem;
  row-gap: 10rem;
}

.row-gap-48 {
  grid-row-gap: 12rem;
  row-gap: 12rem;
}

.row-gap-56 {
  grid-row-gap: 14rem;
  row-gap: 14rem;
}

.row-gap-64 {
  grid-row-gap: 16rem;
  row-gap: 16rem;
}

.row-gap-px {
  grid-row-gap: 1px;
  row-gap: 1px;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.grid-flow-col-dense {
  grid-auto-flow: column dense;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-none {
  grid-template-columns: none;
}

.col-auto {
  grid-column: auto;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-start-7 {
  grid-column-start: 7;
}

.col-start-8 {
  grid-column-start: 8;
}

.col-start-9 {
  grid-column-start: 9;
}

.col-start-10 {
  grid-column-start: 10;
}

.col-start-11 {
  grid-column-start: 11;
}

.col-start-12 {
  grid-column-start: 12;
}

.col-start-13 {
  grid-column-start: 13;
}

.col-start-auto {
  grid-column-start: auto;
}

.col-end-1 {
  grid-column-end: 1;
}

.col-end-2 {
  grid-column-end: 2;
}

.col-end-3 {
  grid-column-end: 3;
}

.col-end-4 {
  grid-column-end: 4;
}

.col-end-5 {
  grid-column-end: 5;
}

.col-end-6 {
  grid-column-end: 6;
}

.col-end-7 {
  grid-column-end: 7;
}

.col-end-8 {
  grid-column-end: 8;
}

.col-end-9 {
  grid-column-end: 9;
}

.col-end-10 {
  grid-column-end: 10;
}

.col-end-11 {
  grid-column-end: 11;
}

.col-end-12 {
  grid-column-end: 12;
}

.col-end-13 {
  grid-column-end: 13;
}

.col-end-auto {
  grid-column-end: auto;
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.grid-rows-none {
  grid-template-rows: none;
}

.row-auto {
  grid-row: auto;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-span-4 {
  grid-row: span 4 / span 4;
}

.row-span-5 {
  grid-row: span 5 / span 5;
}

.row-span-6 {
  grid-row: span 6 / span 6;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-4 {
  grid-row-start: 4;
}

.row-start-5 {
  grid-row-start: 5;
}

.row-start-6 {
  grid-row-start: 6;
}

.row-start-7 {
  grid-row-start: 7;
}

.row-start-auto {
  grid-row-start: auto;
}

.row-end-1 {
  grid-row-end: 1;
}

.row-end-2 {
  grid-row-end: 2;
}

.row-end-3 {
  grid-row-end: 3;
}

.row-end-4 {
  grid-row-end: 4;
}

.row-end-5 {
  grid-row-end: 5;
}

.row-end-6 {
  grid-row-end: 6;
}

.row-end-7 {
  grid-row-end: 7;
}

.row-end-auto {
  grid-row-end: auto;
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x))
    translateY(var(--transform-translate-y)) rotate(var(--transform-rotate))
    skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y))
    scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

.transform-none {
  transform: none;
}

.origin-center {
  transform-origin: center;
}

.origin-top {
  transform-origin: top;
}

.origin-top-right {
  transform-origin: top right;
}

.origin-right {
  transform-origin: right;
}

.origin-bottom-right {
  transform-origin: bottom right;
}

.origin-bottom {
  transform-origin: bottom;
}

.origin-bottom-left {
  transform-origin: bottom left;
}

.origin-left {
  transform-origin: left;
}

.origin-top-left {
  transform-origin: top left;
}

.scale-0 {
  --transform-scale-x: 0;
  --transform-scale-y: 0;
}

.scale-50 {
  --transform-scale-x: 0.5;
  --transform-scale-y: 0.5;
}

.scale-75 {
  --transform-scale-x: 0.75;
  --transform-scale-y: 0.75;
}

.scale-90 {
  --transform-scale-x: 0.9;
  --transform-scale-y: 0.9;
}

.scale-95 {
  --transform-scale-x: 0.95;
  --transform-scale-y: 0.95;
}

.scale-100 {
  --transform-scale-x: 1;
  --transform-scale-y: 1;
}

.scale-105 {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05;
}

.scale-110 {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1;
}

.scale-125 {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25;
}

.scale-150 {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5;
}

.scale-x-0 {
  --transform-scale-x: 0;
}

.scale-x-50 {
  --transform-scale-x: 0.5;
}

.scale-x-75 {
  --transform-scale-x: 0.75;
}

.scale-x-90 {
  --transform-scale-x: 0.9;
}

.scale-x-95 {
  --transform-scale-x: 0.95;
}

.scale-x-100 {
  --transform-scale-x: 1;
}

.scale-x-105 {
  --transform-scale-x: 1.05;
}

.scale-x-110 {
  --transform-scale-x: 1.1;
}

.scale-x-125 {
  --transform-scale-x: 1.25;
}

.scale-x-150 {
  --transform-scale-x: 1.5;
}

.scale-y-0 {
  --transform-scale-y: 0;
}

.scale-y-50 {
  --transform-scale-y: 0.5;
}

.scale-y-75 {
  --transform-scale-y: 0.75;
}

.scale-y-90 {
  --transform-scale-y: 0.9;
}

.scale-y-95 {
  --transform-scale-y: 0.95;
}

.scale-y-100 {
  --transform-scale-y: 1;
}

.scale-y-105 {
  --transform-scale-y: 1.05;
}

.scale-y-110 {
  --transform-scale-y: 1.1;
}

.scale-y-125 {
  --transform-scale-y: 1.25;
}

.scale-y-150 {
  --transform-scale-y: 1.5;
}

.hover\:scale-0:hover {
  --transform-scale-x: 0;
  --transform-scale-y: 0;
}

.hover\:scale-50:hover {
  --transform-scale-x: 0.5;
  --transform-scale-y: 0.5;
}

.hover\:scale-75:hover {
  --transform-scale-x: 0.75;
  --transform-scale-y: 0.75;
}

.hover\:scale-90:hover {
  --transform-scale-x: 0.9;
  --transform-scale-y: 0.9;
}

.hover\:scale-95:hover {
  --transform-scale-x: 0.95;
  --transform-scale-y: 0.95;
}

.hover\:scale-100:hover {
  --transform-scale-x: 1;
  --transform-scale-y: 1;
}

.hover\:scale-105:hover {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05;
}

.hover\:scale-110:hover {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1;
}

.hover\:scale-125:hover {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25;
}

.hover\:scale-150:hover {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5;
}

.hover\:scale-x-0:hover {
  --transform-scale-x: 0;
}

.hover\:scale-x-50:hover {
  --transform-scale-x: 0.5;
}

.hover\:scale-x-75:hover {
  --transform-scale-x: 0.75;
}

.hover\:scale-x-90:hover {
  --transform-scale-x: 0.9;
}

.hover\:scale-x-95:hover {
  --transform-scale-x: 0.95;
}

.hover\:scale-x-100:hover {
  --transform-scale-x: 1;
}

.hover\:scale-x-105:hover {
  --transform-scale-x: 1.05;
}

.hover\:scale-x-110:hover {
  --transform-scale-x: 1.1;
}

.hover\:scale-x-125:hover {
  --transform-scale-x: 1.25;
}

.hover\:scale-x-150:hover {
  --transform-scale-x: 1.5;
}

.hover\:scale-y-0:hover {
  --transform-scale-y: 0;
}

.hover\:scale-y-50:hover {
  --transform-scale-y: 0.5;
}

.hover\:scale-y-75:hover {
  --transform-scale-y: 0.75;
}

.hover\:scale-y-90:hover {
  --transform-scale-y: 0.9;
}

.hover\:scale-y-95:hover {
  --transform-scale-y: 0.95;
}

.hover\:scale-y-100:hover {
  --transform-scale-y: 1;
}

.hover\:scale-y-105:hover {
  --transform-scale-y: 1.05;
}

.hover\:scale-y-110:hover {
  --transform-scale-y: 1.1;
}

.hover\:scale-y-125:hover {
  --transform-scale-y: 1.25;
}

.hover\:scale-y-150:hover {
  --transform-scale-y: 1.5;
}

.focus\:scale-0:focus {
  --transform-scale-x: 0;
  --transform-scale-y: 0;
}

.focus\:scale-50:focus {
  --transform-scale-x: 0.5;
  --transform-scale-y: 0.5;
}

.focus\:scale-75:focus {
  --transform-scale-x: 0.75;
  --transform-scale-y: 0.75;
}

.focus\:scale-90:focus {
  --transform-scale-x: 0.9;
  --transform-scale-y: 0.9;
}

.focus\:scale-95:focus {
  --transform-scale-x: 0.95;
  --transform-scale-y: 0.95;
}

.focus\:scale-100:focus {
  --transform-scale-x: 1;
  --transform-scale-y: 1;
}

.focus\:scale-105:focus {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05;
}

.focus\:scale-110:focus {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1;
}

.focus\:scale-125:focus {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25;
}

.focus\:scale-150:focus {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5;
}

.focus\:scale-x-0:focus {
  --transform-scale-x: 0;
}

.focus\:scale-x-50:focus {
  --transform-scale-x: 0.5;
}

.focus\:scale-x-75:focus {
  --transform-scale-x: 0.75;
}

.focus\:scale-x-90:focus {
  --transform-scale-x: 0.9;
}

.focus\:scale-x-95:focus {
  --transform-scale-x: 0.95;
}

.focus\:scale-x-100:focus {
  --transform-scale-x: 1;
}

.focus\:scale-x-105:focus {
  --transform-scale-x: 1.05;
}

.focus\:scale-x-110:focus {
  --transform-scale-x: 1.1;
}

.focus\:scale-x-125:focus {
  --transform-scale-x: 1.25;
}

.focus\:scale-x-150:focus {
  --transform-scale-x: 1.5;
}

.focus\:scale-y-0:focus {
  --transform-scale-y: 0;
}

.focus\:scale-y-50:focus {
  --transform-scale-y: 0.5;
}

.focus\:scale-y-75:focus {
  --transform-scale-y: 0.75;
}

.focus\:scale-y-90:focus {
  --transform-scale-y: 0.9;
}

.focus\:scale-y-95:focus {
  --transform-scale-y: 0.95;
}

.focus\:scale-y-100:focus {
  --transform-scale-y: 1;
}

.focus\:scale-y-105:focus {
  --transform-scale-y: 1.05;
}

.focus\:scale-y-110:focus {
  --transform-scale-y: 1.1;
}

.focus\:scale-y-125:focus {
  --transform-scale-y: 1.25;
}

.focus\:scale-y-150:focus {
  --transform-scale-y: 1.5;
}

.rotate-0 {
  --transform-rotate: 0;
}

.rotate-45 {
  --transform-rotate: 45deg;
}

.rotate-90 {
  --transform-rotate: 90deg;
}

.rotate-180 {
  --transform-rotate: 180deg;
}

.-rotate-180 {
  --transform-rotate: -180deg;
}

.-rotate-90 {
  --transform-rotate: -90deg;
}

.-rotate-45 {
  --transform-rotate: -45deg;
}

.hover\:rotate-0:hover {
  --transform-rotate: 0;
}

.hover\:rotate-45:hover {
  --transform-rotate: 45deg;
}

.hover\:rotate-90:hover {
  --transform-rotate: 90deg;
}

.hover\:rotate-180:hover {
  --transform-rotate: 180deg;
}

.hover\:-rotate-180:hover {
  --transform-rotate: -180deg;
}

.hover\:-rotate-90:hover {
  --transform-rotate: -90deg;
}

.hover\:-rotate-45:hover {
  --transform-rotate: -45deg;
}

.focus\:rotate-0:focus {
  --transform-rotate: 0;
}

.focus\:rotate-45:focus {
  --transform-rotate: 45deg;
}

.focus\:rotate-90:focus {
  --transform-rotate: 90deg;
}

.focus\:rotate-180:focus {
  --transform-rotate: 180deg;
}

.focus\:-rotate-180:focus {
  --transform-rotate: -180deg;
}

.focus\:-rotate-90:focus {
  --transform-rotate: -90deg;
}

.focus\:-rotate-45:focus {
  --transform-rotate: -45deg;
}

.translate-x-0 {
  --transform-translate-x: 0;
}

.translate-x-1 {
  --transform-translate-x: 0.25rem;
}

.translate-x-2 {
  --transform-translate-x: 0.5rem;
}

.translate-x-3 {
  --transform-translate-x: 0.75rem;
}

.translate-x-4 {
  --transform-translate-x: 1rem;
}

.translate-x-5 {
  --transform-translate-x: 1.25rem;
}

.translate-x-6 {
  --transform-translate-x: 1.5rem;
}

.translate-x-8 {
  --transform-translate-x: 2rem;
}

.translate-x-10 {
  --transform-translate-x: 2.5rem;
}

.translate-x-12 {
  --transform-translate-x: 3rem;
}

.translate-x-16 {
  --transform-translate-x: 4rem;
}

.translate-x-20 {
  --transform-translate-x: 5rem;
}

.translate-x-24 {
  --transform-translate-x: 6rem;
}

.translate-x-32 {
  --transform-translate-x: 8rem;
}

.translate-x-40 {
  --transform-translate-x: 10rem;
}

.translate-x-48 {
  --transform-translate-x: 12rem;
}

.translate-x-56 {
  --transform-translate-x: 14rem;
}

.translate-x-64 {
  --transform-translate-x: 16rem;
}

.translate-x-px {
  --transform-translate-x: 1px;
}

.-translate-x-1 {
  --transform-translate-x: -0.25rem;
}

.-translate-x-2 {
  --transform-translate-x: -0.5rem;
}

.-translate-x-3 {
  --transform-translate-x: -0.75rem;
}

.-translate-x-4 {
  --transform-translate-x: -1rem;
}

.-translate-x-5 {
  --transform-translate-x: -1.25rem;
}

.-translate-x-6 {
  --transform-translate-x: -1.5rem;
}

.-translate-x-8 {
  --transform-translate-x: -2rem;
}

.-translate-x-10 {
  --transform-translate-x: -2.5rem;
}

.-translate-x-12 {
  --transform-translate-x: -3rem;
}

.-translate-x-16 {
  --transform-translate-x: -4rem;
}

.-translate-x-20 {
  --transform-translate-x: -5rem;
}

.-translate-x-24 {
  --transform-translate-x: -6rem;
}

.-translate-x-32 {
  --transform-translate-x: -8rem;
}

.-translate-x-40 {
  --transform-translate-x: -10rem;
}

.-translate-x-48 {
  --transform-translate-x: -12rem;
}

.-translate-x-56 {
  --transform-translate-x: -14rem;
}

.-translate-x-64 {
  --transform-translate-x: -16rem;
}

.-translate-x-px {
  --transform-translate-x: -1px;
}

.-translate-x-full {
  --transform-translate-x: -100%;
}

.-translate-x-1\/2 {
  --transform-translate-x: -50%;
}

.translate-x-1\/2 {
  --transform-translate-x: 50%;
}

.translate-x-full {
  --transform-translate-x: 100%;
}

.translate-y-0 {
  --transform-translate-y: 0;
}

.translate-y-1 {
  --transform-translate-y: 0.25rem;
}

.translate-y-2 {
  --transform-translate-y: 0.5rem;
}

.translate-y-3 {
  --transform-translate-y: 0.75rem;
}

.translate-y-4 {
  --transform-translate-y: 1rem;
}

.translate-y-5 {
  --transform-translate-y: 1.25rem;
}

.translate-y-6 {
  --transform-translate-y: 1.5rem;
}

.translate-y-8 {
  --transform-translate-y: 2rem;
}

.translate-y-10 {
  --transform-translate-y: 2.5rem;
}

.translate-y-12 {
  --transform-translate-y: 3rem;
}

.translate-y-16 {
  --transform-translate-y: 4rem;
}

.translate-y-20 {
  --transform-translate-y: 5rem;
}

.translate-y-24 {
  --transform-translate-y: 6rem;
}

.translate-y-32 {
  --transform-translate-y: 8rem;
}

.translate-y-40 {
  --transform-translate-y: 10rem;
}

.translate-y-48 {
  --transform-translate-y: 12rem;
}

.translate-y-56 {
  --transform-translate-y: 14rem;
}

.translate-y-64 {
  --transform-translate-y: 16rem;
}

.translate-y-px {
  --transform-translate-y: 1px;
}

.-translate-y-1 {
  --transform-translate-y: -0.25rem;
}

.-translate-y-2 {
  --transform-translate-y: -0.5rem;
}

.-translate-y-3 {
  --transform-translate-y: -0.75rem;
}

.-translate-y-4 {
  --transform-translate-y: -1rem;
}

.-translate-y-5 {
  --transform-translate-y: -1.25rem;
}

.-translate-y-6 {
  --transform-translate-y: -1.5rem;
}

.-translate-y-8 {
  --transform-translate-y: -2rem;
}

.-translate-y-10 {
  --transform-translate-y: -2.5rem;
}

.-translate-y-12 {
  --transform-translate-y: -3rem;
}

.-translate-y-16 {
  --transform-translate-y: -4rem;
}

.-translate-y-20 {
  --transform-translate-y: -5rem;
}

.-translate-y-24 {
  --transform-translate-y: -6rem;
}

.-translate-y-32 {
  --transform-translate-y: -8rem;
}

.-translate-y-40 {
  --transform-translate-y: -10rem;
}

.-translate-y-48 {
  --transform-translate-y: -12rem;
}

.-translate-y-56 {
  --transform-translate-y: -14rem;
}

.-translate-y-64 {
  --transform-translate-y: -16rem;
}

.-translate-y-px {
  --transform-translate-y: -1px;
}

.-translate-y-full {
  --transform-translate-y: -100%;
}

.-translate-y-1\/2 {
  --transform-translate-y: -50%;
}

.translate-y-1\/2 {
  --transform-translate-y: 50%;
}

.translate-y-full {
  --transform-translate-y: 100%;
}

.hover\:translate-x-0:hover {
  --transform-translate-x: 0;
}

.hover\:translate-x-1:hover {
  --transform-translate-x: 0.25rem;
}

.hover\:translate-x-2:hover {
  --transform-translate-x: 0.5rem;
}

.hover\:translate-x-3:hover {
  --transform-translate-x: 0.75rem;
}

.hover\:translate-x-4:hover {
  --transform-translate-x: 1rem;
}

.hover\:translate-x-5:hover {
  --transform-translate-x: 1.25rem;
}

.hover\:translate-x-6:hover {
  --transform-translate-x: 1.5rem;
}

.hover\:translate-x-8:hover {
  --transform-translate-x: 2rem;
}

.hover\:translate-x-10:hover {
  --transform-translate-x: 2.5rem;
}

.hover\:translate-x-12:hover {
  --transform-translate-x: 3rem;
}

.hover\:translate-x-16:hover {
  --transform-translate-x: 4rem;
}

.hover\:translate-x-20:hover {
  --transform-translate-x: 5rem;
}

.hover\:translate-x-24:hover {
  --transform-translate-x: 6rem;
}

.hover\:translate-x-32:hover {
  --transform-translate-x: 8rem;
}

.hover\:translate-x-40:hover {
  --transform-translate-x: 10rem;
}

.hover\:translate-x-48:hover {
  --transform-translate-x: 12rem;
}

.hover\:translate-x-56:hover {
  --transform-translate-x: 14rem;
}

.hover\:translate-x-64:hover {
  --transform-translate-x: 16rem;
}

.hover\:translate-x-px:hover {
  --transform-translate-x: 1px;
}

.hover\:-translate-x-1:hover {
  --transform-translate-x: -0.25rem;
}

.hover\:-translate-x-2:hover {
  --transform-translate-x: -0.5rem;
}

.hover\:-translate-x-3:hover {
  --transform-translate-x: -0.75rem;
}

.hover\:-translate-x-4:hover {
  --transform-translate-x: -1rem;
}

.hover\:-translate-x-5:hover {
  --transform-translate-x: -1.25rem;
}

.hover\:-translate-x-6:hover {
  --transform-translate-x: -1.5rem;
}

.hover\:-translate-x-8:hover {
  --transform-translate-x: -2rem;
}

.hover\:-translate-x-10:hover {
  --transform-translate-x: -2.5rem;
}

.hover\:-translate-x-12:hover {
  --transform-translate-x: -3rem;
}

.hover\:-translate-x-16:hover {
  --transform-translate-x: -4rem;
}

.hover\:-translate-x-20:hover {
  --transform-translate-x: -5rem;
}

.hover\:-translate-x-24:hover {
  --transform-translate-x: -6rem;
}

.hover\:-translate-x-32:hover {
  --transform-translate-x: -8rem;
}

.hover\:-translate-x-40:hover {
  --transform-translate-x: -10rem;
}

.hover\:-translate-x-48:hover {
  --transform-translate-x: -12rem;
}

.hover\:-translate-x-56:hover {
  --transform-translate-x: -14rem;
}

.hover\:-translate-x-64:hover {
  --transform-translate-x: -16rem;
}

.hover\:-translate-x-px:hover {
  --transform-translate-x: -1px;
}

.hover\:-translate-x-full:hover {
  --transform-translate-x: -100%;
}

.hover\:-translate-x-1\/2:hover {
  --transform-translate-x: -50%;
}

.hover\:translate-x-1\/2:hover {
  --transform-translate-x: 50%;
}

.hover\:translate-x-full:hover {
  --transform-translate-x: 100%;
}

.hover\:translate-y-0:hover {
  --transform-translate-y: 0;
}

.hover\:translate-y-1:hover {
  --transform-translate-y: 0.25rem;
}

.hover\:translate-y-2:hover {
  --transform-translate-y: 0.5rem;
}

.hover\:translate-y-3:hover {
  --transform-translate-y: 0.75rem;
}

.hover\:translate-y-4:hover {
  --transform-translate-y: 1rem;
}

.hover\:translate-y-5:hover {
  --transform-translate-y: 1.25rem;
}

.hover\:translate-y-6:hover {
  --transform-translate-y: 1.5rem;
}

.hover\:translate-y-8:hover {
  --transform-translate-y: 2rem;
}

.hover\:translate-y-10:hover {
  --transform-translate-y: 2.5rem;
}

.hover\:translate-y-12:hover {
  --transform-translate-y: 3rem;
}

.hover\:translate-y-16:hover {
  --transform-translate-y: 4rem;
}

.hover\:translate-y-20:hover {
  --transform-translate-y: 5rem;
}

.hover\:translate-y-24:hover {
  --transform-translate-y: 6rem;
}

.hover\:translate-y-32:hover {
  --transform-translate-y: 8rem;
}

.hover\:translate-y-40:hover {
  --transform-translate-y: 10rem;
}

.hover\:translate-y-48:hover {
  --transform-translate-y: 12rem;
}

.hover\:translate-y-56:hover {
  --transform-translate-y: 14rem;
}

.hover\:translate-y-64:hover {
  --transform-translate-y: 16rem;
}

.hover\:translate-y-px:hover {
  --transform-translate-y: 1px;
}

.hover\:-translate-y-1:hover {
  --transform-translate-y: -0.25rem;
}

.hover\:-translate-y-2:hover {
  --transform-translate-y: -0.5rem;
}

.hover\:-translate-y-3:hover {
  --transform-translate-y: -0.75rem;
}

.hover\:-translate-y-4:hover {
  --transform-translate-y: -1rem;
}

.hover\:-translate-y-5:hover {
  --transform-translate-y: -1.25rem;
}

.hover\:-translate-y-6:hover {
  --transform-translate-y: -1.5rem;
}

.hover\:-translate-y-8:hover {
  --transform-translate-y: -2rem;
}

.hover\:-translate-y-10:hover {
  --transform-translate-y: -2.5rem;
}

.hover\:-translate-y-12:hover {
  --transform-translate-y: -3rem;
}

.hover\:-translate-y-16:hover {
  --transform-translate-y: -4rem;
}

.hover\:-translate-y-20:hover {
  --transform-translate-y: -5rem;
}

.hover\:-translate-y-24:hover {
  --transform-translate-y: -6rem;
}

.hover\:-translate-y-32:hover {
  --transform-translate-y: -8rem;
}

.hover\:-translate-y-40:hover {
  --transform-translate-y: -10rem;
}

.hover\:-translate-y-48:hover {
  --transform-translate-y: -12rem;
}

.hover\:-translate-y-56:hover {
  --transform-translate-y: -14rem;
}

.hover\:-translate-y-64:hover {
  --transform-translate-y: -16rem;
}

.hover\:-translate-y-px:hover {
  --transform-translate-y: -1px;
}

.hover\:-translate-y-full:hover {
  --transform-translate-y: -100%;
}

.hover\:-translate-y-1\/2:hover {
  --transform-translate-y: -50%;
}

.hover\:translate-y-1\/2:hover {
  --transform-translate-y: 50%;
}

.hover\:translate-y-full:hover {
  --transform-translate-y: 100%;
}

.focus\:translate-x-0:focus {
  --transform-translate-x: 0;
}

.focus\:translate-x-1:focus {
  --transform-translate-x: 0.25rem;
}

.focus\:translate-x-2:focus {
  --transform-translate-x: 0.5rem;
}

.focus\:translate-x-3:focus {
  --transform-translate-x: 0.75rem;
}

.focus\:translate-x-4:focus {
  --transform-translate-x: 1rem;
}

.focus\:translate-x-5:focus {
  --transform-translate-x: 1.25rem;
}

.focus\:translate-x-6:focus {
  --transform-translate-x: 1.5rem;
}

.focus\:translate-x-8:focus {
  --transform-translate-x: 2rem;
}

.focus\:translate-x-10:focus {
  --transform-translate-x: 2.5rem;
}

.focus\:translate-x-12:focus {
  --transform-translate-x: 3rem;
}

.focus\:translate-x-16:focus {
  --transform-translate-x: 4rem;
}

.focus\:translate-x-20:focus {
  --transform-translate-x: 5rem;
}

.focus\:translate-x-24:focus {
  --transform-translate-x: 6rem;
}

.focus\:translate-x-32:focus {
  --transform-translate-x: 8rem;
}

.focus\:translate-x-40:focus {
  --transform-translate-x: 10rem;
}

.focus\:translate-x-48:focus {
  --transform-translate-x: 12rem;
}

.focus\:translate-x-56:focus {
  --transform-translate-x: 14rem;
}

.focus\:translate-x-64:focus {
  --transform-translate-x: 16rem;
}

.focus\:translate-x-px:focus {
  --transform-translate-x: 1px;
}

.focus\:-translate-x-1:focus {
  --transform-translate-x: -0.25rem;
}

.focus\:-translate-x-2:focus {
  --transform-translate-x: -0.5rem;
}

.focus\:-translate-x-3:focus {
  --transform-translate-x: -0.75rem;
}

.focus\:-translate-x-4:focus {
  --transform-translate-x: -1rem;
}

.focus\:-translate-x-5:focus {
  --transform-translate-x: -1.25rem;
}

.focus\:-translate-x-6:focus {
  --transform-translate-x: -1.5rem;
}

.focus\:-translate-x-8:focus {
  --transform-translate-x: -2rem;
}

.focus\:-translate-x-10:focus {
  --transform-translate-x: -2.5rem;
}

.focus\:-translate-x-12:focus {
  --transform-translate-x: -3rem;
}

.focus\:-translate-x-16:focus {
  --transform-translate-x: -4rem;
}

.focus\:-translate-x-20:focus {
  --transform-translate-x: -5rem;
}

.focus\:-translate-x-24:focus {
  --transform-translate-x: -6rem;
}

.focus\:-translate-x-32:focus {
  --transform-translate-x: -8rem;
}

.focus\:-translate-x-40:focus {
  --transform-translate-x: -10rem;
}

.focus\:-translate-x-48:focus {
  --transform-translate-x: -12rem;
}

.focus\:-translate-x-56:focus {
  --transform-translate-x: -14rem;
}

.focus\:-translate-x-64:focus {
  --transform-translate-x: -16rem;
}

.focus\:-translate-x-px:focus {
  --transform-translate-x: -1px;
}

.focus\:-translate-x-full:focus {
  --transform-translate-x: -100%;
}

.focus\:-translate-x-1\/2:focus {
  --transform-translate-x: -50%;
}

.focus\:translate-x-1\/2:focus {
  --transform-translate-x: 50%;
}

.focus\:translate-x-full:focus {
  --transform-translate-x: 100%;
}

.focus\:translate-y-0:focus {
  --transform-translate-y: 0;
}

.focus\:translate-y-1:focus {
  --transform-translate-y: 0.25rem;
}

.focus\:translate-y-2:focus {
  --transform-translate-y: 0.5rem;
}

.focus\:translate-y-3:focus {
  --transform-translate-y: 0.75rem;
}

.focus\:translate-y-4:focus {
  --transform-translate-y: 1rem;
}

.focus\:translate-y-5:focus {
  --transform-translate-y: 1.25rem;
}

.focus\:translate-y-6:focus {
  --transform-translate-y: 1.5rem;
}

.focus\:translate-y-8:focus {
  --transform-translate-y: 2rem;
}

.focus\:translate-y-10:focus {
  --transform-translate-y: 2.5rem;
}

.focus\:translate-y-12:focus {
  --transform-translate-y: 3rem;
}

.focus\:translate-y-16:focus {
  --transform-translate-y: 4rem;
}

.focus\:translate-y-20:focus {
  --transform-translate-y: 5rem;
}

.focus\:translate-y-24:focus {
  --transform-translate-y: 6rem;
}

.focus\:translate-y-32:focus {
  --transform-translate-y: 8rem;
}

.focus\:translate-y-40:focus {
  --transform-translate-y: 10rem;
}

.focus\:translate-y-48:focus {
  --transform-translate-y: 12rem;
}

.focus\:translate-y-56:focus {
  --transform-translate-y: 14rem;
}

.focus\:translate-y-64:focus {
  --transform-translate-y: 16rem;
}

.focus\:translate-y-px:focus {
  --transform-translate-y: 1px;
}

.focus\:-translate-y-1:focus {
  --transform-translate-y: -0.25rem;
}

.focus\:-translate-y-2:focus {
  --transform-translate-y: -0.5rem;
}

.focus\:-translate-y-3:focus {
  --transform-translate-y: -0.75rem;
}

.focus\:-translate-y-4:focus {
  --transform-translate-y: -1rem;
}

.focus\:-translate-y-5:focus {
  --transform-translate-y: -1.25rem;
}

.focus\:-translate-y-6:focus {
  --transform-translate-y: -1.5rem;
}

.focus\:-translate-y-8:focus {
  --transform-translate-y: -2rem;
}

.focus\:-translate-y-10:focus {
  --transform-translate-y: -2.5rem;
}

.focus\:-translate-y-12:focus {
  --transform-translate-y: -3rem;
}

.focus\:-translate-y-16:focus {
  --transform-translate-y: -4rem;
}

.focus\:-translate-y-20:focus {
  --transform-translate-y: -5rem;
}

.focus\:-translate-y-24:focus {
  --transform-translate-y: -6rem;
}

.focus\:-translate-y-32:focus {
  --transform-translate-y: -8rem;
}

.focus\:-translate-y-40:focus {
  --transform-translate-y: -10rem;
}

.focus\:-translate-y-48:focus {
  --transform-translate-y: -12rem;
}

.focus\:-translate-y-56:focus {
  --transform-translate-y: -14rem;
}

.focus\:-translate-y-64:focus {
  --transform-translate-y: -16rem;
}

.focus\:-translate-y-px:focus {
  --transform-translate-y: -1px;
}

.focus\:-translate-y-full:focus {
  --transform-translate-y: -100%;
}

.focus\:-translate-y-1\/2:focus {
  --transform-translate-y: -50%;
}

.focus\:translate-y-1\/2:focus {
  --transform-translate-y: 50%;
}

.focus\:translate-y-full:focus {
  --transform-translate-y: 100%;
}

.skew-x-0 {
  --transform-skew-x: 0;
}

.skew-x-3 {
  --transform-skew-x: 3deg;
}

.skew-x-6 {
  --transform-skew-x: 6deg;
}

.skew-x-12 {
  --transform-skew-x: 12deg;
}

.-skew-x-12 {
  --transform-skew-x: -12deg;
}

.-skew-x-6 {
  --transform-skew-x: -6deg;
}

.-skew-x-3 {
  --transform-skew-x: -3deg;
}

.skew-y-0 {
  --transform-skew-y: 0;
}

.skew-y-3 {
  --transform-skew-y: 3deg;
}

.skew-y-6 {
  --transform-skew-y: 6deg;
}

.skew-y-12 {
  --transform-skew-y: 12deg;
}

.-skew-y-12 {
  --transform-skew-y: -12deg;
}

.-skew-y-6 {
  --transform-skew-y: -6deg;
}

.-skew-y-3 {
  --transform-skew-y: -3deg;
}

.hover\:skew-x-0:hover {
  --transform-skew-x: 0;
}

.hover\:skew-x-3:hover {
  --transform-skew-x: 3deg;
}

.hover\:skew-x-6:hover {
  --transform-skew-x: 6deg;
}

.hover\:skew-x-12:hover {
  --transform-skew-x: 12deg;
}

.hover\:-skew-x-12:hover {
  --transform-skew-x: -12deg;
}

.hover\:-skew-x-6:hover {
  --transform-skew-x: -6deg;
}

.hover\:-skew-x-3:hover {
  --transform-skew-x: -3deg;
}

.hover\:skew-y-0:hover {
  --transform-skew-y: 0;
}

.hover\:skew-y-3:hover {
  --transform-skew-y: 3deg;
}

.hover\:skew-y-6:hover {
  --transform-skew-y: 6deg;
}

.hover\:skew-y-12:hover {
  --transform-skew-y: 12deg;
}

.hover\:-skew-y-12:hover {
  --transform-skew-y: -12deg;
}

.hover\:-skew-y-6:hover {
  --transform-skew-y: -6deg;
}

.hover\:-skew-y-3:hover {
  --transform-skew-y: -3deg;
}

.focus\:skew-x-0:focus {
  --transform-skew-x: 0;
}

.focus\:skew-x-3:focus {
  --transform-skew-x: 3deg;
}

.focus\:skew-x-6:focus {
  --transform-skew-x: 6deg;
}

.focus\:skew-x-12:focus {
  --transform-skew-x: 12deg;
}

.focus\:-skew-x-12:focus {
  --transform-skew-x: -12deg;
}

.focus\:-skew-x-6:focus {
  --transform-skew-x: -6deg;
}

.focus\:-skew-x-3:focus {
  --transform-skew-x: -3deg;
}

.focus\:skew-y-0:focus {
  --transform-skew-y: 0;
}

.focus\:skew-y-3:focus {
  --transform-skew-y: 3deg;
}

.focus\:skew-y-6:focus {
  --transform-skew-y: 6deg;
}

.focus\:skew-y-12:focus {
  --transform-skew-y: 12deg;
}

.focus\:-skew-y-12:focus {
  --transform-skew-y: -12deg;
}

.focus\:-skew-y-6:focus {
  --transform-skew-y: -6deg;
}

.focus\:-skew-y-3:focus {
  --transform-skew-y: -3deg;
}

.transition-none {
  transition-property: none;
}

.transition-all {
  transition-property: all;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
}

.transition-opacity {
  transition-property: opacity;
}

.transition-shadow {
  transition-property: box-shadow;
}

.transition-transform {
  transition-property: transform;
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.duration-75 {
  transition-duration: 75ms;
}

.duration-100 {
  transition-duration: 100ms;
}

.duration-150 {
  transition-duration: 150ms;
}

.duration-200 {
  transition-duration: 200ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.duration-500 {
  transition-duration: 500ms;
}

.duration-700 {
  transition-duration: 700ms;
}

.duration-1000 {
  transition-duration: 1000ms;
}

.delay-75 {
  transition-delay: 75ms;
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-150 {
  transition-delay: 150ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.shadow-outline-white {
  box-shadow: 0 0 0 3px hsla(0, 0%, 100%, 0.45);
}

.shadow-outline-black {
  box-shadow: 0 0 0 3px hsla(0, 0%, 0%, 0.45);
}

.shadow-outline-gray {
  box-shadow: 0 0 0 3px hsla(210, 2.4%, 83.9%, 0.45);
}

.shadow-outline-cool-gray {
  box-shadow: 0 0 0 3px hsla(213, 26.3%, 85.1%, 0.45);
}

.shadow-outline-red {
  box-shadow: 0 0 0 3px hsla(0, 82.9%, 83.9%, 0.45);
}

.shadow-outline-orange {
  box-shadow: 0 0 0 3px hsla(24.399999999999977, 96.6%, 77.1%, 0.45);
}

.shadow-outline-yellow {
  box-shadow: 0 0 0 3px hsla(47.39999999999998, 95.8%, 53.1%, 0.45);
}

.shadow-outline-green {
  box-shadow: 0 0 0 3px hsla(156.10000000000002, 60.8%, 70%, 0.45);
}

.shadow-outline-teal {
  box-shadow: 0 0 0 3px hsla(183.60000000000002, 63.3%, 69%, 0.45);
}

.shadow-outline-blue {
  box-shadow: 0 0 0 3px hsla(214.70000000000005, 97.8%, 82%, 0.45);
}

.shadow-outline-indigo {
  box-shadow: 0 0 0 3px hsla(225, 92.3%, 84.7%, 0.45);
}

.shadow-outline-purple {
  box-shadow: 0 0 0 3px hsla(250.60000000000002, 93.9%, 87.1%, 0.45);
}

.shadow-outline-pink {
  box-shadow: 0 0 0 3px hsla(327.4, 82.9%, 83.9%, 0.45);
}

.hover\:shadow-outline-white:hover {
  box-shadow: 0 0 0 3px hsla(0, 0%, 100%, 0.45);
}

.hover\:shadow-outline-black:hover {
  box-shadow: 0 0 0 3px hsla(0, 0%, 0%, 0.45);
}

.hover\:shadow-outline-gray:hover {
  box-shadow: 0 0 0 3px hsla(210, 2.4%, 83.9%, 0.45);
}

.hover\:shadow-outline-cool-gray:hover {
  box-shadow: 0 0 0 3px hsla(213, 26.3%, 85.1%, 0.45);
}

.hover\:shadow-outline-red:hover {
  box-shadow: 0 0 0 3px hsla(0, 82.9%, 83.9%, 0.45);
}

.hover\:shadow-outline-orange:hover {
  box-shadow: 0 0 0 3px hsla(24.399999999999977, 96.6%, 77.1%, 0.45);
}

.hover\:shadow-outline-yellow:hover {
  box-shadow: 0 0 0 3px hsla(47.39999999999998, 95.8%, 53.1%, 0.45);
}

.hover\:shadow-outline-green:hover {
  box-shadow: 0 0 0 3px hsla(156.10000000000002, 60.8%, 70%, 0.45);
}

.hover\:shadow-outline-teal:hover {
  box-shadow: 0 0 0 3px hsla(183.60000000000002, 63.3%, 69%, 0.45);
}

.hover\:shadow-outline-blue:hover {
  box-shadow: 0 0 0 3px hsla(214.70000000000005, 97.8%, 82%, 0.45);
}

.hover\:shadow-outline-indigo:hover {
  box-shadow: 0 0 0 3px hsla(225, 92.3%, 84.7%, 0.45);
}

.hover\:shadow-outline-purple:hover {
  box-shadow: 0 0 0 3px hsla(250.60000000000002, 93.9%, 87.1%, 0.45);
}

.hover\:shadow-outline-pink:hover {
  box-shadow: 0 0 0 3px hsla(327.4, 82.9%, 83.9%, 0.45);
}

.focus\:shadow-outline-white:focus {
  box-shadow: 0 0 0 3px hsla(0, 0%, 100%, 0.45);
}

.focus\:shadow-outline-black:focus {
  box-shadow: 0 0 0 3px hsla(0, 0%, 0%, 0.45);
}

.focus\:shadow-outline-gray:focus {
  box-shadow: 0 0 0 3px hsla(210, 2.4%, 83.9%, 0.45);
}

.focus\:shadow-outline-cool-gray:focus {
  box-shadow: 0 0 0 3px hsla(213, 26.3%, 85.1%, 0.45);
}

.focus\:shadow-outline-red:focus {
  box-shadow: 0 0 0 3px hsla(0, 82.9%, 83.9%, 0.45);
}

.focus\:shadow-outline-orange:focus {
  box-shadow: 0 0 0 3px hsla(24.399999999999977, 96.6%, 77.1%, 0.45);
}

.focus\:shadow-outline-yellow:focus {
  box-shadow: 0 0 0 3px hsla(47.39999999999998, 95.8%, 53.1%, 0.45);
}

.focus\:shadow-outline-green:focus {
  box-shadow: 0 0 0 3px hsla(156.10000000000002, 60.8%, 70%, 0.45);
}

.focus\:shadow-outline-teal:focus {
  box-shadow: 0 0 0 3px hsla(183.60000000000002, 63.3%, 69%, 0.45);
}

.focus\:shadow-outline-blue:focus {
  box-shadow: 0 0 0 3px hsla(214.70000000000005, 97.8%, 82%, 0.45);
}

.focus\:shadow-outline-indigo:focus {
  box-shadow: 0 0 0 3px hsla(225, 92.3%, 84.7%, 0.45);
}

.focus\:shadow-outline-purple:focus {
  box-shadow: 0 0 0 3px hsla(250.60000000000002, 93.9%, 87.1%, 0.45);
}

.focus\:shadow-outline-pink:focus {
  box-shadow: 0 0 0 3px hsla(327.4, 82.9%, 83.9%, 0.45);
}

@media (min-width: 1024px) {
  .lg\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .lg\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse));
  }

  .lg\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse));
  }

  .lg\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--space-y-reverse));
  }

  .lg\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse));
  }

  .lg\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse));
  }

  .lg\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--space-y-reverse));
  }

  .lg\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse));
  }

  .lg\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse));
  }

  .lg\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(3rem * var(--space-y-reverse));
  }

  .lg\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse));
  }

  .lg\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5rem * var(--space-y-reverse));
  }

  .lg\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(6rem * var(--space-y-reverse));
  }

  .lg\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(8rem * var(--space-y-reverse));
  }

  .lg\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10rem * var(--space-y-reverse));
  }

  .lg\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(12rem * var(--space-y-reverse));
  }

  .lg\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(14rem * var(--space-y-reverse));
  }

  .lg\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(16rem * var(--space-y-reverse));
  }

  .lg\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1px * var(--space-y-reverse));
  }

  .lg\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--space-y-reverse));
  }

  .lg\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--space-y-reverse));
  }

  .lg\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--space-y-reverse));
  }

  .lg\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1rem * var(--space-y-reverse));
  }

  .lg\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--space-y-reverse));
  }

  .lg\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--space-y-reverse));
  }

  .lg\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2rem * var(--space-y-reverse));
  }

  .lg\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--space-y-reverse));
  }

  .lg\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-3rem * var(--space-y-reverse));
  }

  .lg\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-4rem * var(--space-y-reverse));
  }

  .lg\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5rem * var(--space-y-reverse));
  }

  .lg\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-6rem * var(--space-y-reverse));
  }

  .lg\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-8rem * var(--space-y-reverse));
  }

  .lg\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10rem * var(--space-y-reverse));
  }

  .lg\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-12rem * var(--space-y-reverse));
  }

  .lg\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-14rem * var(--space-y-reverse));
  }

  .lg\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-16rem * var(--space-y-reverse));
  }

  .lg\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1px * var(--space-y-reverse));
  }

  .lg\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1;
  }

  .lg\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1;
  }

  .lg\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse));
  }

  .lg\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)));
  }

  .lg\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse));
  }

  .lg\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)));
  }

  .lg\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse));
  }

  .lg\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)));
  }

  .lg\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse));
  }

  .lg\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)));
  }

  .lg\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse));
  }

  .lg\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)));
  }

  .lg\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1;
  }

  .lg\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1;
  }

  .lg\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent;
  }

  .lg\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ffffff;
    border-color: rgba(255, 255, 255, var(--divide-opacity));
  }

  .lg\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #000000;
    border-color: rgba(0, 0, 0, var(--divide-opacity));
  }

  .lg\:divide-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--divide-opacity));
  }

  .lg\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--divide-opacity));
  }

  .lg\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--divide-opacity));
  }

  .lg\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d5d6d7;
    border-color: rgba(213, 214, 215, var(--divide-opacity));
  }

  .lg\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9e9e9e;
    border-color: rgba(158, 158, 158, var(--divide-opacity));
  }

  .lg\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #707275;
    border-color: rgba(112, 114, 117, var(--divide-opacity));
  }

  .lg\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4c4f52;
    border-color: rgba(76, 79, 82, var(--divide-opacity));
  }

  .lg\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #24262d;
    border-color: rgba(36, 38, 45, var(--divide-opacity));
  }

  .lg\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a1c23;
    border-color: rgba(26, 28, 35, var(--divide-opacity));
  }

  .lg\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #121317;
    border-color: rgba(18, 19, 23, var(--divide-opacity));
  }

  .lg\:divide-cool-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--divide-opacity));
  }

  .lg\:divide-cool-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--divide-opacity));
  }

  .lg\:divide-cool-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--divide-opacity));
  }

  .lg\:divide-cool-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--divide-opacity));
  }

  .lg\:divide-cool-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--divide-opacity));
  }

  .lg\:divide-cool-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--divide-opacity));
  }

  .lg\:divide-cool-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--divide-opacity));
  }

  .lg\:divide-cool-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--divide-opacity));
  }

  .lg\:divide-cool-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--divide-opacity));
  }

  .lg\:divide-cool-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--divide-opacity));
  }

  .lg\:divide-red-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--divide-opacity));
  }

  .lg\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--divide-opacity));
  }

  .lg\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--divide-opacity));
  }

  .lg\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--divide-opacity));
  }

  .lg\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--divide-opacity));
  }

  .lg\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--divide-opacity));
  }

  .lg\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--divide-opacity));
  }

  .lg\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--divide-opacity));
  }

  .lg\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--divide-opacity));
  }

  .lg\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--divide-opacity));
  }

  .lg\:divide-orange-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--divide-opacity));
  }

  .lg\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--divide-opacity));
  }

  .lg\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--divide-opacity));
  }

  .lg\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--divide-opacity));
  }

  .lg\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--divide-opacity));
  }

  .lg\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--divide-opacity));
  }

  .lg\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--divide-opacity));
  }

  .lg\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--divide-opacity));
  }

  .lg\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--divide-opacity));
  }

  .lg\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--divide-opacity));
  }

  .lg\:divide-yellow-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--divide-opacity));
  }

  .lg\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--divide-opacity));
  }

  .lg\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--divide-opacity));
  }

  .lg\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--divide-opacity));
  }

  .lg\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--divide-opacity));
  }

  .lg\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--divide-opacity));
  }

  .lg\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--divide-opacity));
  }

  .lg\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--divide-opacity));
  }

  .lg\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--divide-opacity));
  }

  .lg\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--divide-opacity));
  }

  .lg\:divide-green-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--divide-opacity));
  }

  .lg\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--divide-opacity));
  }

  .lg\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--divide-opacity));
  }

  .lg\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--divide-opacity));
  }

  .lg\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--divide-opacity));
  }

  .lg\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--divide-opacity));
  }

  .lg\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--divide-opacity));
  }

  .lg\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--divide-opacity));
  }

  .lg\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--divide-opacity));
  }

  .lg\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--divide-opacity));
  }

  .lg\:divide-teal-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--divide-opacity));
  }

  .lg\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--divide-opacity));
  }

  .lg\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--divide-opacity));
  }

  .lg\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--divide-opacity));
  }

  .lg\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--divide-opacity));
  }

  .lg\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--divide-opacity));
  }

  .lg\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--divide-opacity));
  }

  .lg\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--divide-opacity));
  }

  .lg\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--divide-opacity));
  }

  .lg\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--divide-opacity));
  }

  .lg\:divide-blue-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--divide-opacity));
  }

  .lg\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--divide-opacity));
  }

  .lg\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--divide-opacity));
  }

  .lg\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--divide-opacity));
  }

  .lg\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--divide-opacity));
  }

  .lg\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--divide-opacity));
  }

  .lg\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--divide-opacity));
  }

  .lg\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--divide-opacity));
  }

  .lg\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--divide-opacity));
  }

  .lg\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--divide-opacity));
  }

  .lg\:divide-indigo-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--divide-opacity));
  }

  .lg\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--divide-opacity));
  }

  .lg\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--divide-opacity));
  }

  .lg\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--divide-opacity));
  }

  .lg\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--divide-opacity));
  }

  .lg\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--divide-opacity));
  }

  .lg\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--divide-opacity));
  }

  .lg\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--divide-opacity));
  }

  .lg\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--divide-opacity));
  }

  .lg\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--divide-opacity));
  }

  .lg\:divide-purple-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--divide-opacity));
  }

  .lg\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--divide-opacity));
  }

  .lg\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--divide-opacity));
  }

  .lg\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--divide-opacity));
  }

  .lg\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--divide-opacity));
  }

  .lg\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--divide-opacity));
  }

  .lg\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--divide-opacity));
  }

  .lg\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--divide-opacity));
  }

  .lg\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--divide-opacity));
  }

  .lg\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--divide-opacity));
  }

  .lg\:divide-pink-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--divide-opacity));
  }

  .lg\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--divide-opacity));
  }

  .lg\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--divide-opacity));
  }

  .lg\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--divide-opacity));
  }

  .lg\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--divide-opacity));
  }

  .lg\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--divide-opacity));
  }

  .lg\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--divide-opacity));
  }

  .lg\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--divide-opacity));
  }

  .lg\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--divide-opacity));
  }

  .lg\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--divide-opacity));
  }

  .lg\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0;
  }

  .lg\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25;
  }

  .lg\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5;
  }

  .lg\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75;
  }

  .lg\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
  }

  .lg\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .lg\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .lg\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .lg\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .lg\:appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .lg\:bg-fixed {
    background-attachment: fixed;
  }

  .lg\:bg-local {
    background-attachment: local;
  }

  .lg\:bg-scroll {
    background-attachment: scroll;
  }

  .lg\:bg-transparent {
    background-color: transparent;
  }

  .lg\:bg-white {
    --bg-opacity: 1;
    background-color: #ffffff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .lg\:bg-black {
    --bg-opacity: 1;
    background-color: #000000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .lg\:bg-gray-50 {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity));
  }

  .lg\:bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity));
  }

  .lg\:bg-gray-200 {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity));
  }

  .lg\:bg-gray-300 {
    --bg-opacity: 1;
    background-color: #d5d6d7;
    background-color: rgba(213, 214, 215, var(--bg-opacity));
  }

  .lg\:bg-gray-400 {
    --bg-opacity: 1;
    background-color: #9e9e9e;
    background-color: rgba(158, 158, 158, var(--bg-opacity));
  }

  .lg\:bg-gray-500 {
    --bg-opacity: 1;
    background-color: #707275;
    background-color: rgba(112, 114, 117, var(--bg-opacity));
  }

  .lg\:bg-gray-600 {
    --bg-opacity: 1;
    background-color: #4c4f52;
    background-color: rgba(76, 79, 82, var(--bg-opacity));
  }

  .lg\:bg-gray-700 {
    --bg-opacity: 1;
    background-color: #24262d;
    background-color: rgba(36, 38, 45, var(--bg-opacity));
  }

  .lg\:bg-gray-800 {
    --bg-opacity: 1;
    background-color: #1a1c23;
    background-color: rgba(26, 28, 35, var(--bg-opacity));
  }

  .lg\:bg-gray-900 {
    --bg-opacity: 1;
    background-color: #121317;
    background-color: rgba(18, 19, 23, var(--bg-opacity));
  }

  .lg\:bg-cool-gray-50 {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity));
  }

  .lg\:bg-cool-gray-100 {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity));
  }

  .lg\:bg-cool-gray-200 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }

  .lg\:bg-cool-gray-300 {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity));
  }

  .lg\:bg-cool-gray-400 {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity));
  }

  .lg\:bg-cool-gray-500 {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity));
  }

  .lg\:bg-cool-gray-600 {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity));
  }

  .lg\:bg-cool-gray-700 {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity));
  }

  .lg\:bg-cool-gray-800 {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity));
  }

  .lg\:bg-cool-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity));
  }

  .lg\:bg-red-50 {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity));
  }

  .lg\:bg-red-100 {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity));
  }

  .lg\:bg-red-200 {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity));
  }

  .lg\:bg-red-300 {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity));
  }

  .lg\:bg-red-400 {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity));
  }

  .lg\:bg-red-500 {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity));
  }

  .lg\:bg-red-600 {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity));
  }

  .lg\:bg-red-700 {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity));
  }

  .lg\:bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity));
  }

  .lg\:bg-red-900 {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity));
  }

  .lg\:bg-orange-50 {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity));
  }

  .lg\:bg-orange-100 {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity));
  }

  .lg\:bg-orange-200 {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity));
  }

  .lg\:bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity));
  }

  .lg\:bg-orange-400 {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity));
  }

  .lg\:bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity));
  }

  .lg\:bg-orange-600 {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity));
  }

  .lg\:bg-orange-700 {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity));
  }

  .lg\:bg-orange-800 {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity));
  }

  .lg\:bg-orange-900 {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity));
  }

  .lg\:bg-yellow-50 {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity));
  }

  .lg\:bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity));
  }

  .lg\:bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity));
  }

  .lg\:bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity));
  }

  .lg\:bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity));
  }

  .lg\:bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity));
  }

  .lg\:bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity));
  }

  .lg\:bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity));
  }

  .lg\:bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity));
  }

  .lg\:bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity));
  }

  .lg\:bg-green-50 {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity));
  }

  .lg\:bg-green-100 {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity));
  }

  .lg\:bg-green-200 {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity));
  }

  .lg\:bg-green-300 {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity));
  }

  .lg\:bg-green-400 {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity));
  }

  .lg\:bg-green-500 {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity));
  }

  .lg\:bg-green-600 {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity));
  }

  .lg\:bg-green-700 {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity));
  }

  .lg\:bg-green-800 {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity));
  }

  .lg\:bg-green-900 {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity));
  }

  .lg\:bg-teal-50 {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity));
  }

  .lg\:bg-teal-100 {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity));
  }

  .lg\:bg-teal-200 {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity));
  }

  .lg\:bg-teal-300 {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity));
  }

  .lg\:bg-teal-400 {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity));
  }

  .lg\:bg-teal-500 {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity));
  }

  .lg\:bg-teal-600 {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity));
  }

  .lg\:bg-teal-700 {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity));
  }

  .lg\:bg-teal-800 {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity));
  }

  .lg\:bg-teal-900 {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity));
  }

  .lg\:bg-blue-50 {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity));
  }

  .lg\:bg-blue-100 {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity));
  }

  .lg\:bg-blue-200 {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity));
  }

  .lg\:bg-blue-300 {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity));
  }

  .lg\:bg-blue-400 {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity));
  }

  .lg\:bg-blue-500 {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity));
  }

  .lg\:bg-blue-600 {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity));
  }

  .lg\:bg-blue-700 {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity));
  }

  .lg\:bg-blue-800 {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity));
  }

  .lg\:bg-blue-900 {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity));
  }

  .lg\:bg-indigo-50 {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity));
  }

  .lg\:bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity));
  }

  .lg\:bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity));
  }

  .lg\:bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity));
  }

  .lg\:bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity));
  }

  .lg\:bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity));
  }

  .lg\:bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity));
  }

  .lg\:bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity));
  }

  .lg\:bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity));
  }

  .lg\:bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity));
  }

  .lg\:bg-purple-50 {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity));
  }

  .lg\:bg-purple-100 {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity));
  }

  .lg\:bg-purple-200 {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity));
  }

  .lg\:bg-purple-300 {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity));
  }

  .lg\:bg-purple-400 {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity));
  }

  .lg\:bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity));
  }

  .lg\:bg-purple-600 {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity));
  }

  .lg\:bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity));
  }

  .lg\:bg-purple-800 {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity));
  }

  .lg\:bg-purple-900 {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity));
  }

  .lg\:bg-pink-50 {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity));
  }

  .lg\:bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity));
  }

  .lg\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity));
  }

  .lg\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity));
  }

  .lg\:bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity));
  }

  .lg\:bg-pink-500 {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity));
  }

  .lg\:bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity));
  }

  .lg\:bg-pink-700 {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity));
  }

  .lg\:bg-pink-800 {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity));
  }

  .lg\:bg-pink-900 {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity));
  }

  .lg\:hover\:bg-transparent:hover {
    background-color: transparent;
  }

  .lg\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #ffffff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .lg\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #000000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .lg\:hover\:bg-gray-50:hover {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity));
  }

  .lg\:hover\:bg-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity));
  }

  .lg\:hover\:bg-gray-200:hover {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity));
  }

  .lg\:hover\:bg-gray-300:hover {
    --bg-opacity: 1;
    background-color: #d5d6d7;
    background-color: rgba(213, 214, 215, var(--bg-opacity));
  }

  .lg\:hover\:bg-gray-400:hover {
    --bg-opacity: 1;
    background-color: #9e9e9e;
    background-color: rgba(158, 158, 158, var(--bg-opacity));
  }

  .lg\:hover\:bg-gray-500:hover {
    --bg-opacity: 1;
    background-color: #707275;
    background-color: rgba(112, 114, 117, var(--bg-opacity));
  }

  .lg\:hover\:bg-gray-600:hover {
    --bg-opacity: 1;
    background-color: #4c4f52;
    background-color: rgba(76, 79, 82, var(--bg-opacity));
  }

  .lg\:hover\:bg-gray-700:hover {
    --bg-opacity: 1;
    background-color: #24262d;
    background-color: rgba(36, 38, 45, var(--bg-opacity));
  }

  .lg\:hover\:bg-gray-800:hover {
    --bg-opacity: 1;
    background-color: #1a1c23;
    background-color: rgba(26, 28, 35, var(--bg-opacity));
  }

  .lg\:hover\:bg-gray-900:hover {
    --bg-opacity: 1;
    background-color: #121317;
    background-color: rgba(18, 19, 23, var(--bg-opacity));
  }

  .lg\:hover\:bg-cool-gray-50:hover {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity));
  }

  .lg\:hover\:bg-cool-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity));
  }

  .lg\:hover\:bg-cool-gray-200:hover {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }

  .lg\:hover\:bg-cool-gray-300:hover {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity));
  }

  .lg\:hover\:bg-cool-gray-400:hover {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity));
  }

  .lg\:hover\:bg-cool-gray-500:hover {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity));
  }

  .lg\:hover\:bg-cool-gray-600:hover {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity));
  }

  .lg\:hover\:bg-cool-gray-700:hover {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity));
  }

  .lg\:hover\:bg-cool-gray-800:hover {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity));
  }

  .lg\:hover\:bg-cool-gray-900:hover {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity));
  }

  .lg\:hover\:bg-red-50:hover {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity));
  }

  .lg\:hover\:bg-red-100:hover {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity));
  }

  .lg\:hover\:bg-red-200:hover {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity));
  }

  .lg\:hover\:bg-red-300:hover {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity));
  }

  .lg\:hover\:bg-red-400:hover {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity));
  }

  .lg\:hover\:bg-red-500:hover {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity));
  }

  .lg\:hover\:bg-red-600:hover {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity));
  }

  .lg\:hover\:bg-red-700:hover {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity));
  }

  .lg\:hover\:bg-red-800:hover {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity));
  }

  .lg\:hover\:bg-red-900:hover {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity));
  }

  .lg\:hover\:bg-orange-50:hover {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity));
  }

  .lg\:hover\:bg-orange-100:hover {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity));
  }

  .lg\:hover\:bg-orange-200:hover {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity));
  }

  .lg\:hover\:bg-orange-300:hover {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity));
  }

  .lg\:hover\:bg-orange-400:hover {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity));
  }

  .lg\:hover\:bg-orange-500:hover {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity));
  }

  .lg\:hover\:bg-orange-600:hover {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity));
  }

  .lg\:hover\:bg-orange-700:hover {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity));
  }

  .lg\:hover\:bg-orange-800:hover {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity));
  }

  .lg\:hover\:bg-orange-900:hover {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity));
  }

  .lg\:hover\:bg-yellow-50:hover {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity));
  }

  .lg\:hover\:bg-yellow-100:hover {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity));
  }

  .lg\:hover\:bg-yellow-200:hover {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity));
  }

  .lg\:hover\:bg-yellow-300:hover {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity));
  }

  .lg\:hover\:bg-yellow-400:hover {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity));
  }

  .lg\:hover\:bg-yellow-500:hover {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity));
  }

  .lg\:hover\:bg-yellow-600:hover {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity));
  }

  .lg\:hover\:bg-yellow-700:hover {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity));
  }

  .lg\:hover\:bg-yellow-800:hover {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity));
  }

  .lg\:hover\:bg-yellow-900:hover {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity));
  }

  .lg\:hover\:bg-green-50:hover {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity));
  }

  .lg\:hover\:bg-green-100:hover {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity));
  }

  .lg\:hover\:bg-green-200:hover {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity));
  }

  .lg\:hover\:bg-green-300:hover {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity));
  }

  .lg\:hover\:bg-green-400:hover {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity));
  }

  .lg\:hover\:bg-green-500:hover {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity));
  }

  .lg\:hover\:bg-green-600:hover {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity));
  }

  .lg\:hover\:bg-green-700:hover {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity));
  }

  .lg\:hover\:bg-green-800:hover {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity));
  }

  .lg\:hover\:bg-green-900:hover {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity));
  }

  .lg\:hover\:bg-teal-50:hover {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity));
  }

  .lg\:hover\:bg-teal-100:hover {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity));
  }

  .lg\:hover\:bg-teal-200:hover {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity));
  }

  .lg\:hover\:bg-teal-300:hover {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity));
  }

  .lg\:hover\:bg-teal-400:hover {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity));
  }

  .lg\:hover\:bg-teal-500:hover {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity));
  }

  .lg\:hover\:bg-teal-600:hover {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity));
  }

  .lg\:hover\:bg-teal-700:hover {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity));
  }

  .lg\:hover\:bg-teal-800:hover {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity));
  }

  .lg\:hover\:bg-teal-900:hover {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity));
  }

  .lg\:hover\:bg-blue-50:hover {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity));
  }

  .lg\:hover\:bg-blue-100:hover {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity));
  }

  .lg\:hover\:bg-blue-200:hover {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity));
  }

  .lg\:hover\:bg-blue-300:hover {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity));
  }

  .lg\:hover\:bg-blue-400:hover {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity));
  }

  .lg\:hover\:bg-blue-500:hover {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity));
  }

  .lg\:hover\:bg-blue-600:hover {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity));
  }

  .lg\:hover\:bg-blue-700:hover {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity));
  }

  .lg\:hover\:bg-blue-800:hover {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity));
  }

  .lg\:hover\:bg-blue-900:hover {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity));
  }

  .lg\:hover\:bg-indigo-50:hover {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity));
  }

  .lg\:hover\:bg-indigo-100:hover {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity));
  }

  .lg\:hover\:bg-indigo-200:hover {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity));
  }

  .lg\:hover\:bg-indigo-300:hover {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity));
  }

  .lg\:hover\:bg-indigo-400:hover {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity));
  }

  .lg\:hover\:bg-indigo-500:hover {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity));
  }

  .lg\:hover\:bg-indigo-600:hover {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity));
  }

  .lg\:hover\:bg-indigo-700:hover {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity));
  }

  .lg\:hover\:bg-indigo-800:hover {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity));
  }

  .lg\:hover\:bg-indigo-900:hover {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity));
  }

  .lg\:hover\:bg-purple-50:hover {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity));
  }

  .lg\:hover\:bg-purple-100:hover {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity));
  }

  .lg\:hover\:bg-purple-200:hover {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity));
  }

  .lg\:hover\:bg-purple-300:hover {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity));
  }

  .lg\:hover\:bg-purple-400:hover {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity));
  }

  .lg\:hover\:bg-purple-500:hover {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity));
  }

  .lg\:hover\:bg-purple-600:hover {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity));
  }

  .lg\:hover\:bg-purple-700:hover {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity));
  }

  .lg\:hover\:bg-purple-800:hover {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity));
  }

  .lg\:hover\:bg-purple-900:hover {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity));
  }

  .lg\:hover\:bg-pink-50:hover {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity));
  }

  .lg\:hover\:bg-pink-100:hover {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity));
  }

  .lg\:hover\:bg-pink-200:hover {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity));
  }

  .lg\:hover\:bg-pink-300:hover {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity));
  }

  .lg\:hover\:bg-pink-400:hover {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity));
  }

  .lg\:hover\:bg-pink-500:hover {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity));
  }

  .lg\:hover\:bg-pink-600:hover {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity));
  }

  .lg\:hover\:bg-pink-700:hover {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity));
  }

  .lg\:hover\:bg-pink-800:hover {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity));
  }

  .lg\:hover\:bg-pink-900:hover {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity));
  }

  .lg\:focus\:bg-transparent:focus {
    background-color: transparent;
  }

  .lg\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #ffffff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .lg\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #000000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .lg\:focus\:bg-gray-50:focus {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity));
  }

  .lg\:focus\:bg-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity));
  }

  .lg\:focus\:bg-gray-200:focus {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity));
  }

  .lg\:focus\:bg-gray-300:focus {
    --bg-opacity: 1;
    background-color: #d5d6d7;
    background-color: rgba(213, 214, 215, var(--bg-opacity));
  }

  .lg\:focus\:bg-gray-400:focus {
    --bg-opacity: 1;
    background-color: #9e9e9e;
    background-color: rgba(158, 158, 158, var(--bg-opacity));
  }

  .lg\:focus\:bg-gray-500:focus {
    --bg-opacity: 1;
    background-color: #707275;
    background-color: rgba(112, 114, 117, var(--bg-opacity));
  }

  .lg\:focus\:bg-gray-600:focus {
    --bg-opacity: 1;
    background-color: #4c4f52;
    background-color: rgba(76, 79, 82, var(--bg-opacity));
  }

  .lg\:focus\:bg-gray-700:focus {
    --bg-opacity: 1;
    background-color: #24262d;
    background-color: rgba(36, 38, 45, var(--bg-opacity));
  }

  .lg\:focus\:bg-gray-800:focus {
    --bg-opacity: 1;
    background-color: #1a1c23;
    background-color: rgba(26, 28, 35, var(--bg-opacity));
  }

  .lg\:focus\:bg-gray-900:focus {
    --bg-opacity: 1;
    background-color: #121317;
    background-color: rgba(18, 19, 23, var(--bg-opacity));
  }

  .lg\:focus\:bg-cool-gray-50:focus {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity));
  }

  .lg\:focus\:bg-cool-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity));
  }

  .lg\:focus\:bg-cool-gray-200:focus {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }

  .lg\:focus\:bg-cool-gray-300:focus {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity));
  }

  .lg\:focus\:bg-cool-gray-400:focus {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity));
  }

  .lg\:focus\:bg-cool-gray-500:focus {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity));
  }

  .lg\:focus\:bg-cool-gray-600:focus {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity));
  }

  .lg\:focus\:bg-cool-gray-700:focus {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity));
  }

  .lg\:focus\:bg-cool-gray-800:focus {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity));
  }

  .lg\:focus\:bg-cool-gray-900:focus {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity));
  }

  .lg\:focus\:bg-red-50:focus {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity));
  }

  .lg\:focus\:bg-red-100:focus {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity));
  }

  .lg\:focus\:bg-red-200:focus {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity));
  }

  .lg\:focus\:bg-red-300:focus {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity));
  }

  .lg\:focus\:bg-red-400:focus {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity));
  }

  .lg\:focus\:bg-red-500:focus {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity));
  }

  .lg\:focus\:bg-red-600:focus {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity));
  }

  .lg\:focus\:bg-red-700:focus {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity));
  }

  .lg\:focus\:bg-red-800:focus {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity));
  }

  .lg\:focus\:bg-red-900:focus {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity));
  }

  .lg\:focus\:bg-orange-50:focus {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity));
  }

  .lg\:focus\:bg-orange-100:focus {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity));
  }

  .lg\:focus\:bg-orange-200:focus {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity));
  }

  .lg\:focus\:bg-orange-300:focus {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity));
  }

  .lg\:focus\:bg-orange-400:focus {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity));
  }

  .lg\:focus\:bg-orange-500:focus {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity));
  }

  .lg\:focus\:bg-orange-600:focus {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity));
  }

  .lg\:focus\:bg-orange-700:focus {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity));
  }

  .lg\:focus\:bg-orange-800:focus {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity));
  }

  .lg\:focus\:bg-orange-900:focus {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity));
  }

  .lg\:focus\:bg-yellow-50:focus {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity));
  }

  .lg\:focus\:bg-yellow-100:focus {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity));
  }

  .lg\:focus\:bg-yellow-200:focus {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity));
  }

  .lg\:focus\:bg-yellow-300:focus {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity));
  }

  .lg\:focus\:bg-yellow-400:focus {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity));
  }

  .lg\:focus\:bg-yellow-500:focus {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity));
  }

  .lg\:focus\:bg-yellow-600:focus {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity));
  }

  .lg\:focus\:bg-yellow-700:focus {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity));
  }

  .lg\:focus\:bg-yellow-800:focus {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity));
  }

  .lg\:focus\:bg-yellow-900:focus {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity));
  }

  .lg\:focus\:bg-green-50:focus {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity));
  }

  .lg\:focus\:bg-green-100:focus {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity));
  }

  .lg\:focus\:bg-green-200:focus {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity));
  }

  .lg\:focus\:bg-green-300:focus {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity));
  }

  .lg\:focus\:bg-green-400:focus {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity));
  }

  .lg\:focus\:bg-green-500:focus {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity));
  }

  .lg\:focus\:bg-green-600:focus {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity));
  }

  .lg\:focus\:bg-green-700:focus {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity));
  }

  .lg\:focus\:bg-green-800:focus {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity));
  }

  .lg\:focus\:bg-green-900:focus {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity));
  }

  .lg\:focus\:bg-teal-50:focus {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity));
  }

  .lg\:focus\:bg-teal-100:focus {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity));
  }

  .lg\:focus\:bg-teal-200:focus {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity));
  }

  .lg\:focus\:bg-teal-300:focus {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity));
  }

  .lg\:focus\:bg-teal-400:focus {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity));
  }

  .lg\:focus\:bg-teal-500:focus {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity));
  }

  .lg\:focus\:bg-teal-600:focus {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity));
  }

  .lg\:focus\:bg-teal-700:focus {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity));
  }

  .lg\:focus\:bg-teal-800:focus {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity));
  }

  .lg\:focus\:bg-teal-900:focus {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity));
  }

  .lg\:focus\:bg-blue-50:focus {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity));
  }

  .lg\:focus\:bg-blue-100:focus {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity));
  }

  .lg\:focus\:bg-blue-200:focus {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity));
  }

  .lg\:focus\:bg-blue-300:focus {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity));
  }

  .lg\:focus\:bg-blue-400:focus {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity));
  }

  .lg\:focus\:bg-blue-500:focus {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity));
  }

  .lg\:focus\:bg-blue-600:focus {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity));
  }

  .lg\:focus\:bg-blue-700:focus {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity));
  }

  .lg\:focus\:bg-blue-800:focus {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity));
  }

  .lg\:focus\:bg-blue-900:focus {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity));
  }

  .lg\:focus\:bg-indigo-50:focus {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity));
  }

  .lg\:focus\:bg-indigo-100:focus {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity));
  }

  .lg\:focus\:bg-indigo-200:focus {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity));
  }

  .lg\:focus\:bg-indigo-300:focus {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity));
  }

  .lg\:focus\:bg-indigo-400:focus {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity));
  }

  .lg\:focus\:bg-indigo-500:focus {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity));
  }

  .lg\:focus\:bg-indigo-600:focus {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity));
  }

  .lg\:focus\:bg-indigo-700:focus {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity));
  }

  .lg\:focus\:bg-indigo-800:focus {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity));
  }

  .lg\:focus\:bg-indigo-900:focus {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity));
  }

  .lg\:focus\:bg-purple-50:focus {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity));
  }

  .lg\:focus\:bg-purple-100:focus {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity));
  }

  .lg\:focus\:bg-purple-200:focus {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity));
  }

  .lg\:focus\:bg-purple-300:focus {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity));
  }

  .lg\:focus\:bg-purple-400:focus {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity));
  }

  .lg\:focus\:bg-purple-500:focus {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity));
  }

  .lg\:focus\:bg-purple-600:focus {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity));
  }

  .lg\:focus\:bg-purple-700:focus {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity));
  }

  .lg\:focus\:bg-purple-800:focus {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity));
  }

  .lg\:focus\:bg-purple-900:focus {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity));
  }

  .lg\:focus\:bg-pink-50:focus {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity));
  }

  .lg\:focus\:bg-pink-100:focus {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity));
  }

  .lg\:focus\:bg-pink-200:focus {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity));
  }

  .lg\:focus\:bg-pink-300:focus {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity));
  }

  .lg\:focus\:bg-pink-400:focus {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity));
  }

  .lg\:focus\:bg-pink-500:focus {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity));
  }

  .lg\:focus\:bg-pink-600:focus {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity));
  }

  .lg\:focus\:bg-pink-700:focus {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity));
  }

  .lg\:focus\:bg-pink-800:focus {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity));
  }

  .lg\:focus\:bg-pink-900:focus {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity));
  }

  .lg\:active\:bg-transparent:active {
    background-color: transparent;
  }

  .lg\:active\:bg-white:active {
    --bg-opacity: 1;
    background-color: #ffffff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .lg\:active\:bg-black:active {
    --bg-opacity: 1;
    background-color: #000000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .lg\:active\:bg-gray-50:active {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity));
  }

  .lg\:active\:bg-gray-100:active {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity));
  }

  .lg\:active\:bg-gray-200:active {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity));
  }

  .lg\:active\:bg-gray-300:active {
    --bg-opacity: 1;
    background-color: #d5d6d7;
    background-color: rgba(213, 214, 215, var(--bg-opacity));
  }

  .lg\:active\:bg-gray-400:active {
    --bg-opacity: 1;
    background-color: #9e9e9e;
    background-color: rgba(158, 158, 158, var(--bg-opacity));
  }

  .lg\:active\:bg-gray-500:active {
    --bg-opacity: 1;
    background-color: #707275;
    background-color: rgba(112, 114, 117, var(--bg-opacity));
  }

  .lg\:active\:bg-gray-600:active {
    --bg-opacity: 1;
    background-color: #4c4f52;
    background-color: rgba(76, 79, 82, var(--bg-opacity));
  }

  .lg\:active\:bg-gray-700:active {
    --bg-opacity: 1;
    background-color: #24262d;
    background-color: rgba(36, 38, 45, var(--bg-opacity));
  }

  .lg\:active\:bg-gray-800:active {
    --bg-opacity: 1;
    background-color: #1a1c23;
    background-color: rgba(26, 28, 35, var(--bg-opacity));
  }

  .lg\:active\:bg-gray-900:active {
    --bg-opacity: 1;
    background-color: #121317;
    background-color: rgba(18, 19, 23, var(--bg-opacity));
  }

  .lg\:active\:bg-cool-gray-50:active {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity));
  }

  .lg\:active\:bg-cool-gray-100:active {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity));
  }

  .lg\:active\:bg-cool-gray-200:active {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }

  .lg\:active\:bg-cool-gray-300:active {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity));
  }

  .lg\:active\:bg-cool-gray-400:active {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity));
  }

  .lg\:active\:bg-cool-gray-500:active {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity));
  }

  .lg\:active\:bg-cool-gray-600:active {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity));
  }

  .lg\:active\:bg-cool-gray-700:active {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity));
  }

  .lg\:active\:bg-cool-gray-800:active {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity));
  }

  .lg\:active\:bg-cool-gray-900:active {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity));
  }

  .lg\:active\:bg-red-50:active {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity));
  }

  .lg\:active\:bg-red-100:active {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity));
  }

  .lg\:active\:bg-red-200:active {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity));
  }

  .lg\:active\:bg-red-300:active {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity));
  }

  .lg\:active\:bg-red-400:active {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity));
  }

  .lg\:active\:bg-red-500:active {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity));
  }

  .lg\:active\:bg-red-600:active {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity));
  }

  .lg\:active\:bg-red-700:active {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity));
  }

  .lg\:active\:bg-red-800:active {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity));
  }

  .lg\:active\:bg-red-900:active {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity));
  }

  .lg\:active\:bg-orange-50:active {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity));
  }

  .lg\:active\:bg-orange-100:active {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity));
  }

  .lg\:active\:bg-orange-200:active {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity));
  }

  .lg\:active\:bg-orange-300:active {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity));
  }

  .lg\:active\:bg-orange-400:active {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity));
  }

  .lg\:active\:bg-orange-500:active {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity));
  }

  .lg\:active\:bg-orange-600:active {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity));
  }

  .lg\:active\:bg-orange-700:active {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity));
  }

  .lg\:active\:bg-orange-800:active {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity));
  }

  .lg\:active\:bg-orange-900:active {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity));
  }

  .lg\:active\:bg-yellow-50:active {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity));
  }

  .lg\:active\:bg-yellow-100:active {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity));
  }

  .lg\:active\:bg-yellow-200:active {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity));
  }

  .lg\:active\:bg-yellow-300:active {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity));
  }

  .lg\:active\:bg-yellow-400:active {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity));
  }

  .lg\:active\:bg-yellow-500:active {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity));
  }

  .lg\:active\:bg-yellow-600:active {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity));
  }

  .lg\:active\:bg-yellow-700:active {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity));
  }

  .lg\:active\:bg-yellow-800:active {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity));
  }

  .lg\:active\:bg-yellow-900:active {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity));
  }

  .lg\:active\:bg-green-50:active {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity));
  }

  .lg\:active\:bg-green-100:active {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity));
  }

  .lg\:active\:bg-green-200:active {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity));
  }

  .lg\:active\:bg-green-300:active {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity));
  }

  .lg\:active\:bg-green-400:active {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity));
  }

  .lg\:active\:bg-green-500:active {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity));
  }

  .lg\:active\:bg-green-600:active {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity));
  }

  .lg\:active\:bg-green-700:active {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity));
  }

  .lg\:active\:bg-green-800:active {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity));
  }

  .lg\:active\:bg-green-900:active {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity));
  }

  .lg\:active\:bg-teal-50:active {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity));
  }

  .lg\:active\:bg-teal-100:active {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity));
  }

  .lg\:active\:bg-teal-200:active {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity));
  }

  .lg\:active\:bg-teal-300:active {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity));
  }

  .lg\:active\:bg-teal-400:active {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity));
  }

  .lg\:active\:bg-teal-500:active {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity));
  }

  .lg\:active\:bg-teal-600:active {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity));
  }

  .lg\:active\:bg-teal-700:active {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity));
  }

  .lg\:active\:bg-teal-800:active {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity));
  }

  .lg\:active\:bg-teal-900:active {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity));
  }

  .lg\:active\:bg-blue-50:active {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity));
  }

  .lg\:active\:bg-blue-100:active {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity));
  }

  .lg\:active\:bg-blue-200:active {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity));
  }

  .lg\:active\:bg-blue-300:active {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity));
  }

  .lg\:active\:bg-blue-400:active {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity));
  }

  .lg\:active\:bg-blue-500:active {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity));
  }

  .lg\:active\:bg-blue-600:active {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity));
  }

  .lg\:active\:bg-blue-700:active {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity));
  }

  .lg\:active\:bg-blue-800:active {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity));
  }

  .lg\:active\:bg-blue-900:active {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity));
  }

  .lg\:active\:bg-indigo-50:active {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity));
  }

  .lg\:active\:bg-indigo-100:active {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity));
  }

  .lg\:active\:bg-indigo-200:active {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity));
  }

  .lg\:active\:bg-indigo-300:active {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity));
  }

  .lg\:active\:bg-indigo-400:active {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity));
  }

  .lg\:active\:bg-indigo-500:active {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity));
  }

  .lg\:active\:bg-indigo-600:active {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity));
  }

  .lg\:active\:bg-indigo-700:active {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity));
  }

  .lg\:active\:bg-indigo-800:active {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity));
  }

  .lg\:active\:bg-indigo-900:active {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity));
  }

  .lg\:active\:bg-purple-50:active {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity));
  }

  .lg\:active\:bg-purple-100:active {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity));
  }

  .lg\:active\:bg-purple-200:active {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity));
  }

  .lg\:active\:bg-purple-300:active {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity));
  }

  .lg\:active\:bg-purple-400:active {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity));
  }

  .lg\:active\:bg-purple-500:active {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity));
  }

  .lg\:active\:bg-purple-600:active {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity));
  }

  .lg\:active\:bg-purple-700:active {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity));
  }

  .lg\:active\:bg-purple-800:active {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity));
  }

  .lg\:active\:bg-purple-900:active {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity));
  }

  .lg\:active\:bg-pink-50:active {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity));
  }

  .lg\:active\:bg-pink-100:active {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity));
  }

  .lg\:active\:bg-pink-200:active {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity));
  }

  .lg\:active\:bg-pink-300:active {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity));
  }

  .lg\:active\:bg-pink-400:active {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity));
  }

  .lg\:active\:bg-pink-500:active {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity));
  }

  .lg\:active\:bg-pink-600:active {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity));
  }

  .lg\:active\:bg-pink-700:active {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity));
  }

  .lg\:active\:bg-pink-800:active {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity));
  }

  .lg\:active\:bg-pink-900:active {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity));
  }

  .lg\:odd\:bg-transparent:nth-child(odd) {
    background-color: transparent;
  }

  .lg\:odd\:bg-white:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #ffffff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .lg\:odd\:bg-black:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #000000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .lg\:odd\:bg-gray-50:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity));
  }

  .lg\:odd\:bg-gray-100:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity));
  }

  .lg\:odd\:bg-gray-200:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity));
  }

  .lg\:odd\:bg-gray-300:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #d5d6d7;
    background-color: rgba(213, 214, 215, var(--bg-opacity));
  }

  .lg\:odd\:bg-gray-400:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #9e9e9e;
    background-color: rgba(158, 158, 158, var(--bg-opacity));
  }

  .lg\:odd\:bg-gray-500:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #707275;
    background-color: rgba(112, 114, 117, var(--bg-opacity));
  }

  .lg\:odd\:bg-gray-600:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #4c4f52;
    background-color: rgba(76, 79, 82, var(--bg-opacity));
  }

  .lg\:odd\:bg-gray-700:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #24262d;
    background-color: rgba(36, 38, 45, var(--bg-opacity));
  }

  .lg\:odd\:bg-gray-800:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #1a1c23;
    background-color: rgba(26, 28, 35, var(--bg-opacity));
  }

  .lg\:odd\:bg-gray-900:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #121317;
    background-color: rgba(18, 19, 23, var(--bg-opacity));
  }

  .lg\:odd\:bg-cool-gray-50:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity));
  }

  .lg\:odd\:bg-cool-gray-100:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity));
  }

  .lg\:odd\:bg-cool-gray-200:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }

  .lg\:odd\:bg-cool-gray-300:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity));
  }

  .lg\:odd\:bg-cool-gray-400:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity));
  }

  .lg\:odd\:bg-cool-gray-500:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity));
  }

  .lg\:odd\:bg-cool-gray-600:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity));
  }

  .lg\:odd\:bg-cool-gray-700:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity));
  }

  .lg\:odd\:bg-cool-gray-800:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity));
  }

  .lg\:odd\:bg-cool-gray-900:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity));
  }

  .lg\:odd\:bg-red-50:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity));
  }

  .lg\:odd\:bg-red-100:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity));
  }

  .lg\:odd\:bg-red-200:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity));
  }

  .lg\:odd\:bg-red-300:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity));
  }

  .lg\:odd\:bg-red-400:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity));
  }

  .lg\:odd\:bg-red-500:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity));
  }

  .lg\:odd\:bg-red-600:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity));
  }

  .lg\:odd\:bg-red-700:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity));
  }

  .lg\:odd\:bg-red-800:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity));
  }

  .lg\:odd\:bg-red-900:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity));
  }

  .lg\:odd\:bg-orange-50:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity));
  }

  .lg\:odd\:bg-orange-100:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity));
  }

  .lg\:odd\:bg-orange-200:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity));
  }

  .lg\:odd\:bg-orange-300:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity));
  }

  .lg\:odd\:bg-orange-400:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity));
  }

  .lg\:odd\:bg-orange-500:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity));
  }

  .lg\:odd\:bg-orange-600:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity));
  }

  .lg\:odd\:bg-orange-700:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity));
  }

  .lg\:odd\:bg-orange-800:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity));
  }

  .lg\:odd\:bg-orange-900:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity));
  }

  .lg\:odd\:bg-yellow-50:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity));
  }

  .lg\:odd\:bg-yellow-100:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity));
  }

  .lg\:odd\:bg-yellow-200:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity));
  }

  .lg\:odd\:bg-yellow-300:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity));
  }

  .lg\:odd\:bg-yellow-400:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity));
  }

  .lg\:odd\:bg-yellow-500:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity));
  }

  .lg\:odd\:bg-yellow-600:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity));
  }

  .lg\:odd\:bg-yellow-700:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity));
  }

  .lg\:odd\:bg-yellow-800:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity));
  }

  .lg\:odd\:bg-yellow-900:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity));
  }

  .lg\:odd\:bg-green-50:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity));
  }

  .lg\:odd\:bg-green-100:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity));
  }

  .lg\:odd\:bg-green-200:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity));
  }

  .lg\:odd\:bg-green-300:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity));
  }

  .lg\:odd\:bg-green-400:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity));
  }

  .lg\:odd\:bg-green-500:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity));
  }

  .lg\:odd\:bg-green-600:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity));
  }

  .lg\:odd\:bg-green-700:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity));
  }

  .lg\:odd\:bg-green-800:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity));
  }

  .lg\:odd\:bg-green-900:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity));
  }

  .lg\:odd\:bg-teal-50:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity));
  }

  .lg\:odd\:bg-teal-100:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity));
  }

  .lg\:odd\:bg-teal-200:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity));
  }

  .lg\:odd\:bg-teal-300:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity));
  }

  .lg\:odd\:bg-teal-400:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity));
  }

  .lg\:odd\:bg-teal-500:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity));
  }

  .lg\:odd\:bg-teal-600:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity));
  }

  .lg\:odd\:bg-teal-700:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity));
  }

  .lg\:odd\:bg-teal-800:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity));
  }

  .lg\:odd\:bg-teal-900:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity));
  }

  .lg\:odd\:bg-blue-50:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity));
  }

  .lg\:odd\:bg-blue-100:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity));
  }

  .lg\:odd\:bg-blue-200:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity));
  }

  .lg\:odd\:bg-blue-300:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity));
  }

  .lg\:odd\:bg-blue-400:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity));
  }

  .lg\:odd\:bg-blue-500:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity));
  }

  .lg\:odd\:bg-blue-600:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity));
  }

  .lg\:odd\:bg-blue-700:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity));
  }

  .lg\:odd\:bg-blue-800:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity));
  }

  .lg\:odd\:bg-blue-900:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity));
  }

  .lg\:odd\:bg-indigo-50:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity));
  }

  .lg\:odd\:bg-indigo-100:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity));
  }

  .lg\:odd\:bg-indigo-200:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity));
  }

  .lg\:odd\:bg-indigo-300:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity));
  }

  .lg\:odd\:bg-indigo-400:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity));
  }

  .lg\:odd\:bg-indigo-500:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity));
  }

  .lg\:odd\:bg-indigo-600:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity));
  }

  .lg\:odd\:bg-indigo-700:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity));
  }

  .lg\:odd\:bg-indigo-800:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity));
  }

  .lg\:odd\:bg-indigo-900:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity));
  }

  .lg\:odd\:bg-purple-50:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity));
  }

  .lg\:odd\:bg-purple-100:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity));
  }

  .lg\:odd\:bg-purple-200:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity));
  }

  .lg\:odd\:bg-purple-300:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity));
  }

  .lg\:odd\:bg-purple-400:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity));
  }

  .lg\:odd\:bg-purple-500:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity));
  }

  .lg\:odd\:bg-purple-600:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity));
  }

  .lg\:odd\:bg-purple-700:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity));
  }

  .lg\:odd\:bg-purple-800:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity));
  }

  .lg\:odd\:bg-purple-900:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity));
  }

  .lg\:odd\:bg-pink-50:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity));
  }

  .lg\:odd\:bg-pink-100:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity));
  }

  .lg\:odd\:bg-pink-200:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity));
  }

  .lg\:odd\:bg-pink-300:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity));
  }

  .lg\:odd\:bg-pink-400:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity));
  }

  .lg\:odd\:bg-pink-500:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity));
  }

  .lg\:odd\:bg-pink-600:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity));
  }

  .lg\:odd\:bg-pink-700:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity));
  }

  .lg\:odd\:bg-pink-800:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity));
  }

  .lg\:odd\:bg-pink-900:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity));
  }

  .lg\:bg-opacity-0 {
    --bg-opacity: 0;
  }

  .lg\:bg-opacity-10 {
    --bg-opacity: 0.1;
  }

  .lg\:bg-opacity-25 {
    --bg-opacity: 0.25;
  }

  .lg\:bg-opacity-50 {
    --bg-opacity: 0.5;
  }

  .lg\:bg-opacity-75 {
    --bg-opacity: 0.75;
  }

  .lg\:bg-opacity-100 {
    --bg-opacity: 1;
  }

  .lg\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .lg\:hover\:bg-opacity-10:hover {
    --bg-opacity: 0.1;
  }

  .lg\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25;
  }

  .lg\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5;
  }

  .lg\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75;
  }

  .lg\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .lg\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .lg\:focus\:bg-opacity-10:focus {
    --bg-opacity: 0.1;
  }

  .lg\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25;
  }

  .lg\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5;
  }

  .lg\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75;
  }

  .lg\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .lg\:bg-bottom {
    background-position: bottom;
  }

  .lg\:bg-center {
    background-position: center;
  }

  .lg\:bg-left {
    background-position: left;
  }

  .lg\:bg-left-bottom {
    background-position: left bottom;
  }

  .lg\:bg-left-top {
    background-position: left top;
  }

  .lg\:bg-right {
    background-position: right;
  }

  .lg\:bg-right-bottom {
    background-position: right bottom;
  }

  .lg\:bg-right-top {
    background-position: right top;
  }

  .lg\:bg-top {
    background-position: top;
  }

  .lg\:bg-repeat {
    background-repeat: repeat;
  }

  .lg\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .lg\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .lg\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .lg\:bg-repeat-round {
    background-repeat: round;
  }

  .lg\:bg-repeat-space {
    background-repeat: space;
  }

  .lg\:bg-auto {
    background-size: auto;
  }

  .lg\:bg-cover {
    background-size: cover;
  }

  .lg\:bg-contain {
    background-size: contain;
  }

  .lg\:border-collapse {
    border-collapse: collapse;
  }

  .lg\:border-separate {
    border-collapse: separate;
  }

  .lg\:border-transparent {
    border-color: transparent;
  }

  .lg\:border-white {
    --border-opacity: 1;
    border-color: #ffffff;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .lg\:border-black {
    --border-opacity: 1;
    border-color: #000000;
    border-color: rgba(0, 0, 0, var(--border-opacity));
  }

  .lg\:border-gray-50 {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity));
  }

  .lg\:border-gray-100 {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity));
  }

  .lg\:border-gray-200 {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity));
  }

  .lg\:border-gray-300 {
    --border-opacity: 1;
    border-color: #d5d6d7;
    border-color: rgba(213, 214, 215, var(--border-opacity));
  }

  .lg\:border-gray-400 {
    --border-opacity: 1;
    border-color: #9e9e9e;
    border-color: rgba(158, 158, 158, var(--border-opacity));
  }

  .lg\:border-gray-500 {
    --border-opacity: 1;
    border-color: #707275;
    border-color: rgba(112, 114, 117, var(--border-opacity));
  }

  .lg\:border-gray-600 {
    --border-opacity: 1;
    border-color: #4c4f52;
    border-color: rgba(76, 79, 82, var(--border-opacity));
  }

  .lg\:border-gray-700 {
    --border-opacity: 1;
    border-color: #24262d;
    border-color: rgba(36, 38, 45, var(--border-opacity));
  }

  .lg\:border-gray-800 {
    --border-opacity: 1;
    border-color: #1a1c23;
    border-color: rgba(26, 28, 35, var(--border-opacity));
  }

  .lg\:border-gray-900 {
    --border-opacity: 1;
    border-color: #121317;
    border-color: rgba(18, 19, 23, var(--border-opacity));
  }

  .lg\:border-cool-gray-50 {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity));
  }

  .lg\:border-cool-gray-100 {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity));
  }

  .lg\:border-cool-gray-200 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity));
  }

  .lg\:border-cool-gray-300 {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity));
  }

  .lg\:border-cool-gray-400 {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity));
  }

  .lg\:border-cool-gray-500 {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity));
  }

  .lg\:border-cool-gray-600 {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity));
  }

  .lg\:border-cool-gray-700 {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity));
  }

  .lg\:border-cool-gray-800 {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity));
  }

  .lg\:border-cool-gray-900 {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity));
  }

  .lg\:border-red-50 {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity));
  }

  .lg\:border-red-100 {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity));
  }

  .lg\:border-red-200 {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity));
  }

  .lg\:border-red-300 {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity));
  }

  .lg\:border-red-400 {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity));
  }

  .lg\:border-red-500 {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity));
  }

  .lg\:border-red-600 {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity));
  }

  .lg\:border-red-700 {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity));
  }

  .lg\:border-red-800 {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity));
  }

  .lg\:border-red-900 {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity));
  }

  .lg\:border-orange-50 {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity));
  }

  .lg\:border-orange-100 {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity));
  }

  .lg\:border-orange-200 {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity));
  }

  .lg\:border-orange-300 {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity));
  }

  .lg\:border-orange-400 {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity));
  }

  .lg\:border-orange-500 {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity));
  }

  .lg\:border-orange-600 {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity));
  }

  .lg\:border-orange-700 {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity));
  }

  .lg\:border-orange-800 {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity));
  }

  .lg\:border-orange-900 {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity));
  }

  .lg\:border-yellow-50 {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity));
  }

  .lg\:border-yellow-100 {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity));
  }

  .lg\:border-yellow-200 {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity));
  }

  .lg\:border-yellow-300 {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity));
  }

  .lg\:border-yellow-400 {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity));
  }

  .lg\:border-yellow-500 {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity));
  }

  .lg\:border-yellow-600 {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity));
  }

  .lg\:border-yellow-700 {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity));
  }

  .lg\:border-yellow-800 {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity));
  }

  .lg\:border-yellow-900 {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity));
  }

  .lg\:border-green-50 {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity));
  }

  .lg\:border-green-100 {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity));
  }

  .lg\:border-green-200 {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity));
  }

  .lg\:border-green-300 {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity));
  }

  .lg\:border-green-400 {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity));
  }

  .lg\:border-green-500 {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity));
  }

  .lg\:border-green-600 {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity));
  }

  .lg\:border-green-700 {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity));
  }

  .lg\:border-green-800 {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity));
  }

  .lg\:border-green-900 {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity));
  }

  .lg\:border-teal-50 {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity));
  }

  .lg\:border-teal-100 {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity));
  }

  .lg\:border-teal-200 {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity));
  }

  .lg\:border-teal-300 {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity));
  }

  .lg\:border-teal-400 {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity));
  }

  .lg\:border-teal-500 {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity));
  }

  .lg\:border-teal-600 {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity));
  }

  .lg\:border-teal-700 {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity));
  }

  .lg\:border-teal-800 {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity));
  }

  .lg\:border-teal-900 {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity));
  }

  .lg\:border-blue-50 {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity));
  }

  .lg\:border-blue-100 {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity));
  }

  .lg\:border-blue-200 {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity));
  }

  .lg\:border-blue-300 {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity));
  }

  .lg\:border-blue-400 {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity));
  }

  .lg\:border-blue-500 {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity));
  }

  .lg\:border-blue-600 {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity));
  }

  .lg\:border-blue-700 {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity));
  }

  .lg\:border-blue-800 {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity));
  }

  .lg\:border-blue-900 {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity));
  }

  .lg\:border-indigo-50 {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity));
  }

  .lg\:border-indigo-100 {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity));
  }

  .lg\:border-indigo-200 {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity));
  }

  .lg\:border-indigo-300 {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity));
  }

  .lg\:border-indigo-400 {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity));
  }

  .lg\:border-indigo-500 {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity));
  }

  .lg\:border-indigo-600 {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity));
  }

  .lg\:border-indigo-700 {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity));
  }

  .lg\:border-indigo-800 {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity));
  }

  .lg\:border-indigo-900 {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity));
  }

  .lg\:border-purple-50 {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity));
  }

  .lg\:border-purple-100 {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity));
  }

  .lg\:border-purple-200 {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity));
  }

  .lg\:border-purple-300 {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity));
  }

  .lg\:border-purple-400 {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity));
  }

  .lg\:border-purple-500 {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity));
  }

  .lg\:border-purple-600 {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity));
  }

  .lg\:border-purple-700 {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity));
  }

  .lg\:border-purple-800 {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity));
  }

  .lg\:border-purple-900 {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity));
  }

  .lg\:border-pink-50 {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity));
  }

  .lg\:border-pink-100 {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity));
  }

  .lg\:border-pink-200 {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity));
  }

  .lg\:border-pink-300 {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity));
  }

  .lg\:border-pink-400 {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity));
  }

  .lg\:border-pink-500 {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity));
  }

  .lg\:border-pink-600 {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity));
  }

  .lg\:border-pink-700 {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity));
  }

  .lg\:border-pink-800 {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity));
  }

  .lg\:border-pink-900 {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity));
  }

  .lg\:hover\:border-transparent:hover {
    border-color: transparent;
  }

  .lg\:hover\:border-white:hover {
    --border-opacity: 1;
    border-color: #ffffff;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .lg\:hover\:border-black:hover {
    --border-opacity: 1;
    border-color: #000000;
    border-color: rgba(0, 0, 0, var(--border-opacity));
  }

  .lg\:hover\:border-gray-50:hover {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity));
  }

  .lg\:hover\:border-gray-100:hover {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity));
  }

  .lg\:hover\:border-gray-200:hover {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity));
  }

  .lg\:hover\:border-gray-300:hover {
    --border-opacity: 1;
    border-color: #d5d6d7;
    border-color: rgba(213, 214, 215, var(--border-opacity));
  }

  .lg\:hover\:border-gray-400:hover {
    --border-opacity: 1;
    border-color: #9e9e9e;
    border-color: rgba(158, 158, 158, var(--border-opacity));
  }

  .lg\:hover\:border-gray-500:hover {
    --border-opacity: 1;
    border-color: #707275;
    border-color: rgba(112, 114, 117, var(--border-opacity));
  }

  .lg\:hover\:border-gray-600:hover {
    --border-opacity: 1;
    border-color: #4c4f52;
    border-color: rgba(76, 79, 82, var(--border-opacity));
  }

  .lg\:hover\:border-gray-700:hover {
    --border-opacity: 1;
    border-color: #24262d;
    border-color: rgba(36, 38, 45, var(--border-opacity));
  }

  .lg\:hover\:border-gray-800:hover {
    --border-opacity: 1;
    border-color: #1a1c23;
    border-color: rgba(26, 28, 35, var(--border-opacity));
  }

  .lg\:hover\:border-gray-900:hover {
    --border-opacity: 1;
    border-color: #121317;
    border-color: rgba(18, 19, 23, var(--border-opacity));
  }

  .lg\:hover\:border-cool-gray-50:hover {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity));
  }

  .lg\:hover\:border-cool-gray-100:hover {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity));
  }

  .lg\:hover\:border-cool-gray-200:hover {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity));
  }

  .lg\:hover\:border-cool-gray-300:hover {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity));
  }

  .lg\:hover\:border-cool-gray-400:hover {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity));
  }

  .lg\:hover\:border-cool-gray-500:hover {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity));
  }

  .lg\:hover\:border-cool-gray-600:hover {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity));
  }

  .lg\:hover\:border-cool-gray-700:hover {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity));
  }

  .lg\:hover\:border-cool-gray-800:hover {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity));
  }

  .lg\:hover\:border-cool-gray-900:hover {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity));
  }

  .lg\:hover\:border-red-50:hover {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity));
  }

  .lg\:hover\:border-red-100:hover {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity));
  }

  .lg\:hover\:border-red-200:hover {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity));
  }

  .lg\:hover\:border-red-300:hover {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity));
  }

  .lg\:hover\:border-red-400:hover {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity));
  }

  .lg\:hover\:border-red-500:hover {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity));
  }

  .lg\:hover\:border-red-600:hover {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity));
  }

  .lg\:hover\:border-red-700:hover {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity));
  }

  .lg\:hover\:border-red-800:hover {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity));
  }

  .lg\:hover\:border-red-900:hover {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity));
  }

  .lg\:hover\:border-orange-50:hover {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity));
  }

  .lg\:hover\:border-orange-100:hover {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity));
  }

  .lg\:hover\:border-orange-200:hover {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity));
  }

  .lg\:hover\:border-orange-300:hover {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity));
  }

  .lg\:hover\:border-orange-400:hover {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity));
  }

  .lg\:hover\:border-orange-500:hover {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity));
  }

  .lg\:hover\:border-orange-600:hover {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity));
  }

  .lg\:hover\:border-orange-700:hover {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity));
  }

  .lg\:hover\:border-orange-800:hover {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity));
  }

  .lg\:hover\:border-orange-900:hover {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity));
  }

  .lg\:hover\:border-yellow-50:hover {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity));
  }

  .lg\:hover\:border-yellow-100:hover {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity));
  }

  .lg\:hover\:border-yellow-200:hover {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity));
  }

  .lg\:hover\:border-yellow-300:hover {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity));
  }

  .lg\:hover\:border-yellow-400:hover {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity));
  }

  .lg\:hover\:border-yellow-500:hover {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity));
  }

  .lg\:hover\:border-yellow-600:hover {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity));
  }

  .lg\:hover\:border-yellow-700:hover {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity));
  }

  .lg\:hover\:border-yellow-800:hover {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity));
  }

  .lg\:hover\:border-yellow-900:hover {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity));
  }

  .lg\:hover\:border-green-50:hover {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity));
  }

  .lg\:hover\:border-green-100:hover {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity));
  }

  .lg\:hover\:border-green-200:hover {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity));
  }

  .lg\:hover\:border-green-300:hover {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity));
  }

  .lg\:hover\:border-green-400:hover {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity));
  }

  .lg\:hover\:border-green-500:hover {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity));
  }

  .lg\:hover\:border-green-600:hover {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity));
  }

  .lg\:hover\:border-green-700:hover {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity));
  }

  .lg\:hover\:border-green-800:hover {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity));
  }

  .lg\:hover\:border-green-900:hover {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity));
  }

  .lg\:hover\:border-teal-50:hover {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity));
  }

  .lg\:hover\:border-teal-100:hover {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity));
  }

  .lg\:hover\:border-teal-200:hover {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity));
  }

  .lg\:hover\:border-teal-300:hover {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity));
  }

  .lg\:hover\:border-teal-400:hover {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity));
  }

  .lg\:hover\:border-teal-500:hover {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity));
  }

  .lg\:hover\:border-teal-600:hover {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity));
  }

  .lg\:hover\:border-teal-700:hover {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity));
  }

  .lg\:hover\:border-teal-800:hover {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity));
  }

  .lg\:hover\:border-teal-900:hover {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity));
  }

  .lg\:hover\:border-blue-50:hover {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity));
  }

  .lg\:hover\:border-blue-100:hover {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity));
  }

  .lg\:hover\:border-blue-200:hover {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity));
  }

  .lg\:hover\:border-blue-300:hover {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity));
  }

  .lg\:hover\:border-blue-400:hover {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity));
  }

  .lg\:hover\:border-blue-500:hover {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity));
  }

  .lg\:hover\:border-blue-600:hover {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity));
  }

  .lg\:hover\:border-blue-700:hover {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity));
  }

  .lg\:hover\:border-blue-800:hover {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity));
  }

  .lg\:hover\:border-blue-900:hover {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity));
  }

  .lg\:hover\:border-indigo-50:hover {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity));
  }

  .lg\:hover\:border-indigo-100:hover {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity));
  }

  .lg\:hover\:border-indigo-200:hover {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity));
  }

  .lg\:hover\:border-indigo-300:hover {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity));
  }

  .lg\:hover\:border-indigo-400:hover {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity));
  }

  .lg\:hover\:border-indigo-500:hover {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity));
  }

  .lg\:hover\:border-indigo-600:hover {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity));
  }

  .lg\:hover\:border-indigo-700:hover {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity));
  }

  .lg\:hover\:border-indigo-800:hover {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity));
  }

  .lg\:hover\:border-indigo-900:hover {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity));
  }

  .lg\:hover\:border-purple-50:hover {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity));
  }

  .lg\:hover\:border-purple-100:hover {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity));
  }

  .lg\:hover\:border-purple-200:hover {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity));
  }

  .lg\:hover\:border-purple-300:hover {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity));
  }

  .lg\:hover\:border-purple-400:hover {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity));
  }

  .lg\:hover\:border-purple-500:hover {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity));
  }

  .lg\:hover\:border-purple-600:hover {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity));
  }

  .lg\:hover\:border-purple-700:hover {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity));
  }

  .lg\:hover\:border-purple-800:hover {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity));
  }

  .lg\:hover\:border-purple-900:hover {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity));
  }

  .lg\:hover\:border-pink-50:hover {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity));
  }

  .lg\:hover\:border-pink-100:hover {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity));
  }

  .lg\:hover\:border-pink-200:hover {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity));
  }

  .lg\:hover\:border-pink-300:hover {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity));
  }

  .lg\:hover\:border-pink-400:hover {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity));
  }

  .lg\:hover\:border-pink-500:hover {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity));
  }

  .lg\:hover\:border-pink-600:hover {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity));
  }

  .lg\:hover\:border-pink-700:hover {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity));
  }

  .lg\:hover\:border-pink-800:hover {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity));
  }

  .lg\:hover\:border-pink-900:hover {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity));
  }

  .lg\:focus\:border-transparent:focus {
    border-color: transparent;
  }

  .lg\:focus\:border-white:focus {
    --border-opacity: 1;
    border-color: #ffffff;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .lg\:focus\:border-black:focus {
    --border-opacity: 1;
    border-color: #000000;
    border-color: rgba(0, 0, 0, var(--border-opacity));
  }

  .lg\:focus\:border-gray-50:focus {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity));
  }

  .lg\:focus\:border-gray-100:focus {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity));
  }

  .lg\:focus\:border-gray-200:focus {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity));
  }

  .lg\:focus\:border-gray-300:focus {
    --border-opacity: 1;
    border-color: #d5d6d7;
    border-color: rgba(213, 214, 215, var(--border-opacity));
  }

  .lg\:focus\:border-gray-400:focus {
    --border-opacity: 1;
    border-color: #9e9e9e;
    border-color: rgba(158, 158, 158, var(--border-opacity));
  }

  .lg\:focus\:border-gray-500:focus {
    --border-opacity: 1;
    border-color: #707275;
    border-color: rgba(112, 114, 117, var(--border-opacity));
  }

  .lg\:focus\:border-gray-600:focus {
    --border-opacity: 1;
    border-color: #4c4f52;
    border-color: rgba(76, 79, 82, var(--border-opacity));
  }

  .lg\:focus\:border-gray-700:focus {
    --border-opacity: 1;
    border-color: #24262d;
    border-color: rgba(36, 38, 45, var(--border-opacity));
  }

  .lg\:focus\:border-gray-800:focus {
    --border-opacity: 1;
    border-color: #1a1c23;
    border-color: rgba(26, 28, 35, var(--border-opacity));
  }

  .lg\:focus\:border-gray-900:focus {
    --border-opacity: 1;
    border-color: #121317;
    border-color: rgba(18, 19, 23, var(--border-opacity));
  }

  .lg\:focus\:border-cool-gray-50:focus {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity));
  }

  .lg\:focus\:border-cool-gray-100:focus {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity));
  }

  .lg\:focus\:border-cool-gray-200:focus {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity));
  }

  .lg\:focus\:border-cool-gray-300:focus {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity));
  }

  .lg\:focus\:border-cool-gray-400:focus {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity));
  }

  .lg\:focus\:border-cool-gray-500:focus {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity));
  }

  .lg\:focus\:border-cool-gray-600:focus {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity));
  }

  .lg\:focus\:border-cool-gray-700:focus {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity));
  }

  .lg\:focus\:border-cool-gray-800:focus {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity));
  }

  .lg\:focus\:border-cool-gray-900:focus {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity));
  }

  .lg\:focus\:border-red-50:focus {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity));
  }

  .lg\:focus\:border-red-100:focus {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity));
  }

  .lg\:focus\:border-red-200:focus {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity));
  }

  .lg\:focus\:border-red-300:focus {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity));
  }

  .lg\:focus\:border-red-400:focus {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity));
  }

  .lg\:focus\:border-red-500:focus {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity));
  }

  .lg\:focus\:border-red-600:focus {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity));
  }

  .lg\:focus\:border-red-700:focus {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity));
  }

  .lg\:focus\:border-red-800:focus {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity));
  }

  .lg\:focus\:border-red-900:focus {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity));
  }

  .lg\:focus\:border-orange-50:focus {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity));
  }

  .lg\:focus\:border-orange-100:focus {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity));
  }

  .lg\:focus\:border-orange-200:focus {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity));
  }

  .lg\:focus\:border-orange-300:focus {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity));
  }

  .lg\:focus\:border-orange-400:focus {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity));
  }

  .lg\:focus\:border-orange-500:focus {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity));
  }

  .lg\:focus\:border-orange-600:focus {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity));
  }

  .lg\:focus\:border-orange-700:focus {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity));
  }

  .lg\:focus\:border-orange-800:focus {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity));
  }

  .lg\:focus\:border-orange-900:focus {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity));
  }

  .lg\:focus\:border-yellow-50:focus {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity));
  }

  .lg\:focus\:border-yellow-100:focus {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity));
  }

  .lg\:focus\:border-yellow-200:focus {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity));
  }

  .lg\:focus\:border-yellow-300:focus {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity));
  }

  .lg\:focus\:border-yellow-400:focus {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity));
  }

  .lg\:focus\:border-yellow-500:focus {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity));
  }

  .lg\:focus\:border-yellow-600:focus {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity));
  }

  .lg\:focus\:border-yellow-700:focus {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity));
  }

  .lg\:focus\:border-yellow-800:focus {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity));
  }

  .lg\:focus\:border-yellow-900:focus {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity));
  }

  .lg\:focus\:border-green-50:focus {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity));
  }

  .lg\:focus\:border-green-100:focus {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity));
  }

  .lg\:focus\:border-green-200:focus {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity));
  }

  .lg\:focus\:border-green-300:focus {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity));
  }

  .lg\:focus\:border-green-400:focus {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity));
  }

  .lg\:focus\:border-green-500:focus {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity));
  }

  .lg\:focus\:border-green-600:focus {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity));
  }

  .lg\:focus\:border-green-700:focus {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity));
  }

  .lg\:focus\:border-green-800:focus {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity));
  }

  .lg\:focus\:border-green-900:focus {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity));
  }

  .lg\:focus\:border-teal-50:focus {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity));
  }

  .lg\:focus\:border-teal-100:focus {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity));
  }

  .lg\:focus\:border-teal-200:focus {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity));
  }

  .lg\:focus\:border-teal-300:focus {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity));
  }

  .lg\:focus\:border-teal-400:focus {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity));
  }

  .lg\:focus\:border-teal-500:focus {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity));
  }

  .lg\:focus\:border-teal-600:focus {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity));
  }

  .lg\:focus\:border-teal-700:focus {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity));
  }

  .lg\:focus\:border-teal-800:focus {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity));
  }

  .lg\:focus\:border-teal-900:focus {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity));
  }

  .lg\:focus\:border-blue-50:focus {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity));
  }

  .lg\:focus\:border-blue-100:focus {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity));
  }

  .lg\:focus\:border-blue-200:focus {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity));
  }

  .lg\:focus\:border-blue-300:focus {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity));
  }

  .lg\:focus\:border-blue-400:focus {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity));
  }

  .lg\:focus\:border-blue-500:focus {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity));
  }

  .lg\:focus\:border-blue-600:focus {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity));
  }

  .lg\:focus\:border-blue-700:focus {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity));
  }

  .lg\:focus\:border-blue-800:focus {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity));
  }

  .lg\:focus\:border-blue-900:focus {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity));
  }

  .lg\:focus\:border-indigo-50:focus {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity));
  }

  .lg\:focus\:border-indigo-100:focus {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity));
  }

  .lg\:focus\:border-indigo-200:focus {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity));
  }

  .lg\:focus\:border-indigo-300:focus {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity));
  }

  .lg\:focus\:border-indigo-400:focus {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity));
  }

  .lg\:focus\:border-indigo-500:focus {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity));
  }

  .lg\:focus\:border-indigo-600:focus {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity));
  }

  .lg\:focus\:border-indigo-700:focus {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity));
  }

  .lg\:focus\:border-indigo-800:focus {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity));
  }

  .lg\:focus\:border-indigo-900:focus {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity));
  }

  .lg\:focus\:border-purple-50:focus {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity));
  }

  .lg\:focus\:border-purple-100:focus {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity));
  }

  .lg\:focus\:border-purple-200:focus {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity));
  }

  .lg\:focus\:border-purple-300:focus {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity));
  }

  .lg\:focus\:border-purple-400:focus {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity));
  }

  .lg\:focus\:border-purple-500:focus {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity));
  }

  .lg\:focus\:border-purple-600:focus {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity));
  }

  .lg\:focus\:border-purple-700:focus {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity));
  }

  .lg\:focus\:border-purple-800:focus {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity));
  }

  .lg\:focus\:border-purple-900:focus {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity));
  }

  .lg\:focus\:border-pink-50:focus {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity));
  }

  .lg\:focus\:border-pink-100:focus {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity));
  }

  .lg\:focus\:border-pink-200:focus {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity));
  }

  .lg\:focus\:border-pink-300:focus {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity));
  }

  .lg\:focus\:border-pink-400:focus {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity));
  }

  .lg\:focus\:border-pink-500:focus {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity));
  }

  .lg\:focus\:border-pink-600:focus {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity));
  }

  .lg\:focus\:border-pink-700:focus {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity));
  }

  .lg\:focus\:border-pink-800:focus {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity));
  }

  .lg\:focus\:border-pink-900:focus {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity));
  }

  .lg\:border-opacity-0 {
    --border-opacity: 0;
  }

  .lg\:border-opacity-25 {
    --border-opacity: 0.25;
  }

  .lg\:border-opacity-50 {
    --border-opacity: 0.5;
  }

  .lg\:border-opacity-75 {
    --border-opacity: 0.75;
  }

  .lg\:border-opacity-100 {
    --border-opacity: 1;
  }

  .lg\:hover\:border-opacity-0:hover {
    --border-opacity: 0;
  }

  .lg\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25;
  }

  .lg\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5;
  }

  .lg\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75;
  }

  .lg\:hover\:border-opacity-100:hover {
    --border-opacity: 1;
  }

  .lg\:focus\:border-opacity-0:focus {
    --border-opacity: 0;
  }

  .lg\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25;
  }

  .lg\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5;
  }

  .lg\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75;
  }

  .lg\:focus\:border-opacity-100:focus {
    --border-opacity: 1;
  }

  .lg\:rounded-none {
    border-radius: 0;
  }

  .lg\:rounded-sm {
    border-radius: 0.125rem;
  }

  .lg\:rounded {
    border-radius: 0.25rem;
  }

  .lg\:rounded-md {
    border-radius: 0.375rem;
  }

  .lg\:rounded-lg {
    border-radius: 0.5rem;
  }

  .lg\:rounded-full {
    border-radius: 9999px;
  }

  .lg\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .lg\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .lg\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .lg\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .lg\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .lg\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .lg\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .lg\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .lg\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .lg\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .lg\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .lg\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .lg\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .lg\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .lg\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .lg\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .lg\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .lg\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .lg\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .lg\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .lg\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .lg\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .lg\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .lg\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .lg\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .lg\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .lg\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .lg\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .lg\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }

  .lg\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }

  .lg\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }

  .lg\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }

  .lg\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .lg\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .lg\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .lg\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .lg\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .lg\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .lg\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .lg\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .lg\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .lg\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .lg\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .lg\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .lg\:border-solid {
    border-style: solid;
  }

  .lg\:border-dashed {
    border-style: dashed;
  }

  .lg\:border-dotted {
    border-style: dotted;
  }

  .lg\:border-double {
    border-style: double;
  }

  .lg\:border-none {
    border-style: none;
  }

  .lg\:border-0 {
    border-width: 0;
  }

  .lg\:border-2 {
    border-width: 2px;
  }

  .lg\:border-4 {
    border-width: 4px;
  }

  .lg\:border-8 {
    border-width: 8px;
  }

  .lg\:border {
    border-width: 1px;
  }

  .lg\:border-t-0 {
    border-top-width: 0;
  }

  .lg\:border-r-0 {
    border-right-width: 0;
  }

  .lg\:border-b-0 {
    border-bottom-width: 0;
  }

  .lg\:border-l-0 {
    border-left-width: 0;
  }

  .lg\:border-t-2 {
    border-top-width: 2px;
  }

  .lg\:border-r-2 {
    border-right-width: 2px;
  }

  .lg\:border-b-2 {
    border-bottom-width: 2px;
  }

  .lg\:border-l-2 {
    border-left-width: 2px;
  }

  .lg\:border-t-4 {
    border-top-width: 4px;
  }

  .lg\:border-r-4 {
    border-right-width: 4px;
  }

  .lg\:border-b-4 {
    border-bottom-width: 4px;
  }

  .lg\:border-l-4 {
    border-left-width: 4px;
  }

  .lg\:border-t-8 {
    border-top-width: 8px;
  }

  .lg\:border-r-8 {
    border-right-width: 8px;
  }

  .lg\:border-b-8 {
    border-bottom-width: 8px;
  }

  .lg\:border-l-8 {
    border-left-width: 8px;
  }

  .lg\:border-t {
    border-top-width: 1px;
  }

  .lg\:border-r {
    border-right-width: 1px;
  }

  .lg\:border-b {
    border-bottom-width: 1px;
  }

  .lg\:border-l {
    border-left-width: 1px;
  }

  .lg\:box-border {
    box-sizing: border-box;
  }

  .lg\:box-content {
    box-sizing: content-box;
  }

  .lg\:cursor-auto {
    cursor: auto;
  }

  .lg\:cursor-default {
    cursor: default;
  }

  .lg\:cursor-pointer {
    cursor: pointer;
  }

  .lg\:cursor-wait {
    cursor: wait;
  }

  .lg\:cursor-text {
    cursor: text;
  }

  .lg\:cursor-move {
    cursor: move;
  }

  .lg\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:inline-flex {
    display: inline-flex;
  }

  .lg\:table {
    display: table;
  }

  .lg\:table-caption {
    display: table-caption;
  }

  .lg\:table-cell {
    display: table-cell;
  }

  .lg\:table-column {
    display: table-column;
  }

  .lg\:table-column-group {
    display: table-column-group;
  }

  .lg\:table-footer-group {
    display: table-footer-group;
  }

  .lg\:table-header-group {
    display: table-header-group;
  }

  .lg\:table-row-group {
    display: table-row-group;
  }

  .lg\:table-row {
    display: table-row;
  }

  .lg\:flow-root {
    display: flow-root;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:inline-grid {
    display: inline-grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .lg\:flex-wrap {
    flex-wrap: wrap;
  }

  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .lg\:flex-no-wrap {
    flex-wrap: nowrap;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-end {
    align-items: flex-end;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:items-baseline {
    align-items: baseline;
  }

  .lg\:items-stretch {
    align-items: stretch;
  }

  .lg\:self-auto {
    align-self: auto;
  }

  .lg\:self-start {
    align-self: flex-start;
  }

  .lg\:self-end {
    align-self: flex-end;
  }

  .lg\:self-center {
    align-self: center;
  }

  .lg\:self-stretch {
    align-self: stretch;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:justify-around {
    justify-content: space-around;
  }

  .lg\:justify-evenly {
    justify-content: space-evenly;
  }

  .lg\:content-center {
    align-content: center;
  }

  .lg\:content-start {
    align-content: flex-start;
  }

  .lg\:content-end {
    align-content: flex-end;
  }

  .lg\:content-between {
    align-content: space-between;
  }

  .lg\:content-around {
    align-content: space-around;
  }

  .lg\:flex-1 {
    flex: 1 1 0%;
  }

  .lg\:flex-auto {
    flex: 1 1 auto;
  }

  .lg\:flex-initial {
    flex: 0 1 auto;
  }

  .lg\:flex-none {
    flex: none;
  }

  .lg\:flex-grow-0 {
    flex-grow: 0;
  }

  .lg\:flex-grow {
    flex-grow: 1;
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .lg\:flex-shrink {
    flex-shrink: 1;
  }

  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:order-3 {
    order: 3;
  }

  .lg\:order-4 {
    order: 4;
  }

  .lg\:order-5 {
    order: 5;
  }

  .lg\:order-6 {
    order: 6;
  }

  .lg\:order-7 {
    order: 7;
  }

  .lg\:order-8 {
    order: 8;
  }

  .lg\:order-9 {
    order: 9;
  }

  .lg\:order-10 {
    order: 10;
  }

  .lg\:order-11 {
    order: 11;
  }

  .lg\:order-12 {
    order: 12;
  }

  .lg\:order-first {
    order: -9999;
  }

  .lg\:order-last {
    order: 9999;
  }

  .lg\:order-none {
    order: 0;
  }

  .lg\:float-right {
    float: right;
  }

  .lg\:float-left {
    float: left;
  }

  .lg\:float-none {
    float: none;
  }

  .lg\:clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .lg\:clear-left {
    clear: left;
  }

  .lg\:clear-right {
    clear: right;
  }

  .lg\:clear-both {
    clear: both;
  }

  .lg\:clear-none {
    clear: none;
  }

  .lg\:font-sans {
    font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  }

  .lg\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .lg\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
      monospace;
  }

  .lg\:font-hairline {
    font-weight: 100;
  }

  .lg\:font-thin {
    font-weight: 200;
  }

  .lg\:font-light {
    font-weight: 300;
  }

  .lg\:font-normal {
    font-weight: 400;
  }

  .lg\:font-medium {
    font-weight: 500;
  }

  .lg\:font-semibold {
    font-weight: 600;
  }

  .lg\:font-bold {
    font-weight: 700;
  }

  .lg\:font-extrabold {
    font-weight: 800;
  }

  .lg\:font-black {
    font-weight: 900;
  }

  .lg\:hover\:font-hairline:hover {
    font-weight: 100;
  }

  .lg\:hover\:font-thin:hover {
    font-weight: 200;
  }

  .lg\:hover\:font-light:hover {
    font-weight: 300;
  }

  .lg\:hover\:font-normal:hover {
    font-weight: 400;
  }

  .lg\:hover\:font-medium:hover {
    font-weight: 500;
  }

  .lg\:hover\:font-semibold:hover {
    font-weight: 600;
  }

  .lg\:hover\:font-bold:hover {
    font-weight: 700;
  }

  .lg\:hover\:font-extrabold:hover {
    font-weight: 800;
  }

  .lg\:hover\:font-black:hover {
    font-weight: 900;
  }

  .lg\:focus\:font-hairline:focus {
    font-weight: 100;
  }

  .lg\:focus\:font-thin:focus {
    font-weight: 200;
  }

  .lg\:focus\:font-light:focus {
    font-weight: 300;
  }

  .lg\:focus\:font-normal:focus {
    font-weight: 400;
  }

  .lg\:focus\:font-medium:focus {
    font-weight: 500;
  }

  .lg\:focus\:font-semibold:focus {
    font-weight: 600;
  }

  .lg\:focus\:font-bold:focus {
    font-weight: 700;
  }

  .lg\:focus\:font-extrabold:focus {
    font-weight: 800;
  }

  .lg\:focus\:font-black:focus {
    font-weight: 900;
  }

  .lg\:h-0 {
    height: 0;
  }

  .lg\:h-1 {
    height: 0.25rem;
  }

  .lg\:h-2 {
    height: 0.5rem;
  }

  .lg\:h-3 {
    height: 0.75rem;
  }

  .lg\:h-4 {
    height: 1rem;
  }

  .lg\:h-5 {
    height: 1.25rem;
  }

  .lg\:h-6 {
    height: 1.5rem;
  }

  .lg\:h-8 {
    height: 2rem;
  }

  .lg\:h-10 {
    height: 2.5rem;
  }

  .lg\:h-12 {
    height: 3rem;
  }

  .lg\:h-16 {
    height: 4rem;
  }

  .lg\:h-20 {
    height: 5rem;
  }

  .lg\:h-24 {
    height: 6rem;
  }

  .lg\:h-32 {
    height: 8rem;
  }

  .lg\:h-40 {
    height: 10rem;
  }

  .lg\:h-48 {
    height: 12rem;
  }

  .lg\:h-56 {
    height: 14rem;
  }

  .lg\:h-64 {
    height: 16rem;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:h-px {
    height: 1px;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:h-screen {
    height: 100vh;
  }

  .lg\:text-xs {
    font-size: 0.75rem;
  }

  .lg\:text-sm {
    font-size: 0.875rem;
  }

  .lg\:text-base {
    font-size: 1rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
  }

  .lg\:text-6xl {
    font-size: 4rem;
  }

  .lg\:leading-3 {
    line-height: 0.75rem;
  }

  .lg\:leading-4 {
    line-height: 1rem;
  }

  .lg\:leading-5 {
    line-height: 1.25rem;
  }

  .lg\:leading-6 {
    line-height: 1.5rem;
  }

  .lg\:leading-7 {
    line-height: 1.75rem;
  }

  .lg\:leading-8 {
    line-height: 2rem;
  }

  .lg\:leading-9 {
    line-height: 2.25rem;
  }

  .lg\:leading-10 {
    line-height: 2.5rem;
  }

  .lg\:leading-none {
    line-height: 1;
  }

  .lg\:leading-tight {
    line-height: 1.25;
  }

  .lg\:leading-snug {
    line-height: 1.375;
  }

  .lg\:leading-normal {
    line-height: 1.5;
  }

  .lg\:leading-relaxed {
    line-height: 1.625;
  }

  .lg\:leading-loose {
    line-height: 2;
  }

  .lg\:list-inside {
    list-style-position: inside;
  }

  .lg\:list-outside {
    list-style-position: outside;
  }

  .lg\:list-none {
    list-style-type: none;
  }

  .lg\:list-disc {
    list-style-type: disc;
  }

  .lg\:list-decimal {
    list-style-type: decimal;
  }

  .lg\:m-0 {
    margin: 0;
  }

  .lg\:m-1 {
    margin: 0.25rem;
  }

  .lg\:m-2 {
    margin: 0.5rem;
  }

  .lg\:m-3 {
    margin: 0.75rem;
  }

  .lg\:m-4 {
    margin: 1rem;
  }

  .lg\:m-5 {
    margin: 1.25rem;
  }

  .lg\:m-6 {
    margin: 1.5rem;
  }

  .lg\:m-8 {
    margin: 2rem;
  }

  .lg\:m-10 {
    margin: 2.5rem;
  }

  .lg\:m-12 {
    margin: 3rem;
  }

  .lg\:m-16 {
    margin: 4rem;
  }

  .lg\:m-20 {
    margin: 5rem;
  }

  .lg\:m-24 {
    margin: 6rem;
  }

  .lg\:m-32 {
    margin: 8rem;
  }

  .lg\:m-40 {
    margin: 10rem;
  }

  .lg\:m-48 {
    margin: 12rem;
  }

  .lg\:m-56 {
    margin: 14rem;
  }

  .lg\:m-64 {
    margin: 16rem;
  }

  .lg\:m-auto {
    margin: auto;
  }

  .lg\:m-px {
    margin: 1px;
  }

  .lg\:-m-1 {
    margin: -0.25rem;
  }

  .lg\:-m-2 {
    margin: -0.5rem;
  }

  .lg\:-m-3 {
    margin: -0.75rem;
  }

  .lg\:-m-4 {
    margin: -1rem;
  }

  .lg\:-m-5 {
    margin: -1.25rem;
  }

  .lg\:-m-6 {
    margin: -1.5rem;
  }

  .lg\:-m-8 {
    margin: -2rem;
  }

  .lg\:-m-10 {
    margin: -2.5rem;
  }

  .lg\:-m-12 {
    margin: -3rem;
  }

  .lg\:-m-16 {
    margin: -4rem;
  }

  .lg\:-m-20 {
    margin: -5rem;
  }

  .lg\:-m-24 {
    margin: -6rem;
  }

  .lg\:-m-32 {
    margin: -8rem;
  }

  .lg\:-m-40 {
    margin: -10rem;
  }

  .lg\:-m-48 {
    margin: -12rem;
  }

  .lg\:-m-56 {
    margin: -14rem;
  }

  .lg\:-m-64 {
    margin: -16rem;
  }

  .lg\:-m-px {
    margin: -1px;
  }

  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .lg\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .lg\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .lg\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .lg\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .lg\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .lg\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .lg\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .lg\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .lg\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .lg\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .lg\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .lg\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .lg\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .lg\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .lg\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .lg\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .lg\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .lg\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .lg\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .lg\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .lg\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .lg\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .lg\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .lg\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .lg\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .lg\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .lg\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .lg\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .lg\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .lg\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .lg\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .lg\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .lg\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .lg\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .lg\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .lg\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .lg\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .lg\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .lg\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .lg\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .lg\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .lg\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .lg\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .lg\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .lg\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .lg\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .lg\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .lg\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .lg\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .lg\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .lg\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .lg\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .lg\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .lg\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .lg\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .lg\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .lg\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .lg\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .lg\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .lg\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .lg\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .lg\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .lg\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .lg\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:mt-1 {
    margin-top: 0.25rem;
  }

  .lg\:mr-1 {
    margin-right: 0.25rem;
  }

  .lg\:mb-1 {
    margin-bottom: 0.25rem;
  }

  .lg\:ml-1 {
    margin-left: 0.25rem;
  }

  .lg\:mt-2 {
    margin-top: 0.5rem;
  }

  .lg\:mr-2 {
    margin-right: 0.5rem;
  }

  .lg\:mb-2 {
    margin-bottom: 0.5rem;
  }

  .lg\:ml-2 {
    margin-left: 0.5rem;
  }

  .lg\:mt-3 {
    margin-top: 0.75rem;
  }

  .lg\:mr-3 {
    margin-right: 0.75rem;
  }

  .lg\:mb-3 {
    margin-bottom: 0.75rem;
  }

  .lg\:ml-3 {
    margin-left: 0.75rem;
  }

  .lg\:mt-4 {
    margin-top: 1rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:ml-4 {
    margin-left: 1rem;
  }

  .lg\:mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:mr-5 {
    margin-right: 1.25rem;
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:ml-5 {
    margin-left: 1.25rem;
  }

  .lg\:mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:mr-6 {
    margin-right: 1.5rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:ml-6 {
    margin-left: 1.5rem;
  }

  .lg\:mt-8 {
    margin-top: 2rem;
  }

  .lg\:mr-8 {
    margin-right: 2rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:mr-10 {
    margin-right: 2.5rem;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:ml-10 {
    margin-left: 2.5rem;
  }

  .lg\:mt-12 {
    margin-top: 3rem;
  }

  .lg\:mr-12 {
    margin-right: 3rem;
  }

  .lg\:mb-12 {
    margin-bottom: 3rem;
  }

  .lg\:ml-12 {
    margin-left: 3rem;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:mr-16 {
    margin-right: 4rem;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:ml-16 {
    margin-left: 4rem;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:mr-20 {
    margin-right: 5rem;
  }

  .lg\:mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:ml-20 {
    margin-left: 5rem;
  }

  .lg\:mt-24 {
    margin-top: 6rem;
  }

  .lg\:mr-24 {
    margin-right: 6rem;
  }

  .lg\:mb-24 {
    margin-bottom: 6rem;
  }

  .lg\:ml-24 {
    margin-left: 6rem;
  }

  .lg\:mt-32 {
    margin-top: 8rem;
  }

  .lg\:mr-32 {
    margin-right: 8rem;
  }

  .lg\:mb-32 {
    margin-bottom: 8rem;
  }

  .lg\:ml-32 {
    margin-left: 8rem;
  }

  .lg\:mt-40 {
    margin-top: 10rem;
  }

  .lg\:mr-40 {
    margin-right: 10rem;
  }

  .lg\:mb-40 {
    margin-bottom: 10rem;
  }

  .lg\:ml-40 {
    margin-left: 10rem;
  }

  .lg\:mt-48 {
    margin-top: 12rem;
  }

  .lg\:mr-48 {
    margin-right: 12rem;
  }

  .lg\:mb-48 {
    margin-bottom: 12rem;
  }

  .lg\:ml-48 {
    margin-left: 12rem;
  }

  .lg\:mt-56 {
    margin-top: 14rem;
  }

  .lg\:mr-56 {
    margin-right: 14rem;
  }

  .lg\:mb-56 {
    margin-bottom: 14rem;
  }

  .lg\:ml-56 {
    margin-left: 14rem;
  }

  .lg\:mt-64 {
    margin-top: 16rem;
  }

  .lg\:mr-64 {
    margin-right: 16rem;
  }

  .lg\:mb-64 {
    margin-bottom: 16rem;
  }

  .lg\:ml-64 {
    margin-left: 16rem;
  }

  .lg\:mt-auto {
    margin-top: auto;
  }

  .lg\:mr-auto {
    margin-right: auto;
  }

  .lg\:mb-auto {
    margin-bottom: auto;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:mt-px {
    margin-top: 1px;
  }

  .lg\:mr-px {
    margin-right: 1px;
  }

  .lg\:mb-px {
    margin-bottom: 1px;
  }

  .lg\:ml-px {
    margin-left: 1px;
  }

  .lg\:-mt-1 {
    margin-top: -0.25rem;
  }

  .lg\:-mr-1 {
    margin-right: -0.25rem;
  }

  .lg\:-mb-1 {
    margin-bottom: -0.25rem;
  }

  .lg\:-ml-1 {
    margin-left: -0.25rem;
  }

  .lg\:-mt-2 {
    margin-top: -0.5rem;
  }

  .lg\:-mr-2 {
    margin-right: -0.5rem;
  }

  .lg\:-mb-2 {
    margin-bottom: -0.5rem;
  }

  .lg\:-ml-2 {
    margin-left: -0.5rem;
  }

  .lg\:-mt-3 {
    margin-top: -0.75rem;
  }

  .lg\:-mr-3 {
    margin-right: -0.75rem;
  }

  .lg\:-mb-3 {
    margin-bottom: -0.75rem;
  }

  .lg\:-ml-3 {
    margin-left: -0.75rem;
  }

  .lg\:-mt-4 {
    margin-top: -1rem;
  }

  .lg\:-mr-4 {
    margin-right: -1rem;
  }

  .lg\:-mb-4 {
    margin-bottom: -1rem;
  }

  .lg\:-ml-4 {
    margin-left: -1rem;
  }

  .lg\:-mt-5 {
    margin-top: -1.25rem;
  }

  .lg\:-mr-5 {
    margin-right: -1.25rem;
  }

  .lg\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .lg\:-ml-5 {
    margin-left: -1.25rem;
  }

  .lg\:-mt-6 {
    margin-top: -1.5rem;
  }

  .lg\:-mr-6 {
    margin-right: -1.5rem;
  }

  .lg\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .lg\:-ml-6 {
    margin-left: -1.5rem;
  }

  .lg\:-mt-8 {
    margin-top: -2rem;
  }

  .lg\:-mr-8 {
    margin-right: -2rem;
  }

  .lg\:-mb-8 {
    margin-bottom: -2rem;
  }

  .lg\:-ml-8 {
    margin-left: -2rem;
  }

  .lg\:-mt-10 {
    margin-top: -2.5rem;
  }

  .lg\:-mr-10 {
    margin-right: -2.5rem;
  }

  .lg\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .lg\:-ml-10 {
    margin-left: -2.5rem;
  }

  .lg\:-mt-12 {
    margin-top: -3rem;
  }

  .lg\:-mr-12 {
    margin-right: -3rem;
  }

  .lg\:-mb-12 {
    margin-bottom: -3rem;
  }

  .lg\:-ml-12 {
    margin-left: -3rem;
  }

  .lg\:-mt-16 {
    margin-top: -4rem;
  }

  .lg\:-mr-16 {
    margin-right: -4rem;
  }

  .lg\:-mb-16 {
    margin-bottom: -4rem;
  }

  .lg\:-ml-16 {
    margin-left: -4rem;
  }

  .lg\:-mt-20 {
    margin-top: -5rem;
  }

  .lg\:-mr-20 {
    margin-right: -5rem;
  }

  .lg\:-mb-20 {
    margin-bottom: -5rem;
  }

  .lg\:-ml-20 {
    margin-left: -5rem;
  }

  .lg\:-mt-24 {
    margin-top: -6rem;
  }

  .lg\:-mr-24 {
    margin-right: -6rem;
  }

  .lg\:-mb-24 {
    margin-bottom: -6rem;
  }

  .lg\:-ml-24 {
    margin-left: -6rem;
  }

  .lg\:-mt-32 {
    margin-top: -8rem;
  }

  .lg\:-mr-32 {
    margin-right: -8rem;
  }

  .lg\:-mb-32 {
    margin-bottom: -8rem;
  }

  .lg\:-ml-32 {
    margin-left: -8rem;
  }

  .lg\:-mt-40 {
    margin-top: -10rem;
  }

  .lg\:-mr-40 {
    margin-right: -10rem;
  }

  .lg\:-mb-40 {
    margin-bottom: -10rem;
  }

  .lg\:-ml-40 {
    margin-left: -10rem;
  }

  .lg\:-mt-48 {
    margin-top: -12rem;
  }

  .lg\:-mr-48 {
    margin-right: -12rem;
  }

  .lg\:-mb-48 {
    margin-bottom: -12rem;
  }

  .lg\:-ml-48 {
    margin-left: -12rem;
  }

  .lg\:-mt-56 {
    margin-top: -14rem;
  }

  .lg\:-mr-56 {
    margin-right: -14rem;
  }

  .lg\:-mb-56 {
    margin-bottom: -14rem;
  }

  .lg\:-ml-56 {
    margin-left: -14rem;
  }

  .lg\:-mt-64 {
    margin-top: -16rem;
  }

  .lg\:-mr-64 {
    margin-right: -16rem;
  }

  .lg\:-mb-64 {
    margin-bottom: -16rem;
  }

  .lg\:-ml-64 {
    margin-left: -16rem;
  }

  .lg\:-mt-px {
    margin-top: -1px;
  }

  .lg\:-mr-px {
    margin-right: -1px;
  }

  .lg\:-mb-px {
    margin-bottom: -1px;
  }

  .lg\:-ml-px {
    margin-left: -1px;
  }

  .lg\:last\:m-0:last-child {
    margin: 0;
  }

  .lg\:last\:m-1:last-child {
    margin: 0.25rem;
  }

  .lg\:last\:m-2:last-child {
    margin: 0.5rem;
  }

  .lg\:last\:m-3:last-child {
    margin: 0.75rem;
  }

  .lg\:last\:m-4:last-child {
    margin: 1rem;
  }

  .lg\:last\:m-5:last-child {
    margin: 1.25rem;
  }

  .lg\:last\:m-6:last-child {
    margin: 1.5rem;
  }

  .lg\:last\:m-8:last-child {
    margin: 2rem;
  }

  .lg\:last\:m-10:last-child {
    margin: 2.5rem;
  }

  .lg\:last\:m-12:last-child {
    margin: 3rem;
  }

  .lg\:last\:m-16:last-child {
    margin: 4rem;
  }

  .lg\:last\:m-20:last-child {
    margin: 5rem;
  }

  .lg\:last\:m-24:last-child {
    margin: 6rem;
  }

  .lg\:last\:m-32:last-child {
    margin: 8rem;
  }

  .lg\:last\:m-40:last-child {
    margin: 10rem;
  }

  .lg\:last\:m-48:last-child {
    margin: 12rem;
  }

  .lg\:last\:m-56:last-child {
    margin: 14rem;
  }

  .lg\:last\:m-64:last-child {
    margin: 16rem;
  }

  .lg\:last\:m-auto:last-child {
    margin: auto;
  }

  .lg\:last\:m-px:last-child {
    margin: 1px;
  }

  .lg\:last\:-m-1:last-child {
    margin: -0.25rem;
  }

  .lg\:last\:-m-2:last-child {
    margin: -0.5rem;
  }

  .lg\:last\:-m-3:last-child {
    margin: -0.75rem;
  }

  .lg\:last\:-m-4:last-child {
    margin: -1rem;
  }

  .lg\:last\:-m-5:last-child {
    margin: -1.25rem;
  }

  .lg\:last\:-m-6:last-child {
    margin: -1.5rem;
  }

  .lg\:last\:-m-8:last-child {
    margin: -2rem;
  }

  .lg\:last\:-m-10:last-child {
    margin: -2.5rem;
  }

  .lg\:last\:-m-12:last-child {
    margin: -3rem;
  }

  .lg\:last\:-m-16:last-child {
    margin: -4rem;
  }

  .lg\:last\:-m-20:last-child {
    margin: -5rem;
  }

  .lg\:last\:-m-24:last-child {
    margin: -6rem;
  }

  .lg\:last\:-m-32:last-child {
    margin: -8rem;
  }

  .lg\:last\:-m-40:last-child {
    margin: -10rem;
  }

  .lg\:last\:-m-48:last-child {
    margin: -12rem;
  }

  .lg\:last\:-m-56:last-child {
    margin: -14rem;
  }

  .lg\:last\:-m-64:last-child {
    margin: -16rem;
  }

  .lg\:last\:-m-px:last-child {
    margin: -1px;
  }

  .lg\:last\:my-0:last-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:last\:mx-0:last-child {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:last\:my-1:last-child {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .lg\:last\:mx-1:last-child {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .lg\:last\:my-2:last-child {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .lg\:last\:mx-2:last-child {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .lg\:last\:my-3:last-child {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .lg\:last\:mx-3:last-child {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .lg\:last\:my-4:last-child {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .lg\:last\:mx-4:last-child {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg\:last\:my-5:last-child {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .lg\:last\:mx-5:last-child {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .lg\:last\:my-6:last-child {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .lg\:last\:mx-6:last-child {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .lg\:last\:my-8:last-child {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:last\:mx-8:last-child {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .lg\:last\:my-10:last-child {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:last\:mx-10:last-child {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .lg\:last\:my-12:last-child {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .lg\:last\:mx-12:last-child {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .lg\:last\:my-16:last-child {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .lg\:last\:mx-16:last-child {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .lg\:last\:my-20:last-child {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .lg\:last\:mx-20:last-child {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .lg\:last\:my-24:last-child {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .lg\:last\:mx-24:last-child {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .lg\:last\:my-32:last-child {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .lg\:last\:mx-32:last-child {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .lg\:last\:my-40:last-child {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .lg\:last\:mx-40:last-child {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .lg\:last\:my-48:last-child {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .lg\:last\:mx-48:last-child {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .lg\:last\:my-56:last-child {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .lg\:last\:mx-56:last-child {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .lg\:last\:my-64:last-child {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .lg\:last\:mx-64:last-child {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .lg\:last\:my-auto:last-child {
    margin-top: auto;
    margin-bottom: auto;
  }

  .lg\:last\:mx-auto:last-child {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:last\:my-px:last-child {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .lg\:last\:mx-px:last-child {
    margin-left: 1px;
    margin-right: 1px;
  }

  .lg\:last\:-my-1:last-child {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .lg\:last\:-mx-1:last-child {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .lg\:last\:-my-2:last-child {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .lg\:last\:-mx-2:last-child {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .lg\:last\:-my-3:last-child {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .lg\:last\:-mx-3:last-child {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .lg\:last\:-my-4:last-child {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .lg\:last\:-mx-4:last-child {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .lg\:last\:-my-5:last-child {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .lg\:last\:-mx-5:last-child {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .lg\:last\:-my-6:last-child {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .lg\:last\:-mx-6:last-child {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .lg\:last\:-my-8:last-child {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .lg\:last\:-mx-8:last-child {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:last\:-my-10:last-child {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .lg\:last\:-mx-10:last-child {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .lg\:last\:-my-12:last-child {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .lg\:last\:-mx-12:last-child {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .lg\:last\:-my-16:last-child {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .lg\:last\:-mx-16:last-child {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .lg\:last\:-my-20:last-child {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .lg\:last\:-mx-20:last-child {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .lg\:last\:-my-24:last-child {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .lg\:last\:-mx-24:last-child {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .lg\:last\:-my-32:last-child {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .lg\:last\:-mx-32:last-child {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .lg\:last\:-my-40:last-child {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .lg\:last\:-mx-40:last-child {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .lg\:last\:-my-48:last-child {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .lg\:last\:-mx-48:last-child {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .lg\:last\:-my-56:last-child {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .lg\:last\:-mx-56:last-child {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .lg\:last\:-my-64:last-child {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .lg\:last\:-mx-64:last-child {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .lg\:last\:-my-px:last-child {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .lg\:last\:-mx-px:last-child {
    margin-left: -1px;
    margin-right: -1px;
  }

  .lg\:last\:mt-0:last-child {
    margin-top: 0;
  }

  .lg\:last\:mr-0:last-child {
    margin-right: 0;
  }

  .lg\:last\:mb-0:last-child {
    margin-bottom: 0;
  }

  .lg\:last\:ml-0:last-child {
    margin-left: 0;
  }

  .lg\:last\:mt-1:last-child {
    margin-top: 0.25rem;
  }

  .lg\:last\:mr-1:last-child {
    margin-right: 0.25rem;
  }

  .lg\:last\:mb-1:last-child {
    margin-bottom: 0.25rem;
  }

  .lg\:last\:ml-1:last-child {
    margin-left: 0.25rem;
  }

  .lg\:last\:mt-2:last-child {
    margin-top: 0.5rem;
  }

  .lg\:last\:mr-2:last-child {
    margin-right: 0.5rem;
  }

  .lg\:last\:mb-2:last-child {
    margin-bottom: 0.5rem;
  }

  .lg\:last\:ml-2:last-child {
    margin-left: 0.5rem;
  }

  .lg\:last\:mt-3:last-child {
    margin-top: 0.75rem;
  }

  .lg\:last\:mr-3:last-child {
    margin-right: 0.75rem;
  }

  .lg\:last\:mb-3:last-child {
    margin-bottom: 0.75rem;
  }

  .lg\:last\:ml-3:last-child {
    margin-left: 0.75rem;
  }

  .lg\:last\:mt-4:last-child {
    margin-top: 1rem;
  }

  .lg\:last\:mr-4:last-child {
    margin-right: 1rem;
  }

  .lg\:last\:mb-4:last-child {
    margin-bottom: 1rem;
  }

  .lg\:last\:ml-4:last-child {
    margin-left: 1rem;
  }

  .lg\:last\:mt-5:last-child {
    margin-top: 1.25rem;
  }

  .lg\:last\:mr-5:last-child {
    margin-right: 1.25rem;
  }

  .lg\:last\:mb-5:last-child {
    margin-bottom: 1.25rem;
  }

  .lg\:last\:ml-5:last-child {
    margin-left: 1.25rem;
  }

  .lg\:last\:mt-6:last-child {
    margin-top: 1.5rem;
  }

  .lg\:last\:mr-6:last-child {
    margin-right: 1.5rem;
  }

  .lg\:last\:mb-6:last-child {
    margin-bottom: 1.5rem;
  }

  .lg\:last\:ml-6:last-child {
    margin-left: 1.5rem;
  }

  .lg\:last\:mt-8:last-child {
    margin-top: 2rem;
  }

  .lg\:last\:mr-8:last-child {
    margin-right: 2rem;
  }

  .lg\:last\:mb-8:last-child {
    margin-bottom: 2rem;
  }

  .lg\:last\:ml-8:last-child {
    margin-left: 2rem;
  }

  .lg\:last\:mt-10:last-child {
    margin-top: 2.5rem;
  }

  .lg\:last\:mr-10:last-child {
    margin-right: 2.5rem;
  }

  .lg\:last\:mb-10:last-child {
    margin-bottom: 2.5rem;
  }

  .lg\:last\:ml-10:last-child {
    margin-left: 2.5rem;
  }

  .lg\:last\:mt-12:last-child {
    margin-top: 3rem;
  }

  .lg\:last\:mr-12:last-child {
    margin-right: 3rem;
  }

  .lg\:last\:mb-12:last-child {
    margin-bottom: 3rem;
  }

  .lg\:last\:ml-12:last-child {
    margin-left: 3rem;
  }

  .lg\:last\:mt-16:last-child {
    margin-top: 4rem;
  }

  .lg\:last\:mr-16:last-child {
    margin-right: 4rem;
  }

  .lg\:last\:mb-16:last-child {
    margin-bottom: 4rem;
  }

  .lg\:last\:ml-16:last-child {
    margin-left: 4rem;
  }

  .lg\:last\:mt-20:last-child {
    margin-top: 5rem;
  }

  .lg\:last\:mr-20:last-child {
    margin-right: 5rem;
  }

  .lg\:last\:mb-20:last-child {
    margin-bottom: 5rem;
  }

  .lg\:last\:ml-20:last-child {
    margin-left: 5rem;
  }

  .lg\:last\:mt-24:last-child {
    margin-top: 6rem;
  }

  .lg\:last\:mr-24:last-child {
    margin-right: 6rem;
  }

  .lg\:last\:mb-24:last-child {
    margin-bottom: 6rem;
  }

  .lg\:last\:ml-24:last-child {
    margin-left: 6rem;
  }

  .lg\:last\:mt-32:last-child {
    margin-top: 8rem;
  }

  .lg\:last\:mr-32:last-child {
    margin-right: 8rem;
  }

  .lg\:last\:mb-32:last-child {
    margin-bottom: 8rem;
  }

  .lg\:last\:ml-32:last-child {
    margin-left: 8rem;
  }

  .lg\:last\:mt-40:last-child {
    margin-top: 10rem;
  }

  .lg\:last\:mr-40:last-child {
    margin-right: 10rem;
  }

  .lg\:last\:mb-40:last-child {
    margin-bottom: 10rem;
  }

  .lg\:last\:ml-40:last-child {
    margin-left: 10rem;
  }

  .lg\:last\:mt-48:last-child {
    margin-top: 12rem;
  }

  .lg\:last\:mr-48:last-child {
    margin-right: 12rem;
  }

  .lg\:last\:mb-48:last-child {
    margin-bottom: 12rem;
  }

  .lg\:last\:ml-48:last-child {
    margin-left: 12rem;
  }

  .lg\:last\:mt-56:last-child {
    margin-top: 14rem;
  }

  .lg\:last\:mr-56:last-child {
    margin-right: 14rem;
  }

  .lg\:last\:mb-56:last-child {
    margin-bottom: 14rem;
  }

  .lg\:last\:ml-56:last-child {
    margin-left: 14rem;
  }

  .lg\:last\:mt-64:last-child {
    margin-top: 16rem;
  }

  .lg\:last\:mr-64:last-child {
    margin-right: 16rem;
  }

  .lg\:last\:mb-64:last-child {
    margin-bottom: 16rem;
  }

  .lg\:last\:ml-64:last-child {
    margin-left: 16rem;
  }

  .lg\:last\:mt-auto:last-child {
    margin-top: auto;
  }

  .lg\:last\:mr-auto:last-child {
    margin-right: auto;
  }

  .lg\:last\:mb-auto:last-child {
    margin-bottom: auto;
  }

  .lg\:last\:ml-auto:last-child {
    margin-left: auto;
  }

  .lg\:last\:mt-px:last-child {
    margin-top: 1px;
  }

  .lg\:last\:mr-px:last-child {
    margin-right: 1px;
  }

  .lg\:last\:mb-px:last-child {
    margin-bottom: 1px;
  }

  .lg\:last\:ml-px:last-child {
    margin-left: 1px;
  }

  .lg\:last\:-mt-1:last-child {
    margin-top: -0.25rem;
  }

  .lg\:last\:-mr-1:last-child {
    margin-right: -0.25rem;
  }

  .lg\:last\:-mb-1:last-child {
    margin-bottom: -0.25rem;
  }

  .lg\:last\:-ml-1:last-child {
    margin-left: -0.25rem;
  }

  .lg\:last\:-mt-2:last-child {
    margin-top: -0.5rem;
  }

  .lg\:last\:-mr-2:last-child {
    margin-right: -0.5rem;
  }

  .lg\:last\:-mb-2:last-child {
    margin-bottom: -0.5rem;
  }

  .lg\:last\:-ml-2:last-child {
    margin-left: -0.5rem;
  }

  .lg\:last\:-mt-3:last-child {
    margin-top: -0.75rem;
  }

  .lg\:last\:-mr-3:last-child {
    margin-right: -0.75rem;
  }

  .lg\:last\:-mb-3:last-child {
    margin-bottom: -0.75rem;
  }

  .lg\:last\:-ml-3:last-child {
    margin-left: -0.75rem;
  }

  .lg\:last\:-mt-4:last-child {
    margin-top: -1rem;
  }

  .lg\:last\:-mr-4:last-child {
    margin-right: -1rem;
  }

  .lg\:last\:-mb-4:last-child {
    margin-bottom: -1rem;
  }

  .lg\:last\:-ml-4:last-child {
    margin-left: -1rem;
  }

  .lg\:last\:-mt-5:last-child {
    margin-top: -1.25rem;
  }

  .lg\:last\:-mr-5:last-child {
    margin-right: -1.25rem;
  }

  .lg\:last\:-mb-5:last-child {
    margin-bottom: -1.25rem;
  }

  .lg\:last\:-ml-5:last-child {
    margin-left: -1.25rem;
  }

  .lg\:last\:-mt-6:last-child {
    margin-top: -1.5rem;
  }

  .lg\:last\:-mr-6:last-child {
    margin-right: -1.5rem;
  }

  .lg\:last\:-mb-6:last-child {
    margin-bottom: -1.5rem;
  }

  .lg\:last\:-ml-6:last-child {
    margin-left: -1.5rem;
  }

  .lg\:last\:-mt-8:last-child {
    margin-top: -2rem;
  }

  .lg\:last\:-mr-8:last-child {
    margin-right: -2rem;
  }

  .lg\:last\:-mb-8:last-child {
    margin-bottom: -2rem;
  }

  .lg\:last\:-ml-8:last-child {
    margin-left: -2rem;
  }

  .lg\:last\:-mt-10:last-child {
    margin-top: -2.5rem;
  }

  .lg\:last\:-mr-10:last-child {
    margin-right: -2.5rem;
  }

  .lg\:last\:-mb-10:last-child {
    margin-bottom: -2.5rem;
  }

  .lg\:last\:-ml-10:last-child {
    margin-left: -2.5rem;
  }

  .lg\:last\:-mt-12:last-child {
    margin-top: -3rem;
  }

  .lg\:last\:-mr-12:last-child {
    margin-right: -3rem;
  }

  .lg\:last\:-mb-12:last-child {
    margin-bottom: -3rem;
  }

  .lg\:last\:-ml-12:last-child {
    margin-left: -3rem;
  }

  .lg\:last\:-mt-16:last-child {
    margin-top: -4rem;
  }

  .lg\:last\:-mr-16:last-child {
    margin-right: -4rem;
  }

  .lg\:last\:-mb-16:last-child {
    margin-bottom: -4rem;
  }

  .lg\:last\:-ml-16:last-child {
    margin-left: -4rem;
  }

  .lg\:last\:-mt-20:last-child {
    margin-top: -5rem;
  }

  .lg\:last\:-mr-20:last-child {
    margin-right: -5rem;
  }

  .lg\:last\:-mb-20:last-child {
    margin-bottom: -5rem;
  }

  .lg\:last\:-ml-20:last-child {
    margin-left: -5rem;
  }

  .lg\:last\:-mt-24:last-child {
    margin-top: -6rem;
  }

  .lg\:last\:-mr-24:last-child {
    margin-right: -6rem;
  }

  .lg\:last\:-mb-24:last-child {
    margin-bottom: -6rem;
  }

  .lg\:last\:-ml-24:last-child {
    margin-left: -6rem;
  }

  .lg\:last\:-mt-32:last-child {
    margin-top: -8rem;
  }

  .lg\:last\:-mr-32:last-child {
    margin-right: -8rem;
  }

  .lg\:last\:-mb-32:last-child {
    margin-bottom: -8rem;
  }

  .lg\:last\:-ml-32:last-child {
    margin-left: -8rem;
  }

  .lg\:last\:-mt-40:last-child {
    margin-top: -10rem;
  }

  .lg\:last\:-mr-40:last-child {
    margin-right: -10rem;
  }

  .lg\:last\:-mb-40:last-child {
    margin-bottom: -10rem;
  }

  .lg\:last\:-ml-40:last-child {
    margin-left: -10rem;
  }

  .lg\:last\:-mt-48:last-child {
    margin-top: -12rem;
  }

  .lg\:last\:-mr-48:last-child {
    margin-right: -12rem;
  }

  .lg\:last\:-mb-48:last-child {
    margin-bottom: -12rem;
  }

  .lg\:last\:-ml-48:last-child {
    margin-left: -12rem;
  }

  .lg\:last\:-mt-56:last-child {
    margin-top: -14rem;
  }

  .lg\:last\:-mr-56:last-child {
    margin-right: -14rem;
  }

  .lg\:last\:-mb-56:last-child {
    margin-bottom: -14rem;
  }

  .lg\:last\:-ml-56:last-child {
    margin-left: -14rem;
  }

  .lg\:last\:-mt-64:last-child {
    margin-top: -16rem;
  }

  .lg\:last\:-mr-64:last-child {
    margin-right: -16rem;
  }

  .lg\:last\:-mb-64:last-child {
    margin-bottom: -16rem;
  }

  .lg\:last\:-ml-64:last-child {
    margin-left: -16rem;
  }

  .lg\:last\:-mt-px:last-child {
    margin-top: -1px;
  }

  .lg\:last\:-mr-px:last-child {
    margin-right: -1px;
  }

  .lg\:last\:-mb-px:last-child {
    margin-bottom: -1px;
  }

  .lg\:last\:-ml-px:last-child {
    margin-left: -1px;
  }

  .lg\:max-h-0 {
    max-height: 0;
  }

  .lg\:max-h-1 {
    max-height: 0.25rem;
  }

  .lg\:max-h-2 {
    max-height: 0.5rem;
  }

  .lg\:max-h-3 {
    max-height: 0.75rem;
  }

  .lg\:max-h-4 {
    max-height: 1rem;
  }

  .lg\:max-h-5 {
    max-height: 1.25rem;
  }

  .lg\:max-h-6 {
    max-height: 1.5rem;
  }

  .lg\:max-h-8 {
    max-height: 2rem;
  }

  .lg\:max-h-10 {
    max-height: 2.5rem;
  }

  .lg\:max-h-12 {
    max-height: 3rem;
  }

  .lg\:max-h-16 {
    max-height: 4rem;
  }

  .lg\:max-h-20 {
    max-height: 5rem;
  }

  .lg\:max-h-24 {
    max-height: 6rem;
  }

  .lg\:max-h-32 {
    max-height: 8rem;
  }

  .lg\:max-h-40 {
    max-height: 10rem;
  }

  .lg\:max-h-48 {
    max-height: 12rem;
  }

  .lg\:max-h-56 {
    max-height: 14rem;
  }

  .lg\:max-h-64 {
    max-height: 16rem;
  }

  .lg\:max-h-xl {
    max-height: 36rem;
  }

  .lg\:max-h-px {
    max-height: 1px;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:max-w-xs {
    max-width: 20rem;
  }

  .lg\:max-w-sm {
    max-width: 24rem;
  }

  .lg\:max-w-md {
    max-width: 28rem;
  }

  .lg\:max-w-lg {
    max-width: 32rem;
  }

  .lg\:max-w-xl {
    max-width: 36rem;
  }

  .lg\:max-w-2xl {
    max-width: 42rem;
  }

  .lg\:max-w-3xl {
    max-width: 48rem;
  }

  .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .lg\:max-w-5xl {
    max-width: 64rem;
  }

  .lg\:max-w-6xl {
    max-width: 72rem;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:max-w-screen-sm {
    max-width: 640px;
  }

  .lg\:max-w-screen-lg {
    max-width: 1024px;
  }

  .lg\:max-w-screen-xl {
    max-width: 1280px;
  }

  .lg\:min-h-0 {
    min-height: 0;
  }

  .lg\:min-h-full {
    min-height: 100%;
  }

  .lg\:min-h-screen {
    min-height: 100vh;
  }

  .lg\:min-w-0 {
    min-width: 0;
  }

  .lg\:min-w-1 {
    min-width: 0.25rem;
  }

  .lg\:min-w-2 {
    min-width: 0.5rem;
  }

  .lg\:min-w-3 {
    min-width: 0.75rem;
  }

  .lg\:min-w-4 {
    min-width: 1rem;
  }

  .lg\:min-w-5 {
    min-width: 1.25rem;
  }

  .lg\:min-w-6 {
    min-width: 1.5rem;
  }

  .lg\:min-w-8 {
    min-width: 2rem;
  }

  .lg\:min-w-10 {
    min-width: 2.5rem;
  }

  .lg\:min-w-12 {
    min-width: 3rem;
  }

  .lg\:min-w-16 {
    min-width: 4rem;
  }

  .lg\:min-w-20 {
    min-width: 5rem;
  }

  .lg\:min-w-24 {
    min-width: 6rem;
  }

  .lg\:min-w-32 {
    min-width: 8rem;
  }

  .lg\:min-w-40 {
    min-width: 10rem;
  }

  .lg\:min-w-48 {
    min-width: 12rem;
  }

  .lg\:min-w-56 {
    min-width: 14rem;
  }

  .lg\:min-w-64 {
    min-width: 16rem;
  }

  .lg\:min-w-max-content {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
  }

  .lg\:min-w-px {
    min-width: 1px;
  }

  .lg\:object-contain {
    object-fit: contain;
  }

  .lg\:object-cover {
    object-fit: cover;
  }

  .lg\:object-fill {
    object-fit: fill;
  }

  .lg\:object-none {
    object-fit: none;
  }

  .lg\:object-scale-down {
    object-fit: scale-down;
  }

  .lg\:object-bottom {
    object-position: bottom;
  }

  .lg\:object-center {
    object-position: center;
  }

  .lg\:object-left {
    object-position: left;
  }

  .lg\:object-left-bottom {
    object-position: left bottom;
  }

  .lg\:object-left-top {
    object-position: left top;
  }

  .lg\:object-right {
    object-position: right;
  }

  .lg\:object-right-bottom {
    object-position: right bottom;
  }

  .lg\:object-right-top {
    object-position: right top;
  }

  .lg\:object-top {
    object-position: top;
  }

  .lg\:opacity-0 {
    opacity: 0;
  }

  .lg\:opacity-25 {
    opacity: 0.25;
  }

  .lg\:opacity-50 {
    opacity: 0.5;
  }

  .lg\:opacity-75 {
    opacity: 0.75;
  }

  .lg\:opacity-100 {
    opacity: 1;
  }

  .lg\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .lg\:hover\:opacity-25:hover {
    opacity: 0.25;
  }

  .lg\:hover\:opacity-50:hover {
    opacity: 0.5;
  }

  .lg\:hover\:opacity-75:hover {
    opacity: 0.75;
  }

  .lg\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .lg\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .lg\:focus\:opacity-25:focus {
    opacity: 0.25;
  }

  .lg\:focus\:opacity-50:focus {
    opacity: 0.5;
  }

  .lg\:focus\:opacity-75:focus {
    opacity: 0.75;
  }

  .lg\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .lg\:outline-none {
    outline: 0;
  }

  .lg\:focus\:outline-none:focus {
    outline: 0;
  }

  .lg\:overflow-auto {
    overflow: auto;
  }

  .lg\:overflow-hidden {
    overflow: hidden;
  }

  .lg\:overflow-visible {
    overflow: visible;
  }

  .lg\:overflow-scroll {
    overflow: scroll;
  }

  .lg\:overflow-x-auto {
    overflow-x: auto;
  }

  .lg\:overflow-y-auto {
    overflow-y: auto;
  }

  .lg\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .lg\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .lg\:overflow-x-visible {
    overflow-x: visible;
  }

  .lg\:overflow-y-visible {
    overflow-y: visible;
  }

  .lg\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .lg\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .lg\:scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .lg\:scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:p-1 {
    padding: 0.25rem;
  }

  .lg\:p-2 {
    padding: 0.5rem;
  }

  .lg\:p-3 {
    padding: 0.75rem;
  }

  .lg\:p-4 {
    padding: 1rem;
  }

  .lg\:p-5 {
    padding: 1.25rem;
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:p-12 {
    padding: 3rem;
  }

  .lg\:p-16 {
    padding: 4rem;
  }

  .lg\:p-20 {
    padding: 5rem;
  }

  .lg\:p-24 {
    padding: 6rem;
  }

  .lg\:p-32 {
    padding: 8rem;
  }

  .lg\:p-40 {
    padding: 10rem;
  }

  .lg\:p-48 {
    padding: 12rem;
  }

  .lg\:p-56 {
    padding: 14rem;
  }

  .lg\:p-64 {
    padding: 16rem;
  }

  .lg\:p-px {
    padding: 1px;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .lg\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .lg\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .lg\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .lg\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .lg\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .lg\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .lg\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .lg\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .lg\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .lg\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .lg\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .lg\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .lg\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pl-0 {
    padding-left: 0;
  }

  .lg\:pt-1 {
    padding-top: 0.25rem;
  }

  .lg\:pr-1 {
    padding-right: 0.25rem;
  }

  .lg\:pb-1 {
    padding-bottom: 0.25rem;
  }

  .lg\:pl-1 {
    padding-left: 0.25rem;
  }

  .lg\:pt-2 {
    padding-top: 0.5rem;
  }

  .lg\:pr-2 {
    padding-right: 0.5rem;
  }

  .lg\:pb-2 {
    padding-bottom: 0.5rem;
  }

  .lg\:pl-2 {
    padding-left: 0.5rem;
  }

  .lg\:pt-3 {
    padding-top: 0.75rem;
  }

  .lg\:pr-3 {
    padding-right: 0.75rem;
  }

  .lg\:pb-3 {
    padding-bottom: 0.75rem;
  }

  .lg\:pl-3 {
    padding-left: 0.75rem;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:pr-4 {
    padding-right: 1rem;
  }

  .lg\:pb-4 {
    padding-bottom: 1rem;
  }

  .lg\:pl-4 {
    padding-left: 1rem;
  }

  .lg\:pt-5 {
    padding-top: 1.25rem;
  }

  .lg\:pr-5 {
    padding-right: 1.25rem;
  }

  .lg\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .lg\:pl-5 {
    padding-left: 1.25rem;
  }

  .lg\:pt-6 {
    padding-top: 1.5rem;
  }

  .lg\:pr-6 {
    padding-right: 1.5rem;
  }

  .lg\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .lg\:pl-6 {
    padding-left: 1.5rem;
  }

  .lg\:pt-8 {
    padding-top: 2rem;
  }

  .lg\:pr-8 {
    padding-right: 2rem;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:pl-8 {
    padding-left: 2rem;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:pr-10 {
    padding-right: 2.5rem;
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:pl-10 {
    padding-left: 2.5rem;
  }

  .lg\:pt-12 {
    padding-top: 3rem;
  }

  .lg\:pr-12 {
    padding-right: 3rem;
  }

  .lg\:pb-12 {
    padding-bottom: 3rem;
  }

  .lg\:pl-12 {
    padding-left: 3rem;
  }

  .lg\:pt-16 {
    padding-top: 4rem;
  }

  .lg\:pr-16 {
    padding-right: 4rem;
  }

  .lg\:pb-16 {
    padding-bottom: 4rem;
  }

  .lg\:pl-16 {
    padding-left: 4rem;
  }

  .lg\:pt-20 {
    padding-top: 5rem;
  }

  .lg\:pr-20 {
    padding-right: 5rem;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pl-20 {
    padding-left: 5rem;
  }

  .lg\:pt-24 {
    padding-top: 6rem;
  }

  .lg\:pr-24 {
    padding-right: 6rem;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:pl-24 {
    padding-left: 6rem;
  }

  .lg\:pt-32 {
    padding-top: 8rem;
  }

  .lg\:pr-32 {
    padding-right: 8rem;
  }

  .lg\:pb-32 {
    padding-bottom: 8rem;
  }

  .lg\:pl-32 {
    padding-left: 8rem;
  }

  .lg\:pt-40 {
    padding-top: 10rem;
  }

  .lg\:pr-40 {
    padding-right: 10rem;
  }

  .lg\:pb-40 {
    padding-bottom: 10rem;
  }

  .lg\:pl-40 {
    padding-left: 10rem;
  }

  .lg\:pt-48 {
    padding-top: 12rem;
  }

  .lg\:pr-48 {
    padding-right: 12rem;
  }

  .lg\:pb-48 {
    padding-bottom: 12rem;
  }

  .lg\:pl-48 {
    padding-left: 12rem;
  }

  .lg\:pt-56 {
    padding-top: 14rem;
  }

  .lg\:pr-56 {
    padding-right: 14rem;
  }

  .lg\:pb-56 {
    padding-bottom: 14rem;
  }

  .lg\:pl-56 {
    padding-left: 14rem;
  }

  .lg\:pt-64 {
    padding-top: 16rem;
  }

  .lg\:pr-64 {
    padding-right: 16rem;
  }

  .lg\:pb-64 {
    padding-bottom: 16rem;
  }

  .lg\:pl-64 {
    padding-left: 16rem;
  }

  .lg\:pt-px {
    padding-top: 1px;
  }

  .lg\:pr-px {
    padding-right: 1px;
  }

  .lg\:pb-px {
    padding-bottom: 1px;
  }

  .lg\:pl-px {
    padding-left: 1px;
  }

  .lg\:placeholder-transparent::-webkit-input-placeholder {
    color: transparent;
  }

  .lg\:placeholder-transparent:-ms-input-placeholder {
    color: transparent;
  }

  .lg\:placeholder-transparent::-ms-input-placeholder {
    color: transparent;
  }

  .lg\:placeholder-transparent::placeholder {
    color: transparent;
  }

  .lg\:placeholder-white::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ffffff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-white:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ffffff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-white::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ffffff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #ffffff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-black::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #000000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .lg\:placeholder-black:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .lg\:placeholder-black::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .lg\:placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #000000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-50::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-50:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-50::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-50::placeholder {
    --placeholder-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-200::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-300::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d5d6d7;
    color: rgba(213, 214, 215, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d5d6d7;
    color: rgba(213, 214, 215, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d5d6d7;
    color: rgba(213, 214, 215, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #d5d6d7;
    color: rgba(213, 214, 215, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-400::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #9e9e9e;
    color: rgba(158, 158, 158, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9e9e9e;
    color: rgba(158, 158, 158, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9e9e9e;
    color: rgba(158, 158, 158, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #9e9e9e;
    color: rgba(158, 158, 158, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-500::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #707275;
    color: rgba(112, 114, 117, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #707275;
    color: rgba(112, 114, 117, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #707275;
    color: rgba(112, 114, 117, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #707275;
    color: rgba(112, 114, 117, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-600::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c4f52;
    color: rgba(76, 79, 82, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c4f52;
    color: rgba(76, 79, 82, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c4f52;
    color: rgba(76, 79, 82, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #4c4f52;
    color: rgba(76, 79, 82, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-700::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #24262d;
    color: rgba(36, 38, 45, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #24262d;
    color: rgba(36, 38, 45, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #24262d;
    color: rgba(36, 38, 45, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #24262d;
    color: rgba(36, 38, 45, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-800::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1c23;
    color: rgba(26, 28, 35, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1c23;
    color: rgba(26, 28, 35, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1c23;
    color: rgba(26, 28, 35, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #1a1c23;
    color: rgba(26, 28, 35, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-900::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #121317;
    color: rgba(18, 19, 23, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #121317;
    color: rgba(18, 19, 23, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #121317;
    color: rgba(18, 19, 23, var(--placeholder-opacity));
  }

  .lg\:placeholder-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #121317;
    color: rgba(18, 19, 23, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-50::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-50:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-50::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-50::placeholder {
    --placeholder-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-200::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-300::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-400::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-500::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-600::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-700::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-800::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-900::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--placeholder-opacity));
  }

  .lg\:placeholder-cool-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-50::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-50:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-50::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-50::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-100::placeholder {
    --placeholder-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-200::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-200::placeholder {
    --placeholder-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-300::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-300::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-400::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-400::placeholder {
    --placeholder-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-500::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-500::placeholder {
    --placeholder-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-600::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-600::placeholder {
    --placeholder-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-700::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-700::placeholder {
    --placeholder-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-800::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-800::placeholder {
    --placeholder-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-900::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity));
  }

  .lg\:placeholder-red-900::placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-50::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-50:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-50::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-50::placeholder {
    --placeholder-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-100::placeholder {
    --placeholder-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-200::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-200::placeholder {
    --placeholder-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-300::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-300::placeholder {
    --placeholder-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-400::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-400::placeholder {
    --placeholder-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-500::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-500::placeholder {
    --placeholder-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-600::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-600::placeholder {
    --placeholder-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-700::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-700::placeholder {
    --placeholder-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-800::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-800::placeholder {
    --placeholder-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-900::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity));
  }

  .lg\:placeholder-orange-900::placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-50::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-50:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-50::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-50::placeholder {
    --placeholder-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-200::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-300::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-400::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-500::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-600::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-700::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-800::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-900::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--placeholder-opacity));
  }

  .lg\:placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-50::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-50:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-50::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-50::placeholder {
    --placeholder-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-100::placeholder {
    --placeholder-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-200::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-200::placeholder {
    --placeholder-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-300::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-300::placeholder {
    --placeholder-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-400::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-400::placeholder {
    --placeholder-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-500::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-500::placeholder {
    --placeholder-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-600::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-600::placeholder {
    --placeholder-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-700::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-700::placeholder {
    --placeholder-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-800::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-800::placeholder {
    --placeholder-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-900::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--placeholder-opacity));
  }

  .lg\:placeholder-green-900::placeholder {
    --placeholder-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-50::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-50:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-50::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-50::placeholder {
    --placeholder-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-100::placeholder {
    --placeholder-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-200::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-200::placeholder {
    --placeholder-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-300::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-300::placeholder {
    --placeholder-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-400::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-400::placeholder {
    --placeholder-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-500::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-500::placeholder {
    --placeholder-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-600::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-600::placeholder {
    --placeholder-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-700::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-700::placeholder {
    --placeholder-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-800::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-800::placeholder {
    --placeholder-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-900::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--placeholder-opacity));
  }

  .lg\:placeholder-teal-900::placeholder {
    --placeholder-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-50::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-50:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-50::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-50::placeholder {
    --placeholder-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-100::placeholder {
    --placeholder-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-200::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-200::placeholder {
    --placeholder-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-300::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-300::placeholder {
    --placeholder-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-400::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-400::placeholder {
    --placeholder-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-500::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-500::placeholder {
    --placeholder-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-600::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-600::placeholder {
    --placeholder-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-700::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-700::placeholder {
    --placeholder-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-800::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-800::placeholder {
    --placeholder-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-900::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--placeholder-opacity));
  }

  .lg\:placeholder-blue-900::placeholder {
    --placeholder-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-50::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-50:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-50::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-50::placeholder {
    --placeholder-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-200::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-300::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-400::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-500::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-600::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-700::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-800::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-900::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--placeholder-opacity));
  }

  .lg\:placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-50::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-50:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-50::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-50::placeholder {
    --placeholder-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-100::placeholder {
    --placeholder-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-200::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-200::placeholder {
    --placeholder-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-300::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-300::placeholder {
    --placeholder-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-400::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-400::placeholder {
    --placeholder-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-500::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-500::placeholder {
    --placeholder-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-600::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-600::placeholder {
    --placeholder-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-700::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-700::placeholder {
    --placeholder-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-800::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-800::placeholder {
    --placeholder-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-900::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--placeholder-opacity));
  }

  .lg\:placeholder-purple-900::placeholder {
    --placeholder-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-50::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-50:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-50::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-50::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-100::placeholder {
    --placeholder-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-200::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-200:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-200::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-200::placeholder {
    --placeholder-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-300::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-300:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-300::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-300::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-400::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-400::placeholder {
    --placeholder-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-500::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-500:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-500::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-500::placeholder {
    --placeholder-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-600::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-600:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-600::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-600::placeholder {
    --placeholder-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-700::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-700:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-700::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-700::placeholder {
    --placeholder-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-800::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-800:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-800::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-800::placeholder {
    --placeholder-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-900::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-900:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-900::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--placeholder-opacity));
  }

  .lg\:placeholder-pink-900::placeholder {
    --placeholder-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .lg\:focus\:placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent;
  }

  .lg\:focus\:placeholder-transparent:focus::-ms-input-placeholder {
    color: transparent;
  }

  .lg\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }

  .lg\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ffffff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ffffff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-white:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ffffff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ffffff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #000000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-black:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #000000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #000000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d5d6d7;
    color: rgba(213, 214, 215, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d5d6d7;
    color: rgba(213, 214, 215, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d5d6d7;
    color: rgba(213, 214, 215, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d5d6d7;
    color: rgba(213, 214, 215, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #9e9e9e;
    color: rgba(158, 158, 158, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9e9e9e;
    color: rgba(158, 158, 158, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9e9e9e;
    color: rgba(158, 158, 158, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9e9e9e;
    color: rgba(158, 158, 158, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #707275;
    color: rgba(112, 114, 117, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #707275;
    color: rgba(112, 114, 117, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #707275;
    color: rgba(112, 114, 117, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #707275;
    color: rgba(112, 114, 117, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c4f52;
    color: rgba(76, 79, 82, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c4f52;
    color: rgba(76, 79, 82, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4c4f52;
    color: rgba(76, 79, 82, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4c4f52;
    color: rgba(76, 79, 82, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #24262d;
    color: rgba(36, 38, 45, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #24262d;
    color: rgba(36, 38, 45, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #24262d;
    color: rgba(36, 38, 45, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #24262d;
    color: rgba(36, 38, 45, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1c23;
    color: rgba(26, 28, 35, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1c23;
    color: rgba(26, 28, 35, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1c23;
    color: rgba(26, 28, 35, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a1c23;
    color: rgba(26, 28, 35, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #121317;
    color: rgba(18, 19, 23, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #121317;
    color: rgba(18, 19, 23, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #121317;
    color: rgba(18, 19, 23, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #121317;
    color: rgba(18, 19, 23, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-cool-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-200:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-300:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-400:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-500:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-600:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-700:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-800:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-900:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--placeholder-opacity));
  }

  .lg\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0;
  }

  .lg\:placeholder-opacity-0:-ms-input-placeholder {
    --placeholder-opacity: 0;
  }

  .lg\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0;
  }

  .lg\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0;
  }

  .lg\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .lg\:placeholder-opacity-25:-ms-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .lg\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .lg\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25;
  }

  .lg\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .lg\:placeholder-opacity-50:-ms-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .lg\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .lg\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5;
  }

  .lg\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .lg\:placeholder-opacity-75:-ms-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .lg\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .lg\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75;
  }

  .lg\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
  }

  .lg\:placeholder-opacity-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
  }

  .lg\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1;
  }

  .lg\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1;
  }

  .lg\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0;
  }

  .lg\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --placeholder-opacity: 0;
  }

  .lg\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0;
  }

  .lg\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0;
  }

  .lg\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .lg\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .lg\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .lg\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25;
  }

  .lg\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .lg\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .lg\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .lg\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5;
  }

  .lg\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .lg\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .lg\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .lg\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75;
  }

  .lg\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
  }

  .lg\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
  }

  .lg\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1;
  }

  .lg\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1;
  }

  .lg\:pointer-events-none {
    pointer-events: none;
  }

  .lg\:pointer-events-auto {
    pointer-events: auto;
  }

  .lg\:static {
    position: static;
  }

  .lg\:fixed {
    position: fixed;
  }

  .lg\:absolute {
    position: absolute;
  }

  .lg\:relative {
    position: relative;
  }

  .lg\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .lg\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .lg\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .lg\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .lg\:inset-x-0 {
    right: 0;
    left: 0;
  }

  .lg\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .lg\:inset-x-auto {
    right: auto;
    left: auto;
  }

  .lg\:top-0 {
    top: 0;
  }

  .lg\:right-0 {
    right: 0;
  }

  .lg\:bottom-0 {
    bottom: 0;
  }

  .lg\:left-0 {
    left: 0;
  }

  .lg\:top-auto {
    top: auto;
  }

  .lg\:right-auto {
    right: auto;
  }

  .lg\:bottom-auto {
    bottom: auto;
  }

  .lg\:left-auto {
    left: auto;
  }

  .lg\:resize-none {
    resize: none;
  }

  .lg\:resize-y {
    resize: vertical;
  }

  .lg\:resize-x {
    resize: horizontal;
  }

  .lg\:resize {
    resize: both;
  }

  .lg\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .lg\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .lg\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .lg\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .lg\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .lg\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .lg\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .lg\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .lg\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .lg\:shadow-none {
    box-shadow: none;
  }

  .lg\:shadow-bottom {
    box-shadow: 0 5px 6px -7px rgba(0, 0, 0, 0.6),
      0 2px 4px -5px rgba(0, 0, 0, 0.06);
  }

  .lg\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .lg\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .lg\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .lg\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .lg\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .lg\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .lg\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .lg\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .lg\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .lg\:hover\:shadow-none:hover {
    box-shadow: none;
  }

  .lg\:hover\:shadow-bottom:hover {
    box-shadow: 0 5px 6px -7px rgba(0, 0, 0, 0.6),
      0 2px 4px -5px rgba(0, 0, 0, 0.06);
  }

  .lg\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .lg\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .lg\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .lg\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .lg\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .lg\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .lg\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .lg\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .lg\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .lg\:focus\:shadow-none:focus {
    box-shadow: none;
  }

  .lg\:focus\:shadow-bottom:focus {
    box-shadow: 0 5px 6px -7px rgba(0, 0, 0, 0.6),
      0 2px 4px -5px rgba(0, 0, 0, 0.06);
  }

  .lg\:fill-current {
    fill: currentColor;
  }

  .lg\:stroke-current {
    stroke: currentColor;
  }

  .lg\:stroke-0 {
    stroke-width: 0;
  }

  .lg\:stroke-1 {
    stroke-width: 1;
  }

  .lg\:stroke-2 {
    stroke-width: 2;
  }

  .lg\:table-auto {
    table-layout: auto;
  }

  .lg\:table-fixed {
    table-layout: fixed;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:text-justify {
    text-align: justify;
  }

  .lg\:text-transparent {
    color: transparent;
  }

  .lg\:text-white {
    --text-opacity: 1;
    color: #ffffff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .lg\:text-black {
    --text-opacity: 1;
    color: #000000;
    color: rgba(0, 0, 0, var(--text-opacity));
  }

  .lg\:text-gray-50 {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity));
  }

  .lg\:text-gray-100 {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity));
  }

  .lg\:text-gray-200 {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity));
  }

  .lg\:text-gray-300 {
    --text-opacity: 1;
    color: #d5d6d7;
    color: rgba(213, 214, 215, var(--text-opacity));
  }

  .lg\:text-gray-400 {
    --text-opacity: 1;
    color: #9e9e9e;
    color: rgba(158, 158, 158, var(--text-opacity));
  }

  .lg\:text-gray-500 {
    --text-opacity: 1;
    color: #707275;
    color: rgba(112, 114, 117, var(--text-opacity));
  }

  .lg\:text-gray-600 {
    --text-opacity: 1;
    color: #4c4f52;
    color: rgba(76, 79, 82, var(--text-opacity));
  }

  .lg\:text-gray-700 {
    --text-opacity: 1;
    color: #24262d;
    color: rgba(36, 38, 45, var(--text-opacity));
  }

  .lg\:text-gray-800 {
    --text-opacity: 1;
    color: #1a1c23;
    color: rgba(26, 28, 35, var(--text-opacity));
  }

  .lg\:text-gray-900 {
    --text-opacity: 1;
    color: #121317;
    color: rgba(18, 19, 23, var(--text-opacity));
  }

  .lg\:text-cool-gray-50 {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity));
  }

  .lg\:text-cool-gray-100 {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity));
  }

  .lg\:text-cool-gray-200 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
  }

  .lg\:text-cool-gray-300 {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity));
  }

  .lg\:text-cool-gray-400 {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity));
  }

  .lg\:text-cool-gray-500 {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity));
  }

  .lg\:text-cool-gray-600 {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity));
  }

  .lg\:text-cool-gray-700 {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity));
  }

  .lg\:text-cool-gray-800 {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity));
  }

  .lg\:text-cool-gray-900 {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity));
  }

  .lg\:text-red-50 {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity));
  }

  .lg\:text-red-100 {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity));
  }

  .lg\:text-red-200 {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity));
  }

  .lg\:text-red-300 {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity));
  }

  .lg\:text-red-400 {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity));
  }

  .lg\:text-red-500 {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity));
  }

  .lg\:text-red-600 {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity));
  }

  .lg\:text-red-700 {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity));
  }

  .lg\:text-red-800 {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity));
  }

  .lg\:text-red-900 {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity));
  }

  .lg\:text-orange-50 {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity));
  }

  .lg\:text-orange-100 {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity));
  }

  .lg\:text-orange-200 {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity));
  }

  .lg\:text-orange-300 {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity));
  }

  .lg\:text-orange-400 {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity));
  }

  .lg\:text-orange-500 {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity));
  }

  .lg\:text-orange-600 {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity));
  }

  .lg\:text-orange-700 {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity));
  }

  .lg\:text-orange-800 {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity));
  }

  .lg\:text-orange-900 {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity));
  }

  .lg\:text-yellow-50 {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity));
  }

  .lg\:text-yellow-100 {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity));
  }

  .lg\:text-yellow-200 {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity));
  }

  .lg\:text-yellow-300 {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity));
  }

  .lg\:text-yellow-400 {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity));
  }

  .lg\:text-yellow-500 {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity));
  }

  .lg\:text-yellow-600 {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity));
  }

  .lg\:text-yellow-700 {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity));
  }

  .lg\:text-yellow-800 {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity));
  }

  .lg\:text-yellow-900 {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity));
  }

  .lg\:text-green-50 {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity));
  }

  .lg\:text-green-100 {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity));
  }

  .lg\:text-green-200 {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity));
  }

  .lg\:text-green-300 {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity));
  }

  .lg\:text-green-400 {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity));
  }

  .lg\:text-green-500 {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity));
  }

  .lg\:text-green-600 {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity));
  }

  .lg\:text-green-700 {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity));
  }

  .lg\:text-green-800 {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity));
  }

  .lg\:text-green-900 {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity));
  }

  .lg\:text-teal-50 {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity));
  }

  .lg\:text-teal-100 {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity));
  }

  .lg\:text-teal-200 {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity));
  }

  .lg\:text-teal-300 {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity));
  }

  .lg\:text-teal-400 {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity));
  }

  .lg\:text-teal-500 {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity));
  }

  .lg\:text-teal-600 {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity));
  }

  .lg\:text-teal-700 {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity));
  }

  .lg\:text-teal-800 {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity));
  }

  .lg\:text-teal-900 {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity));
  }

  .lg\:text-blue-50 {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity));
  }

  .lg\:text-blue-100 {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity));
  }

  .lg\:text-blue-200 {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity));
  }

  .lg\:text-blue-300 {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity));
  }

  .lg\:text-blue-400 {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity));
  }

  .lg\:text-blue-500 {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity));
  }

  .lg\:text-blue-600 {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity));
  }

  .lg\:text-blue-700 {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity));
  }

  .lg\:text-blue-800 {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity));
  }

  .lg\:text-blue-900 {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity));
  }

  .lg\:text-indigo-50 {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity));
  }

  .lg\:text-indigo-100 {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity));
  }

  .lg\:text-indigo-200 {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity));
  }

  .lg\:text-indigo-300 {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity));
  }

  .lg\:text-indigo-400 {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity));
  }

  .lg\:text-indigo-500 {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity));
  }

  .lg\:text-indigo-600 {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity));
  }

  .lg\:text-indigo-700 {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity));
  }

  .lg\:text-indigo-800 {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity));
  }

  .lg\:text-indigo-900 {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity));
  }

  .lg\:text-purple-50 {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity));
  }

  .lg\:text-purple-100 {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity));
  }

  .lg\:text-purple-200 {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity));
  }

  .lg\:text-purple-300 {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity));
  }

  .lg\:text-purple-400 {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity));
  }

  .lg\:text-purple-500 {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity));
  }

  .lg\:text-purple-600 {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity));
  }

  .lg\:text-purple-700 {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity));
  }

  .lg\:text-purple-800 {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity));
  }

  .lg\:text-purple-900 {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity));
  }

  .lg\:text-pink-50 {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity));
  }

  .lg\:text-pink-100 {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity));
  }

  .lg\:text-pink-200 {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity));
  }

  .lg\:text-pink-300 {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity));
  }

  .lg\:text-pink-400 {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity));
  }

  .lg\:text-pink-500 {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity));
  }

  .lg\:text-pink-600 {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity));
  }

  .lg\:text-pink-700 {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity));
  }

  .lg\:text-pink-800 {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity));
  }

  .lg\:text-pink-900 {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity));
  }

  .lg\:focus\:text-transparent:focus {
    color: transparent;
  }

  .lg\:focus\:text-white:focus {
    --text-opacity: 1;
    color: #ffffff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .lg\:focus\:text-black:focus {
    --text-opacity: 1;
    color: #000000;
    color: rgba(0, 0, 0, var(--text-opacity));
  }

  .lg\:focus\:text-gray-50:focus {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity));
  }

  .lg\:focus\:text-gray-100:focus {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity));
  }

  .lg\:focus\:text-gray-200:focus {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity));
  }

  .lg\:focus\:text-gray-300:focus {
    --text-opacity: 1;
    color: #d5d6d7;
    color: rgba(213, 214, 215, var(--text-opacity));
  }

  .lg\:focus\:text-gray-400:focus {
    --text-opacity: 1;
    color: #9e9e9e;
    color: rgba(158, 158, 158, var(--text-opacity));
  }

  .lg\:focus\:text-gray-500:focus {
    --text-opacity: 1;
    color: #707275;
    color: rgba(112, 114, 117, var(--text-opacity));
  }

  .lg\:focus\:text-gray-600:focus {
    --text-opacity: 1;
    color: #4c4f52;
    color: rgba(76, 79, 82, var(--text-opacity));
  }

  .lg\:focus\:text-gray-700:focus {
    --text-opacity: 1;
    color: #24262d;
    color: rgba(36, 38, 45, var(--text-opacity));
  }

  .lg\:focus\:text-gray-800:focus {
    --text-opacity: 1;
    color: #1a1c23;
    color: rgba(26, 28, 35, var(--text-opacity));
  }

  .lg\:focus\:text-gray-900:focus {
    --text-opacity: 1;
    color: #121317;
    color: rgba(18, 19, 23, var(--text-opacity));
  }

  .lg\:focus\:text-cool-gray-50:focus {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity));
  }

  .lg\:focus\:text-cool-gray-100:focus {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity));
  }

  .lg\:focus\:text-cool-gray-200:focus {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
  }

  .lg\:focus\:text-cool-gray-300:focus {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity));
  }

  .lg\:focus\:text-cool-gray-400:focus {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity));
  }

  .lg\:focus\:text-cool-gray-500:focus {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity));
  }

  .lg\:focus\:text-cool-gray-600:focus {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity));
  }

  .lg\:focus\:text-cool-gray-700:focus {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity));
  }

  .lg\:focus\:text-cool-gray-800:focus {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity));
  }

  .lg\:focus\:text-cool-gray-900:focus {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity));
  }

  .lg\:focus\:text-red-50:focus {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity));
  }

  .lg\:focus\:text-red-100:focus {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity));
  }

  .lg\:focus\:text-red-200:focus {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity));
  }

  .lg\:focus\:text-red-300:focus {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity));
  }

  .lg\:focus\:text-red-400:focus {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity));
  }

  .lg\:focus\:text-red-500:focus {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity));
  }

  .lg\:focus\:text-red-600:focus {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity));
  }

  .lg\:focus\:text-red-700:focus {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity));
  }

  .lg\:focus\:text-red-800:focus {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity));
  }

  .lg\:focus\:text-red-900:focus {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity));
  }

  .lg\:focus\:text-orange-50:focus {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity));
  }

  .lg\:focus\:text-orange-100:focus {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity));
  }

  .lg\:focus\:text-orange-200:focus {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity));
  }

  .lg\:focus\:text-orange-300:focus {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity));
  }

  .lg\:focus\:text-orange-400:focus {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity));
  }

  .lg\:focus\:text-orange-500:focus {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity));
  }

  .lg\:focus\:text-orange-600:focus {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity));
  }

  .lg\:focus\:text-orange-700:focus {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity));
  }

  .lg\:focus\:text-orange-800:focus {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity));
  }

  .lg\:focus\:text-orange-900:focus {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity));
  }

  .lg\:focus\:text-yellow-50:focus {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity));
  }

  .lg\:focus\:text-yellow-100:focus {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity));
  }

  .lg\:focus\:text-yellow-200:focus {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity));
  }

  .lg\:focus\:text-yellow-300:focus {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity));
  }

  .lg\:focus\:text-yellow-400:focus {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity));
  }

  .lg\:focus\:text-yellow-500:focus {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity));
  }

  .lg\:focus\:text-yellow-600:focus {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity));
  }

  .lg\:focus\:text-yellow-700:focus {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity));
  }

  .lg\:focus\:text-yellow-800:focus {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity));
  }

  .lg\:focus\:text-yellow-900:focus {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity));
  }

  .lg\:focus\:text-green-50:focus {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity));
  }

  .lg\:focus\:text-green-100:focus {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity));
  }

  .lg\:focus\:text-green-200:focus {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity));
  }

  .lg\:focus\:text-green-300:focus {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity));
  }

  .lg\:focus\:text-green-400:focus {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity));
  }

  .lg\:focus\:text-green-500:focus {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity));
  }

  .lg\:focus\:text-green-600:focus {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity));
  }

  .lg\:focus\:text-green-700:focus {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity));
  }

  .lg\:focus\:text-green-800:focus {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity));
  }

  .lg\:focus\:text-green-900:focus {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity));
  }

  .lg\:focus\:text-teal-50:focus {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity));
  }

  .lg\:focus\:text-teal-100:focus {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity));
  }

  .lg\:focus\:text-teal-200:focus {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity));
  }

  .lg\:focus\:text-teal-300:focus {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity));
  }

  .lg\:focus\:text-teal-400:focus {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity));
  }

  .lg\:focus\:text-teal-500:focus {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity));
  }

  .lg\:focus\:text-teal-600:focus {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity));
  }

  .lg\:focus\:text-teal-700:focus {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity));
  }

  .lg\:focus\:text-teal-800:focus {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity));
  }

  .lg\:focus\:text-teal-900:focus {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity));
  }

  .lg\:focus\:text-blue-50:focus {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity));
  }

  .lg\:focus\:text-blue-100:focus {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity));
  }

  .lg\:focus\:text-blue-200:focus {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity));
  }

  .lg\:focus\:text-blue-300:focus {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity));
  }

  .lg\:focus\:text-blue-400:focus {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity));
  }

  .lg\:focus\:text-blue-500:focus {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity));
  }

  .lg\:focus\:text-blue-600:focus {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity));
  }

  .lg\:focus\:text-blue-700:focus {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity));
  }

  .lg\:focus\:text-blue-800:focus {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity));
  }

  .lg\:focus\:text-blue-900:focus {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity));
  }

  .lg\:focus\:text-indigo-50:focus {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity));
  }

  .lg\:focus\:text-indigo-100:focus {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity));
  }

  .lg\:focus\:text-indigo-200:focus {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity));
  }

  .lg\:focus\:text-indigo-300:focus {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity));
  }

  .lg\:focus\:text-indigo-400:focus {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity));
  }

  .lg\:focus\:text-indigo-500:focus {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity));
  }

  .lg\:focus\:text-indigo-600:focus {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity));
  }

  .lg\:focus\:text-indigo-700:focus {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity));
  }

  .lg\:focus\:text-indigo-800:focus {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity));
  }

  .lg\:focus\:text-indigo-900:focus {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity));
  }

  .lg\:focus\:text-purple-50:focus {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity));
  }

  .lg\:focus\:text-purple-100:focus {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity));
  }

  .lg\:focus\:text-purple-200:focus {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity));
  }

  .lg\:focus\:text-purple-300:focus {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity));
  }

  .lg\:focus\:text-purple-400:focus {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity));
  }

  .lg\:focus\:text-purple-500:focus {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity));
  }

  .lg\:focus\:text-purple-600:focus {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity));
  }

  .lg\:focus\:text-purple-700:focus {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity));
  }

  .lg\:focus\:text-purple-800:focus {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity));
  }

  .lg\:focus\:text-purple-900:focus {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity));
  }

  .lg\:focus\:text-pink-50:focus {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity));
  }

  .lg\:focus\:text-pink-100:focus {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity));
  }

  .lg\:focus\:text-pink-200:focus {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity));
  }

  .lg\:focus\:text-pink-300:focus {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity));
  }

  .lg\:focus\:text-pink-400:focus {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity));
  }

  .lg\:focus\:text-pink-500:focus {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity));
  }

  .lg\:focus\:text-pink-600:focus {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity));
  }

  .lg\:focus\:text-pink-700:focus {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity));
  }

  .lg\:focus\:text-pink-800:focus {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity));
  }

  .lg\:focus\:text-pink-900:focus {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity));
  }

  .lg\:focus-within\:text-transparent:focus-within {
    color: transparent;
  }

  .lg\:focus-within\:text-white:focus-within {
    --text-opacity: 1;
    color: #ffffff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .lg\:focus-within\:text-black:focus-within {
    --text-opacity: 1;
    color: #000000;
    color: rgba(0, 0, 0, var(--text-opacity));
  }

  .lg\:focus-within\:text-gray-50:focus-within {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity));
  }

  .lg\:focus-within\:text-gray-100:focus-within {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity));
  }

  .lg\:focus-within\:text-gray-200:focus-within {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity));
  }

  .lg\:focus-within\:text-gray-300:focus-within {
    --text-opacity: 1;
    color: #d5d6d7;
    color: rgba(213, 214, 215, var(--text-opacity));
  }

  .lg\:focus-within\:text-gray-400:focus-within {
    --text-opacity: 1;
    color: #9e9e9e;
    color: rgba(158, 158, 158, var(--text-opacity));
  }

  .lg\:focus-within\:text-gray-500:focus-within {
    --text-opacity: 1;
    color: #707275;
    color: rgba(112, 114, 117, var(--text-opacity));
  }

  .lg\:focus-within\:text-gray-600:focus-within {
    --text-opacity: 1;
    color: #4c4f52;
    color: rgba(76, 79, 82, var(--text-opacity));
  }

  .lg\:focus-within\:text-gray-700:focus-within {
    --text-opacity: 1;
    color: #24262d;
    color: rgba(36, 38, 45, var(--text-opacity));
  }

  .lg\:focus-within\:text-gray-800:focus-within {
    --text-opacity: 1;
    color: #1a1c23;
    color: rgba(26, 28, 35, var(--text-opacity));
  }

  .lg\:focus-within\:text-gray-900:focus-within {
    --text-opacity: 1;
    color: #121317;
    color: rgba(18, 19, 23, var(--text-opacity));
  }

  .lg\:focus-within\:text-cool-gray-50:focus-within {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity));
  }

  .lg\:focus-within\:text-cool-gray-100:focus-within {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity));
  }

  .lg\:focus-within\:text-cool-gray-200:focus-within {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
  }

  .lg\:focus-within\:text-cool-gray-300:focus-within {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity));
  }

  .lg\:focus-within\:text-cool-gray-400:focus-within {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity));
  }

  .lg\:focus-within\:text-cool-gray-500:focus-within {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity));
  }

  .lg\:focus-within\:text-cool-gray-600:focus-within {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity));
  }

  .lg\:focus-within\:text-cool-gray-700:focus-within {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity));
  }

  .lg\:focus-within\:text-cool-gray-800:focus-within {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity));
  }

  .lg\:focus-within\:text-cool-gray-900:focus-within {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity));
  }

  .lg\:focus-within\:text-red-50:focus-within {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity));
  }

  .lg\:focus-within\:text-red-100:focus-within {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity));
  }

  .lg\:focus-within\:text-red-200:focus-within {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity));
  }

  .lg\:focus-within\:text-red-300:focus-within {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity));
  }

  .lg\:focus-within\:text-red-400:focus-within {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity));
  }

  .lg\:focus-within\:text-red-500:focus-within {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity));
  }

  .lg\:focus-within\:text-red-600:focus-within {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity));
  }

  .lg\:focus-within\:text-red-700:focus-within {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity));
  }

  .lg\:focus-within\:text-red-800:focus-within {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity));
  }

  .lg\:focus-within\:text-red-900:focus-within {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity));
  }

  .lg\:focus-within\:text-orange-50:focus-within {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity));
  }

  .lg\:focus-within\:text-orange-100:focus-within {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity));
  }

  .lg\:focus-within\:text-orange-200:focus-within {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity));
  }

  .lg\:focus-within\:text-orange-300:focus-within {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity));
  }

  .lg\:focus-within\:text-orange-400:focus-within {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity));
  }

  .lg\:focus-within\:text-orange-500:focus-within {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity));
  }

  .lg\:focus-within\:text-orange-600:focus-within {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity));
  }

  .lg\:focus-within\:text-orange-700:focus-within {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity));
  }

  .lg\:focus-within\:text-orange-800:focus-within {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity));
  }

  .lg\:focus-within\:text-orange-900:focus-within {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity));
  }

  .lg\:focus-within\:text-yellow-50:focus-within {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity));
  }

  .lg\:focus-within\:text-yellow-100:focus-within {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity));
  }

  .lg\:focus-within\:text-yellow-200:focus-within {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity));
  }

  .lg\:focus-within\:text-yellow-300:focus-within {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity));
  }

  .lg\:focus-within\:text-yellow-400:focus-within {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity));
  }

  .lg\:focus-within\:text-yellow-500:focus-within {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity));
  }

  .lg\:focus-within\:text-yellow-600:focus-within {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity));
  }

  .lg\:focus-within\:text-yellow-700:focus-within {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity));
  }

  .lg\:focus-within\:text-yellow-800:focus-within {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity));
  }

  .lg\:focus-within\:text-yellow-900:focus-within {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity));
  }

  .lg\:focus-within\:text-green-50:focus-within {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity));
  }

  .lg\:focus-within\:text-green-100:focus-within {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity));
  }

  .lg\:focus-within\:text-green-200:focus-within {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity));
  }

  .lg\:focus-within\:text-green-300:focus-within {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity));
  }

  .lg\:focus-within\:text-green-400:focus-within {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity));
  }

  .lg\:focus-within\:text-green-500:focus-within {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity));
  }

  .lg\:focus-within\:text-green-600:focus-within {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity));
  }

  .lg\:focus-within\:text-green-700:focus-within {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity));
  }

  .lg\:focus-within\:text-green-800:focus-within {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity));
  }

  .lg\:focus-within\:text-green-900:focus-within {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity));
  }

  .lg\:focus-within\:text-teal-50:focus-within {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity));
  }

  .lg\:focus-within\:text-teal-100:focus-within {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity));
  }

  .lg\:focus-within\:text-teal-200:focus-within {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity));
  }

  .lg\:focus-within\:text-teal-300:focus-within {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity));
  }

  .lg\:focus-within\:text-teal-400:focus-within {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity));
  }

  .lg\:focus-within\:text-teal-500:focus-within {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity));
  }

  .lg\:focus-within\:text-teal-600:focus-within {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity));
  }

  .lg\:focus-within\:text-teal-700:focus-within {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity));
  }

  .lg\:focus-within\:text-teal-800:focus-within {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity));
  }

  .lg\:focus-within\:text-teal-900:focus-within {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity));
  }

  .lg\:focus-within\:text-blue-50:focus-within {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity));
  }

  .lg\:focus-within\:text-blue-100:focus-within {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity));
  }

  .lg\:focus-within\:text-blue-200:focus-within {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity));
  }

  .lg\:focus-within\:text-blue-300:focus-within {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity));
  }

  .lg\:focus-within\:text-blue-400:focus-within {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity));
  }

  .lg\:focus-within\:text-blue-500:focus-within {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity));
  }

  .lg\:focus-within\:text-blue-600:focus-within {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity));
  }

  .lg\:focus-within\:text-blue-700:focus-within {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity));
  }

  .lg\:focus-within\:text-blue-800:focus-within {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity));
  }

  .lg\:focus-within\:text-blue-900:focus-within {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity));
  }

  .lg\:focus-within\:text-indigo-50:focus-within {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity));
  }

  .lg\:focus-within\:text-indigo-100:focus-within {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity));
  }

  .lg\:focus-within\:text-indigo-200:focus-within {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity));
  }

  .lg\:focus-within\:text-indigo-300:focus-within {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity));
  }

  .lg\:focus-within\:text-indigo-400:focus-within {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity));
  }

  .lg\:focus-within\:text-indigo-500:focus-within {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity));
  }

  .lg\:focus-within\:text-indigo-600:focus-within {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity));
  }

  .lg\:focus-within\:text-indigo-700:focus-within {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity));
  }

  .lg\:focus-within\:text-indigo-800:focus-within {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity));
  }

  .lg\:focus-within\:text-indigo-900:focus-within {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity));
  }

  .lg\:focus-within\:text-purple-50:focus-within {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity));
  }

  .lg\:focus-within\:text-purple-100:focus-within {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity));
  }

  .lg\:focus-within\:text-purple-200:focus-within {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity));
  }

  .lg\:focus-within\:text-purple-300:focus-within {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity));
  }

  .lg\:focus-within\:text-purple-400:focus-within {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity));
  }

  .lg\:focus-within\:text-purple-500:focus-within {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity));
  }

  .lg\:focus-within\:text-purple-600:focus-within {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity));
  }

  .lg\:focus-within\:text-purple-700:focus-within {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity));
  }

  .lg\:focus-within\:text-purple-800:focus-within {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity));
  }

  .lg\:focus-within\:text-purple-900:focus-within {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity));
  }

  .lg\:focus-within\:text-pink-50:focus-within {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity));
  }

  .lg\:focus-within\:text-pink-100:focus-within {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity));
  }

  .lg\:focus-within\:text-pink-200:focus-within {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity));
  }

  .lg\:focus-within\:text-pink-300:focus-within {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity));
  }

  .lg\:focus-within\:text-pink-400:focus-within {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity));
  }

  .lg\:focus-within\:text-pink-500:focus-within {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity));
  }

  .lg\:focus-within\:text-pink-600:focus-within {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity));
  }

  .lg\:focus-within\:text-pink-700:focus-within {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity));
  }

  .lg\:focus-within\:text-pink-800:focus-within {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity));
  }

  .lg\:focus-within\:text-pink-900:focus-within {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity));
  }

  .lg\:hover\:text-transparent:hover {
    color: transparent;
  }

  .lg\:hover\:text-white:hover {
    --text-opacity: 1;
    color: #ffffff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .lg\:hover\:text-black:hover {
    --text-opacity: 1;
    color: #000000;
    color: rgba(0, 0, 0, var(--text-opacity));
  }

  .lg\:hover\:text-gray-50:hover {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity));
  }

  .lg\:hover\:text-gray-100:hover {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity));
  }

  .lg\:hover\:text-gray-200:hover {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity));
  }

  .lg\:hover\:text-gray-300:hover {
    --text-opacity: 1;
    color: #d5d6d7;
    color: rgba(213, 214, 215, var(--text-opacity));
  }

  .lg\:hover\:text-gray-400:hover {
    --text-opacity: 1;
    color: #9e9e9e;
    color: rgba(158, 158, 158, var(--text-opacity));
  }

  .lg\:hover\:text-gray-500:hover {
    --text-opacity: 1;
    color: #707275;
    color: rgba(112, 114, 117, var(--text-opacity));
  }

  .lg\:hover\:text-gray-600:hover {
    --text-opacity: 1;
    color: #4c4f52;
    color: rgba(76, 79, 82, var(--text-opacity));
  }

  .lg\:hover\:text-gray-700:hover {
    --text-opacity: 1;
    color: #24262d;
    color: rgba(36, 38, 45, var(--text-opacity));
  }

  .lg\:hover\:text-gray-800:hover {
    --text-opacity: 1;
    color: #1a1c23;
    color: rgba(26, 28, 35, var(--text-opacity));
  }

  .lg\:hover\:text-gray-900:hover {
    --text-opacity: 1;
    color: #121317;
    color: rgba(18, 19, 23, var(--text-opacity));
  }

  .lg\:hover\:text-cool-gray-50:hover {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity));
  }

  .lg\:hover\:text-cool-gray-100:hover {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity));
  }

  .lg\:hover\:text-cool-gray-200:hover {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
  }

  .lg\:hover\:text-cool-gray-300:hover {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity));
  }

  .lg\:hover\:text-cool-gray-400:hover {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity));
  }

  .lg\:hover\:text-cool-gray-500:hover {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity));
  }

  .lg\:hover\:text-cool-gray-600:hover {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity));
  }

  .lg\:hover\:text-cool-gray-700:hover {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity));
  }

  .lg\:hover\:text-cool-gray-800:hover {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity));
  }

  .lg\:hover\:text-cool-gray-900:hover {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity));
  }

  .lg\:hover\:text-red-50:hover {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity));
  }

  .lg\:hover\:text-red-100:hover {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity));
  }

  .lg\:hover\:text-red-200:hover {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity));
  }

  .lg\:hover\:text-red-300:hover {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity));
  }

  .lg\:hover\:text-red-400:hover {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity));
  }

  .lg\:hover\:text-red-500:hover {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity));
  }

  .lg\:hover\:text-red-600:hover {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity));
  }

  .lg\:hover\:text-red-700:hover {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity));
  }

  .lg\:hover\:text-red-800:hover {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity));
  }

  .lg\:hover\:text-red-900:hover {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity));
  }

  .lg\:hover\:text-orange-50:hover {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity));
  }

  .lg\:hover\:text-orange-100:hover {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity));
  }

  .lg\:hover\:text-orange-200:hover {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity));
  }

  .lg\:hover\:text-orange-300:hover {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity));
  }

  .lg\:hover\:text-orange-400:hover {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity));
  }

  .lg\:hover\:text-orange-500:hover {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity));
  }

  .lg\:hover\:text-orange-600:hover {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity));
  }

  .lg\:hover\:text-orange-700:hover {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity));
  }

  .lg\:hover\:text-orange-800:hover {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity));
  }

  .lg\:hover\:text-orange-900:hover {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity));
  }

  .lg\:hover\:text-yellow-50:hover {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity));
  }

  .lg\:hover\:text-yellow-100:hover {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity));
  }

  .lg\:hover\:text-yellow-200:hover {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity));
  }

  .lg\:hover\:text-yellow-300:hover {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity));
  }

  .lg\:hover\:text-yellow-400:hover {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity));
  }

  .lg\:hover\:text-yellow-500:hover {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity));
  }

  .lg\:hover\:text-yellow-600:hover {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity));
  }

  .lg\:hover\:text-yellow-700:hover {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity));
  }

  .lg\:hover\:text-yellow-800:hover {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity));
  }

  .lg\:hover\:text-yellow-900:hover {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity));
  }

  .lg\:hover\:text-green-50:hover {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity));
  }

  .lg\:hover\:text-green-100:hover {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity));
  }

  .lg\:hover\:text-green-200:hover {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity));
  }

  .lg\:hover\:text-green-300:hover {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity));
  }

  .lg\:hover\:text-green-400:hover {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity));
  }

  .lg\:hover\:text-green-500:hover {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity));
  }

  .lg\:hover\:text-green-600:hover {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity));
  }

  .lg\:hover\:text-green-700:hover {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity));
  }

  .lg\:hover\:text-green-800:hover {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity));
  }

  .lg\:hover\:text-green-900:hover {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity));
  }

  .lg\:hover\:text-teal-50:hover {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity));
  }

  .lg\:hover\:text-teal-100:hover {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity));
  }

  .lg\:hover\:text-teal-200:hover {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity));
  }

  .lg\:hover\:text-teal-300:hover {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity));
  }

  .lg\:hover\:text-teal-400:hover {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity));
  }

  .lg\:hover\:text-teal-500:hover {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity));
  }

  .lg\:hover\:text-teal-600:hover {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity));
  }

  .lg\:hover\:text-teal-700:hover {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity));
  }

  .lg\:hover\:text-teal-800:hover {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity));
  }

  .lg\:hover\:text-teal-900:hover {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity));
  }

  .lg\:hover\:text-blue-50:hover {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity));
  }

  .lg\:hover\:text-blue-100:hover {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity));
  }

  .lg\:hover\:text-blue-200:hover {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity));
  }

  .lg\:hover\:text-blue-300:hover {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity));
  }

  .lg\:hover\:text-blue-400:hover {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity));
  }

  .lg\:hover\:text-blue-500:hover {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity));
  }

  .lg\:hover\:text-blue-600:hover {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity));
  }

  .lg\:hover\:text-blue-700:hover {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity));
  }

  .lg\:hover\:text-blue-800:hover {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity));
  }

  .lg\:hover\:text-blue-900:hover {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity));
  }

  .lg\:hover\:text-indigo-50:hover {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity));
  }

  .lg\:hover\:text-indigo-100:hover {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity));
  }

  .lg\:hover\:text-indigo-200:hover {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity));
  }

  .lg\:hover\:text-indigo-300:hover {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity));
  }

  .lg\:hover\:text-indigo-400:hover {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity));
  }

  .lg\:hover\:text-indigo-500:hover {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity));
  }

  .lg\:hover\:text-indigo-600:hover {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity));
  }

  .lg\:hover\:text-indigo-700:hover {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity));
  }

  .lg\:hover\:text-indigo-800:hover {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity));
  }

  .lg\:hover\:text-indigo-900:hover {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity));
  }

  .lg\:hover\:text-purple-50:hover {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity));
  }

  .lg\:hover\:text-purple-100:hover {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity));
  }

  .lg\:hover\:text-purple-200:hover {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity));
  }

  .lg\:hover\:text-purple-300:hover {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity));
  }

  .lg\:hover\:text-purple-400:hover {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity));
  }

  .lg\:hover\:text-purple-500:hover {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity));
  }

  .lg\:hover\:text-purple-600:hover {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity));
  }

  .lg\:hover\:text-purple-700:hover {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity));
  }

  .lg\:hover\:text-purple-800:hover {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity));
  }

  .lg\:hover\:text-purple-900:hover {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity));
  }

  .lg\:hover\:text-pink-50:hover {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity));
  }

  .lg\:hover\:text-pink-100:hover {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity));
  }

  .lg\:hover\:text-pink-200:hover {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity));
  }

  .lg\:hover\:text-pink-300:hover {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity));
  }

  .lg\:hover\:text-pink-400:hover {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity));
  }

  .lg\:hover\:text-pink-500:hover {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity));
  }

  .lg\:hover\:text-pink-600:hover {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity));
  }

  .lg\:hover\:text-pink-700:hover {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity));
  }

  .lg\:hover\:text-pink-800:hover {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity));
  }

  .lg\:hover\:text-pink-900:hover {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity));
  }

  .lg\:active\:text-transparent:active {
    color: transparent;
  }

  .lg\:active\:text-white:active {
    --text-opacity: 1;
    color: #ffffff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .lg\:active\:text-black:active {
    --text-opacity: 1;
    color: #000000;
    color: rgba(0, 0, 0, var(--text-opacity));
  }

  .lg\:active\:text-gray-50:active {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity));
  }

  .lg\:active\:text-gray-100:active {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity));
  }

  .lg\:active\:text-gray-200:active {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity));
  }

  .lg\:active\:text-gray-300:active {
    --text-opacity: 1;
    color: #d5d6d7;
    color: rgba(213, 214, 215, var(--text-opacity));
  }

  .lg\:active\:text-gray-400:active {
    --text-opacity: 1;
    color: #9e9e9e;
    color: rgba(158, 158, 158, var(--text-opacity));
  }

  .lg\:active\:text-gray-500:active {
    --text-opacity: 1;
    color: #707275;
    color: rgba(112, 114, 117, var(--text-opacity));
  }

  .lg\:active\:text-gray-600:active {
    --text-opacity: 1;
    color: #4c4f52;
    color: rgba(76, 79, 82, var(--text-opacity));
  }

  .lg\:active\:text-gray-700:active {
    --text-opacity: 1;
    color: #24262d;
    color: rgba(36, 38, 45, var(--text-opacity));
  }

  .lg\:active\:text-gray-800:active {
    --text-opacity: 1;
    color: #1a1c23;
    color: rgba(26, 28, 35, var(--text-opacity));
  }

  .lg\:active\:text-gray-900:active {
    --text-opacity: 1;
    color: #121317;
    color: rgba(18, 19, 23, var(--text-opacity));
  }

  .lg\:active\:text-cool-gray-50:active {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity));
  }

  .lg\:active\:text-cool-gray-100:active {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity));
  }

  .lg\:active\:text-cool-gray-200:active {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
  }

  .lg\:active\:text-cool-gray-300:active {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity));
  }

  .lg\:active\:text-cool-gray-400:active {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity));
  }

  .lg\:active\:text-cool-gray-500:active {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity));
  }

  .lg\:active\:text-cool-gray-600:active {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity));
  }

  .lg\:active\:text-cool-gray-700:active {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity));
  }

  .lg\:active\:text-cool-gray-800:active {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity));
  }

  .lg\:active\:text-cool-gray-900:active {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity));
  }

  .lg\:active\:text-red-50:active {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity));
  }

  .lg\:active\:text-red-100:active {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity));
  }

  .lg\:active\:text-red-200:active {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity));
  }

  .lg\:active\:text-red-300:active {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity));
  }

  .lg\:active\:text-red-400:active {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity));
  }

  .lg\:active\:text-red-500:active {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity));
  }

  .lg\:active\:text-red-600:active {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity));
  }

  .lg\:active\:text-red-700:active {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity));
  }

  .lg\:active\:text-red-800:active {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity));
  }

  .lg\:active\:text-red-900:active {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity));
  }

  .lg\:active\:text-orange-50:active {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity));
  }

  .lg\:active\:text-orange-100:active {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity));
  }

  .lg\:active\:text-orange-200:active {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity));
  }

  .lg\:active\:text-orange-300:active {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity));
  }

  .lg\:active\:text-orange-400:active {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity));
  }

  .lg\:active\:text-orange-500:active {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity));
  }

  .lg\:active\:text-orange-600:active {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity));
  }

  .lg\:active\:text-orange-700:active {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity));
  }

  .lg\:active\:text-orange-800:active {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity));
  }

  .lg\:active\:text-orange-900:active {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity));
  }

  .lg\:active\:text-yellow-50:active {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity));
  }

  .lg\:active\:text-yellow-100:active {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity));
  }

  .lg\:active\:text-yellow-200:active {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity));
  }

  .lg\:active\:text-yellow-300:active {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity));
  }

  .lg\:active\:text-yellow-400:active {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity));
  }

  .lg\:active\:text-yellow-500:active {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity));
  }

  .lg\:active\:text-yellow-600:active {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity));
  }

  .lg\:active\:text-yellow-700:active {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity));
  }

  .lg\:active\:text-yellow-800:active {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity));
  }

  .lg\:active\:text-yellow-900:active {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity));
  }

  .lg\:active\:text-green-50:active {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity));
  }

  .lg\:active\:text-green-100:active {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity));
  }

  .lg\:active\:text-green-200:active {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity));
  }

  .lg\:active\:text-green-300:active {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity));
  }

  .lg\:active\:text-green-400:active {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity));
  }

  .lg\:active\:text-green-500:active {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity));
  }

  .lg\:active\:text-green-600:active {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity));
  }

  .lg\:active\:text-green-700:active {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity));
  }

  .lg\:active\:text-green-800:active {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity));
  }

  .lg\:active\:text-green-900:active {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity));
  }

  .lg\:active\:text-teal-50:active {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity));
  }

  .lg\:active\:text-teal-100:active {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity));
  }

  .lg\:active\:text-teal-200:active {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity));
  }

  .lg\:active\:text-teal-300:active {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity));
  }

  .lg\:active\:text-teal-400:active {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity));
  }

  .lg\:active\:text-teal-500:active {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity));
  }

  .lg\:active\:text-teal-600:active {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity));
  }

  .lg\:active\:text-teal-700:active {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity));
  }

  .lg\:active\:text-teal-800:active {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity));
  }

  .lg\:active\:text-teal-900:active {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity));
  }

  .lg\:active\:text-blue-50:active {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity));
  }

  .lg\:active\:text-blue-100:active {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity));
  }

  .lg\:active\:text-blue-200:active {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity));
  }

  .lg\:active\:text-blue-300:active {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity));
  }

  .lg\:active\:text-blue-400:active {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity));
  }

  .lg\:active\:text-blue-500:active {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity));
  }

  .lg\:active\:text-blue-600:active {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity));
  }

  .lg\:active\:text-blue-700:active {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity));
  }

  .lg\:active\:text-blue-800:active {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity));
  }

  .lg\:active\:text-blue-900:active {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity));
  }

  .lg\:active\:text-indigo-50:active {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity));
  }

  .lg\:active\:text-indigo-100:active {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity));
  }

  .lg\:active\:text-indigo-200:active {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity));
  }

  .lg\:active\:text-indigo-300:active {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity));
  }

  .lg\:active\:text-indigo-400:active {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity));
  }

  .lg\:active\:text-indigo-500:active {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity));
  }

  .lg\:active\:text-indigo-600:active {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity));
  }

  .lg\:active\:text-indigo-700:active {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity));
  }

  .lg\:active\:text-indigo-800:active {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity));
  }

  .lg\:active\:text-indigo-900:active {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity));
  }

  .lg\:active\:text-purple-50:active {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity));
  }

  .lg\:active\:text-purple-100:active {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity));
  }

  .lg\:active\:text-purple-200:active {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity));
  }

  .lg\:active\:text-purple-300:active {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity));
  }

  .lg\:active\:text-purple-400:active {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity));
  }

  .lg\:active\:text-purple-500:active {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity));
  }

  .lg\:active\:text-purple-600:active {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity));
  }

  .lg\:active\:text-purple-700:active {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity));
  }

  .lg\:active\:text-purple-800:active {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity));
  }

  .lg\:active\:text-purple-900:active {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity));
  }

  .lg\:active\:text-pink-50:active {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity));
  }

  .lg\:active\:text-pink-100:active {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity));
  }

  .lg\:active\:text-pink-200:active {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity));
  }

  .lg\:active\:text-pink-300:active {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity));
  }

  .lg\:active\:text-pink-400:active {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity));
  }

  .lg\:active\:text-pink-500:active {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity));
  }

  .lg\:active\:text-pink-600:active {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity));
  }

  .lg\:active\:text-pink-700:active {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity));
  }

  .lg\:active\:text-pink-800:active {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity));
  }

  .lg\:active\:text-pink-900:active {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity));
  }

  .lg\:text-opacity-0 {
    --text-opacity: 0;
  }

  .lg\:text-opacity-25 {
    --text-opacity: 0.25;
  }

  .lg\:text-opacity-50 {
    --text-opacity: 0.5;
  }

  .lg\:text-opacity-75 {
    --text-opacity: 0.75;
  }

  .lg\:text-opacity-100 {
    --text-opacity: 1;
  }

  .lg\:hover\:text-opacity-0:hover {
    --text-opacity: 0;
  }

  .lg\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25;
  }

  .lg\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5;
  }

  .lg\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75;
  }

  .lg\:hover\:text-opacity-100:hover {
    --text-opacity: 1;
  }

  .lg\:focus\:text-opacity-0:focus {
    --text-opacity: 0;
  }

  .lg\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25;
  }

  .lg\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5;
  }

  .lg\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75;
  }

  .lg\:focus\:text-opacity-100:focus {
    --text-opacity: 1;
  }

  .lg\:italic {
    font-style: italic;
  }

  .lg\:not-italic {
    font-style: normal;
  }

  .lg\:uppercase {
    text-transform: uppercase;
  }

  .lg\:lowercase {
    text-transform: lowercase;
  }

  .lg\:capitalize {
    text-transform: capitalize;
  }

  .lg\:normal-case {
    text-transform: none;
  }

  .lg\:underline {
    text-decoration: underline;
  }

  .lg\:line-through {
    text-decoration: line-through;
  }

  .lg\:no-underline {
    text-decoration: none;
  }

  .lg\:hover\:underline:hover {
    text-decoration: underline;
  }

  .lg\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .lg\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .lg\:focus\:underline:focus {
    text-decoration: underline;
  }

  .lg\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .lg\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .lg\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .lg\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .lg\:tracking-tighter {
    letter-spacing: -0.05em;
  }

  .lg\:tracking-tight {
    letter-spacing: -0.025em;
  }

  .lg\:tracking-normal {
    letter-spacing: 0;
  }

  .lg\:tracking-wide {
    letter-spacing: 0.025em;
  }

  .lg\:tracking-wider {
    letter-spacing: 0.05em;
  }

  .lg\:tracking-widest {
    letter-spacing: 0.1em;
  }

  .lg\:select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .lg\:select-text {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  .lg\:select-all {
    -webkit-user-select: all;
    -moz-user-select: all;
    -ms-user-select: all;
    user-select: all;
  }

  .lg\:select-auto {
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }

  .lg\:align-baseline {
    vertical-align: baseline;
  }

  .lg\:align-top {
    vertical-align: top;
  }

  .lg\:align-middle {
    vertical-align: middle;
  }

  .lg\:align-bottom {
    vertical-align: bottom;
  }

  .lg\:align-text-top {
    vertical-align: text-top;
  }

  .lg\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .lg\:visible {
    visibility: visible;
  }

  .lg\:invisible {
    visibility: hidden;
  }

  .lg\:whitespace-normal {
    white-space: normal;
  }

  .lg\:whitespace-no-wrap {
    white-space: nowrap;
  }

  .lg\:whitespace-pre {
    white-space: pre;
  }

  .lg\:whitespace-pre-line {
    white-space: pre-line;
  }

  .lg\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .lg\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .lg\:break-words {
    overflow-wrap: break-word;
  }

  .lg\:break-all {
    word-break: break-all;
  }

  .lg\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lg\:w-0 {
    width: 0;
  }

  .lg\:w-1 {
    width: 0.25rem;
  }

  .lg\:w-2 {
    width: 0.5rem;
  }

  .lg\:w-3 {
    width: 0.75rem;
  }

  .lg\:w-4 {
    width: 1rem;
  }

  .lg\:w-5 {
    width: 1.25rem;
  }

  .lg\:w-6 {
    width: 1.5rem;
  }

  .lg\:w-8 {
    width: 2rem;
  }

  .lg\:w-10 {
    width: 2.5rem;
  }

  .lg\:w-12 {
    width: 3rem;
  }

  .lg\:w-16 {
    width: 4rem;
  }

  .lg\:w-20 {
    width: 5rem;
  }

  .lg\:w-24 {
    width: 6rem;
  }

  .lg\:w-32 {
    width: 8rem;
  }

  .lg\:w-40 {
    width: 10rem;
  }

  .lg\:w-48 {
    width: 12rem;
  }

  .lg\:w-56 {
    width: 14rem;
  }

  .lg\:w-64 {
    width: 16rem;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-px {
    width: 1px;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/3 {
    width: 33.333333%;
  }

  .lg\:w-2\/3 {
    width: 66.666667%;
  }

  .lg\:w-1\/4 {
    width: 25%;
  }

  .lg\:w-2\/4 {
    width: 50%;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:w-1\/5 {
    width: 20%;
  }

  .lg\:w-2\/5 {
    width: 40%;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:w-4\/5 {
    width: 80%;
  }

  .lg\:w-1\/6 {
    width: 16.666667%;
  }

  .lg\:w-2\/6 {
    width: 33.333333%;
  }

  .lg\:w-3\/6 {
    width: 50%;
  }

  .lg\:w-4\/6 {
    width: 66.666667%;
  }

  .lg\:w-5\/6 {
    width: 83.333333%;
  }

  .lg\:w-1\/12 {
    width: 8.333333%;
  }

  .lg\:w-2\/12 {
    width: 16.666667%;
  }

  .lg\:w-3\/12 {
    width: 25%;
  }

  .lg\:w-4\/12 {
    width: 33.333333%;
  }

  .lg\:w-5\/12 {
    width: 41.666667%;
  }

  .lg\:w-6\/12 {
    width: 50%;
  }

  .lg\:w-7\/12 {
    width: 58.333333%;
  }

  .lg\:w-8\/12 {
    width: 66.666667%;
  }

  .lg\:w-9\/12 {
    width: 75%;
  }

  .lg\:w-10\/12 {
    width: 83.333333%;
  }

  .lg\:w-11\/12 {
    width: 91.666667%;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:w-screen {
    width: 100vw;
  }

  .lg\:z-0 {
    z-index: 0;
  }

  .lg\:z-10 {
    z-index: 10;
  }

  .lg\:z-20 {
    z-index: 20;
  }

  .lg\:z-30 {
    z-index: 30;
  }

  .lg\:z-40 {
    z-index: 40;
  }

  .lg\:z-50 {
    z-index: 50;
  }

  .lg\:z-auto {
    z-index: auto;
  }

  .lg\:gap-0 {
    grid-gap: 0;
    gap: 0;
  }

  .lg\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }

  .lg\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .lg\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }

  .lg\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .lg\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }

  .lg\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .lg\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }

  .lg\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }

  .lg\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }

  .lg\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }

  .lg\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }

  .lg\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }

  .lg\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem;
  }

  .lg\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }

  .lg\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem;
  }

  .lg\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem;
  }

  .lg\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem;
  }

  .lg\:gap-px {
    grid-gap: 1px;
    gap: 1px;
  }

  .lg\:col-gap-0 {
    grid-column-gap: 0;
    -webkit-column-gap: 0;
    column-gap: 0;
  }

  .lg\:col-gap-1 {
    grid-column-gap: 0.25rem;
    -webkit-column-gap: 0.25rem;
    column-gap: 0.25rem;
  }

  .lg\:col-gap-2 {
    grid-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
    column-gap: 0.5rem;
  }

  .lg\:col-gap-3 {
    grid-column-gap: 0.75rem;
    -webkit-column-gap: 0.75rem;
    column-gap: 0.75rem;
  }

  .lg\:col-gap-4 {
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
    column-gap: 1rem;
  }

  .lg\:col-gap-5 {
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }

  .lg\:col-gap-6 {
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  .lg\:col-gap-8 {
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
    column-gap: 2rem;
  }

  .lg\:col-gap-10 {
    grid-column-gap: 2.5rem;
    -webkit-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }

  .lg\:col-gap-12 {
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
    column-gap: 3rem;
  }

  .lg\:col-gap-16 {
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
    column-gap: 4rem;
  }

  .lg\:col-gap-20 {
    grid-column-gap: 5rem;
    -webkit-column-gap: 5rem;
    column-gap: 5rem;
  }

  .lg\:col-gap-24 {
    grid-column-gap: 6rem;
    -webkit-column-gap: 6rem;
    column-gap: 6rem;
  }

  .lg\:col-gap-32 {
    grid-column-gap: 8rem;
    -webkit-column-gap: 8rem;
    column-gap: 8rem;
  }

  .lg\:col-gap-40 {
    grid-column-gap: 10rem;
    -webkit-column-gap: 10rem;
    column-gap: 10rem;
  }

  .lg\:col-gap-48 {
    grid-column-gap: 12rem;
    -webkit-column-gap: 12rem;
    column-gap: 12rem;
  }

  .lg\:col-gap-56 {
    grid-column-gap: 14rem;
    -webkit-column-gap: 14rem;
    column-gap: 14rem;
  }

  .lg\:col-gap-64 {
    grid-column-gap: 16rem;
    -webkit-column-gap: 16rem;
    column-gap: 16rem;
  }

  .lg\:col-gap-px {
    grid-column-gap: 1px;
    -webkit-column-gap: 1px;
    column-gap: 1px;
  }

  .lg\:row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .lg\:row-gap-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }

  .lg\:row-gap-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  .lg\:row-gap-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }

  .lg\:row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .lg\:row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .lg\:row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .lg\:row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .lg\:row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .lg\:row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }

  .lg\:row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .lg\:row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }

  .lg\:row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }

  .lg\:row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }

  .lg\:row-gap-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }

  .lg\:row-gap-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }

  .lg\:row-gap-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }

  .lg\:row-gap-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }

  .lg\:row-gap-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .lg\:grid-flow-row {
    grid-auto-flow: row;
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .lg\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:grid-cols-none {
    grid-template-columns: none;
  }

  .lg\:col-auto {
    grid-column: auto;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg\:col-start-1 {
    grid-column-start: 1;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:col-start-3 {
    grid-column-start: 3;
  }

  .lg\:col-start-4 {
    grid-column-start: 4;
  }

  .lg\:col-start-5 {
    grid-column-start: 5;
  }

  .lg\:col-start-6 {
    grid-column-start: 6;
  }

  .lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:col-start-8 {
    grid-column-start: 8;
  }

  .lg\:col-start-9 {
    grid-column-start: 9;
  }

  .lg\:col-start-10 {
    grid-column-start: 10;
  }

  .lg\:col-start-11 {
    grid-column-start: 11;
  }

  .lg\:col-start-12 {
    grid-column-start: 12;
  }

  .lg\:col-start-13 {
    grid-column-start: 13;
  }

  .lg\:col-start-auto {
    grid-column-start: auto;
  }

  .lg\:col-end-1 {
    grid-column-end: 1;
  }

  .lg\:col-end-2 {
    grid-column-end: 2;
  }

  .lg\:col-end-3 {
    grid-column-end: 3;
  }

  .lg\:col-end-4 {
    grid-column-end: 4;
  }

  .lg\:col-end-5 {
    grid-column-end: 5;
  }

  .lg\:col-end-6 {
    grid-column-end: 6;
  }

  .lg\:col-end-7 {
    grid-column-end: 7;
  }

  .lg\:col-end-8 {
    grid-column-end: 8;
  }

  .lg\:col-end-9 {
    grid-column-end: 9;
  }

  .lg\:col-end-10 {
    grid-column-end: 10;
  }

  .lg\:col-end-11 {
    grid-column-end: 11;
  }

  .lg\:col-end-12 {
    grid-column-end: 12;
  }

  .lg\:col-end-13 {
    grid-column-end: 13;
  }

  .lg\:col-end-auto {
    grid-column-end: auto;
  }

  .lg\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .lg\:grid-rows-none {
    grid-template-rows: none;
  }

  .lg\:row-auto {
    grid-row: auto;
  }

  .lg\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .lg\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .lg\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .lg\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .lg\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .lg\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .lg\:row-start-1 {
    grid-row-start: 1;
  }

  .lg\:row-start-2 {
    grid-row-start: 2;
  }

  .lg\:row-start-3 {
    grid-row-start: 3;
  }

  .lg\:row-start-4 {
    grid-row-start: 4;
  }

  .lg\:row-start-5 {
    grid-row-start: 5;
  }

  .lg\:row-start-6 {
    grid-row-start: 6;
  }

  .lg\:row-start-7 {
    grid-row-start: 7;
  }

  .lg\:row-start-auto {
    grid-row-start: auto;
  }

  .lg\:row-end-1 {
    grid-row-end: 1;
  }

  .lg\:row-end-2 {
    grid-row-end: 2;
  }

  .lg\:row-end-3 {
    grid-row-end: 3;
  }

  .lg\:row-end-4 {
    grid-row-end: 4;
  }

  .lg\:row-end-5 {
    grid-row-end: 5;
  }

  .lg\:row-end-6 {
    grid-row-end: 6;
  }

  .lg\:row-end-7 {
    grid-row-end: 7;
  }

  .lg\:row-end-auto {
    grid-row-end: auto;
  }

  .lg\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x))
      translateY(var(--transform-translate-y)) rotate(var(--transform-rotate))
      skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y))
      scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  }

  .lg\:transform-none {
    transform: none;
  }

  .lg\:origin-center {
    transform-origin: center;
  }

  .lg\:origin-top {
    transform-origin: top;
  }

  .lg\:origin-top-right {
    transform-origin: top right;
  }

  .lg\:origin-right {
    transform-origin: right;
  }

  .lg\:origin-bottom-right {
    transform-origin: bottom right;
  }

  .lg\:origin-bottom {
    transform-origin: bottom;
  }

  .lg\:origin-bottom-left {
    transform-origin: bottom left;
  }

  .lg\:origin-left {
    transform-origin: left;
  }

  .lg\:origin-top-left {
    transform-origin: top left;
  }

  .lg\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .lg\:scale-50 {
    --transform-scale-x: 0.5;
    --transform-scale-y: 0.5;
  }

  .lg\:scale-75 {
    --transform-scale-x: 0.75;
    --transform-scale-y: 0.75;
  }

  .lg\:scale-90 {
    --transform-scale-x: 0.9;
    --transform-scale-y: 0.9;
  }

  .lg\:scale-95 {
    --transform-scale-x: 0.95;
    --transform-scale-y: 0.95;
  }

  .lg\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .lg\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .lg\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .lg\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .lg\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .lg\:scale-x-0 {
    --transform-scale-x: 0;
  }

  .lg\:scale-x-50 {
    --transform-scale-x: 0.5;
  }

  .lg\:scale-x-75 {
    --transform-scale-x: 0.75;
  }

  .lg\:scale-x-90 {
    --transform-scale-x: 0.9;
  }

  .lg\:scale-x-95 {
    --transform-scale-x: 0.95;
  }

  .lg\:scale-x-100 {
    --transform-scale-x: 1;
  }

  .lg\:scale-x-105 {
    --transform-scale-x: 1.05;
  }

  .lg\:scale-x-110 {
    --transform-scale-x: 1.1;
  }

  .lg\:scale-x-125 {
    --transform-scale-x: 1.25;
  }

  .lg\:scale-x-150 {
    --transform-scale-x: 1.5;
  }

  .lg\:scale-y-0 {
    --transform-scale-y: 0;
  }

  .lg\:scale-y-50 {
    --transform-scale-y: 0.5;
  }

  .lg\:scale-y-75 {
    --transform-scale-y: 0.75;
  }

  .lg\:scale-y-90 {
    --transform-scale-y: 0.9;
  }

  .lg\:scale-y-95 {
    --transform-scale-y: 0.95;
  }

  .lg\:scale-y-100 {
    --transform-scale-y: 1;
  }

  .lg\:scale-y-105 {
    --transform-scale-y: 1.05;
  }

  .lg\:scale-y-110 {
    --transform-scale-y: 1.1;
  }

  .lg\:scale-y-125 {
    --transform-scale-y: 1.25;
  }

  .lg\:scale-y-150 {
    --transform-scale-y: 1.5;
  }

  .lg\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .lg\:hover\:scale-50:hover {
    --transform-scale-x: 0.5;
    --transform-scale-y: 0.5;
  }

  .lg\:hover\:scale-75:hover {
    --transform-scale-x: 0.75;
    --transform-scale-y: 0.75;
  }

  .lg\:hover\:scale-90:hover {
    --transform-scale-x: 0.9;
    --transform-scale-y: 0.9;
  }

  .lg\:hover\:scale-95:hover {
    --transform-scale-x: 0.95;
    --transform-scale-y: 0.95;
  }

  .lg\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .lg\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .lg\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .lg\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .lg\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .lg\:hover\:scale-x-0:hover {
    --transform-scale-x: 0;
  }

  .lg\:hover\:scale-x-50:hover {
    --transform-scale-x: 0.5;
  }

  .lg\:hover\:scale-x-75:hover {
    --transform-scale-x: 0.75;
  }

  .lg\:hover\:scale-x-90:hover {
    --transform-scale-x: 0.9;
  }

  .lg\:hover\:scale-x-95:hover {
    --transform-scale-x: 0.95;
  }

  .lg\:hover\:scale-x-100:hover {
    --transform-scale-x: 1;
  }

  .lg\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05;
  }

  .lg\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1;
  }

  .lg\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25;
  }

  .lg\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5;
  }

  .lg\:hover\:scale-y-0:hover {
    --transform-scale-y: 0;
  }

  .lg\:hover\:scale-y-50:hover {
    --transform-scale-y: 0.5;
  }

  .lg\:hover\:scale-y-75:hover {
    --transform-scale-y: 0.75;
  }

  .lg\:hover\:scale-y-90:hover {
    --transform-scale-y: 0.9;
  }

  .lg\:hover\:scale-y-95:hover {
    --transform-scale-y: 0.95;
  }

  .lg\:hover\:scale-y-100:hover {
    --transform-scale-y: 1;
  }

  .lg\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05;
  }

  .lg\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1;
  }

  .lg\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25;
  }

  .lg\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5;
  }

  .lg\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .lg\:focus\:scale-50:focus {
    --transform-scale-x: 0.5;
    --transform-scale-y: 0.5;
  }

  .lg\:focus\:scale-75:focus {
    --transform-scale-x: 0.75;
    --transform-scale-y: 0.75;
  }

  .lg\:focus\:scale-90:focus {
    --transform-scale-x: 0.9;
    --transform-scale-y: 0.9;
  }

  .lg\:focus\:scale-95:focus {
    --transform-scale-x: 0.95;
    --transform-scale-y: 0.95;
  }

  .lg\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .lg\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .lg\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .lg\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .lg\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .lg\:focus\:scale-x-0:focus {
    --transform-scale-x: 0;
  }

  .lg\:focus\:scale-x-50:focus {
    --transform-scale-x: 0.5;
  }

  .lg\:focus\:scale-x-75:focus {
    --transform-scale-x: 0.75;
  }

  .lg\:focus\:scale-x-90:focus {
    --transform-scale-x: 0.9;
  }

  .lg\:focus\:scale-x-95:focus {
    --transform-scale-x: 0.95;
  }

  .lg\:focus\:scale-x-100:focus {
    --transform-scale-x: 1;
  }

  .lg\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05;
  }

  .lg\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1;
  }

  .lg\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25;
  }

  .lg\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5;
  }

  .lg\:focus\:scale-y-0:focus {
    --transform-scale-y: 0;
  }

  .lg\:focus\:scale-y-50:focus {
    --transform-scale-y: 0.5;
  }

  .lg\:focus\:scale-y-75:focus {
    --transform-scale-y: 0.75;
  }

  .lg\:focus\:scale-y-90:focus {
    --transform-scale-y: 0.9;
  }

  .lg\:focus\:scale-y-95:focus {
    --transform-scale-y: 0.95;
  }

  .lg\:focus\:scale-y-100:focus {
    --transform-scale-y: 1;
  }

  .lg\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05;
  }

  .lg\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1;
  }

  .lg\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25;
  }

  .lg\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5;
  }

  .lg\:rotate-0 {
    --transform-rotate: 0;
  }

  .lg\:rotate-45 {
    --transform-rotate: 45deg;
  }

  .lg\:rotate-90 {
    --transform-rotate: 90deg;
  }

  .lg\:rotate-180 {
    --transform-rotate: 180deg;
  }

  .lg\:-rotate-180 {
    --transform-rotate: -180deg;
  }

  .lg\:-rotate-90 {
    --transform-rotate: -90deg;
  }

  .lg\:-rotate-45 {
    --transform-rotate: -45deg;
  }

  .lg\:hover\:rotate-0:hover {
    --transform-rotate: 0;
  }

  .lg\:hover\:rotate-45:hover {
    --transform-rotate: 45deg;
  }

  .lg\:hover\:rotate-90:hover {
    --transform-rotate: 90deg;
  }

  .lg\:hover\:rotate-180:hover {
    --transform-rotate: 180deg;
  }

  .lg\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg;
  }

  .lg\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg;
  }

  .lg\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg;
  }

  .lg\:focus\:rotate-0:focus {
    --transform-rotate: 0;
  }

  .lg\:focus\:rotate-45:focus {
    --transform-rotate: 45deg;
  }

  .lg\:focus\:rotate-90:focus {
    --transform-rotate: 90deg;
  }

  .lg\:focus\:rotate-180:focus {
    --transform-rotate: 180deg;
  }

  .lg\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg;
  }

  .lg\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg;
  }

  .lg\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg;
  }

  .lg\:translate-x-0 {
    --transform-translate-x: 0;
  }

  .lg\:translate-x-1 {
    --transform-translate-x: 0.25rem;
  }

  .lg\:translate-x-2 {
    --transform-translate-x: 0.5rem;
  }

  .lg\:translate-x-3 {
    --transform-translate-x: 0.75rem;
  }

  .lg\:translate-x-4 {
    --transform-translate-x: 1rem;
  }

  .lg\:translate-x-5 {
    --transform-translate-x: 1.25rem;
  }

  .lg\:translate-x-6 {
    --transform-translate-x: 1.5rem;
  }

  .lg\:translate-x-8 {
    --transform-translate-x: 2rem;
  }

  .lg\:translate-x-10 {
    --transform-translate-x: 2.5rem;
  }

  .lg\:translate-x-12 {
    --transform-translate-x: 3rem;
  }

  .lg\:translate-x-16 {
    --transform-translate-x: 4rem;
  }

  .lg\:translate-x-20 {
    --transform-translate-x: 5rem;
  }

  .lg\:translate-x-24 {
    --transform-translate-x: 6rem;
  }

  .lg\:translate-x-32 {
    --transform-translate-x: 8rem;
  }

  .lg\:translate-x-40 {
    --transform-translate-x: 10rem;
  }

  .lg\:translate-x-48 {
    --transform-translate-x: 12rem;
  }

  .lg\:translate-x-56 {
    --transform-translate-x: 14rem;
  }

  .lg\:translate-x-64 {
    --transform-translate-x: 16rem;
  }

  .lg\:translate-x-px {
    --transform-translate-x: 1px;
  }

  .lg\:-translate-x-1 {
    --transform-translate-x: -0.25rem;
  }

  .lg\:-translate-x-2 {
    --transform-translate-x: -0.5rem;
  }

  .lg\:-translate-x-3 {
    --transform-translate-x: -0.75rem;
  }

  .lg\:-translate-x-4 {
    --transform-translate-x: -1rem;
  }

  .lg\:-translate-x-5 {
    --transform-translate-x: -1.25rem;
  }

  .lg\:-translate-x-6 {
    --transform-translate-x: -1.5rem;
  }

  .lg\:-translate-x-8 {
    --transform-translate-x: -2rem;
  }

  .lg\:-translate-x-10 {
    --transform-translate-x: -2.5rem;
  }

  .lg\:-translate-x-12 {
    --transform-translate-x: -3rem;
  }

  .lg\:-translate-x-16 {
    --transform-translate-x: -4rem;
  }

  .lg\:-translate-x-20 {
    --transform-translate-x: -5rem;
  }

  .lg\:-translate-x-24 {
    --transform-translate-x: -6rem;
  }

  .lg\:-translate-x-32 {
    --transform-translate-x: -8rem;
  }

  .lg\:-translate-x-40 {
    --transform-translate-x: -10rem;
  }

  .lg\:-translate-x-48 {
    --transform-translate-x: -12rem;
  }

  .lg\:-translate-x-56 {
    --transform-translate-x: -14rem;
  }

  .lg\:-translate-x-64 {
    --transform-translate-x: -16rem;
  }

  .lg\:-translate-x-px {
    --transform-translate-x: -1px;
  }

  .lg\:-translate-x-full {
    --transform-translate-x: -100%;
  }

  .lg\:-translate-x-1\/2 {
    --transform-translate-x: -50%;
  }

  .lg\:translate-x-1\/2 {
    --transform-translate-x: 50%;
  }

  .lg\:translate-x-full {
    --transform-translate-x: 100%;
  }

  .lg\:translate-y-0 {
    --transform-translate-y: 0;
  }

  .lg\:translate-y-1 {
    --transform-translate-y: 0.25rem;
  }

  .lg\:translate-y-2 {
    --transform-translate-y: 0.5rem;
  }

  .lg\:translate-y-3 {
    --transform-translate-y: 0.75rem;
  }

  .lg\:translate-y-4 {
    --transform-translate-y: 1rem;
  }

  .lg\:translate-y-5 {
    --transform-translate-y: 1.25rem;
  }

  .lg\:translate-y-6 {
    --transform-translate-y: 1.5rem;
  }

  .lg\:translate-y-8 {
    --transform-translate-y: 2rem;
  }

  .lg\:translate-y-10 {
    --transform-translate-y: 2.5rem;
  }

  .lg\:translate-y-12 {
    --transform-translate-y: 3rem;
  }

  .lg\:translate-y-16 {
    --transform-translate-y: 4rem;
  }

  .lg\:translate-y-20 {
    --transform-translate-y: 5rem;
  }

  .lg\:translate-y-24 {
    --transform-translate-y: 6rem;
  }

  .lg\:translate-y-32 {
    --transform-translate-y: 8rem;
  }

  .lg\:translate-y-40 {
    --transform-translate-y: 10rem;
  }

  .lg\:translate-y-48 {
    --transform-translate-y: 12rem;
  }

  .lg\:translate-y-56 {
    --transform-translate-y: 14rem;
  }

  .lg\:translate-y-64 {
    --transform-translate-y: 16rem;
  }

  .lg\:translate-y-px {
    --transform-translate-y: 1px;
  }

  .lg\:-translate-y-1 {
    --transform-translate-y: -0.25rem;
  }

  .lg\:-translate-y-2 {
    --transform-translate-y: -0.5rem;
  }

  .lg\:-translate-y-3 {
    --transform-translate-y: -0.75rem;
  }

  .lg\:-translate-y-4 {
    --transform-translate-y: -1rem;
  }

  .lg\:-translate-y-5 {
    --transform-translate-y: -1.25rem;
  }

  .lg\:-translate-y-6 {
    --transform-translate-y: -1.5rem;
  }

  .lg\:-translate-y-8 {
    --transform-translate-y: -2rem;
  }

  .lg\:-translate-y-10 {
    --transform-translate-y: -2.5rem;
  }

  .lg\:-translate-y-12 {
    --transform-translate-y: -3rem;
  }

  .lg\:-translate-y-16 {
    --transform-translate-y: -4rem;
  }

  .lg\:-translate-y-20 {
    --transform-translate-y: -5rem;
  }

  .lg\:-translate-y-24 {
    --transform-translate-y: -6rem;
  }

  .lg\:-translate-y-32 {
    --transform-translate-y: -8rem;
  }

  .lg\:-translate-y-40 {
    --transform-translate-y: -10rem;
  }

  .lg\:-translate-y-48 {
    --transform-translate-y: -12rem;
  }

  .lg\:-translate-y-56 {
    --transform-translate-y: -14rem;
  }

  .lg\:-translate-y-64 {
    --transform-translate-y: -16rem;
  }

  .lg\:-translate-y-px {
    --transform-translate-y: -1px;
  }

  .lg\:-translate-y-full {
    --transform-translate-y: -100%;
  }

  .lg\:-translate-y-1\/2 {
    --transform-translate-y: -50%;
  }

  .lg\:translate-y-1\/2 {
    --transform-translate-y: 50%;
  }

  .lg\:translate-y-full {
    --transform-translate-y: 100%;
  }

  .lg\:hover\:translate-x-0:hover {
    --transform-translate-x: 0;
  }

  .lg\:hover\:translate-x-1:hover {
    --transform-translate-x: 0.25rem;
  }

  .lg\:hover\:translate-x-2:hover {
    --transform-translate-x: 0.5rem;
  }

  .lg\:hover\:translate-x-3:hover {
    --transform-translate-x: 0.75rem;
  }

  .lg\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem;
  }

  .lg\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem;
  }

  .lg\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem;
  }

  .lg\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem;
  }

  .lg\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem;
  }

  .lg\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem;
  }

  .lg\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem;
  }

  .lg\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem;
  }

  .lg\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem;
  }

  .lg\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem;
  }

  .lg\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem;
  }

  .lg\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem;
  }

  .lg\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem;
  }

  .lg\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem;
  }

  .lg\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px;
  }

  .lg\:hover\:-translate-x-1:hover {
    --transform-translate-x: -0.25rem;
  }

  .lg\:hover\:-translate-x-2:hover {
    --transform-translate-x: -0.5rem;
  }

  .lg\:hover\:-translate-x-3:hover {
    --transform-translate-x: -0.75rem;
  }

  .lg\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem;
  }

  .lg\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem;
  }

  .lg\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem;
  }

  .lg\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem;
  }

  .lg\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem;
  }

  .lg\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem;
  }

  .lg\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem;
  }

  .lg\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem;
  }

  .lg\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem;
  }

  .lg\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem;
  }

  .lg\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem;
  }

  .lg\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem;
  }

  .lg\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem;
  }

  .lg\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem;
  }

  .lg\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px;
  }

  .lg\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%;
  }

  .lg\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%;
  }

  .lg\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%;
  }

  .lg\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%;
  }

  .lg\:hover\:translate-y-0:hover {
    --transform-translate-y: 0;
  }

  .lg\:hover\:translate-y-1:hover {
    --transform-translate-y: 0.25rem;
  }

  .lg\:hover\:translate-y-2:hover {
    --transform-translate-y: 0.5rem;
  }

  .lg\:hover\:translate-y-3:hover {
    --transform-translate-y: 0.75rem;
  }

  .lg\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem;
  }

  .lg\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem;
  }

  .lg\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem;
  }

  .lg\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem;
  }

  .lg\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem;
  }

  .lg\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem;
  }

  .lg\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem;
  }

  .lg\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem;
  }

  .lg\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem;
  }

  .lg\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem;
  }

  .lg\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem;
  }

  .lg\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem;
  }

  .lg\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem;
  }

  .lg\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem;
  }

  .lg\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px;
  }

  .lg\:hover\:-translate-y-1:hover {
    --transform-translate-y: -0.25rem;
  }

  .lg\:hover\:-translate-y-2:hover {
    --transform-translate-y: -0.5rem;
  }

  .lg\:hover\:-translate-y-3:hover {
    --transform-translate-y: -0.75rem;
  }

  .lg\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem;
  }

  .lg\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem;
  }

  .lg\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem;
  }

  .lg\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem;
  }

  .lg\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem;
  }

  .lg\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem;
  }

  .lg\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem;
  }

  .lg\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem;
  }

  .lg\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem;
  }

  .lg\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem;
  }

  .lg\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem;
  }

  .lg\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem;
  }

  .lg\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem;
  }

  .lg\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem;
  }

  .lg\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px;
  }

  .lg\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%;
  }

  .lg\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%;
  }

  .lg\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%;
  }

  .lg\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%;
  }

  .lg\:focus\:translate-x-0:focus {
    --transform-translate-x: 0;
  }

  .lg\:focus\:translate-x-1:focus {
    --transform-translate-x: 0.25rem;
  }

  .lg\:focus\:translate-x-2:focus {
    --transform-translate-x: 0.5rem;
  }

  .lg\:focus\:translate-x-3:focus {
    --transform-translate-x: 0.75rem;
  }

  .lg\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem;
  }

  .lg\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem;
  }

  .lg\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem;
  }

  .lg\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem;
  }

  .lg\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem;
  }

  .lg\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem;
  }

  .lg\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem;
  }

  .lg\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem;
  }

  .lg\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem;
  }

  .lg\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem;
  }

  .lg\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem;
  }

  .lg\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem;
  }

  .lg\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem;
  }

  .lg\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem;
  }

  .lg\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px;
  }

  .lg\:focus\:-translate-x-1:focus {
    --transform-translate-x: -0.25rem;
  }

  .lg\:focus\:-translate-x-2:focus {
    --transform-translate-x: -0.5rem;
  }

  .lg\:focus\:-translate-x-3:focus {
    --transform-translate-x: -0.75rem;
  }

  .lg\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem;
  }

  .lg\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem;
  }

  .lg\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem;
  }

  .lg\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem;
  }

  .lg\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem;
  }

  .lg\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem;
  }

  .lg\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem;
  }

  .lg\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem;
  }

  .lg\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem;
  }

  .lg\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem;
  }

  .lg\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem;
  }

  .lg\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem;
  }

  .lg\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem;
  }

  .lg\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem;
  }

  .lg\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px;
  }

  .lg\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%;
  }

  .lg\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%;
  }

  .lg\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%;
  }

  .lg\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%;
  }

  .lg\:focus\:translate-y-0:focus {
    --transform-translate-y: 0;
  }

  .lg\:focus\:translate-y-1:focus {
    --transform-translate-y: 0.25rem;
  }

  .lg\:focus\:translate-y-2:focus {
    --transform-translate-y: 0.5rem;
  }

  .lg\:focus\:translate-y-3:focus {
    --transform-translate-y: 0.75rem;
  }

  .lg\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem;
  }

  .lg\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem;
  }

  .lg\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem;
  }

  .lg\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem;
  }

  .lg\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem;
  }

  .lg\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem;
  }

  .lg\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem;
  }

  .lg\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem;
  }

  .lg\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem;
  }

  .lg\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem;
  }

  .lg\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem;
  }

  .lg\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem;
  }

  .lg\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem;
  }

  .lg\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem;
  }

  .lg\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px;
  }

  .lg\:focus\:-translate-y-1:focus {
    --transform-translate-y: -0.25rem;
  }

  .lg\:focus\:-translate-y-2:focus {
    --transform-translate-y: -0.5rem;
  }

  .lg\:focus\:-translate-y-3:focus {
    --transform-translate-y: -0.75rem;
  }

  .lg\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem;
  }

  .lg\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem;
  }

  .lg\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem;
  }

  .lg\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem;
  }

  .lg\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem;
  }

  .lg\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem;
  }

  .lg\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem;
  }

  .lg\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem;
  }

  .lg\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem;
  }

  .lg\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem;
  }

  .lg\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem;
  }

  .lg\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem;
  }

  .lg\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem;
  }

  .lg\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem;
  }

  .lg\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px;
  }

  .lg\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%;
  }

  .lg\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%;
  }

  .lg\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%;
  }

  .lg\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%;
  }

  .lg\:skew-x-0 {
    --transform-skew-x: 0;
  }

  .lg\:skew-x-3 {
    --transform-skew-x: 3deg;
  }

  .lg\:skew-x-6 {
    --transform-skew-x: 6deg;
  }

  .lg\:skew-x-12 {
    --transform-skew-x: 12deg;
  }

  .lg\:-skew-x-12 {
    --transform-skew-x: -12deg;
  }

  .lg\:-skew-x-6 {
    --transform-skew-x: -6deg;
  }

  .lg\:-skew-x-3 {
    --transform-skew-x: -3deg;
  }

  .lg\:skew-y-0 {
    --transform-skew-y: 0;
  }

  .lg\:skew-y-3 {
    --transform-skew-y: 3deg;
  }

  .lg\:skew-y-6 {
    --transform-skew-y: 6deg;
  }

  .lg\:skew-y-12 {
    --transform-skew-y: 12deg;
  }

  .lg\:-skew-y-12 {
    --transform-skew-y: -12deg;
  }

  .lg\:-skew-y-6 {
    --transform-skew-y: -6deg;
  }

  .lg\:-skew-y-3 {
    --transform-skew-y: -3deg;
  }

  .lg\:hover\:skew-x-0:hover {
    --transform-skew-x: 0;
  }

  .lg\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg;
  }

  .lg\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg;
  }

  .lg\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg;
  }

  .lg\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg;
  }

  .lg\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg;
  }

  .lg\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg;
  }

  .lg\:hover\:skew-y-0:hover {
    --transform-skew-y: 0;
  }

  .lg\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg;
  }

  .lg\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg;
  }

  .lg\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg;
  }

  .lg\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg;
  }

  .lg\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg;
  }

  .lg\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg;
  }

  .lg\:focus\:skew-x-0:focus {
    --transform-skew-x: 0;
  }

  .lg\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg;
  }

  .lg\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg;
  }

  .lg\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg;
  }

  .lg\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg;
  }

  .lg\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg;
  }

  .lg\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg;
  }

  .lg\:focus\:skew-y-0:focus {
    --transform-skew-y: 0;
  }

  .lg\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg;
  }

  .lg\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg;
  }

  .lg\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg;
  }

  .lg\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg;
  }

  .lg\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg;
  }

  .lg\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg;
  }

  .lg\:transition-none {
    transition-property: none;
  }

  .lg\:transition-all {
    transition-property: all;
  }

  .lg\:transition {
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform;
  }

  .lg\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .lg\:transition-opacity {
    transition-property: opacity;
  }

  .lg\:transition-shadow {
    transition-property: box-shadow;
  }

  .lg\:transition-transform {
    transition-property: transform;
  }

  .lg\:ease-linear {
    transition-timing-function: linear;
  }

  .lg\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .lg\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .lg\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .lg\:duration-75 {
    transition-duration: 75ms;
  }

  .lg\:duration-100 {
    transition-duration: 100ms;
  }

  .lg\:duration-150 {
    transition-duration: 150ms;
  }

  .lg\:duration-200 {
    transition-duration: 200ms;
  }

  .lg\:duration-300 {
    transition-duration: 300ms;
  }

  .lg\:duration-500 {
    transition-duration: 500ms;
  }

  .lg\:duration-700 {
    transition-duration: 700ms;
  }

  .lg\:duration-1000 {
    transition-duration: 1000ms;
  }

  .lg\:delay-75 {
    transition-delay: 75ms;
  }

  .lg\:delay-100 {
    transition-delay: 100ms;
  }

  .lg\:delay-150 {
    transition-delay: 150ms;
  }

  .lg\:delay-200 {
    transition-delay: 200ms;
  }

  .lg\:delay-300 {
    transition-delay: 300ms;
  }

  .lg\:delay-500 {
    transition-delay: 500ms;
  }

  .lg\:delay-700 {
    transition-delay: 700ms;
  }

  .lg\:delay-1000 {
    transition-delay: 1000ms;
  }

  .lg\:shadow-outline-white {
    box-shadow: 0 0 0 3px hsla(0, 0%, 100%, 0.45);
  }

  .lg\:shadow-outline-black {
    box-shadow: 0 0 0 3px hsla(0, 0%, 0%, 0.45);
  }

  .lg\:shadow-outline-gray {
    box-shadow: 0 0 0 3px hsla(210, 2.4%, 83.9%, 0.45);
  }

  .lg\:shadow-outline-cool-gray {
    box-shadow: 0 0 0 3px hsla(213, 26.3%, 85.1%, 0.45);
  }

  .lg\:shadow-outline-red {
    box-shadow: 0 0 0 3px hsla(0, 82.9%, 83.9%, 0.45);
  }

  .lg\:shadow-outline-orange {
    box-shadow: 0 0 0 3px hsla(24.399999999999977, 96.6%, 77.1%, 0.45);
  }

  .lg\:shadow-outline-yellow {
    box-shadow: 0 0 0 3px hsla(47.39999999999998, 95.8%, 53.1%, 0.45);
  }

  .lg\:shadow-outline-green {
    box-shadow: 0 0 0 3px hsla(156.10000000000002, 60.8%, 70%, 0.45);
  }

  .lg\:shadow-outline-teal {
    box-shadow: 0 0 0 3px hsla(183.60000000000002, 63.3%, 69%, 0.45);
  }

  .lg\:shadow-outline-blue {
    box-shadow: 0 0 0 3px hsla(214.70000000000005, 97.8%, 82%, 0.45);
  }

  .lg\:shadow-outline-indigo {
    box-shadow: 0 0 0 3px hsla(225, 92.3%, 84.7%, 0.45);
  }

  .lg\:shadow-outline-purple {
    box-shadow: 0 0 0 3px hsla(250.60000000000002, 93.9%, 87.1%, 0.45);
  }

  .lg\:shadow-outline-pink {
    box-shadow: 0 0 0 3px hsla(327.4, 82.9%, 83.9%, 0.45);
  }

  .lg\:hover\:shadow-outline-white:hover {
    box-shadow: 0 0 0 3px hsla(0, 0%, 100%, 0.45);
  }

  .lg\:hover\:shadow-outline-black:hover {
    box-shadow: 0 0 0 3px hsla(0, 0%, 0%, 0.45);
  }

  .lg\:hover\:shadow-outline-gray:hover {
    box-shadow: 0 0 0 3px hsla(210, 2.4%, 83.9%, 0.45);
  }

  .lg\:hover\:shadow-outline-cool-gray:hover {
    box-shadow: 0 0 0 3px hsla(213, 26.3%, 85.1%, 0.45);
  }

  .lg\:hover\:shadow-outline-red:hover {
    box-shadow: 0 0 0 3px hsla(0, 82.9%, 83.9%, 0.45);
  }

  .lg\:hover\:shadow-outline-orange:hover {
    box-shadow: 0 0 0 3px hsla(24.399999999999977, 96.6%, 77.1%, 0.45);
  }

  .lg\:hover\:shadow-outline-yellow:hover {
    box-shadow: 0 0 0 3px hsla(47.39999999999998, 95.8%, 53.1%, 0.45);
  }

  .lg\:hover\:shadow-outline-green:hover {
    box-shadow: 0 0 0 3px hsla(156.10000000000002, 60.8%, 70%, 0.45);
  }

  .lg\:hover\:shadow-outline-teal:hover {
    box-shadow: 0 0 0 3px hsla(183.60000000000002, 63.3%, 69%, 0.45);
  }

  .lg\:hover\:shadow-outline-blue:hover {
    box-shadow: 0 0 0 3px hsla(214.70000000000005, 97.8%, 82%, 0.45);
  }

  .lg\:hover\:shadow-outline-indigo:hover {
    box-shadow: 0 0 0 3px hsla(225, 92.3%, 84.7%, 0.45);
  }

  .lg\:hover\:shadow-outline-purple:hover {
    box-shadow: 0 0 0 3px hsla(250.60000000000002, 93.9%, 87.1%, 0.45);
  }

  .lg\:hover\:shadow-outline-pink:hover {
    box-shadow: 0 0 0 3px hsla(327.4, 82.9%, 83.9%, 0.45);
  }

  .lg\:focus\:shadow-outline-white:focus {
    box-shadow: 0 0 0 3px hsla(0, 0%, 100%, 0.45);
  }

  .lg\:focus\:shadow-outline-black:focus {
    box-shadow: 0 0 0 3px hsla(0, 0%, 0%, 0.45);
  }

  .lg\:focus\:shadow-outline-gray:focus {
    box-shadow: 0 0 0 3px hsla(210, 2.4%, 83.9%, 0.45);
  }

  .lg\:focus\:shadow-outline-cool-gray:focus {
    box-shadow: 0 0 0 3px hsla(213, 26.3%, 85.1%, 0.45);
  }

  .lg\:focus\:shadow-outline-red:focus {
    box-shadow: 0 0 0 3px hsla(0, 82.9%, 83.9%, 0.45);
  }

  .lg\:focus\:shadow-outline-orange:focus {
    box-shadow: 0 0 0 3px hsla(24.399999999999977, 96.6%, 77.1%, 0.45);
  }

  .lg\:focus\:shadow-outline-yellow:focus {
    box-shadow: 0 0 0 3px hsla(47.39999999999998, 95.8%, 53.1%, 0.45);
  }

  .lg\:focus\:shadow-outline-green:focus {
    box-shadow: 0 0 0 3px hsla(156.10000000000002, 60.8%, 70%, 0.45);
  }

  .lg\:focus\:shadow-outline-teal:focus {
    box-shadow: 0 0 0 3px hsla(183.60000000000002, 63.3%, 69%, 0.45);
  }

  .lg\:focus\:shadow-outline-blue:focus {
    box-shadow: 0 0 0 3px hsla(214.70000000000005, 97.8%, 82%, 0.45);
  }

  .lg\:focus\:shadow-outline-indigo:focus {
    box-shadow: 0 0 0 3px hsla(225, 92.3%, 84.7%, 0.45);
  }

  .lg\:focus\:shadow-outline-purple:focus {
    box-shadow: 0 0 0 3px hsla(250.60000000000002, 93.9%, 87.1%, 0.45);
  }

  .lg\:focus\:shadow-outline-pink:focus {
    box-shadow: 0 0 0 3px hsla(327.4, 82.9%, 83.9%, 0.45);
  }
}

/* Существующие стили, которые мы оставляем без изменений */

@media (max-width: 768px) {
  .desktop-only {
    display: none !important;
  }

  .mobile-only {
    display: block !important;
  }

  .text-center {
    text-align: center;
  }

  .bg-gray-200 {
    background-color: #e5e7eb;
  }

  .p-4 {
    padding: 1rem;
  }

  .rounded-lg {
    border-radius: 0.5rem;
  }

  .mt-2 {
    margin-top: 0.5rem;
  }

  .mt-4 {
    margin-top: 1rem;
  }

  .shadow-md {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .focus\:outline-none:focus {
    outline: 0;
  }

  .focus\:shadow-outline-purple:focus {
    box-shadow: 0 0 0 3px rgba(129, 140, 248, 0.5);
  }

  /* Добавление стилей для AnimatedText на мобильных устройствах */

  .animated-text-container {
    background-color: #e5e7eb;
  }

  .animated-text {
    font-size: 0.875rem;
    animation: scroll-text 10s linear infinite;
  }
}

@media (min-width: 769px) {
  .mobile-only {
    display: none !important;
  }

  .desktop-only {
    display: block !important;
  }
}

/* Добавленные стили для адаптации */

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.lg\:flex-row {
  flex-direction: row;
}

.h-screen {
  height: 100vh;
}

.bg-gray-100 {
  background-color: #f4f5f7;
}

.w-full {
  width: 100%;
}

.p-4 {
  padding: 1rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.font-bold {
  font-weight: 700;
}

.text-sm {
  font-size: 0.875rem;
}

.text-gray-700 {
  color: #4a5568;
}

.bg-purple-600 {
  background-color: #805ad5;
}

.text-white {
  color: #ffffff;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.focus\:outline-none:focus {
  outline: 0;
}

.focus\:shadow-outline-purple:focus {
  box-shadow: 0 0 0 3px rgba(129, 140, 248, 0.5);
}

/* new */

/* CreateAccount.css */
.create-account-container {
  display: flex;
  max-width: 800px;
  margin: 50px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.image-section {
  flex: 1;
}

.image-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-section {
  flex: 1;
  padding: 40px;
  background-color: white;
}

.form-section h2 {
  margin-bottom: 20px;
}

.form-section input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.checkbox-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.checkbox-section input {
  margin-right: 10px;
}

.form-section button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #6c63ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-section button:hover {
  background-color: #5550c9;
}

.social-login {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.social-button {
  flex: 1;
  padding: 10px;
  margin: 5px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.footer-links a {
  color: #6c63ff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}


/* Login.css */
.login-container {
  display: flex;
  max-width: 800px;
  margin: 50px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.image-section {
  flex: 1;
}

.image-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-section {
  flex: 1;
  padding: 40px;
  background-color: white;
}

.form-section h2 {
  margin-bottom: 20px;
}

.form-section input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-section button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #6c63ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-section button:hover {
  background-color: #5550c9;
}

.social-login {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.social-button {
  flex: 1;
  padding: 10px;
  margin: 5px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.footer-links a {
  color: #6c63ff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}
